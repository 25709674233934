export default {
    id: '2020-04-23',
    year: 2020,
    month: 4,
    date: 23,
    title: `An In-Depth Exploration of the Array.concat() Function`,
    blog_url: `https://levelup.gitconnected.com/an-in-depth-exploration-of-the-array-concat-function-3672b134dc9c`,
    image_url: `https://images.unsplash.com/photo-1555949963-ff9fe0c870eb`,
    image_caption: `Photo by <a class="text--primary" href="https://unsplash.com/@hishahadat">Shahadat Rahman</a> on <a class="text--primary" href="https://unsplash.com/s/photos/javascript">Unsplash</a>`,
    contents: [
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `There have been quite a few useful functions that have been added to the Javascript `,
                },
                {
                    type: 'code',
                    content: `Array`,
                },
                {
                    type: 'text',
                    content: ` global object over the last few years that offer developers a wide variety of options when they are writing code that works with arrays. These functions offer a number of advantages, the most noteworthy of which is the fact that, while at one time in the past developers had to implement their own complex logic to perform various array operations, now the need for such homegrown implementations has been eliminated by all of these new functions. One of those useful functions that will be explored in this article is the `,
                },
                {
                    type: 'code_link',
                    url: `https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/concat`,
                    content: `concat()`,
                },
                {
                    type: 'text',
                    content: ` function.`,
                },
            ],
        },
        {
            type: 'title',
            content: `Function Overview`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The `,
                },
                {
                    type: 'code',
                    content: `concat()`,
                },
                {
                    type: 'text',
                    content: ` function provides the ability to merge a set of specified array items with the shallow copied contents of an array into a completely new array and return that new array upon function completion. As this function produces a completely new array, the original array and any existing arrays that are merged with the original array are left completely unaltered once the function has completed the operation. No precautions need to be taken before beginning the operation in order to ensure that the original array or any of the contained arrays are maintained.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The `,
                },
                {
                    type: 'code',
                    content: `concat()`,
                },
                {
                    type: 'text',
                    content: ` function is able to take an unlimited number of parameters with all of them being <span class="font-italic">optional</span>. Each specified parameter can be either an individual item or an array of any number of items. Each parameter is named `,
                },
                {
                    type: 'code',
                    content: `valueN`,
                },
                {
                    type: 'text',
                    content: ` with the `,
                },
                {
                    type: 'code',
                    content: `N`,
                },
                {
                    type: 'text',
                    content: ` replaced by the place number of the specific parameter. As all parameters to the `,
                },
                {
                    type: 'code',
                    content: `concat()`,
                },
                {
                    type: 'text',
                    content: ` function are optional, it is entirely unnecessary to provide any parameters, and in such cases there are no default values that are used by the function to make up for a lack of parameters.`,
                },
            ],
        },
        {
            type: 'title',
            content: `No Parameters`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `With the general function behavior covered, let’s take a look at some examples of how the `,
                },
                {
                    type: 'code',
                    content: `concat()`,
                },
                {
                    type: 'text',
                    content: ` function works in practice. The following example demonstrates the situation where no parameter values are specified:`,
                },
            ],
        },
        {
            type: 'code',
            content: `var array1 = [1, 2, 3];
var array2 = array1.concat();
// array2: [1, 2, 3]`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The `,
                },
                {
                    type: 'code',
                    content: `concat()`,
                },
                {
                    type: 'text',
                    content: ` function is called with no parameter values. The function shallow copies the contents of the original array and simply returns this copy as a completely new array once the function has completed. Using the `,
                },
                {
                    type: 'code',
                    content: `concat()`,
                },
                {
                    type: 'text',
                    content: ` function is this manner basically provides the ability to produce a shallow clone of the original array.`,
                },
            ],
        },
        {
            type: 'title',
            content: `Multiple Individual Parameters`,
        },
        {
            type: 'text',
            content: `The following example demonstrates the situation where multiple individual items are specified:`,
        },
        {
            type: 'code',
            content: `var array1 = [1, 2, 3];
var array2 = array1.concat('abc', 'def', 'ghi');
// array2: [1, 2, 3, 'abc', 'def', 'ghi']`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The `,
                },
                {
                    type: 'code',
                    content: `concat()`,
                },
                {
                    type: 'text',
                    content: ` function is called with three individual string parameter values. The function shallow copies the contents of the original array and concatenates each of the three string parameter values to the end of this copy in the order that they were specified before returning it as a completely new array upon function completion. This is the most simple way to use the `,
                },
                {
                    type: 'code',
                    content: `concat()`,
                },
                {
                    type: 'text',
                    content: ` function.`,
                },
            ],
        },
        {
            type: 'title',
            content: `Multiple Array Parameters`,
        },
        {
            type: 'text',
            content: `The following example demonstrates the situation where multiple arrays are specified:`,
        },
        {
            type: 'code',
            content: `var array1 = [1, 2, 3];
var array2 = ['abc', 'def', 'ghi'];
var array3 = [4, 5, 6];
var array4 = array1.concat(array2, array3);
// array4: [1, 2, 3, 'abc', 'def', 'ghi', 4, 5, 6]`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The `,
                },
                {
                    type: 'code',
                    content: `concat()`,
                },
                {
                    type: 'text',
                    content: ` function is called with two array parameter values. The function shallow copies the contents of the original array and concatenates a shallow copy of the contents of the first array to the end of the original array copy before concatenating a shallow copy of the contents of the second array. After completing the concatenation it returns a completely new array containing the contents of the original array and the contents of the two specified arrays. Essentially, when encountering an array parameter, the `,
                },
                {
                    type: 'code',
                    content: `concat()`,
                },
                {
                    type: 'text',
                    content: ` function treats the contents of that array in the same way that it would treat them as if they were specified in the same way as the previous example, handling each array parameter in succession in the order that they were specified.`,
                },
            ],
        },
        {
            type: 'title',
            content: `Multiple Nested Array Parameters`,
        },
        {
            type: 'text',
            content: `The following example demonstrates the situation where multiple nested arrays are specified:`,
        },
        {
            type: 'code',
            content: `var array1 = [1, 2, 3];
var array2 = [[4, 5], [6, [7, 8]]];
var array3 = [[[9, 10], 11], [12, 13]];
var array4 = array1.concat(array2, array3);
// array4: [1, 2, 3, [4, 5], [6, [7, 8]], [[9, 10], 11], [12, 13]]`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The `,
                },
                {
                    type: 'code',
                    content: `concat()`,
                },
                {
                    type: 'text',
                    content: ` function is called with two nested array parameter values. The function shallow copies the contents of the original array and concatenates a shallow copy of the contents of the first array to the end of the original array copy before concatenating a shallow copy of the contents of the second array. After completing the concatenation it returns a completely new array containing the contents of the original array and the contents of the two specified nested arrays.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `Unlike how it treats non-nested array parameter values, the `,
                },
                {
                    type: 'code',
                    content: `concat()`,
                },
                {
                    type: 'text',
                    content: ` function treats nested array parameter values differently. The `,
                },
                {
                    type: 'code',
                    content: `concat()`,
                },
                {
                    type: 'text',
                    content: ` function does not recurse into nested arrays and instead concatenates the contents of each nested array as they appear at the first level to the copy of the original array. For nested array contents, this means that they will be concatenated directly as arrays to the copy of the original array, effectively reducing the nested level of those nested arrays by one.`,
                },
            ],
        },
        {
            type: 'title',
            content: `Multiple Individual and Array Parameters`,
        },
        {
            type: 'text',
            content: `The following example demonstrates the situation where multiple individual items and arrays are specified:`,
        },
        {
            type: 'code',
            content: `var array1 = [1, 2, 3];
var array2 = [4, 5];
var array3 = [8, 9];
var array4 = array1.concat(array2, 6, 7, array3);
// array4: [1, 2, 3, 4, 5, 6, 7, 8, 9]`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The `,
                },
                {
                    type: 'code',
                    content: `concat()`,
                },
                {
                    type: 'text',
                    content: ` function is called with two individual number parameter values and two array parameter values. The function shallow copies the contents of the original array and concatenates a shallow copy of the first array to the end of the original array copy. After concatenating the two individual parameter values it concatenates a shallow copy of the second array before completing the concatenation and returning a completely new array. When working with the `,
                },
                {
                    type: 'code',
                    content: `concat()`,
                },
                {
                    type: 'text',
                    content: ` function, it is possible to mix individual item parameter values and array parameter values as necessary. Concern only needs to be paid to the order in which all of these parameter values are specified, as they will be concatenated to a shallow copy of the contents of the original array in the order that they are specified.`,
                },
            ],
        },
        {
            type: 'title',
            content: `Lessons Learned`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `There are a few lessons to take away from this article regarding the `,
                },
                {
                    type: 'code',
                    content: `concat()`,
                },
                {
                    type: 'text',
                    content: ` function. The first thing to keep in mind is that the `,
                },
                {
                    type: 'code',
                    content: `concat()`,
                },
                {
                    type: 'text',
                    content: ` function does not alter the original array in any way so there is no need to maintain its state before using the function. The only array that will be altered by the `,
                },
                {
                    type: 'code',
                    content: `concat()`,
                },
                {
                    type: 'text',
                    content: ` function is the completely new array that is returned once the function has completed.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The second and most important thing to keep in mind about the `,
                },
                {
                    type: 'code',
                    content: `concat()`,
                },
                {
                    type: 'text',
                    content: ` function is how it copies the items contained in the original array and any arrays that are specified for concatenation to the original array. For array items that are primitives like strings, numbers, and booleans, the `,
                },
                {
                    type: 'code',
                    content: `concat()`,
                },
                {
                    type: 'text',
                    content: ` function will copy their value into the new array. This means that any changes that are made to copied strings, numbers, or booleans in either the original array or parameter value arrays will not affect the new array. The following example shows this functionality in action:`,
                },
            ],
        },
        {
            type: 'code',
            content: `var array1 = [1, 2, 3];
var array2 = [4, 5, 6];
var array3 = array1.concat(array2);

// array3: [1, 2, 3, 4, 5, 6]

array1[0] = 7;
array1[1] = 8;
array1[2] = 9;
array2[0] = 10;
array2[1] = 11;
array2[2] = 12;

// array3: [1, 2, 3, 4, 5, 6]`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `Making changes to the primitive values in the original and concatenated arrays do not result in changes being made to the primitive values contained in the new array that was produced by the `,
                },
                {
                    type: 'code',
                    content: `concat()`,
                },
                {
                    type: 'text',
                    content: ` function.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `Unlike how it treats primitives, the `,
                },
                {
                    type: 'code',
                    content: `concat()`,
                },
                {
                    type: 'text',
                    content: ` function treats object references differently. For array items that are object references, the `,
                },
                {
                    type: 'code',
                    content: `concat()`,
                },
                {
                    type: 'text',
                    content: ` function will copy those references into the new array. This means that any object references that are copied from the original array or parameter value arrays into the new array will refer to the exact same object. If any changes are made to a referenced object, the changes will be seen in either the original array or parameter value arrays and the new array. The following example shows this functionality in action:`,
                },
            ],
        },
        {
            type: 'code',
            content: `var array1 = [{ a: 1, b: 2, c: 3 }];
var array2 = [{ d: 4, e: 5, f: 6 }];
var array3 = array1.concat(array2);

// array3: [{ a: 1, b: 2, c: 3 }, { d: 4, e: 5, f: 6 }]

array1[0].a = 7;
array1[0].b = 8;
array1[0].c = 9;
array2[0].d = 10;
array2[0].e = 11;
array2[0].f = 12;

// array3: [{ a: 7, b: 8, c: 9 }, { d: 10, e: 11, f: 12 }]`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `Making changes to the objects that are referenced in the original array or parameter value array will also result in those changes being reflected in the new array that was produced by the `,
                },
                {
                    type: 'code',
                    content: `concat()`,
                },
                {
                    type: 'text',
                    content: ` function. Both the changed array and the new array will reflect any referenced object changes being made.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The final thing to keep in mind about the `,
                },
                {
                    type: 'code',
                    content: `concat()`,
                },
                {
                    type: 'text',
                    content: ` function is that it serves as one of a few options available on the Javascript `,
                },
                {
                    type: 'code',
                    content: `Array`,
                },
                {
                    type: 'text',
                    content: ` global object when it is necessary to make a copy of an existing array. As the `,
                },
                {
                    type: 'code',
                    content: `concat()`,
                },
                {
                    type: 'text',
                    content: ` function produces a completely new array from the original array, simply providing no parameters when calling this function can effect this specific behavior. Just remember that any object references will be copied to the new array as well, so the `,
                },
                {
                    type: 'code',
                    content: `concat()`,
                },
                {
                    type: 'text',
                    content: ` function is only a viable option when shallow copy functionality is required and not deep copy functionality.`,
                },
            ],
        },
        {
            type: 'title',
            content: `Conclusions`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `Thank you very much for reading this article. I hope that this exploration of the `,
                },
                {
                    type: 'code',
                    content: `concat()`,
                },
                {
                    type: 'text',
                    content: ` function on the Javascript `,
                },
                {
                    type: 'code',
                    content: `Array`,
                },
                {
                    type: 'text',
                    content: ` global object has been informative and I hope that after gaining some knowledge about it you will be able to put it to good use in your own code. I encourage you to consult the resource link below for all information pertaining to the `,
                },
                {
                    type: 'code',
                    content: `concat()`,
                },
                {
                    type: 'text',
                    content: ` function if you still have any further questions about how it works. Please stop back in the future for more articles about interesting and useful functions that can be found on the Javascript `,
                },
                {
                    type: 'code',
                    content: `Array`,
                },
                {
                    type: 'text',
                    content: ` global object.`,
                },
            ],
        },
    ],
}