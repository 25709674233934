<template>
    <v-select
        :append-icon="icons.menuDown"
        class="ma-2 white"
        color="light-blue darken-4"
        dense
        :disabled="disabled"
        hide-details
        item-color="light-blue darken-4"
        :items="monthOptions"
        outlined
        v-model="trackedMonth"
    />
</template>

<script>
import { mdiMenuDown } from '@mdi/js'

export default {
    name: 'BlogMonthSelect',
    props: [
        'disabled',
        'monthOptions',
        'selectedMonth',
    ],
    data() {
        return {
            icons: {
                menuDown: mdiMenuDown,
            },
        }
    },
    computed: {
        trackedMonth: {
            get() { return this.selectedMonth },
            set(newMonth) { this.$emit('input', newMonth) },
        },
    },
}
</script>

<style scoped>

</style>