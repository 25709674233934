export default {
    id: '2019-12-20',
    year: 2019,
    month: 12,
    date: 20,
    title: `Developer Story: Single Database Interface`,
    blog_url: `https://medium.com/swlh/developer-story-single-database-interface-442dd6804424`,
    image_url: `https://images.unsplash.com/photo-1523961131990-5ea7c61b2107`,
    image_caption: `Photo by <a class="text--primary" href="https://unsplash.com/@fabioha">fabio</a> on <a class="text--primary" href="https://unsplash.com/s/photos/database">Unsplash</a>`,
    contents: [
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `In my `,
                },
                {
                    type: 'internal_link',
                    year: 2019,
                    month: 12,
                    date: 17,
                    content: `previous`,
                },
                {
                    type: 'text',
                    content: ` developer story entry I discussed how to create a single source of truth for all configuration data in a NodeJS application. That single source of truth contained a validated and reconstructed version of the `,
                },
                {
                    type: 'code',
                    content: `process.env`,
                },
                {
                    type: 'text',
                    content: ` global variable available in all NodeJS applications. Although this configuration data could be used for any purpose anywhere in the application, a primary use of this data I had mentioned was initializing a connection to the database. Just like I explained how to make that configuration data available as a single global object in my previous entry, in this developer story entry I will discuss how to provide a single database interface that can be retrieved and used anywhere in the application.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `In `,
                },
                {
                    type: 'internal_link',
                    year: 2019,
                    month: 12,
                    date: 13,
                    content: `another previous`,
                },
                {
                    type: 'text',
                    content: ` developer story entry I had discussed my thoughts on how best to design and construct a proper RESTful server module structure. In that entry I had mentioned that I prefer to keep all of the interfaces for each individual database table or collection housed in a single <span class="font-italic">db</span> module. The structure of this module has the following folder structure:`,
                },
            ],
        },
        {
            type: 'code',
            title: `db/`,
            content: `
database.js
index.js
table_or_collection_a.js
table_or_collection_b.js
table_or_collection_c.js`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The single direct interface to the database would be implemented in `,
                },
                {
                    type: 'code',
                    content: `database.js`,
                },
                {
                    type: 'text',
                    content: ` while the interfaces for each individual table or collection inside that database would be implemented in `,
                },
                {
                    type: 'code',
                    content: `table_or_collection_*.js`,
                },
                {
                    type: 'text',
                    content: `. As `,
                },
                {
                    type: 'code',
                    content: `database.js`,
                },
                {
                    type: 'text',
                    content: ` provides the single direct interface to the database, it would be the only sub-module in the <span class="font-italic">db</span> module that would directly interact with the database. As such the individual `,
                },
                {
                    type: 'code',
                    content: `table_or_collection_*.js`,
                },
                {
                    type: 'text',
                    content: ` sub-modules would use it to provide their own particular functionality for interacting with their corresponding database table or collection. So the db module basically has an upside-down pyramid shape, where all database interactions throughout the entire application flow through the `,
                },
                {
                    type: 'code',
                    content: `table_or_collection_*.js`,
                },
                {
                    type: 'text',
                    content: ` sub-modules which then make direct calls to the `,
                },
                {
                    type: 'code',
                    content: `database.js`,
                },
                {
                    type: 'text',
                    content: ` sub-module, which servers as the single point-of-contact to the underlying database.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `Since I prefer MongoDB as the backing database for all of my NodeJS applications, the following is how the single database interface contained in the `,
                },
                {
                    type: 'code',
                    content: `database.js`,
                },
                {
                    type: 'text',
                    content: ` sub-module would be implemented to connect to an instance of MongoDB:`,
                },
            ],
        },
        {
            type: 'code',
            title: `db/database.js`,
            content: `
const env = require('../env').retrieve()
const err = require('../util/error')
const logger = require('../log').app
const MongoClient = require('mongodb').MongoClient

let db

module.exports.connectDB = async () => {
    const FUNCTION_NAME = 'db.database.connectDB'
    try {
        logger.info(\`Connecting to database\`)

        let dbURL
        if (env.DB_HOSTS.length > 1) {
            dbURL = \`mongodb://\${env.DB_HOSTS.map((host, index) => \`\${host}:\${env.DB_PORTS[index]}\`).join(',')}\`
            dbOptions.auth = {
                user: env.DB_USER,
                password: env.DB_PASSWORD,
            }
            dbOptions.authSource = 'admin'
            dbOptions.replicaSet = env.RS_NAME
        } else {
            dbURL = \`mongodb+srv://\${env.DB_USER}:\${env.DB_PASSWORD}@\${env.DB_HOSTS[0]}\`
        }

        try {
            client = await MongoClient.connect(dbURL, dbOptions)
        } catch(e) {
            throw err.createError(e, FUNCTION_NAME)
        }
        if (!client) throw err.createError('DB_CONNECTION_FAILED', FUNCTION_NAME)
        db = client.db(env.DB_NAME)

        return db
    } catch(e) {
        const error = err.createError(e, FUNCTION_NAME)
        logger.error(error.display)
        throw error
    }
}

module.exports.retrieveDB = () => {
    const FUNCTION_NAME = 'db.database.retrieveDB'
    try {
        if (!db) throw err.createError('DB_CONNECTION_NOT_ESTABLISHED', FUNCTION_NAME)

        return db
    } catch(e) {
        const error = err.createError(e, FUNCTION_NAME)
        logger.error(error.display)
        throw error
    }
}`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `There are a number of things to note in this code sample, some of which I have discussed in previous developer story entries and some of which I will cover in future story entries. At the top you will notice the initialization of the `,
                },
                {
                    type: 'code',
                    content: `env`,
                },
                {
                    type: 'text',
                    content: ` variable, which I discussed in my `,
                },
                {
                    type: 'internal_link',
                    year: 2019,
                    month: 12,
                    date: 17,
                    content: `previous`,
                },
                {
                    type: 'text',
                    content: ` story entry about how to deliver a single source of truth for all configuration data in a NodeJS application. Also, you will notice the initialization of the `,
                },
                {
                    type: 'code',
                    content: `logger`,
                },
                {
                    type: 'text',
                    content: ` variable, which I discussed in `,
                },
                {
                    type: 'internal_link',
                    year: 2019,
                    month: 12,
                    date: 9,
                    content: `another`,
                },
                {
                    type: 'text',
                    content: ` earlier story entry about my personal strategy for providing logging functionality in a NodeJS application. The `,
                },
                {
                    type: 'code',
                    content: `error`,
                },
                {
                    type: 'text',
                    content: ` variable is something that I will discuss in a future story entry about my strategy for error handling in NodeJS applications. Apart from those three variables, the final one to discuss is the `,
                },
                {
                    type: 'code',
                    content: `db`,
                },
                {
                    type: 'text',
                    content: ` variable, which is used here in both the `,
                },
                {
                    type: 'code',
                    content: `connectDB()`,
                },
                {
                    type: 'text',
                    content: ` and `,
                },
                {
                    type: 'code',
                    content: `retrieveDB()`,
                },
                {
                    type: 'text',
                    content: ` functions.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `Similar to how the module behind the `,
                },
                {
                    type: 'code',
                    content: `env`,
                },
                {
                    type: 'text',
                    content: ` variable provides a function for initializing the resulting object and another function for retrieving that object, this module provides the `,
                },
                {
                    type: 'code',
                    content: `connectDB()`,
                },
                {
                    type: 'text',
                    content: ` function for initializing the resulting database object. It does this by retrieving all of the necessary database configuration data from the `,
                },
                {
                    type: 'code',
                    content: `env`,
                },
                {
                    type: 'text',
                    content: ` variable and using it to connect to the database through the use of the `,
                },
                {
                    type: 'code',
                    content: `MongoClient`,
                },
                {
                    type: 'text',
                    content: ` object imported at the top of the code sample. If this connection process is successful then the db variable is initialized properly so that when the `,
                },
                {
                    type: 'code',
                    content: `retrieveDB()`,
                },
                {
                    type: 'text',
                    content: ` function is subsequently called it will return the database object. Just like with the `,
                },
                {
                    type: 'code',
                    content: `env`,
                },
                {
                    type: 'text',
                    content: ` variable, if an attempt is made to retrieve the database object before it has been initialized then an error will be thrown.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The final thing to note in this code sample is the logic for constructing the database URL. The reason why there are two separate flavors of database URL is because there are two configurations of MongoDB that I typically interact with during the development and production phases of my NodeJS applications. The first and more complicated flavor being constructed here is for connecting to all of the individual nodes in a replica set directly, which would usually be done in a development context where a manual database cluster is being used. The second flavor being constructed here is for connecting to an Atlas cluster, which is still a replica set but can be connected to using a much simpler URL with the very important distinction of having the `,
                },
                {
                    type: 'code',
                    content: `mongodb+srv://`,
                },
                {
                    type: 'text',
                    content: ` prefix. Typically an Atlas cluster would only be used in a production context where the entire database structure has been fully architected and built out.`,
                },
            ],
        },
        {
            type: 'text',
            content: `In order to create the global database object and initialize the database connection before the global database object is retrieved anywhere in the application the following code appears in the startup script for all of my NodeJS applications:`,
        },
        {
            type: 'code',
            title: `server.js`,
            content: `
async function run() {
    logger.info(\`Initializing application\`)

    const env = require('./env')
    await env.initialize()

    const db = require('./db/database')
    await db.connectDB()
    ...
    })
}`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `Immediately after the global configuration object has been initialized the global database object is initialized. Since the <span class="font-italic">db</span> module requires data contained in the global configuration object, obviously that object must be initialized first. But due to the fact that the global database object is equally important, it must be initialized immediately after the global configuration object. From that point forward an object for interacting directly with the database will be available through the `,
                },
                {
                    type: 'code',
                    content: `db`,
                },
                {
                    type: 'text',
                    content: ` variable when the following line of code is placed at the top of any file in any module in the entire application:`,
                },
            ],
        },
        {
            type: 'code',
            content: `const db = require('./database').retrieveDB()`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `Of course, as I said above, the only place where this line of code should appear is at the top of each of the `,
                },
                {
                    type: 'code',
                    content: `table_or_collection_*.js`,
                },
                {
                    type: 'text',
                    content: ` sub-modules inside of the <span class="font-italic">db</span> module, since only the <span class="font-italic">db</span> module should ever be interacting directly with the database. But if you have different ideas about how to construct your application logic and want to interact directly with the database from other modules in your application, you would only need to adjust the string inside the `,
                },
                {
                    type: 'code',
                    content: `require()`,
                },
                {
                    type: 'text',
                    content: ` call in this line of code appropriately to make it work in places outside the <span class="font-italic">db</span> module.`,
                },
            ],
        },
        {
            type: 'text',
            content: `And now you have a better idea of the way in which I provide a unified database interface to all of my NodeJS applications. Abstracting the point-of-contact between your application and other systems into a single module like this is a great way to not only organize your code for general development but also to make it easier for when errors occur. Hunting down the source of an error is much easier when all of the logic for an external interface like this is bundled into a nice little code package as I have presented above. I really hope that sharing my strategy for providing a single point-of-contact between your NodeJS application and other systems is helpful in your own development process. Please stay tuned for more developer story entries as I continue to make further progress on my personal project.`,
        },
    ],
}