<template>
    <v-btn
        class="white--text ma-4"
        @click="toResume"
        color="light-blue darken-4"
    >
        <v-icon class="blue--text text--lighten-3">{{ icons.cardAccountDetails }}</v-icon>
        <span class="ml-2">View Resume</span>
    </v-btn>
</template>

<script>
import { mdiCardAccountDetails } from '@mdi/js'

export default {
    name: 'HeroResumeButton',
    data() {
        return {
            icons: {
                cardAccountDetails: mdiCardAccountDetails,
            },
        }
    },
    methods: {
        toResume() {
            this.$vuetify.goTo('#resume')
        },
    },
}
</script>

<style scoped>

</style>