<template>
    <v-btn
        class="white--text ma-4"
        color="light-blue darken-4"
        to="/blogs"
    >
        <v-icon class="blue--text text--lighten-3">{{ icons.newspaper }}</v-icon>
        <span class="ml-2">View Blog</span>
    </v-btn>
</template>

<script>
import { mdiNewspaper } from '@mdi/js'

export default {
    name: 'BlogBlogButton',
    data() {
        return {
            icons: {
                newspaper: mdiNewspaper,
            },
        }
    },
}
</script>

<style scoped>

</style>