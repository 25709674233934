<template>
    <span>
        <home-hero />
        <home-resume />
        <home-blog />
        <home-reading />
    </span>
</template>

<script>
import HomeBlog from '../components/home/blog/HomeBlog'
import HomeHero from '../components/home/hero/HomeHero'
import HomeReading from '../components/home/reading/HomeReading'
import HomeResume from '../components/home/resume/HomeResume'

export default {
    name: 'Home',
    components: {
        HomeBlog,
        HomeHero,
        HomeReading,
        HomeResume,
    },
}
</script>

<style scoped>

</style>