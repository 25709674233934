export default {
    id: '2020-01-15',
    year: 2020,
    month: 1,
    date: 15,
    title: `An SE’s Perspective: The Elements of User Experience (Part 2)`,
    blog_url: `https://keithvictordawson.medium.com/an-ses-perspective-the-elements-of-user-experience-part-2-6efe5629b78a`,
    image_url: `https://miro.medium.com/max/700/1*De5Lfps7YDWxi1LBTAwGyw.png`,
    image_caption: `The Elements of User Experience, Jesse James Garrett`,
    contents: [
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `In this series I read through each chapter of the groundbreaking UX design book `,
                },
                {
                    type: 'italic_link',
                    url: `http://www.jjg.net/elements/`,
                    content: `The Elements of User Experience`,
                },
                {
                    type: 'text',
                    content: ` and discuss the content from my perspective as a software engineer. The author of this book, Jesse James Garrett, wrote it in the hope that it would help UX designers or anyone else interested in UX design to understand the decisions that UX practitioners make during the course of their work. Unlike the first edition, this second edition of the book is not so heavily focused on UX as it pertains to web development, but rather written in such a way as to apply to any context in which UX plays an important role. Please keep in mind that my perspective comes from the direction not of a UX designer but of a software engineer who strives to wield the principles of UX as a tool to inform all of the software development decisions that I make and enhance all of the software that I create. As a result, all discussion about this book will be in that context.`,
                },
            ],
        },
        {
            type: 'spacer',
        },
        {
            type: 'highlight',
            content: `The user experience design process is all about ensuring that no aspect of the user’s experience with your product happens without your conscious, explicit intent. This means taking into account every possibility of every action the user is likely to take and understanding the user’s expectations at every step of the way through that process. It sounds like a big job, and in some ways it is. But by breaking the job of crafting the user experience down into its component elements, we can better understand the task as a whole.`,
        },
        {
            type: 'text',
            content: `The title of the second chapter is <span class="font-italic">Meet the Elements</span> and, as the title suggests, the author dedicates this chapter to presenting a high-level overview of the five planes that make up his framework for discussing UX problems and the tools that UX designers use to solve them. The five planes that make up this framework are <span class="font-italic">strategy</span>, <span class="font-italic">scope</span>, <span class="font-italic">structure</span>, <span class="font-italic">skeleton</span>, and <span class="font-italic">surface</span>. The legendary diagram showing their relation to each other, and the original idea from which this entire book was conceived, is the following:`,
        },
        {
            type: 'image',
            url: `https://miro.medium.com/max/396/1*8F3Sby8p9xMrSAIBCcA3vQ.png`,
            caption: `The Elements of User Experience, Jesse James Garrett`,
        },
        {
            type: 'quote',
            content: `By breaking the job of crafting the user experience down into its component elements, we can better understand the task as a whole.`,
        },
        {
            type: 'text',
            content: `This final line in the opening passage for this chapter is wisdom that I feel a strong connection to as a software engineer. The reason why this quote speaks to me so much as a software engineer is because this approach to solving user experience problems in many ways parallels the primary way in which software engineers are taught to solve software engineering problems.`,
        },
        {
            type: 'text',
            content: `One of the most important skills that I learned in my time at university becoming a software engineer and still employ to this day on every software project is the ability to take large and complex software problems and break them down into small, manageable tasks. It is probably one of the most challenging and terrifying things about software engineering because it requires you to constantly second-guess all of the decisions that you make about how best to approach and solve a software problem. Always having that self-doubt in the back of your mind can generate a lot of stress if you do not develop the skills necessary to manage it properly. In my opinion, having this ability to effectively and efficiently break down large and complex software problems is one of the main differentiators between someone who succeeds at developing high-quality software and someone who fails at it.`,
        },
        {
            type: 'text',
            content: `Although I personally am not a UX designer by trade, I do understand the UX design process and I have a wide range of personal and professional experience employing many of the various tools that comprise UX design. I believe that being able to break down complex UX problems into manageable tasks is a core ability of any successful UX designer and it is probably a skill that is lacking in those who are unable to succeed at UX design. Reflecting on my history with UX design, I think that the similarity between the UX design process and the software engineering process is one of the main reasons why I have come to respect it as much as I have and value its role in any effort to develop high-quality software.`,
        },
        {
            type: 'title',
            content: `The Five Planes`,
        },
        {
            type: 'text',
            content: `In order to provide a very brief introduction of each of the five planes, the author begins with an example of a typical online experience that we are all familiar with: shopping for something online. Since this is an experience that virtually everyone in the world has had and everyone has their own mental picture of how a typical online shopping website looks, it is a great example from which to work in order to begin explaining the role that each of the five planes serves in building such an application.`,
        },
        {
            type: 'subtitle',
            content: `The Surface Plane`,
        },
        {
            type: 'quote',
            content: `On the <span class="font-weight-bold">surface</span> you see a series of Web pages, made up of images and text. Some of these images are things you can click on, performing some sort of function. Some of these images are just illustrations.`,
        },
        {
            type: 'text',
            content: `At the top of the five planes is the surface plane, which deals with everything that the user will see and experience while using the application, from the images and text that fill it to the colors and typography that give it character. The surface plane focuses all of its effort on defining the tangible parts of the application with which the user will connect and come to associate with the particular application in which they are implemented.`,
        },
        {
            type: 'subtitle',
            content: `The Skeleton Plane`,
        },
        {
            type: 'quote',
            content: `Beneath that surface is the <span class="font-weight-bold">skeleton</span> of the site: the placement of buttons, controls, photos, and blocks of text. The skeleton is designed to optimize the arrangement of these elements for maximum effect and efficiency.`,
        },
        {
            type: 'text',
            content: `Just below the surface plane is the skeleton plane. All of the content that fills an application cannot simply be placed without considering how it will be perceived by the user. That content needs to be considered from the dual perspectives of what effect it will have on the user and how efficiently it will be conveyed to the user. The skeleton plane focuses on arranging all of the application content in the most optimal way possible in order to maximize these concerns.`,
        },
        {
            type: 'subtitle',
            content: `The Structure Plane`,
        },
        {
            type: 'quote',
            content: `The skeleton is a concrete expression of the more abstract <span class="font-weight-bold">structure</span> of the site. The skeleton might define the placement of the interface elements; the structure would define how users got to that page and where they could go when they were finished there. The skeleton might define the arrangement of navigational elements allowing the users to browse categories of products; the structure would define what those categories were.`,
        },
        {
            type: 'text',
            content: `In the middle of the five planes, just below the skeleton plane, is the structure plane. The structure plane maintains an even higher-level focus than the skeleton plane by concerning itself with the interconnected nature of all of the different parts of an application as well as the content within those parts. This means ensuring that the user can navigate through the application efficiently. It also means that the information presented throughout the application is structured in such a way that it makes the most logical sense to the user.`,
        },
        {
            type: 'subtitle',
            content: `The Scope Plane`,
        },
        {
            type: 'quote',
            content: `The structure defines the way in which the various features and functions of the site fit together. Just what those features and functions are constitutes the <span class="font-weight-bold">scope</span> of the site.`,
        },
        {
            type: 'text',
            content: `Every software engineer receives training on how to document all of the requirements for an application during the course of their education. While creating a requirements document for an application, you are in effect defining the bounds or scope of what functionality that application will provide to the user. In doing so, you are performing the work that is the focus of the scope plane.`,
        },
        {
            type: 'subtitle',
            content: `The Strategy Plane`,
        },
        {
            type: 'quote',
            content: `The scope is fundamentally determined by the <span class="font-weight-bold">strategy</span> of the site. This strategy incorporates not only what the people running the site want to get out of it but what the users want to get out of the site as well.`,
        },
        {
            type: 'text',
            content: `At the beginning of development of a new application, the highest-level questions need to be answered about what purpose that application will serve for the person, team, or company developing the application as well as what purpose it will serve for the users of that application. What is the impetus for developing this solution? How will it serve the interests of both sets of parties? At the bottom of the five planes is the strategy plane, which is concerned with answering all of these questions about the application and defining all of the work that will be performed at each of the four planes above it.`,
        },
        {
            type: 'title',
            content: `Building from Bottom to Top`,
        },
        {
            type: 'image',
            url: `https://miro.medium.com/max/407/1*rxhWb87Uq4WSIL6ShwPoyA.png`,
            caption: `Simplified version of The Elements of User Experience, Jesse James Garrett`,
        },
        {
            type: 'text',
            content: `When looking at the simplified version of the <span class="font-italic">Elements of User Experience</span> diagram above, one of the first things that will probably draw the attention of your eye is the scale on the right side of the diagram indicating a scale of concreteness to abstraction on which the five planes exist. The author explains what this scale means in the context of each plane:`,
        },
        {
            type: 'quote',
            content: `On each plane, the issues we must deal with become a little less abstract and a little more concrete. On the lowest plane, we are not concerned with the final shape of the site, product, or service at all — we only care about how the site will fit into our strategy (while meeting the needs of our users). On the highest plane, we are only concerned with the most concrete details of the appearance of the product. Plane by plane, the decisions we have to make become a little more specific and involve finer levels of detail.`,
        },
        {
            type: 'text',
            content: `Even though at first glance, at either the full diagram at the beginning of this chapter or the simplified version at the beginning of this section, it would seem that work progresses from the top plane down, it is in fact the opposite and work progresses from the bottom plane up. The reason for this is that higher level decisions about the product need to be considered and made before work can begin on digging deep down into all of the details that will ultimately shape the final version of the product. If you think about the diagram as having a pyramid shape rather than the rectangular shape in the diagram, it makes a whole lot more sense why work progresses from the bottom of the diagram towards the top. The strategy plane provides a necessary foundation upon which all other decisions will be made and work will be performed. Without that solid foundation to support all of the future efforts on the product, a clear direction for all of that effort would not exist.`,
        },
        {
            type: 'text',
            content: `This relationship between the strategy plane and the rest of the planes above it is actually true for all of the planes in the diagram below the surface plane. All decisions made and work performed at any particular plane are based on the decisions and work in the planes below it, and serve to support the decisions and work in the planes above it. As such, there is what the author refers to as a ripple effect felt by planes towards the top of the diagram as decisions are made in planes towards the bottom of the diagram. All of the options that are available at any given plane are constrained by the decisions made in all of the planes below it.`,
        },
        {
            type: 'text',
            content: `One conclusion that may be drawn from this layered relationship between the planes is that all of the decisions need to be made and work needs to be completed on one plane before those efforts can begin on the plane above it. The author explains why this is not the case and offers advice on an alternative approach to product development:`,
        },
        {
            type: 'quote',
            content: `Requiring work on each plane to finish before work on the next can start leads to unsatisfactory results for you and your users. A better approach is to have work on each plane finish before work on the next can finish.`,
        },
        {
            type: 'text',
            content: `The major reason why the latter approach is much better than the former approach in this explanation is because it may become clear while working on a given plane that some of the decisions made at a plane below it were not quite right and need to be reconsidered. A certain level of flexibility needs to be afforded while working through each of the planes and this approach provides that flexibility. Remaining flexible while working up through the planes keeps open the possibility of returning to previous decisions and changing the outcome, which thereby allows for options that were previously out of the bounds of the decision tree to once again become available.`,
        },
        {
            type: 'text',
            content: `This flexible approach to executing the UX design process is quite similar to the way in which the software development process should be executed. Consider the situation of creating a new web application where there is a database, a server, and a client. My personal approach to completing such a task is to begin working on the database first and working up towards the client from there. In such a situation, making the assumption that once work has been completed on the database that it will never change again would be counterproductive and would inevitably result in a lot of unnecessary difficulties in the future. While working on the server or client, it may become apparent that some alterations need to be made to the database for any number of reasons. Providing room for flexibility in the development of such a system makes these changes easy to account for and ensures that the project can ultimately remain on track.`,
        },
        {
            type: 'title',
            content: `A Basic Duality`,
        },
        {
            type: 'text',
            content: `One of the major difficulties that one will encounter when entering the UX design space is coping with all of the industry jargon and seemingly overlapping or identical terms for various disciplines and techniques that are utilized throughout the UX design process. To make matters worse, many people use these terms incorrectly out of confusion or because they simply do not know the actual meaning of them, which only serves to exacerbate the situation further. The author acknowledges this issue and provides an explanation for how it came about in the first place:`,
        },
        {
            type: 'quote',
            content: `When the Web user experience community started to form, its members spoke two different languages. One group saw every problem as an application design problem, and applied problem-solving approaches from the traditional desktop and mainframe software worlds. The other group saw the Web in terms of information distribution and retrieval, and applied problem-solving approaches from the traditional worlds of publishing, media, and information science.`,
        },
        {
            type: 'text',
            content: `Since the UX design community formed with people coming from a wide variety of backgrounds, the approaches that they took to designing for the web were neither compatible with each other nor were they truly compatible with the web either. There was, however, one thing that all of these people had in common, which was that their approach to UX design could be divided into one of two categories based on the type of product that their traditional line of work sought to create. One category represented the group whose ultimate goal was to create a product that centered around providing functionality to the user. The other category represented the group whose ultimate goal was to create a product that served as an information medium.`,
        },
        {
            type: 'text',
            content: `In order to account for these two distinct sides of the UX design discipline as a whole, the author enhanced the basic version of his diagram from above to be the following:`,
        },
        {
            type: 'image',
            url: `https://miro.medium.com/max/395/1*VEPI0bN-vY7wRlV7TR1ctQ.png`,
            caption: `Bisected version of The Elements of User Experience, Jesse James Garrett`,
        },
        {
            type: 'text',
            content: `This bisected version of the simplified version of the <span class="font-italic">Elements of User Experience</span> diagram accounts for both sides of the UX design discipline, while also emphasizing the fact that each of the five planes is concerned with fulfilling the goals of both sides of the product outcome divide at their respective levels of focus within the overall UX design process. The author provides a clear explanation of what each of these sides means in the context of creating a product:`,
        },
        {
            type: 'quote',
            content: `On the functionality side, we are mainly concerned with <span class="font-weight-bold">tasks</span> — the steps involved in a process and how people think about completing them. Here we consider the product as a tool or set of tools that the user employs to accomplish one or more tasks. On the opposite side, our concern is what <span class="font-weight-bold">information</span> the product offers and what it means to our users. Creating an information-rich user experience is about enabling people to find, absorb, and make sense of the information we provide.`,
        },
        {
            type: 'title',
            content: `The Elements of User Experience`,
        },
        {
            type: 'text',
            content: `With the basic scaffolding of the five planes assembled and the division between the two product outcomes defined, it is finally time to provide some guidance on which industry terms fall within the purview of which planes. The <span class="font-italic">Elements of User Experience</span> diagram in its complete form is as follows:`,
        },
        {
            type: 'image',
            url: `https://miro.medium.com/max/396/1*8F3Sby8p9xMrSAIBCcA3vQ.png`,
            caption: `The Elements of User Experience, Jesse James Garrett`,
        },
        {
            type: 'subtitle',
            content: `The Strategy Plane`,
        },
        {
            type: 'quote',
            content: `The same strategic concerns come into play for both functionality-oriented products and information-oriented resources. <span class="font-weight-bold">User needs</span> are the goals for the site that come from outside our organization — specifically from the people who will use our site. We must understand what our audience wants from us and how that fits in with other goals they have. Balanced against user needs are our own objectives for the site. These <span class="font-weight-bold">product objectives</span> can be business goals or other kinds of goals.`,
        },
        {
            type: 'text',
            content: `The strategy plane is defined by two chief concerns, both of which fall on both sides of the product outcome divide. Both of these concerns are goals, but they are differentiated based on their origin. User needs are, as the term clearly suggests, goals that arise from the users of the product. Half of the equation that makes up the strategy plane is figuring out what exactly the users being served by your product want to get out of it and also what they don’t want to get out of it. It is not, however, simply about figuring out those goals in the context of the product, but also requires an understanding of how those goals fit into other goals possessed by the users. An application cannot and should not try to do everything for its users. Determining where to draw the line presents a unique challenge when it comes to truly knowing what your users want the product to do for them.`,
        },
        {
            type: 'text',
            content: `Product objectives represent the other half of the equation that makes up the strategy plane. These are goals that are presented by yourself, your team, and your organization and they mirror user needs in terms of how you should come to understand them. Not only should you understand these goals in terms of what the product will do to serve you and your company, but you should understand how the product fits into the overall strategy that the company follows, outside of the individual product being developed. This is also a challenging proposition because it requires many decisions to be made and intentions to be articulated that do not necessarily at first glance correlate to the product being developed. These are concerns that need to be addressed, however, to ensure that the strategy for the product fits into all of the other that are undertaken by the company as a whole.`,
        },
        {
            type: 'subtitle',
            content: `The Scope Plane`,
        },
        {
            type: 'quote',
            content: `On the functionality side, the strategy is translated into scope through the creation of <span class="font-weight-bold">functional specifications</span>: a detailed description of the “feature set” of the product. On the information side, scope takes the form of <span class="font-weight-bold">content requirements</span>: a description of the various content elements that will be required.`,
        },
        {
            type: 'text',
            content: `Whereas the strategy plane was dominated by two concerns that both straddled the product outcome divide, the scope plane is dominated by two concerns that each focus on one side of that divide. Functional specifications represent the functionality-oriented concerns of the scope plane as they serve to provide a clear picture of all of the features that will be contained within the product. Content requirements represent the information-oriented concerns of the scope plane as they serve to provide a clear picture of all of the images and text that will be contained within the product.`,
        },
        {
            type: 'subtitle',
            content: `The Structure Plane`,
        },
        {
            type: 'quote',
            content: `The scope is given structure on the functionality side through <span class="font-weight-bold">interaction design</span>, in which we define how the system behaves in response to the user. For information resources, the structure is the <span class="font-weight-bold">information architecture</span>: the arrangement of content elements to facilitate human understanding.`,
        },
        {
            type: 'text',
            content: `Just like in the scope plane, the structure plane is dominated by two concerns that each focus on one side of the product outcome divide. Interaction design represents the functionality-oriented concerns of the structure plane by determining how the product will respond to the user as they use the various features offered within it. Information architecture represents the information-oriented concerns of the structure plane by determining how all of the content in the product will be presented in order to maximize the understanding of that content by the user.`,
        },
        {
            type: 'subtitle',
            content: `The Skeleton Plane`,
        },
        {
            type: 'quote',
            content: `The skeleton plane breaks down into three components. On both sides, we must address <span class="font-weight-bold">information design</span>: the presentation of information in a way that facilitates understanding. For functionality-oriented products, the skeleton also includes <span class="font-weight-bold">interface design</span>, or arranging interface elements to enable users to interact with the functionality of the system. The interface for an information resource is its <span class="font-weight-bold">navigation design</span>: the set of screen elements that allow the user to move through the information architecture.`,
        },
        {
            type: 'text',
            content: `The skeleton plane is rather complex in that it represents the intersection of three closely related design disciplines that are oftentimes confused with one another. The first is interface design, which lies entirely on the functionality-oriented side of the product outcome divide. The interface design process is the natural progression of all of the work that was performed during the interaction design process in the structure plane.`,
        },
        {
            type: 'text',
            content: `The second is navigation design, which lies entirely on the information-oriented side of the product outcome divide. The navigation design process is the natural progression of all of the work that was performed during the information architecture process in the structure plane.`,
        },
        {
            type: 'text',
            content: `The third is information design, which straddles the product outcome divide. Information design ties the deliverables from the interface design and navigation design processes into one cohesive whole that makes it easy to navigate between all of the individual parts of the product as well as within each of those individual parts.`,
        },
        {
            type: 'subtitle',
            content: `The Surface Plane`,
        },
        {
            type: 'quote',
            content: `Regardless of whether we are dealing with a functionality-oriented product or an information resource, our concern here is the same: the sensory experience created by the finished product.`,
        },
        {
            type: 'text',
            content: `The surface plane is dominated by a single concern that straddles both sides of the product outcome divide, sensory design. Sensory design focuses entirely on making the product interface desirable to the user. This involves not only worrying about things like color and typography in order create an overall theme within the product itself, but also considers those things in relation to other products produced by the company and the overall company brand.`,
        },
        {
            type: 'title',
            content: `Using the Elements`,
        },
        {
            type: 'text',
            content: `Armed with a high-level overview of each of the five planes and all of the more specialized disciplines and deliverables from which they are constituted, the final section of this chapter provides the reader with a short discussion about some of the difficulties that they can expect to encounter while working through the seemingly fractured and disjointed process that is collectively known as UX design. One of the great challenges that every UX designer needs to overcome on a daily basis is determining which solution in a toolbox full of solutions is the best fit for each of the different problems that they will encounter while developing a new product. The author provides a brief reality check that serves to jolt the reader out of a false sense of security that the <span class="font-italic">Elements of User Experience</span> diagram may evoke due to its seemingly simple representation of the UX design process:`,
        },
        {
            type: 'quote',
            content: `Few products or services fall exclusively on one side of this model or the other. Within each plane, the elements must work together to accomplish that plane’s goals. Separating the effects of decisions you make about one element from all other elements on the plane is very difficult. For example, information design, navigation design, and interface design jointly define the skeleton of a product. All the elements on every plane have a common function in determining the larger user experience — in this case, defining the product’s skeleton — even if they perform that function in different ways.`,
        },
        {
            type: 'text',
            content: `The example provided in this quote is particularly powerful because those three design disciplines work so closely together in order to define the skeleton plane that it can oftentimes be difficult to separate them out into clearly defined processes when it is necessary to use one of them individually to solve a problem. This is also the case when looking at multiple planes and trying to determine if a solution is better handled towards the more abstract end of the diagram or towards the more concrete end. And what about those problems that are so complex that they require solutions to be implemented in multiple planes or disciplines within a plane? At some point, only good old-fashioned experience can help to ensure that you have a strong enough sense for determining which solution fits which problem. Failing and learning from your mistakes works the same in UX design as it does for every other aspect of life.`,
        },
        {
            type: 'text',
            content: `One of the other great challenges when working in the UX design space is grappling with the lack of consistency with which different companies seem to give titles to their employees working within the bounds of the UX design process. The author provides some insight into how to overcome this challenge that you will most undoubtedly encounter more than once in your product development career:`,
        },
        {
            type: 'quote',
            content: `In some organizations, you will encounter people with job titles like information architect or interface designer. Don’t be confused by this. These people generally have expertise spanning many of the elements of user experience, not just the specialty indicated by their title. It’s not necessary to have a member of your team who is a specialist in each of these areas; instead, you only have to ensure that someone spends at least part of their time thinking about each of these issues.`,
        },
        {
            type: 'text',
            content: `The key to remember in and amongst all of the noise and distraction of the UX design process is making sure that you cover all of your bases. This means making sure that you give the product every consideration that it is due at each plane in the <span class="font-italic">Elements of User Experience</span> diagram. For the four planes that are comprised of multiple disciplines or deliverables, taking the time to consider each discipline or deliverable in isolation will ensure that you have thoroughly considered your product from every angle. Only after considering the product from all of these different perspectives and in all of these different lights can you be certain that the product has been imbued with an effective and consistent UX design.`,
        },
    ],
}