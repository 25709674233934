<template>
    <v-container
        class="blue lighten-4"
        fill-height
        fluid
        id="reading"
        style="background: url('https://images.unsplash.com/photo-1474267119072-677dd7959e96'); background-size: cover; height: 100%; width: 100%;"
    >
        <v-layout
            align-center
            column
            justify-center
        >
            <div class="text-h2 font-weight-black white--text text-xs-center hidden-sm-and-down">Keith Dawson</div>
            <div class="text-h3 font-weight-black white--text text-xs-center hidden-md-and-up">Keith Dawson</div>

            <div class="text-h3 font-weight-black white--text text-xs-center hidden-sm-and-down">Knowledge Explorer / Avid Reader</div>
            <div class="text-h5 font-weight-black white--text text-xs-center hidden-md-and-up hidden-xs-only">Knowledge Explorer / Avid Reader</div>
            <div class="text-h6 font-weight-black white--text text-xs-center hidden-sm-and-up">Knowledge Explorer / Avid Reader</div>
            <!-- Display only on extra small screens -->
            <div class="d-flex d-sm-none flex-column">
                <reading-list-button />
            </div>
            <!-- Display on small and larger screens -->
            <div class="d-none d-sm-flex">
                <reading-list-button />
            </div>
        </v-layout>
    </v-container>
</template>

<script>
// Components
import ReadingListButton from './ReadingListButton'

export default {
    name: 'HomeReading',
    components: {
        ReadingListButton,
    },
}
</script>

<style scoped>

</style>