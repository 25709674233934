<template>
    <v-container>
        <v-row class="justify-center text-h3 font-weight-light my-1">
            <slot />
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'PageTitle',
}
</script>

<style scoped>

</style>