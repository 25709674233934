export default {
    id: '2020-03-12',
    year: 2020,
    month: 3,
    date: 12,
    title: `An In-Depth Exploration of the Array.copyWithin() Function`,
    blog_url: `https://levelup.gitconnected.com/an-in-depth-exploration-of-the-array-copywithin-function-cbb22c88c9f8`,
    image_url: `https://images.unsplash.com/photo-1468070454955-c5b6932bd08d`,
    image_caption: `Photo by <a class="text--primary" href="https://unsplash.com/@markusspiske">Markus Spiske</a> on <a class="text--primary" href="https://unsplash.com/s/photos/javascript-code">Unsplash</a>`,
    contents: [
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `Over the last several years there have been many useful functions that have been added to the Javascript `,
                },
                {
                    type: 'code',
                    content: `Array`,
                },
                {
                    type: 'text',
                    content: ` global object that offer developers a wide array of new possibilities when it comes to writing code that works with arrays. One of the major advantages of these new functions is that they provide developers with the opportunity and ability to abstract away complex logical implementations that they would have normally had to create on their own before with every new codebase. Now the need for those hand-rolled implementations has been eliminated and they have instead been made available in tidy little packages in the form of individual functions on the Javascript `,
                },
                {
                    type: 'code',
                    content: `Array`,
                },
                {
                    type: 'text',
                    content: ` global object. One of those useful functions that will be explored in this article is the `,
                },
                {
                    type: 'code_link',
                    url: `https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/copyWithin`,
                    content: `copyWithin()`,
                },
                {
                    type: 'text',
                    content: ` function.`,
                },
            ],
        },
        {
            type: 'title',
            content: `Function Overview`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The `,
                },
                {
                    type: 'code',
                    content: `copyWithin()`,
                },
                {
                    type: 'text',
                    content: ` function provides the ability to shallow copy a portion of the items in an array, paste them into another location within the same array, and then return that modified array upon function completion. This function does not produce a completely new array, but instead modifies the original array on which the `,
                },
                {
                    type: 'code',
                    content: `copyWithin()`,
                },
                {
                    type: 'text',
                    content: ` function was called. If you choose to use this function keep in mind that you will not be able to maintain the original array without making a completely new copy of it beforehand. One of the most important things to remember is that the length of the array will not be changed when using this function.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The `,
                },
                {
                    type: 'code',
                    content: `copyWithin()`,
                },
                {
                    type: 'text',
                    content: ` function takes up to three parameters, all of which are zero-based indexes, with the first parameter being <span class="font-italic">required</span> and the second and third parameters being <span class="font-italic">optional</span>. If any of these parameters has a negative value, they will be counted from the end of the array instead of from the beginning. This means that a parameter value of `,
                },
                {
                    type: 'code',
                    content: `-3`,
                },
                {
                    type: 'text',
                    content: ` would actually result in the value of that parameter being `,
                },
                {
                    type: 'code',
                    content: `3`,
                },
                {
                    type: 'text',
                    content: ` less than the length of the array (`,
                },
                {
                    type: 'code',
                    content: `Array.length + -3`,
                },
                {
                    type: 'text',
                    content: `).`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The first parameter is the `,
                },
                {
                    type: 'code',
                    content: `target`,
                },
                {
                    type: 'text',
                    content: ` parameter. This parameter is the target index at which the copied array items will be pasted into the array. The second parameter is the `,
                },
                {
                    type: 'code',
                    content: `start`,
                },
                {
                    type: 'text',
                    content: ` parameter. This parameter is the starting index from which array items will be copied and the copied items <span class="font-italic font-weight-bold">will</span> include the item at this index. As this parameter is optional, it has a default value of `,
                },
                {
                    type: 'code',
                    content: `0`,
                },
                {
                    type: 'text',
                    content: ` and data will be copied starting from the beginning of the array if this parameter is not specified. The third parameter is the `,
                },
                {
                    type: 'code',
                    content: `end`,
                },
                {
                    type: 'text',
                    content: ` parameter. This parameter is the ending index up to which array items will be copied and the copied items <span class="font-italic font-weight-bold">will not</span> include the item at this index. As this parameter is optional, it has a default value of the length of the array (`,
                },
                {
                    type: 'code',
                    content: `Array.length`,
                },
                {
                    type: 'text',
                    content: `) and data will be copied up to the last index of the array if this parameter is not specified.`,
                },
            ],
        },
        {
            type: 'title',
            content: `Positive Target`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `With the general function behavior covered, let’s take a look at some examples of how the `,
                },
                {
                    type: 'code',
                    content: `copyWithin()`,
                },
                {
                    type: 'text',
                    content: ` function works in practice. The following example demonstrates the situation where only a positive `,
                },
                {
                    type: 'code',
                    content: `target`,
                },
                {
                    type: 'text',
                    content: ` parameter value is specified:`,
                },
            ],
        },
        {
            type: 'code',
            content: `var array = ['abc', 'def', 'ghi', 'jkl', 'mno'];
array.copyWithin(2);
// array: ['abc', 'def', 'abc', 'def', 'ghi']`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The `,
                },
                {
                    type: 'code',
                    content: `copyWithin()`,
                },
                {
                    type: 'text',
                    content: ` function is called with only a `,
                },
                {
                    type: 'code',
                    content: `target`,
                },
                {
                    type: 'text',
                    content: ` parameter value of `,
                },
                {
                    type: 'code',
                    content: `2`,
                },
                {
                    type: 'text',
                    content: `. Considering the optional parameter default values, this function call is the same as `,
                },
                {
                    type: 'code',
                    content: `copyWithin(2, 0, 5)`,
                },
                {
                    type: 'text',
                    content: `. This means that all of the array items from the beginning to the end of the array are copied and pasted into the array beginning at index `,
                },
                {
                    type: 'code',
                    content: `2`,
                },
                {
                    type: 'text',
                    content: `. Since there are only three index places left in the array when beginning at index `,
                },
                {
                    type: 'code',
                    content: `2`,
                },
                {
                    type: 'text',
                    content: `, only the first three array items are pasted into indexes `,
                },
                {
                    type: 'code',
                    content: `2`,
                },
                {
                    type: 'text',
                    content: ` through `,
                },
                {
                    type: 'code',
                    content: `4`,
                },
                {
                    type: 'text',
                    content: `.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `This code sample exemplifies what was mentioned earlier about how the `,
                },
                {
                    type: 'code',
                    content: `copyWithin()`,
                },
                {
                    type: 'text',
                    content: ` function does not alter the length of the array. All of the items in the array were copied, but due to the fact that the target index was `,
                },
                {
                    type: 'code',
                    content: `2`,
                },
                {
                    type: 'text',
                    content: ` the last two copied array items were trimmed because the end of the array was reached before they could be pasted into the array.`,
                },
            ],
        },
        {
            type: 'title',
            content: `Negative Target`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The following example demonstrates the situation where only a negative `,
                },
                {
                    type: 'code',
                    content: `target`,
                },
                {
                    type: 'text',
                    content: ` parameter value is specified:`,
                },
            ],
        },
        {
            type: 'code',
            content: `var array = ['abc', 'def', 'ghi', 'jkl', 'mno'];
array.copyWithin(-2);
// array: ['abc', 'def', 'ghi', 'abc', 'def']`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The `,
                },
                {
                    type: 'code',
                    content: `copyWithin()`,
                },
                {
                    type: 'text',
                    content: ` function is called with only a `,
                },
                {
                    type: 'code',
                    content: `target`,
                },
                {
                    type: 'text',
                    content: ` parameter value of `,
                },
                {
                    type: 'code',
                    content: `-2`,
                },
                {
                    type: 'text',
                    content: `. Considering the optional parameter default values and how negative parameter values are evaluated, this function call is the same as `,
                },
                {
                    type: 'code',
                    content: `copyWithin(3, 0, 5)`,
                },
                {
                    type: 'text',
                    content: `. Just like with the previous example, all of the array items from the beginning to the end of the array are copied and pasted into the array, this time beginning at index `,
                },
                {
                    type: 'code',
                    content: `3`,
                },
                {
                    type: 'text',
                    content: `. Since there are only two index places left in the array when beginning at index `,
                },
                {
                    type: 'code',
                    content: `3`,
                },
                {
                    type: 'text',
                    content: `, only the first two array items are pasted into indexes `,
                },
                {
                    type: 'code',
                    content: `3`,
                },
                {
                    type: 'text',
                    content: ` and `,
                },
                {
                    type: 'code',
                    content: `4`,
                },
                {
                    type: 'text',
                    content: `. This code sample also exemplifies how the `,
                },
                {
                    type: 'code',
                    content: `copyWithin()`,
                },
                {
                    type: 'text',
                    content: ` function does not result in an altered array length.`,
                },
            ],
        },
        {
            type: 'title',
            content: `Positive Target, Positive Start`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The following example demonstrates the situation where positive `,
                },
                {
                    type: 'code',
                    content: `target`,
                },
                {
                    type: 'text',
                    content: ` and `,
                },
                {
                    type: 'code',
                    content: `start`,
                },
                {
                    type: 'text',
                    content: ` parameter values are specified:`,
                },
            ],
        },
        {
            type: 'code',
            content: `var array = ['abc', 'def', 'ghi', 'jkl', 'mno'];
array.copyWithin(1, 3);
// array: ['abc', 'jkl', 'mno', 'jkl', 'mno']`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The `,
                },
                {
                    type: 'code',
                    content: `copyWithin()`,
                },
                {
                    type: 'text',
                    content: ` function is called with a `,
                },
                {
                    type: 'code',
                    content: `target`,
                },
                {
                    type: 'text',
                    content: ` parameter value of `,
                },
                {
                    type: 'code',
                    content: `1`,
                },
                {
                    type: 'text',
                    content: ` and a `,
                },
                {
                    type: 'code',
                    content: `start`,
                },
                {
                    type: 'text',
                    content: ` parameter value of `,
                },
                {
                    type: 'code',
                    content: `3`,
                },
                {
                    type: 'text',
                    content: `. Considering the optional parameter default values, this function call is the same as `,
                },
                {
                    type: 'code',
                    content: `copyWithin(1, 3, 5)`,
                },
                {
                    type: 'text',
                    content: `. This time, with the array items specified to be copied starting at index `,
                },
                {
                    type: 'code',
                    content: `3`,
                },
                {
                    type: 'text',
                    content: `, there are only two array items available for copying before reaching the end of the array. As a result, those two array items are pasted into indexes `,
                },
                {
                    type: 'code',
                    content: `1`,
                },
                {
                    type: 'text',
                    content: ` and `,
                },
                {
                    type: 'code',
                    content: `2`,
                },
                {
                    type: 'text',
                    content: `. The array items at indexes `,
                },
                {
                    type: 'code',
                    content: `3`,
                },
                {
                    type: 'text',
                    content: ` and `,
                },
                {
                    type: 'code',
                    content: `4`,
                },
                {
                    type: 'text',
                    content: ` are left untouched because there were no more array items to paste into those two indexes based on what items were specified to be copied from the array.`,
                },
            ],
        },
        {
            type: 'title',
            content: `Positive Target, Negative Start`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The following example demonstrates the situation where a positive `,
                },
                {
                    type: 'code',
                    content: `target`,
                },
                {
                    type: 'text',
                    content: ` parameter value and a negative `,
                },
                {
                    type: 'code',
                    content: `start`,
                },
                {
                    type: 'text',
                    content: ` parameter value are specified:`,
                },
            ],
        },
        {
            type: 'code',
            content: `var array = ['abc', 'def', 'ghi', 'jkl', 'mno'];
array.copyWithin(1, -3);
// array: ['abc', 'ghi', 'jkl', 'mno', 'mno']`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The `,
                },
                {
                    type: 'code',
                    content: `copyWithin()`,
                },
                {
                    type: 'text',
                    content: ` function is called with a `,
                },
                {
                    type: 'code',
                    content: `target`,
                },
                {
                    type: 'text',
                    content: ` parameter value of `,
                },
                {
                    type: 'code',
                    content: `1`,
                },
                {
                    type: 'text',
                    content: ` and a `,
                },
                {
                    type: 'code',
                    content: `start`,
                },
                {
                    type: 'text',
                    content: ` parameter value of `,
                },
                {
                    type: 'code',
                    content: `-3`,
                },
                {
                    type: 'text',
                    content: `. Considering the optional parameter default values and how negative parameter values are evaluated, this function call is the same as `,
                },
                {
                    type: 'code',
                    content: `copyWithin(1, 2, 5)`,
                },
                {
                    type: 'text',
                    content: `. With the array items specified to be copied starting at index `,
                },
                {
                    type: 'code',
                    content: `2`,
                },
                {
                    type: 'text',
                    content: `, there are only three array items available for copying before reaching the end of the array. As a result, those three items are pasted into indexes `,
                },
                {
                    type: 'code',
                    content: `1`,
                },
                {
                    type: 'text',
                    content: ` through `,
                },
                {
                    type: 'code',
                    content: `3`,
                },
                {
                    type: 'text',
                    content: `. The array item at index `,
                },
                {
                    type: 'code',
                    content: `4`,
                },
                {
                    type: 'text',
                    content: ` is left untouched because there were no more array items to paste into that index based on what items were specified to be copied from the array.`,
                },
            ],
        },
        {
            type: 'title',
            content: `Positive Target, Positive Start, Positive End`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The following example demonstrates the situation where positive `,
                },
                {
                    type: 'code',
                    content: `target`,
                },
                {
                    type: 'text',
                    content: `, `,
                },
                {
                    type: 'code',
                    content: `start`,
                },
                {
                    type: 'text',
                    content: `, and `,
                },
                {
                    type: 'code',
                    content: `end`,
                },
                {
                    type: 'text',
                    content: ` parameter values are specified:`,
                },
            ],
        },
        {
            type: 'code',
            content: `var array = ['abc', 'def', 'ghi', 'jkl', 'mno'];
array.copyWithin(1, 2, 3);
// array: ['abc', 'ghi', 'ghi', 'jkl', 'mno']`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The `,
                },
                {
                    type: 'code',
                    content: `copyWithin()`,
                },
                {
                    type: 'text',
                    content: ` function is called with a `,
                },
                {
                    type: 'code',
                    content: `target`,
                },
                {
                    type: 'text',
                    content: ` parameter value of `,
                },
                {
                    type: 'code',
                    content: `1`,
                },
                {
                    type: 'text',
                    content: `, a `,
                },
                {
                    type: 'code',
                    content: `start`,
                },
                {
                    type: 'text',
                    content: ` parameter value of `,
                },
                {
                    type: 'code',
                    content: `2`,
                },
                {
                    type: 'text',
                    content: `, and an `,
                },
                {
                    type: 'code',
                    content: `end`,
                },
                {
                    type: 'text',
                    content: ` parameter value of `,
                },
                {
                    type: 'code',
                    content: `3`,
                },
                {
                    type: 'text',
                    content: `. With the bounds of the array items to be copied starting and ending at index `,
                },
                {
                    type: 'code',
                    content: `2`,
                },
                {
                    type: 'text',
                    content: `, only the array item at index `,
                },
                {
                    type: 'code',
                    content: `2`,
                },
                {
                    type: 'text',
                    content: ` is copied and pasted into index `,
                },
                {
                    type: 'code',
                    content: `1`,
                },
                {
                    type: 'text',
                    content: `. The array items at indexes `,
                },
                {
                    type: 'code',
                    content: `3`,
                },
                {
                    type: 'text',
                    content: ` through `,
                },
                {
                    type: 'code',
                    content: `5`,
                },
                {
                    type: 'text',
                    content: ` are left untouched.`,
                },
            ],
        },
        {
            type: 'title',
            content: `Positive Target, Positive Start, Negative End`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The following final example demonstrates the situation where positive `,
                },
                {
                    type: 'code',
                    content: `target`,
                },
                {
                    type: 'text',
                    content: ` and `,
                },
                {
                    type: 'code',
                    content: `start`,
                },
                {
                    type: 'text',
                    content: ` parameter values and a negative `,
                },
                {
                    type: 'code',
                    content: `end`,
                },
                {
                    type: 'text',
                    content: ` parameter value are specified:`,
                },
            ],
        },
        {
            type: 'code',
            content: `var array = ['abc', 'def', 'ghi', 'jkl', 'mno'];
array.copyWithin(1, 2, -3);
// array: ['abc', 'def', 'ghi', 'jkl', 'mno']`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The `,
                },
                {
                    type: 'code',
                    content: `copyWithin()`,
                },
                {
                    type: 'text',
                    content: ` function is called with a `,
                },
                {
                    type: 'code',
                    content: `target`,
                },
                {
                    type: 'text',
                    content: ` parameter value of `,
                },
                {
                    type: 'code',
                    content: `1`,
                },
                {
                    type: 'text',
                    content: `, a `,
                },
                {
                    type: 'code',
                    content: `start`,
                },
                {
                    type: 'text',
                    content: ` parameter value of `,
                },
                {
                    type: 'code',
                    content: `2`,
                },
                {
                    type: 'text',
                    content: `, and an `,
                },
                {
                    type: 'code',
                    content: `end`,
                },
                {
                    type: 'text',
                    content: ` parameter value of `,
                },
                {
                    type: 'code',
                    content: `-3`,
                },
                {
                    type: 'text',
                    content: `. Considering how negative parameter values are evaluated, this function call is the same as `,
                },
                {
                    type: 'code',
                    content: `copyWithin(1, 2, 2)`,
                },
                {
                    type: 'text',
                    content: `. This code sample is the first example that we have seen in this article where the `,
                },
                {
                    type: 'code',
                    content: `start`,
                },
                {
                    type: 'text',
                    content: ` and `,
                },
                {
                    type: 'code',
                    content: `end`,
                },
                {
                    type: 'text',
                    content: ` parameter values are the same. Due to the fact that these two parameter values reference the same index, no array items are actually copied from the array and pasted into index `,
                },
                {
                    type: 'code',
                    content: `1`,
                },
                {
                    type: 'text',
                    content: `, resulting in an array that is exactly the same as it was before performing the operation.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `This same behavior where no changes are made to the array will occur in any of the following situations when using the `,
                },
                {
                    type: 'code',
                    content: `copyWithin()`,
                },
                {
                    type: 'text',
                    content: ` function: the `,
                },
                {
                    type: 'code',
                    content: `start`,
                },
                {
                    type: 'text',
                    content: ` and `,
                },
                {
                    type: 'code',
                    content: `end`,
                },
                {
                    type: 'text',
                    content: ` parameters reference the same index; the `,
                },
                {
                    type: 'code',
                    content: `end`,
                },
                {
                    type: 'text',
                    content: ` parameter index falls before the `,
                },
                {
                    type: 'code',
                    content: `start`,
                },
                {
                    type: 'text',
                    content: ` parameter index; the `,
                },
                {
                    type: 'code',
                    content: `target`,
                },
                {
                    type: 'text',
                    content: ` parameter value is equal to or greater than the length of the array; and the `,
                },
                {
                    type: 'code',
                    content: `start`,
                },
                {
                    type: 'text',
                    content: ` and `,
                },
                {
                    type: 'code',
                    content: `end`,
                },
                {
                    type: 'text',
                    content: ` parameter values are equal to or greater than the length of the array.`,
                },
            ],
        },
        {
            type: 'title',
            content: `Lessons Learned`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `One of the biggest takeaways from this article should be that it is no longer necessary to build complex logic to alter the contents of an array if all that you want to do is copy the contents of an array and paste them into another location within that same array. Typically, when altering the contents of an array, most developers would handle the situation by working with another complete separate array and copy values out of the original array into the new array as necessary. This type of hand-rolled logic is no longer necessary as all of that work can be done within the original array itself using the `,
                },
                {
                    type: 'code',
                    content: `copyWithin()`,
                },
                {
                    type: 'text',
                    content: ` function that is now built in to the Javascript `,
                },
                {
                    type: 'code',
                    content: `Array`,
                },
                {
                    type: 'text',
                    content: ` global object.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `Another major lesson about the `,
                },
                {
                    type: 'code',
                    content: `copyWithin()`,
                },
                {
                    type: 'text',
                    content: ` function to keep in mind is the fact that the copying and pasting being done on the array is actually handled as a single operation. Even if the copied array items are located at index locations that overlap with the index locations specified for pasting, the pasted array items will have the values of the copied array items. This is because the function snapshots the array item values in the specified copy index locations before doing the pasting, ensuring that no copy array items are inadvertently overwritten before completing the operation.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The final lesson that should be stressed one last time before concluding this article is the fact that the `,
                },
                {
                    type: 'code',
                    content: `copyWithin()`,
                },
                {
                    type: 'text',
                    content: ` function <span class="font-italic font-weight-bold">will not</span> alter the length of the array. The function will trim items off the end of the copied array items if there is not enough space to fit all of them when pasting them into the specified array index locations. This is important to remember because it means that you will not have to worry about accounting for an array that has expanded in size when developing logic that uses the `,
                },
                {
                    type: 'code',
                    content: `copyWithin()`,
                },
                {
                    type: 'text',
                    content: ` function.`,
                },
            ],
        },
        {
            type: 'title',
            content: `Conclusions`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `Thank you very much for reading this article. I hope that this exploration of the `,
                },
                {
                    type: 'code',
                    content: `copyWithin()`,
                },
                {
                    type: 'text',
                    content: ` function on the Javascript `,
                },
                {
                    type: 'code',
                    content: `Array`,
                },
                {
                    type: 'text',
                    content: ` global object has been informative and I hope that after gaining some knowledge about it you will be able to put it to good use in your own code. I encourage you to consult the resource link below for all information pertaining to the `,
                },
                {
                    type: 'code',
                    content: `copyWithin()`,
                },
                {
                    type: 'text',
                    content: ` function if you still have any further questions about how it works. Please stop back in the future for more articles about interesting and useful functions that can be found on the Javascript `,
                },
                {
                    type: 'code',
                    content: `Array`,
                },
                {
                    type: 'text',
                    content: ` global object.`,
                },
            ],
        },
    ],
}