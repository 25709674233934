export default [
    {
        category: 'Certifications',
        tech: [
            'Microsoft Certified Technology Specialist: Microsoft .NET Framework – Application Development Foundation',
        ],
    },
    {
        category: 'Languages',
        tech: [
            'JavaScript',
            'HTML5',
            'CSS3',
            'NoSQL',
            'GraphQL',
            'Java',
            'Go',
            'PHP',
            'C#',
            'ASP.NET',
            'VB.NET',
            'XML',
            'T-SQL',
            'CAML',
            'C++',
            'ASM',
            'Visual Basic 6.0',
        ],
    },
    {
        category: 'Frameworks',
        tech: [
            'Quasar',
            'React',
            'Redux',
            'Vue.js',
            'Vuex',
            'Node.js',
            'Express.js',
            'Socket.io',
            'Laravel',
            'Unity 2019',
            'Unreal Engine 4',
            'Meteor.js',
            'PhoneGap',
            'Bootstrap 3',
            'jQuery',
            '.NET 2.0/3.0/3.5/4.0',
            'Windows Communication Foundation (WCF)',
            'Windows Presentation Foundation (WPF)',
            'Telerik',
            'Infragistics',
            'Java Platform Standard Edition',
            'Java Swing',
        ],
    },
    {
        category: 'APIs',
        tech: [
            'Twilio',
            'Stripe',
            'Shopify',
            'Google',
            'LinkedIn',
        ],
    },
    {
        category: 'Databases',
        tech: [
            'MongoDB',
            'Redis',
            'PostgreSQL',
            'MySQL',
            'Microsoft SQL Server',
        ],
    },
    {
        category: 'Concepts',
        tech: [
            'UX Design',
            'Testing (Unit, Integration, System)',
            'OOAD (UML, Design Patterns, Refactoring)',
        ],
    },
    {
        category: 'Testing',
        tech: [
            'Mocha',
            'Jest',
            'Should.js',
            'Junit',
            'NUnit',
        ],
    },
    {
        category: 'Software',
        tech: [
            'Atom',
            'Visual Studio Code',
            'JetBrains WebStorm/PHPStorm',
            'Sublime Text',
            'Adobe Photoshop CC',
            'Microsoft Visual Studio 2005/2008/2010/2019',
            'Microsoft SQL Server Management Studio',
            'Microsoft SharePoint 2007/2010',
            'Eclipse',
            'Rational ClearCase and ClearQuest',
        ],
    },
    {
        category: 'Systems',
        tech: [
            'Mac OSX',
            'Ubuntu Linux',
            'Microsoft Windows XP, Vista, 7, 8, and 10',
            'AWS',
            'GCP',
        ],
    },
    {
        category: 'Processes',
        tech: [
            'Agile (SCRUM and XP)',
            'Lean',
            'Waterfall',
        ],
    },
]