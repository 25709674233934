export default {
    id: '2020-02-29',
    year: 2020,
    month: 2,
    date: 29,
    title: `Developer Story: Obfuscating Database Shape from Server API Clients`,
    blog_url: `https://medium.com/javascript-in-plain-english/developer-story-obfuscating-database-shape-from-server-api-clients-971d66a1b565`,
    image_url: `https://miro.medium.com/max/700/0*2fZPIaLuV5Q0rHS9.jpg`,
    contents: [
        {
            type: 'text',
            content: `When developing server API applications for web-based client interfaces, it is very common to create a single server application that services the needs of both admin user and regular user clients. Due to the fact that most of the logic contained within admin user and regular user server API applications is identical or very similar and it requires a lot more time and effort to maintain two separate applications rather than one, many development teams often choose the option of going with a single unified server API application that services all client applications. Throughout my career, I have created more server API applications than I can remember, so I would contend that I possess a deep understanding of the importance of approaching server API application development with a sensible strategy in mind.`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `In a `,
                },
                {
                    type: 'internal_link',
                    year: 2019,
                    month: 12,
                    date: 20,
                    content: `previous`,
                },
                {
                    type: 'text',
                    content: ` developer story entry I discussed my personal strategy for creating a single database interface in all of the NodeJS server API applications that I have developed. That strategy involved creating a single entry point to the database for all logic throughout a server API application so that all interaction with the collections or tables in the database is consistent. Another database-related strategy that I would like to discuss in this developer story entry that works hand-in-hand with that strategy is how to obfuscate the true shape of the database backing your server API application. As anyone who has worked on a server API application will know, these applications typically offer functionality supporting the four basic `,
                },
                {
                    type: 'external_link',
                    url: `https://en.wikipedia.org/wiki/Create,_read,_update_and_delete`,
                    content: `CRUD`,
                },
                {
                    type: 'text',
                    content: ` functions, depending on the authorization scheme implemented within the application. The strategy that is the subject of this entry will focus on the R in that acronym, Read.`,
                },
            ],
        },
        {
            type: 'text',
            content: `One of the many decisions that API developers always need to make when developing any new server API application is how to package the data that is retrieved from the database and passed up to the client. For many developers, the simple solution to this problem is to just pass it up to the client exactly as it comes out of the database. Although this might be an acceptable solution during the initial development phase when you are just trying to get a server API application up and running to assist in the client development effort, I would argue that it is definitely not an acceptable long-term solution.`,
        },
        {
            type: 'text',
            content: `The first reason why I believe that a simple pass-it-along solution is not acceptable over the long-term is because the database will almost always hold more data than it is necessary to make available to the client. For example, databases typically hold a lot of metadata-type information that is only useful in the context of the database or to the database administrators and should never really appear in any context outside of the database. Additionally, other information held in the database can be highly sensitive from a security standpoint and would never typically be accessed except in very specific situations and only by users who hold the correct credentials. Before passing information retrieved from the database up to the client, it is always important to sanitize the dataset and filter out all sensitive and unnecessary information.`,
        },
        {
            type: 'text',
            content: `The second reason why I believe that a simple pass-it-along solution is not acceptable over the long-term is because the actual shape of the data as it is housed in the database is usually not the same shape in which the client should be expected to consume it. Unless your database only holds a single collection or table with simple flat documents or rows that have no connections to other collections or tables, you will need to reshape the data at least a little bit before sending it up to the client. The client cannot reasonably be expected to always receive the data exactly in the shape in which it is housed in the database. Data stored in databases is not typically in a shape resembling that which would be most useful to the client because it is best to store data in the database in the most efficient way possible and in the way in which it is easiest to work with that data while it is housed in the database. It is the job of an API developer to perform as much work as possible upfront in order to simplify the shape of the data and make it easily digestible before passing it on to the client.`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `My personal database of choice is MongoDB, so all of the code that I present in this entry will be oriented towards working specifically with that database system. In a `,
                },
                {
                    type: 'internal_link',
                    year: 2019,
                    month: 11,
                    date: 18,
                    content: `previous`,
                },
                {
                    type: 'text',
                    content: ` developer story entry I discussed my personal workflow for working with MongoDB to build out an entire database. We will examine the strategy that is the focus of this developer story entry by focusing on the creation of a common user collection. The following is the schema creation logic for such a collection:`,
                },
            ],
        },
        {
            type: 'code',
            title: `migrations/20200101000000-create_collection_users.js`,
            content: `
module.exports = {
    async up(db) {
        return await db.creationCollection('users', {
            validator: {
                $jsonSchema: {
                    bsonType: 'object',
                    properties: {
                        active: {
                            bsonType: 'bool',
                        },
                        created_at: {
                            bsonType: 'date',
                        },
                        details: {
                            bsonType: 'object',
                            properties: {
                                email: {
                                    bsonType: 'string',
                                    pattern: '[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}',
                                },
                                password: {
                                    bsonType: 'string',
                                },
                                username: {
                                    bsonType: 'string',
                                },
                            },
                        },
                        email_confirmed: {
                            bsonType: 'bool',
                        },
                        last_activity: {
                            bsonType: 'date',
                        },
                        last_login: {
                            bsonType: 'date',
                        },
                        token: {
                            bsonType: 'string',
                        },
                    },
                },
            },
            validationLevel: 'strict',
            validationAction: 'error',
        })
    },
    async down(db) {
        return await db.collection('users').drop()
    },
}`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `In this code sample a `,
                },
                {
                    type: 'code',
                    content: `users`,
                },
                {
                    type: 'text',
                    content: ` collection with two levels is created, meaning that the collection is not completely flat. When documents stored in this collection are retrieved, they will contain fields that have primitive values as well as object values. The `,
                },
                {
                    type: 'code',
                    content: `details`,
                },
                {
                    type: 'text',
                    content: ` field in the document contains a sub-document which contains additional fields. Various fields in a document from this collection would need to be flattened or omitted depending on who is requesting the information.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `With the database schema covered, the next important part of my obfuscation strategy is the use of `,
                },
                {
                    type: 'external_link',
                    url: `https://docs.mongodb.com/manual/core/views/`,
                    content: `views`,
                },
                {
                    type: 'text',
                    content: `. In order for this strategy to work, there needs to be a view for each type of client that will be accessing the server API application. This developer story entry is detailing the creation of a server API application that services two types of clients, admin user client and regular user clients. As a result, there will need to be two separate views that are created for the `,
                },
                {
                    type: 'code',
                    content: `users`,
                },
                {
                    type: 'text',
                    content: ` collection. The following is the creation logic for a regular user view of the `,
                },
                {
                    type: 'code',
                    content: `users`,
                },
                {
                    type: 'text',
                    content: ` collection:`,
                },
            ],
        },
        {
            type: 'code',
            title: `migrations/20200101000001-create_view_user_users.js`,
            content: `
module.exports = {
    async up(db) {
        return await db.creationCollection('user_users', {
            pipeline: [
                {
                    $project: {
                        email: '$details.email',
                        name: '$details.username',
                    },
                },
            ],
            viewOn: 'users',
        })
    },
    async down(db) {
        return await db.collection('user_users').drop()
    },
}`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `This view is quite simple as it only allows the user to access two fields from the `,
                },
                {
                    type: 'code',
                    content: `users`,
                },
                {
                    type: 'text',
                    content: ` collection, `,
                },
                {
                    type: 'code',
                    content: `email`,
                },
                {
                    type: 'text',
                    content: ` and `,
                },
                {
                    type: 'code',
                    content: `name`,
                },
                {
                    type: 'text',
                    content: `. As you can see, these two fields are being flattened out and brought up to the top level of the resulting document rather than remaining in their original `,
                },
                {
                    type: 'code',
                    content: `details`,
                },
                {
                    type: 'text',
                    content: ` sub-document location. Additionally, the `,
                },
                {
                    type: 'code',
                    content: `username`,
                },
                {
                    type: 'text',
                    content: ` field from the sub-document has had its name changed to simply `,
                },
                {
                    type: 'code',
                    content: `name`,
                },
                {
                    type: 'text',
                    content: ` in the view. These are the only two fields that are allowed to be seen through the regular user view because these are the only fields that would be useful to a user client application. None of the other information housed in the `,
                },
                {
                    type: 'code',
                    content: `users`,
                },
                {
                    type: 'text',
                    content: ` collection is necessary in order for a client application to work with a user. With the user view covered, let’s move on to the creation logic for an admin user view of the `,
                },
                {
                    type: 'code',
                    content: `users`,
                },
                {
                    type: 'text',
                    content: ` collection:`,
                },
            ],
        },
        {
            type: 'code',
            title: `migrations/20200101000002-create_view_admin_users.js`,
            content: `
module.exports = {
    async up(db) {
        return await db.creationCollection('admin_users', {
            pipeline: [
                {
                    $project: {
                        active: '$active',
                        activity: '$last_activity',
                        confirmed: '$email_confirmed',
                        creation: '$created_at',
                        email: '$details.email',
                        login: '$last_login',
                        name: '$details.username',
                    },
                },
            ],
            viewOn: 'users',
        })
    },
    async down(db) {
        return await db.collection('admin_users').drop()
    },
}`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `This view is basically the same as the view for regular users, but with several more fields from the `,
                },
                {
                    type: 'code',
                    content: `users`,
                },
                {
                    type: 'text',
                    content: ` collection. It contains the same two fields from the regular users view as well as most of the fields that can be considered metadata fields from the `,
                },
                {
                    type: 'code',
                    content: `users`,
                },
                {
                    type: 'text',
                    content: ` collection. Just like in the regular user view, the `,
                },
                {
                    type: 'code',
                    content: `email`,
                },
                {
                    type: 'text',
                    content: ` and `,
                },
                {
                    type: 'code',
                    content: `username`,
                },
                {
                    type: 'text',
                    content: ` fields from the `,
                },
                {
                    type: 'code',
                    content: `details`,
                },
                {
                    type: 'text',
                    content: ` sub-document have been brought to the top level of the resulting document and the name of the `,
                },
                {
                    type: 'code',
                    content: `username`,
                },
                {
                    type: 'text',
                    content: ` field has been simplified to `,
                },
                {
                    type: 'code',
                    content: `name`,
                },
                {
                    type: 'text',
                    content: `. The names of most of the other fields that are being accessed in this view have also been simplified to something shorter. This shortening of the names is another technique that I prefer to use in my database obfuscation strategy and it serves two purposes.`,
                },
            ],
        },
        {
            type: 'text',
            content: `The first purpose is to make it easier for the client to reference these fields when making requests for specific document fields that it would like to receive in an HTTP GET response. Since HTTP GET response requests cannot contain a body, all of the request information must be passed in the URL string. Providing the client application with a shorter and easier set of field names to reference in those requests can help to shorten the URL character length. The second purpose is to provide the ability to further obfuscate the true shape of the database. For database security purposes, it is always best not to share the exact shape of the database with the outside world. Altering the shape of data retrieved from the database as well as changing field names can place more distance between what a client thinks is the shape of the database and the actual shape of the database.`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `With the database schema and view creation scripts covered, let’s move on to the server API application logic that interfaces directly with the database. As mentioned in my `,
                },
                {
                    type: 'internal_link',
                    year: 2019,
                    month: 12,
                    date: 20,
                    content: ``,
                },
                {
                    type: 'text',
                    content: ` article about creating a single database interface, I prefer to house all of my database interface logic in a single <span class="font-italic">db</span> module. Within that <span class="font-italic">db</span> module would be a separate sub-module for each of the collections in the database. The following is a simplified version of the logic that would appear in the <span class="font-italic">users</span> sub-module within the <span class="font-italic">db</span> module:`,
                },
            ],
        },
        {
            type: 'code',
            title: `db/users.js`,
            content: `
const db = require('./database')

module.exports.read = async (query = {}, query_options = {}, multi = false) => {
    const options = Object.assign({}, { readPreference: 'primary' }, query_options)
    const collection = db.retrieveDB().collection('users')
    return multi ? await collection.find(query, options).toArray() : await collection.findOne(query, options)
}

module.exports.admin_read = async (query = {}, query_options = {}, multi = false) => {
    const options = Object.assign({}, { readPreference: 'primary' }, query_options)
    const collection = db.retrieveDB().collection('admin_users')
    return multi ? await collection.find(query, options).toArray() : await collection.findOne(query, options)
}

module.exports.user_read = async (query = {}, query_options = {}, multi = false) => {
    const options = Object.assign({}, { readPreference: 'primary' }, query_options)
    const collection = db.retrieveDB().collection('user_users')
    return multi ? await collection.find(query, options).toArray() : await collection.findOne(query, options)
}`,
        },
        {
            type: 'text',
            content: `As mentioned above, this code sample is just a simplified version of what would normally appear in my own code. Some of the features that I always include in all of my server API application code do not appear in this code sample because I want to focus on only the parts that matter as it pertains to my obfuscation strategy. The logic that is presented in this code sample is broken into three separate parts.`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The `,
                },
                {
                    type: 'code',
                    content: `read()`,
                },
                {
                    type: 'text',
                    content: ` function is used by all logic that is internal to the server API application and requires unbridled access to all of the information in the database. Basically, any business logic that is not specifically geared towards honoring direct client requests for data from the database would use this function and specify all of the necessary query and options information in the function parameters. The `,
                },
                {
                    type: 'code',
                    content: `admin_read()`,
                },
                {
                    type: 'text',
                    content: ` function is only used by the logic that specifically services admin user client HTTP GET requests. This isolates the entire admin user client data retrieval logic flow to this function and everything below it. The `,
                },
                {
                    type: 'code',
                    content: `user_read()`,
                },
                {
                    type: 'text',
                    content: ` function is only used by the logic that specifically services regular user client HTTP GET requests. This isolates the entire regular user client data retrieval logic flow to this function and everything below it.`,
                },
            ],
        },
        {
            type: 'text',
            content: `Separating the database interface logic in this manner as it pertains to Read operations allows for a clear separation of concerns of the logic contained inside the server API application. Additionally, it ensures that if there are bugs in the logic that pertains specifically to handling client HTTP GET requests, it is easier to narrow down where the bugs may be located, either somewhere in the application logic or deeper in the database view logic. Making it easier for other developers to narrow down the locations where the logic may be failing in the system will generally serve to make their job easier and save them time and effort.`,
        },
        {
            type: 'text',
            content: `Now that you have a better understanding of my personal strategy for obfuscating the database supporting a server API application, I hope that you can apply this strategy in your own API development as well. Dividing the retrieval portion of your logic in this manner and pushing the responsibility of reshaping the data coming out of the database down to the database system itself really does help to clean up the logic necessary in the server API application. This will also allow you to focus more of your attention on all of the business logic that the application really should contain. Please stay tuned for more developer story entries as I continue to make further progress on my personal project.`,
        },
    ],
}