<template>
    <v-select
        :append-icon="icons.menuDown"
        class="ma-2 white"
        color="light-blue darken-4"
        dense
        hide-details
        item-color="light-blue darken-4"
        :items="yearOptions"
        outlined
        v-model="trackedYear"
    />
</template>

<script>
import { mdiMenuDown } from '@mdi/js'

export default {
    name: 'BlogYearSelect',
    props: [
        'selectedYear',
        'yearOptions',
    ],
    data() {
        return {
            icons: {
                menuDown: mdiMenuDown,
            },
        }
    },
    computed: {
        trackedYear: {
            get() { return this.selectedYear },
            set(newYear) { this.$emit('input', newYear) },
        },
    },
}
</script>

<style scoped>

</style>