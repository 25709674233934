<template>
    <div>
        <span class="text-subtitle-2 font-weight-bold light-blue--text text--darken-4">{{ title }}</span>
        <span class="text-body-2 grey--text text--darken-4">{{ content }}</span>
    </div>
</template>

<script>
export default {
    name: 'ResumeItemContentItem',
    props: [
        'content',
        'title',
    ],
}
</script>

<style scoped>

</style>