export default {
    id: '2020-04-29',
    year: 2020,
    month: 4,
    date: 29,
    title: `An In-Depth Exploration of the Array.fill() Function`,
    blog_url: `https://levelup.gitconnected.com/an-in-depth-exploration-of-the-array-fill-function-800155bf9dd`,
    image_url: `https://images.unsplash.com/photo-1551641145-a1e18544acb9`,
    image_caption: `Photo by <a class="text--primary" href="https://unsplash.com/@tracycodes">Tracy Adams</a> on <a class="text--primary" href="https://unsplash.com/s/photos/javascript">Unsplash</a>`,
    contents: [
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `There have been many useful functions that have been added to the Javascript `,
                },
                {
                    type: 'code',
                    content: `Array`,
                },
                {
                    type: 'text',
                    content: ` global object over the last few years that offer developers a wide variety of options when they are writing code that works with arrays. These functions offer a number of advantages, the most noteworthy of which is the fact that, while at one time in the past developers had to implement their own complex logic to perform various array operations, now the need for such homegrown implementations has been eliminated by all of these new functions. One of those useful functions that will be explored in this article is the `,
                },
                {
                    type: 'code_link',
                    url: `https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/fill`,
                    content: `fill()`,
                },
                {
                    type: 'text',
                    content: ` function.`,
                },
            ],
        },
        {
            type: 'title',
            content: `Function Overview`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The `,
                },
                {
                    type: 'code',
                    content: `fill()`,
                },
                {
                    type: 'text',
                    content: ` function provides the ability to change all elements in a given range within an array to a specific value. Not only does this function modify the array in-place, but it also returns the updated version of the array upon function completion. A very important thing to keep in mind if you choose to use the `,
                },
                {
                    type: 'code',
                    content: `fill()`,
                },
                {
                    type: 'text',
                    content: ` function is that you will not be able to maintain the original array without making a completely new copy of it beforehand. It is also worth pointing out that this function will not alter the length of the original array.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The `,
                },
                {
                    type: 'code',
                    content: `fill()`,
                },
                {
                    type: 'text',
                    content: ` function takes up to three parameters with the first parameter being <span class="font-italic">required</span> and the second and third parameters being <span class="font-italic">optional</span>. While the first parameter can be any desired value, the second and third parameters are zero-based indexes. If either of these parameters has a negative value, it will be counted from the end of the array instead of from the beginning. This means that a parameter value of `,
                },
                {
                    type: 'code',
                    content: `-3`,
                },
                {
                    type: 'text',
                    content: ` would actually result in the value of that parameter being `,
                },
                {
                    type: 'code',
                    content: `3`,
                },
                {
                    type: 'text',
                    content: ` less than the length of the array (`,
                },
                {
                    type: 'code',
                    content: `Array.length + -3`,
                },
                {
                    type: 'text',
                    content: `).`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The first parameter is the `,
                },
                {
                    type: 'code',
                    content: `value`,
                },
                {
                    type: 'text',
                    content: ` parameter. This parameter can have any desired value and the same exact value will be used to fill the array within the specified range. The second parameter is the `,
                },
                {
                    type: 'code',
                    content: `start`,
                },
                {
                    type: 'text',
                    content: ` parameter. This parameter is the starting index of the range that will be filled with the specified value and the range <span class="font-italic font-weight-bold">will</span> include the item at this index. As this parameter is optional, it has a default value of `,
                },
                {
                    type: 'code',
                    content: `0`,
                },
                {
                    type: 'text',
                    content: ` and the array will be filled from the beginning of the array if this parameter is not specified. The third parameter is the `,
                },
                {
                    type: 'code',
                    content: `end`,
                },
                {
                    type: 'text',
                    content: ` parameter. This parameter is the ending index of the range that will be filled with the specified value and the range <span class="font-italic font-weight-bold">will not</span> include the item at this index. As this parameter is optional, it has a default value of the length of the array (`,
                },
                {
                    type: 'code',
                    content: `Array.length`,
                },
                {
                    type: 'text',
                    content: `) and the array will be filled through the end of the array if this parameter is not specified.`,
                },
            ],
        },
        {
            type: 'title',
            content: `Primitive Value`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `With the general function behavior covered, let’s take a look at some examples of how the `,
                },
                {
                    type: 'code',
                    content: `fill()`,
                },
                {
                    type: 'text',
                    content: ` function works in practice. The following example demonstrates the situation where only a primitive `,
                },
                {
                    type: 'code',
                    content: `value`,
                },
                {
                    type: 'text',
                    content: ` parameter value is specified:`,
                },
            ],
        },
        {
            type: 'code',
            content: `var array = [1, 2, 3, 4, 5];
array.fill(0);
// array: [0, 0, 0, 0, 0]`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The `,
                },
                {
                    type: 'code',
                    content: `fill()`,
                },
                {
                    type: 'text',
                    content: ` function is called with a `,
                },
                {
                    type: 'code',
                    content: `value`,
                },
                {
                    type: 'text',
                    content: ` parameter value of `,
                },
                {
                    type: 'code',
                    content: `0`,
                },
                {
                    type: 'text',
                    content: `. Considering the optional parameter default values, this function call is the same as `,
                },
                {
                    type: 'code',
                    content: `fill(0, 0, 5)`,
                },
                {
                    type: 'text',
                    content: `. This means that the fill range for the specified value is the entire array from beginning to end.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `This code sample exemplifies what was mentioned earlier about how the `,
                },
                {
                    type: 'code',
                    content: `fill()`,
                },
                {
                    type: 'text',
                    content: ` function does not alter the length of the array. Despite the fact that no bounds to the fill range were explicitly specified, the fill range simply defaults to the exact size of the array and the function only fills each of the existing array items with the specified value and no more.`,
                },
            ],
        },
        {
            type: 'title',
            content: `Primitive Value, Positive Start`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The following example demonstrates the situation where a primitive `,
                },
                {
                    type: 'code',
                    content: `value`,
                },
                {
                    type: 'text',
                    content: ` parameter value and a positive `,
                },
                {
                    type: 'code',
                    content: `start`,
                },
                {
                    type: 'text',
                    content: ` parameter value are specified:`,
                },
            ],
        },
        {
            type: 'code',
            content: `var array = [1, 2, 3, 4, 5];
array.fill(0, 2);
// array: [1, 2, 0, 0, 0]`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The `,
                },
                {
                    type: 'code',
                    content: `fill()`,
                },
                {
                    type: 'text',
                    content: ` function is called with a `,
                },
                {
                    type: 'code',
                    content: `value`,
                },
                {
                    type: 'text',
                    content: ` parameter value of `,
                },
                {
                    type: 'code',
                    content: `0`,
                },
                {
                    type: 'text',
                    content: ` and a `,
                },
                {
                    type: 'code',
                    content: `start`,
                },
                {
                    type: 'text',
                    content: ` parameter value of `,
                },
                {
                    type: 'code',
                    content: `2`,
                },
                {
                    type: 'text',
                    content: `. Considering the optional parameter default values, this function call is the same as `,
                },
                {
                    type: 'code',
                    content: `fill(0, 2, 5)`,
                },
                {
                    type: 'text',
                    content: `. This means that the fill range for the specified value begins at index `,
                },
                {
                    type: 'code',
                    content: `2`,
                },
                {
                    type: 'text',
                    content: ` and continues to the end of the array.`,
                },
            ],
        },
        {
            type: 'title',
            content: `Primitive Value, Negative Start`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The following example demonstrates the situation where a primitive `,
                },
                {
                    type: 'code',
                    content: `value`,
                },
                {
                    type: 'text',
                    content: ` parameter value and a negative `,
                },
                {
                    type: 'code',
                    content: `start`,
                },
                {
                    type: 'text',
                    content: ` parameter value are specified:`,
                },
            ],
        },
        {
            type: 'code',
            content: `var array = [1, 2, 3, 4, 5];
array.fill(0, -2);
// array: [1, 2, 3, 0, 0]`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The `,
                },
                {
                    type: 'code',
                    content: `fill()`,
                },
                {
                    type: 'text',
                    content: ` function is called with a `,
                },
                {
                    type: 'code',
                    content: `value`,
                },
                {
                    type: 'text',
                    content: ` parameter value of `,
                },
                {
                    type: 'code',
                    content: `0`,
                },
                {
                    type: 'text',
                    content: ` and a `,
                },
                {
                    type: 'code',
                    content: `start`,
                },
                {
                    type: 'text',
                    content: ` parameter value of `,
                },
                {
                    type: 'code',
                    content: `-2`,
                },
                {
                    type: 'text',
                    content: `. Considering the optional parameter default values and how negative parameter values are evaluated, this function call is the same as `,
                },
                {
                    type: 'code',
                    content: `fill(0, 3, 5)`,
                },
                {
                    type: 'text',
                    content: `. This means that the fill range for the specified value begins at index `,
                },
                {
                    type: 'code',
                    content: `3`,
                },
                {
                    type: 'text',
                    content: ` and continues to the end of the array. One important thing to keep in mind when specifying a negative `,
                },
                {
                    type: 'code',
                    content: `start`,
                },
                {
                    type: 'text',
                    content: ` parameter value is that if the value causes the `,
                },
                {
                    type: 'code',
                    content: `start`,
                },
                {
                    type: 'text',
                    content: ` parameter value to be less than `,
                },
                {
                    type: 'code',
                    content: `0`,
                },
                {
                    type: 'text',
                    content: ` when added to the array length, the `,
                },
                {
                    type: 'code',
                    content: `fill()`,
                },
                {
                    type: 'text',
                    content: ` function will ignore the `,
                },
                {
                    type: 'code',
                    content: `start`,
                },
                {
                    type: 'text',
                    content: ` parameter value and instead fill the specified value from the beginning of the array.`,
                },
            ],
        },
        {
            type: 'title',
            content: `Primitive Value, Positive Start, Positive End`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The following example demonstrates the situation where a primitive `,
                },
                {
                    type: 'code',
                    content: `value`,
                },
                {
                    type: 'text',
                    content: ` parameter value and positive `,
                },
                {
                    type: 'code',
                    content: `start`,
                },
                {
                    type: 'text',
                    content: ` and `,
                },
                {
                    type: 'code',
                    content: `end`,
                },
                {
                    type: 'text',
                    content: ` parameter values are specified:`,
                },
            ],
        },
        {
            type: 'code',
            content: `var array = [1, 2, 3, 4, 5];
array.fill(0, 2, 4);
// array: [1, 2, 0, 0, 5]`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The `,
                },
                {
                    type: 'code',
                    content: `fill()`,
                },
                {
                    type: 'text',
                    content: ` function is called with a `,
                },
                {
                    type: 'code',
                    content: `value`,
                },
                {
                    type: 'text',
                    content: ` parameter value of `,
                },
                {
                    type: 'code',
                    content: `0`,
                },
                {
                    type: 'text',
                    content: `, a `,
                },
                {
                    type: 'code',
                    content: `start`,
                },
                {
                    type: 'text',
                    content: ` parameter value of `,
                },
                {
                    type: 'code',
                    content: `2`,
                },
                {
                    type: 'text',
                    content: `, and an `,
                },
                {
                    type: 'code',
                    content: `end`,
                },
                {
                    type: 'text',
                    content: ` parameter value of `,
                },
                {
                    type: 'code',
                    content: `4`,
                },
                {
                    type: 'text',
                    content: `. With the fill range for the specified value beginning at index `,
                },
                {
                    type: 'code',
                    content: `2`,
                },
                {
                    type: 'text',
                    content: ` and ending at index `,
                },
                {
                    type: 'code',
                    content: `3`,
                },
                {
                    type: 'text',
                    content: `, only the two indexes beginning at index `,
                },
                {
                    type: 'code',
                    content: `2`,
                },
                {
                    type: 'text',
                    content: ` are filled with the specified value. One important thing to keep in mind when specifying a positive `,
                },
                {
                    type: 'code',
                    content: `end`,
                },
                {
                    type: 'text',
                    content: ` parameter value is that if the value is greater than the length of the array, the `,
                },
                {
                    type: 'code',
                    content: `fill()`,
                },
                {
                    type: 'text',
                    content: ` function will ignore the `,
                },
                {
                    type: 'code',
                    content: `end`,
                },
                {
                    type: 'text',
                    content: ` parameter value and instead fill the specified value to the end of the array.`,
                },
            ],
        },
        {
            type: 'title',
            content: `Primitive Value, Positive Start, Negative End`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The following example demonstrates the situation where a primitive `,
                },
                {
                    type: 'code',
                    content: `value`,
                },
                {
                    type: 'text',
                    content: ` parameter value, a positive `,
                },
                {
                    type: 'code',
                    content: `start`,
                },
                {
                    type: 'text',
                    content: ` parameter value, and a negative `,
                },
                {
                    type: 'code',
                    content: `end`,
                },
                {
                    type: 'text',
                    content: ` parameter value are specified:`,
                },
            ],
        },
        {
            type: 'code',
            content: `var array = [1, 2, 3, 4, 5];
array.fill(0, 2, -3);
// array: [1, 2, 3, 4, 5]`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The `,
                },
                {
                    type: 'code',
                    content: `fill()`,
                },
                {
                    type: 'text',
                    content: ` function is called with a `,
                },
                {
                    type: 'code',
                    content: `value`,
                },
                {
                    type: 'text',
                    content: ` parameter value of `,
                },
                {
                    type: 'code',
                    content: `0`,
                },
                {
                    type: 'text',
                    content: `, a `,
                },
                {
                    type: 'code',
                    content: `start`,
                },
                {
                    type: 'text',
                    content: ` parameter value of `,
                },
                {
                    type: 'code',
                    content: `1`,
                },
                {
                    type: 'text',
                    content: `, and an `,
                },
                {
                    type: 'code',
                    content: `end`,
                },
                {
                    type: 'text',
                    content: ` parameter value of `,
                },
                {
                    type: 'code',
                    content: `-3`,
                },
                {
                    type: 'text',
                    content: `. Considering how negative parameter values are evaluated, this function call is the same as `,
                },
                {
                    type: 'code',
                    content: `fill(0, 2, 2)`,
                },
                {
                    type: 'text',
                    content: `. This code sample is the first example that we have seen in this article where the `,
                },
                {
                    type: 'code',
                    content: `start`,
                },
                {
                    type: 'text',
                    content: ` and `,
                },
                {
                    type: 'code',
                    content: `end`,
                },
                {
                    type: 'text',
                    content: ` parameter values are the same. Due to the fact that these two parameter values reference the same index, no array items are filled with the specified value, resulting in an array that is in the exact same state as it was before the function was executed.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `This same behavior where the resulting array is in the exact same state as it was before the function was executed will occur in any of the following situations when using the `,
                },
                {
                    type: 'code',
                    content: `fill()`,
                },
                {
                    type: 'text',
                    content: ` function: the `,
                },
                {
                    type: 'code',
                    content: `end`,
                },
                {
                    type: 'text',
                    content: ` parameter index falls before the `,
                },
                {
                    type: 'code',
                    content: `start`,
                },
                {
                    type: 'text',
                    content: ` parameter index; the `,
                },
                {
                    type: 'code',
                    content: `start`,
                },
                {
                    type: 'text',
                    content: ` parameter value is equal to or greater than the length of the array; and the `,
                },
                {
                    type: 'code',
                    content: `end`,
                },
                {
                    type: 'text',
                    content: ` parameter value is equal to or less than `,
                },
                {
                    type: 'code',
                    content: `0`,
                },
                {
                    type: 'text',
                    content: `.`,
                },
            ],
        },
        {
            type: 'title',
            content: `Object Value`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The following example demonstrates the situation where an object `,
                },
                {
                    type: 'code',
                    content: `value`,
                },
                {
                    type: 'text',
                    content: ` parameter value is specified:`,
                },
            ],
        },
        {
            type: 'code',
            content: `var array = [1, 2, 3];
array.fill({ a: 1, b: 2 });
// array: [{ a: 1, b: 2 }, { a: 1, b: 2 }, { a: 1, b: 2 }]`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The `,
                },
                {
                    type: 'code',
                    content: `fill()`,
                },
                {
                    type: 'text',
                    content: ` function is called with an object `,
                },
                {
                    type: 'code',
                    content: `value`,
                },
                {
                    type: 'text',
                    content: ` parameter value. Considering the optional parameter default values, this function call is the same as `,
                },
                {
                    type: 'code',
                    content: `fill({ a: 1, b: 2 }, 0, 3)`,
                },
                {
                    type: 'text',
                    content: `. This means that the fill range for the specified value is the entire array from beginning to end. Whereas all of the previous code samples only used a primitive value of `,
                },
                {
                    type: 'code',
                    content: `0`,
                },
                {
                    type: 'text',
                    content: ` for the `,
                },
                {
                    type: 'code',
                    content: `value`,
                },
                {
                    type: 'text',
                    content: ` parameter value, this code sample shows that it is equally acceptable to use an object should it be necessary to fill a range in an array with such a value.`,
                },
            ],
        },
        {
            type: 'title',
            content: `Lessons Learned`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `There are a few lessons to take away from this article regarding the `,
                },
                {
                    type: 'code',
                    content: `fill()`,
                },
                {
                    type: 'text',
                    content: ` function. The first thing to keep in mind is that the `,
                },
                {
                    type: 'code',
                    content: `fill()`,
                },
                {
                    type: 'text',
                    content: ` function <span class="font-italic font-weight-bold">will not</span> alter the size of the original array at all, no matter what values are specified for the `,
                },
                {
                    type: 'code',
                    content: `start`,
                },
                {
                    type: 'text',
                    content: ` and `,
                },
                {
                    type: 'code',
                    content: `end`,
                },
                {
                    type: 'text',
                    content: ` parameters. The second thing to keep in mind is that the `,
                },
                {
                    type: 'code',
                    content: `fill()`,
                },
                {
                    type: 'text',
                    content: ` function <span class="font-italic font-weight-bold">will</span> modify the original array in-place, in addition to returning a reference to the modified array. This means that it is absolutely imperative that you make a completely new copy of the original array before executing the function if you need to maintain the state of the original array for any reason.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `Another takeaway from this article should be that it is no longer necessary to implement any sort of custom logic for replacing all or a subset of items in an array with a single value. In such a situation, the first impulse may be to implement such logic using a for loop, which would certainly work just fine, but it would not be nearly as compact and easy-to-read as using the `,
                },
                {
                    type: 'code',
                    content: `fill()`,
                },
                {
                    type: 'text',
                    content: ` function. Another similar use case may be when it is necessary to create an array of a specific size with a default value set for every index within that array. Once again, the first impulse may be to create the array and then use a for loop to fill the entire array with the default value. Using the `,
                },
                {
                    type: 'code',
                    content: `fill()`,
                },
                {
                    type: 'text',
                    content: ` function, however, it is as simple as writing the following statement: `,
                },
                {
                    type: 'code',
                    content: `Array(10).fill(0)`,
                },
                {
                    type: 'text',
                    content: `.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The final thing to keep in mind about the `,
                },
                {
                    type: 'code',
                    content: `fill()`,
                },
                {
                    type: 'text',
                    content: ` function is how it handles an object that is passed as the `,
                },
                {
                    type: 'code',
                    content: `value`,
                },
                {
                    type: 'text',
                    content: ` parameter value. When passing any value as the `,
                },
                {
                    type: 'code',
                    content: `value`,
                },
                {
                    type: 'text',
                    content: ` parameter value, all of the items in the array that are filled with that value will be exactly the same. In the case of an object, all of the items that are filled will have a reference to the same exact object. This means that updating any one of the objects that has been filled in the array will also update all of the other objects that were filled. The following example shows this functionality in action:`,
                },
            ],
        },
        {
            type: 'code',
            content: `var array1 = [1, 2, 3];
array1.fill([1, 2, 3]);

// array1: [[1, 2, 3], [1, 2, 3], [1, 2, 3]]

array1[0].fill(0);

// array1: [[0, 0, 0], [0, 0, 0], [0, 0, 0]]

var array2 = [1, 2, 3];
array2.fill({ a: 1, b: 2});

// array2: [{ a: 1, b: 2 }, { a: 1, b: 2 }, { a: 1, b: 2 }]

array2[1].a = 3;
array2[2].b = 4;

// array2: [{ a: 3, b: 4 }, { a: 3, b: 4 }, { a: 3, b: 4 }]`,
        },
        {
            type: 'text',
            content: `In the case of the first array, the original array is filled with three arrays and then the first of these arrays is filled with zeroes. This results in the other two arrays also being filled with zeroes. In the case of the second array, the original array is filled with three objects and the second and third objects are both altered. This results in all three of the objects being altered in the same way. Of course, as mentioned earlier, this is a result of each of the filled arrays or objects referencing the exact same object in all places where the array or object was filled. Altering any of the arrays or objects will only ever be altering the single array or object that is actually being referenced.`,
        },
        {
            type: 'title',
            content: `Conclusions`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `Thank you very much for reading this article. I hope that this exploration of the `,
                },
                {
                    type: 'code',
                    content: `fill()`,
                },
                {
                    type: 'text',
                    content: ` function on the Javascript `,
                },
                {
                    type: 'code',
                    content: `Array`,
                },
                {
                    type: 'text',
                    content: ` global object has been informative and I hope that after gaining some knowledge about it you will be able to put it to good use in your own code. I encourage you to consult the resource link below for all information pertaining to the `,
                },
                {
                    type: 'code',
                    content: `fill()`,
                },
                {
                    type: 'text',
                    content: ` function if you still have any further questions about how it works. Please stop back in the future for more articles about interesting and useful functions that can be found on the Javascript `,
                },
                {
                    type: 'code',
                    content: `Array`,
                },
                {
                    type: 'text',
                    content: ` global object.`,
                },
            ],
        },
    ],
}