import blog_2020_01_05 from './2020-01-05'
import blog_2020_01_08 from './2020-01-08'
import blog_2020_01_12 from './2020-01-12'
import blog_2020_01_15 from './2020-01-15'
import blog_2020_01_18 from './2020-01-18'
import blog_2020_01_22 from './2020-01-22'
import blog_2020_01_24 from './2020-01-24'
import blog_2020_01_27 from './2020-01-27'
import blog_2020_01_29 from './2020-01-29'
import blog_2020_01_31 from './2020-01-31'
import blog_2020_02_11 from './2020-02-11'
import blog_2020_02_18 from './2020-02-18'
import blog_2020_02_25 from './2020-02-25'
import blog_2020_02_29 from './2020-02-29'
import blog_2020_03_12 from './2020-03-12'
import blog_2020_03_13 from './2020-03-13'
import blog_2020_03_30 from './2020-03-30'
import blog_2020_03_31 from './2020-03-31'
import blog_2020_04_17 from './2020-04-17'
import blog_2020_04_23 from './2020-04-23'
import blog_2020_04_29 from './2020-04-29'
import blog_2020_04_30 from './2020-04-30'
import blog_2020_10_12 from './2020-10-12'

export default [
    blog_2020_01_05,
    blog_2020_01_08,
    blog_2020_01_12,
    blog_2020_01_15,
    blog_2020_01_18,
    blog_2020_01_22,
    blog_2020_01_24,
    blog_2020_01_27,
    blog_2020_01_29,
    blog_2020_01_31,
    blog_2020_02_11,
    blog_2020_02_18,
    blog_2020_02_25,
    blog_2020_02_29,
    blog_2020_03_12,
    blog_2020_03_13,
    blog_2020_03_30,
    blog_2020_03_31,
    blog_2020_04_17,
    blog_2020_04_23,
    blog_2020_04_29,
    blog_2020_04_30,
    blog_2020_10_12,
]