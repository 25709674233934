export default {
    id: '2019-12-31',
    year: 2019,
    month: 12,
    date: 31,
    title: `Developer Story: Goodbye 2019 and Hello 2020`,
    blog_url: `https://keithvictordawson.medium.com/developer-story-goodbye-2019-and-hello-2020-4bf81901db7`,
    image_url: `https://images.unsplash.com/photo-1519817914152-22d216bb9170`,
    image_caption: `Photo by <a class="text--primary" href="https://unsplash.com/@withluke">Luke Stackpoole</a> on <a class="text--primary" href="https://unsplash.com/s/photos/road">Unsplash</a>`,
    contents: [
        {
            type: 'text',
            content: `Since leaving my previous job three months ago tomorrow a lot has happened, both in terms of my personal project and in terms of my understanding of what it is that I want to accomplish while working on my personal project in the future. Aside from producing a final product that is usable and useful for a large audience, the other major outcome that I want from working on my personal project is to learn and gain meaningful experience with a number of different technologies that I have thus far been unable to do so in my professional career and personal development. This outcome has informed the work that I have already completed on my personal project and will continue to guide my efforts throughout 2020. Admittedly, I did not have a completely clear plan for my near future three months ago when I first left my job. After having completed three months worth of work on my personal project, however, I have certainly had some time to more deeply consider and solidify my plans and develop a clear set of objectives for the upcoming year. In this developer story entry, published on the last day of 2019, I want to discuss three main topics. First, I want to begin with a review of what I have accomplished on my personal project in 2019. From those accomplishments, I want to pivot to laying out the goals that I have formulated for my personal project for 2020. Finally, I want to discuss my plans for further writing in 2020.`,
        },
        {
            type: 'text',
            content: `In terms of what I have accomplished so far on my personal project, I think that it can be broken up into three main accomplishments. First, I spent the first half of these three months processing the dataset that will become the seed dataset inserted into the database for my personal project. That may seem like a long time to take for such a mundane task, but I must remind you that the amount of data that I plan to seed my database with is literally the size of a dictionary. In those six weeks I did not even complete the task, but I definitely made a sizable dent. I will need to regularly come back to that task in order to complete it in 2020.`,
        },
        {
            type: 'text',
            content: `My second accomplishment in 2019 was completing a preliminary version of the NodeJS/ExpressJS version of my API server application that will be the central component of the entire system that I am developing for my personal project. When developing API server applications I prefer to use NodesJS and ExpressJS, which is why I completed this version of my API server application first. I plan to use it as a model or starting point for other versions of the API server application that I will develop in the future.`,
        },
        {
            type: 'text',
            content: `Finally, my third accomplishment in 2019 was completing a preliminary version of the NodeJS/GraphQL version of my API server application. The main logic of the two applications is meant to be the same so that the same action executed in both applications produces the same result in the database, but the client interfaces for the two applications is different due to the nature of how you interact with both ExpressJS and GraphQL API server applications. I thoroughly enjoyed developing these two different versions of the same API server application because it helped me to improve my implementation of both versions based on ideas borrowed from the other.`,
        },
        {
            type: 'text',
            content: `In terms of my goals for my personal project in 2020, the list is pretty substantial and I have a lot of work cut out for myself. But I welcome the opportunity to develop new applications using new technologies and learn whole new ways to develop both client and server applications, so I am very excited for what 2020 has in store for me. My philosophy for learning how to develop using these new technologies is rather simple, but I truly think that it will be the most effective way to gain a large amount of knowledge about each new technology in a short period of time. I am most knowledgeable about building API server applications using NodeJS and ExpressJS, while I am most knowledgeable about building web client applications using ReactJS. After implementing a working full-stack solution using those two technologies, I will use them as a model from which to build alternate versions, both on the server side and on the client side. Keeping this philosophy in mind, I think that my list of goals for 2020 in terms of my personal project can be divided into four groups.`,
        },
        {
            type: 'text',
            content: `The first group is web client applications. Now that I have an API server foundation for the overall system that I am developing for my personal project, I really want and need to move on to client development for the system. I will begin by creating a ReactJS client for the NodeJS/ExpressJS API server application that I have already created. After completing a preliminary version of that, I want to move on to creating AngularJS and VueJS versions of that client. Although I have substantial experience with ReactJS, I have not had much experience with AngularJS or VueJS, either professionally or in my personal projects. I want to gain a good working understanding of these two other frameworks, so I think that this is the best path forward in pursuing those efforts.`,
        },
        {
            type: 'text',
            content: `The second group is mobile client applications. In reality this is just one task, which is to create an Android client for my system. This is something that I have wanted to do for a very long time, but I never had the chance professionally and never could find the time while working on my personal projects. Now is the perfect time to execute such a plan, especially because a mobile client is such an integral component in the final result that I want to deliver for my personal project. This task should take a substantial part of my time this next year, since I have never developed a mobile app and I suspect there will be a steep learning curve involved in completing this effort.`,
        },
        {
            type: 'text',
            content: `The third group is API server applications. I have already built two API server applications in 2019, although they are not completely finished and will require a lot more work in 2020. While continuing to improve upon those two applications, I also plan to develop two other versions of the NodeJS/ExpressJS version of the application using Go and Java. I had some brief experience working with Go at a recent job in Korea, but I was never able to get deep into the language at the time due to priority shifts at the company. I want to continue down that learning path by fully developing a Go API server application for my personal project. And I would like to do the same thing with Java, since I live and work in Korea and Java is one of the dominant development languages in Korea. I had extensive experience working with Java way back in university, but once I entered the working world I never touched it again. I always regretted losing my knowledge of that language, which has changed substantially in the ensuing years. I plan to correct that gap in my knowledge by using the opportunity afforded by my personal project to fully develop a Java API server application. Implementing these two new API server applications should also take a substantial part of my time this next year, because I will essentially be building a complex system using two completely new languages. But I am very excited about the software development lessons that I will learn while completing these two applications.`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The fourth group is databases. Although I am already on my way to building a fully seeded MongoDB database to use in my personal project, I also want to build a PostgreSQL version of that database. I need to refresh the SQL side of my brain and I think that this is an ideal opportunity to do so. Additionally, as I have mentioned in a `,
                },
                {
                    type: 'internal_link',
                    year: 2019,
                    month: 11,
                    date: 15,
                    content: `previous`,
                },
                {
                    type: 'text',
                    content: ` developer story entry, I would like to work with RedisDB again after having worked with it a couple years ago. I am not completely sure how I can incorporate it into my overall personal project system, but I have some ideas and hopefully I can execute one of them in order to refresh my knowledge of RedisDB as well.`,
                },
            ],
        },
        {
            type: 'text',
            content: `In addition to development goals for my personal project, I also have some writing goals for 2020 as well. Thus far I have mainly written about the different moving parts of NodeJS API server applications that I have previously developed, although I have also written a couple more general opinion pieces as well. In the year ahead, I definitely want to widen the subject matter of my writing. I still intend to write about the different moving parts of the server applications that I develop, but I also want to write about the different moving parts of my client applications as well. Since I will be pivoting to client application work, I will be in a head space to think about and discuss my thought process in that context as well, so I want to share all of those thoughts. Also, due to the fact that I will be developing both server and client applications using different languages and frameworks, I want to compare and contrast the performance of the different versions of those applications and share any test results that I find to be useful or insightful.`,
        },
        {
            type: 'text',
            content: `Additionally, I want to write more opinion pieces about software development in general, since I have been working in the industry for quite a while now and there are a lot of lessons that I have learned that I would like to pass on to younger developers who are new to the industry. A lot of these lessons are things that I wish I could have learned much earlier in my career, so I hope that the wisdom that I can impart will help the next generation of software developers. In addition to working on my personal project, I have also used some of my time while not working these past three months to do some technical reading. To go along with these opinion pieces that I want to write, I would also like to have discussions about the things that I am reading, probably in the form of summaries of and reactions to the books that I am reading. As someone who has taught a fair number of less-experienced developers how to improve their development skills, I have found that teaching someone else about something really helps you to solidify the concept that you are teaching in your own head. In order to fully process and think about the things that I have read this year and will be reading next year, I want to share my thoughts and hopefully gain some useful insights, while also communicating those insights to others as well.`,
        },
        {
            type: 'text',
            content: `As you can see, 2020 is certainly not going to be a rest year for me, despite the fact that I have no plans to find another job right now. I fully intend to use every minute of this year as effectively as possible and deeply learn some new technologies and concepts while also building some great software systems. The whole point of this break from working at a company is to not only improve my overall development skills but to also evolve my thought process as a software engineer to the next level. This is the time to do things in my development that I would otherwise not have an opportunity to do while working for a company. Combining all of the individual goals that I have laid out in this developer story entry into a packed 2020 schedule, I believe that I can achieve that. Please stay tuned for more developer story entries as I continue further work on my personal project. There will be no shortage of things for me to write about in the new year.`,
        },
    ],
}