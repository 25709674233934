export default {
    id: '2020-01-18',
    year: 2020,
    month: 1,
    date: 18,
    title: `An SE’s Perspective: The Elements of User Experience (Part 3)`,
    blog_url: `https://keithvictordawson.medium.com/an-ses-perspective-the-elements-of-user-experience-part-3-890589d7601b`,
    image_url: `https://miro.medium.com/max/700/1*De5Lfps7YDWxi1LBTAwGyw.png`,
    image_caption: `The Elements of User Experience, Jesse James Garrett`,
    contents: [
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `In this series I read through each chapter of the groundbreaking UX design book `,
                },
                {
                    type: 'italic_link',
                    url: `http://www.jjg.net/elements/`,
                    content: `The Elements of User Experience`,
                },
                {
                    type: 'text',
                    content: ` and discuss the content from my perspective as a software engineer. The author of this book, Jesse James Garrett, wrote it in the hope that it would help UX designers or anyone else interested in UX design to understand the decisions that UX practitioners make during the course of their work. Unlike the first edition, this second edition of the book is not so heavily focused on UX as it pertains to web development, but rather written in such a way as to apply to any context in which UX plays an important role. Please keep in mind that my perspective comes from the direction not of a UX designer but of a software engineer who strives to wield the principles of UX as a tool to inform all of the software development decisions that I make and enhance all of the software that I create. As a result, all discussion about this book will be in that context.`,
                },
            ],
        },
        {
            type: 'spacer',
        },
        {
            type: 'text',
            content: `The title of the third chapter is <span class="font-italic">The Strategy Plane - Product Objectives and User Needs</span> and, as its title suggests, it discusses effective strategies for formulating a coherent and thoughtful strategy for fulfilling all of the needs of not only the users of a product or service, but also that of the organization developing that product or service. The focus of the Strategy Plane is divided into two concerns, the product objectives of the organization and the needs of the user. The part of the <span class="font-italic">Elements of User Experience</span> diagram for the Strategy Plane which shows this more clearly is the following:`,
        },
        {
            type: 'image',
            url: `https://miro.medium.com/max/429/1*ZIF27nfMmFCZv0QPy7bxtA.png`,
            caption: `The Strategy Plane, Jesse James Garrett`,
        },
        {
            type: 'text',
            content: `Despite these two concerns of the Strategy Plane occupying equal space in the diagram, the product objectives concern seems to be far outweighed by the user needs, which makes a lot of sense when you consider that the entire book is about <span class="font-italic">user experience</span> and <span class="font-weight-bold">not</span> <span class="font-italic">product objectives</span>. This fact is made even more evident by the fact that the author spends much more time on the topic of discerning user needs in order to use them in the UX design process than determining the organization’s product objectives. Despite this emphasis being placed on the user needs, the part of this chapter that I connected with most as a software engineer is the part that discusses product objectives. I believe that this is because the UX design process of developing product objectives is remarkably similar to some of the earlier steps of the software design process, such as requirements gathering and analysis, requirements document creation, and software architecture document creation.`,
        },
        {
            type: 'text',
            content: `The author begins by discussing product objectives and how, even though they are do not necessarily concerned with the user explicitly, they still have a definite effect and determination on the eventual final experience of the user. In this discussion the author highlights a common problem that comes up with respect to product objectives in many companies.`,
        },
        {
            type: 'quote',
            content: `Too often, product objectives exist only as an unspoken understanding among those building the product. When that understanding remains unspoken, different people often have different ideas about what the product is supposed to accomplish.`,
        },
        {
            type: 'text',
            content: `After having worked for ten different companies throughout my career, I can definitely attest to the fact that this problem is way too commonplace in most companies. Product objectives knowledge can be elusive, however, not only when product objectives are not properly documented, but even when they are documented. In my experience, there are two major reasons why the issue of the product objectives for a given product or service not being clearly defined and disseminated to the people who need them is so prevalent. The first reason for this issue is that the product objectives are mistakenly taken to be the business goals of the organization. Since business goals for an organization are typically defined by executives in boardrooms, they are not usually meant for the eyes of the people getting their hands dirty and actually building the product or service. So in some cases, the product objectives never get to the people who need them most because that was never the intention. In such cases, only the gist of the product objectives is communicated to those people who most need to know them, leaving them in the dark about the rationale for the product objectives or how to determine when those objectives have been reached.`,
        },
        {
            type: 'text',
            content: `The second reason for this issue is that different members of the development team tend to only focus their attention on the part of the product objectives that pertain to those parts of the overall system on which they need to work. So in fact, the team members who need to know the product objectives do actually receive them, but they promptly ignore all of those that do not concern their personal efforts on the project. This is a problem that can become worse over time as the passage of time warps people’s recollections of things and what someone may vaguely recall about one thing can be wildly different from reality. On software development teams where all of the team members share in the development of all parts of the system, this problem does not seem to occur nearly as much because the nature of working on all parts of the system means that all team members need to be aware of all of the product objectives. It is pretty difficult to avoid being aware of the product objectives when you are working directly on a part of the system that is dictated by those product objectives. In general, I think this issue mostly only affects those teams where tasks and duties are siloed into individual team members or small groups of team members and no team members are involved in the development of all parts of the system. Luckily, most modern software developments teams that I have seen no longer operate this way, hopefully reducing the chances of this issue arising much in the future.`,
        },
        {
            type: 'text',
            content: `The author highlights another issue with product objectives that can plague development teams, even when they put the appropriate effort into defining them:`,
        },
        {
            type: 'quote',
            content: `In trying to strike a balance between being too specific and being too general, we want to avoid jumping ahead to identify solutions when we don’t yet fully understand the problems.`,
        },
        {
            type: 'text',
            content: `I have seen first-hand such cases where the business team has defined a set of product objectives for a product or service that are so specific that they give virtually no room for decisions to be made by the development team. This kind of situation is particularly frustrating and disheartening because as the development team continues development on a product they learn many lessons. Typically those lessons do not reinforce the decisions that were made before the project even began and require course corrections to be made to the overall direction of development efforts. When there is no ability to make changes to the development plan based on lessons learned during the actual development process, that can result in particularly low team morale and always results in a lower-quality product or service. As the previous quote makes clear, it is important to leave the business of formulating concrete solutions to a particular problem until after the development team can dig its hands into working towards that solution. No one ever has all of the answers about how to solve a particular problem before getting their hands dirty trying to solve that problem. Only enough direction is needed by the development team so that they can begin their development efforts in the general direction of the right solution. Course corrections can and should be made to zero in on the right solution after development efforts uncover the wisdom and truth necessary to arrive at an appropriate solution.`,
        },
        {
            type: 'text',
            content: `A final critical point made by the author regarding product objectives highlights another pitfall that can trip up development teams in their development efforts:`,
        },
        {
            type: 'quote',
            content: `An important part of understanding your objectives is understanding how you will know when you have reached them.`,
        },
        {
            type: 'text',
            content: `Having been part of a great many different software development teams of varying sizes developing solutions for a wide variety of problems, I can definitely say that it is oftentimes difficult to know when you are done. Are you done when you have checked off all of the items on a development checklist? What if the list was missing some important items that the team forgot to document? I think that in contrast to the previous paragraphs highlighting the dangers of devising solutions that are too specific too early in the development process, there is also a very real danger of devising a set of product objectives that lack enough specificity to give anyone an indication of when those objectives have been met.`,
        },
        {
            type: 'text',
            content: `In my experience, one of the reasons for this situation can be when the organization lacks enough decisive will to commit to concrete numbers in their product objectives. No one wants to be in charge of a project that completely misses its target when that target is too high. Conversely, no one wants to be in charge of a project that hits a target that is too easy and causes the organization to miss out on the additional benefits that could have been gained had the target been set higher. The people in charge of devising the product objectives and their necessary completion metrics prefer to surround such targets in a cloud of mist and wait for a later date when it is safer to guess how far the development efforts can go in the allotted development time (because project completion dates are usually determined before the project has started).`,
        },
        {
            type: 'text',
            content: `Playing these games on the part of those people who define product objectives can really have a negative impact on the overall performance of the software development team. Without knowing where you are going in terms of metrics, it is hard as a developer to know how much further from your current position that you need to go in order to reach completion. And even as a development manager, it is much more difficult to lead a team when you are not sure what to tell them in terms of where the current development efforts stand in relation to the ultimate development goal. There is certainly a loss of morale when there is no end in sight for the team, and not having a definitive goal to strive for can sap the energy out of a development team much faster than anyone may realize. I have been in exactly these types of situations many times in my career, and I have seen how it can really bring a team down and cause development efforts to grind to a halt. Just simply having a little courage to commit to actionable metrics when devising product objectives can avoid all of this trouble and keep development efforts sailing smoothly into the future.`,
        },
        {
            type: 'text',
            content: `As a software engineer, I can definitely say that there are some phases of the UX design process that do not have a similarity to the software design and development process, despite the obvious similarities and synergies between the two disciplines. The user needs part of the Strategy Plane is one of these phases. I feel that the user needs discovery process is wholly a UX design process phase. As all of my software engineering experience cannot really be compared to those efforts inherent in this part of the UX design process, I will not speak much about the part of this chapter that discusses discovering and documenting user needs. Up to this point, I have found my discussions of this book most interesting when I can highlight the cases where the UX design process and software design process are closely aligned. The only part of this chapter that discusses user needs and how to effectively discern what they are and use them in the process of developing an overall UX design strategy to which I really felt a connection to as a software engineer is the following:`,
        },
        {
            type: 'quote',
            content: `It can be easy to fall into the trap of thinking that we are designing our product or service for one idealized user — someone exactly like us. But we aren’t designing for ourselves; we’re designing for other people, and if those other people are going to like and use what we create, we need to understand who they are and what they need.`,
        },
        {
            type: 'text',
            content: `I believe that this quote sums up best the part of this chapter where the author discusses all of the techniques and tools that UX designers use to accurately document user needs so that they can be used to form a coherent UX strategy. No matter what UX designers are doing while working in this stage of the development process, they are only trying to answer a few seemingly simple but in reality deceptively tricky questions. Who will be using this product or service? How many different distinct types of users constitute the product or service audience? What do those users want this product or service to accomplish for them? All too often, software developers allow their own biases and assumptions to seep into the software that they create.`,
        },
        {
            type: 'text',
            content: `In order to combat this subconscious tendency of software developers, it is my personal belief that all software developers need to have at least a general understanding of the principles of UX design and understand the goals that it strives to achieve as well as the methods it employs to achieve those goals. Having an understanding and appreciation for the effort that goes into defining an accurate set of user needs for a given software system is something that I think all software developers can benefit from. Possession of this knowledge can reinforce in their conscious mind the fact that just because they are the ones developing the software doesn’t mean that they will be the only users of that software. Hopefully it can instill in those developers a sense of empathy and understanding for all of the people out there who are very different from them in terms of life experience, knowledge, and biases and that those people will be a blank state when they encounter the software being developed for the first time.`,
        },
    ],
}