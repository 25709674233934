<template>
    <v-navigation-drawer
        class="light-blue darken-4 mt-14"
        disable-route-watcher
        fixed
        hide-overlay
        :mini-variant="mini"
        permanent
    >
        <v-list class="py-0">
            <template v-for="(item, index) in items">
                <v-divider :key="`divider-${index}b`" v-if="index === 0" />
                <v-list-item @click="navigate(item.to)" :key="index">
                    <v-list-item-icon>
                        <v-icon class="blue--text text--lighten-3">
                            {{ item.icon }}
                        </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title class="white--text" v-text="item.title" />
                    </v-list-item-content>
                </v-list-item>
                <v-divider :key="`divider-${index + 1}a`" v-if="index < items.length" />
            </template>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex'
import {
    mdiBookOpenVariant,
    mdiCardAccountDetails,
    mdiHome,
    mdiNewspaper,
} from '@mdi/js'

export default {
    name: 'Navbar',
    data() {
        return {
            items: [
                { title: 'Home', icon: mdiHome, to: '/' },
                { title: 'Resume', icon: mdiCardAccountDetails, to: '/resume' },
                { title: 'Blogs', icon: mdiNewspaper, to: '/blogs' },
                { title: 'Reading List', icon: mdiBookOpenVariant, to: '/reading' },
            ],
        }
    },
    computed: {
        ...mapGetters('Settings', {
            showNavbar: 'showNavbar',
        }),
        mini() {
            return !this.showNavbar || this.$vuetify.breakpoint.smAndDown
        },
    },
    methods: {
        navigate(route) {
            if (route !== this.$route.path) {
                this.$router.push(route)
            }
        },
    },
}
</script>

<style scoped>

</style>