<template>
    <v-container
        class="blue lighten-2"
        fill-height
        fluid
        id="blog"
        style="background: url('https://images.unsplash.com/photo-1461229813543-5c214846bb3f'); background-size: cover; height: 100%; width: 100%;"
    >
        <v-layout
            align-center
            column
            justify-center
        >
            <div class="text-h2 font-weight-black white--text text-xs-center hidden-sm-and-down">Keith Dawson</div>
            <div class="text-h3 font-weight-black white--text text-xs-center hidden-md-and-up">Keith Dawson</div>

            <div class="text-h3 font-weight-black white--text text-xs-center hidden-sm-and-down">Thought Leader / Blogger</div>
            <div class="text-h4 font-weight-black white--text text-xs-center hidden-md-and-up hidden-xs-only">Thought Leader / Blogger</div>
            <div class="text-h5 font-weight-black white--text text-xs-center hidden-sm-and-up">Thought Leader / Blogger</div>
            <!-- Display only on extra small screens -->
            <div class="d-flex d-sm-none flex-column">
                <blog-blog-button />
                <blog-medium-button />
            </div>
            <!-- Display on small and larger screens -->
            <div class="d-none d-sm-flex">
                <blog-blog-button />
                <blog-medium-button />
            </div>
        </v-layout>
    </v-container>
</template>

<script>
// Components
import BlogBlogButton from './BlogBlogButton'
import BlogMediumButton from './BlogMediumButton'

export default {
    name: 'HomeBlog',
    components: {
        BlogBlogButton,
        BlogMediumButton,
    },
}
</script>

<style scoped>

</style>