export default {
    id: '2020-02-18',
    year: 2020,
    month: 2,
    date: 18,
    title: `Improve Code Readability While Working with Javascript Arrays`,
    blog_url: `https://levelup.gitconnected.com/improve-code-readability-while-working-with-javascript-arrays-419d48dd21b`,
    image_url: `https://miro.medium.com/max/1000/1*ML-vm3sMrktH8UhMduiDWA.jpeg`,
    image_caption: `Photo by Mazhar Zandsalimi on <a class="text--primary" href="https://unsplash.com/s/photos/javascript">Unsplash</a>`,
    contents: [
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `While working with many other developers over the years to create Javascript applications, one of the things that I have found most striking is the lack of diversity in approaches that developers take while working with arrays. Over the past several years there have been many useful functions that have been added to the Javascript `,
                },
                {
                    type: 'code',
                    content: `Array`,
                },
                {
                    type: 'text',
                    content: ` global object.`,
                },
            ],
        },
        {
            type: 'text',
            content: `In addition to eliminating the need to constantly reinvent the wheel in so many similar or identical coding situations, these functions also provide developers with the ability to leave behind code for other developers that is <span class="font-italic">much cleaner</span> and <span class="font-italic">more readable</span>.`,
        },
        {
            type: 'text',
            content: `One of the things that I have always stressed throughout my career when mentoring other developers is the importance of writing code that is as easy to read as any plain English sentence. Not only should your logic operate according to the requirements of the software that you are writing, but for you or anyone else who reads your code in the future it should be a quick and easy task to figure out what exactly that code is doing.`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `As developers, we look at thousands of lines of code every day. It is far from an easy task, and in many cases a rather painful task, to go through large and complex applications to figure out exactly how they work, even when their code is well-written. When the code is cryptic and difficult to decipher, it certainly does not make such tasks any easier or less painful. In this article, I will be discussing a number of Javascript `,
                },
                {
                    type: 'code',
                    content: `Array`,
                },
                {
                    type: 'text',
                    content: ` functions that you can use to not only inject some variety into your code, but more importantly, improve the overall readability of your code when working with arrays.`,
                },
            ],
        },
        {
            type: 'title',
            content: `for loop`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `When it comes to working with arrays, the granddaddy of all modern techniques is the `,
                },
                {
                    type: 'external_link',
                    url: `https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Loops_and_iteration#for_statement`,
                    content: `for loop`,
                },
                {
                    type: 'text',
                    content: `. In Javascript, a <span class="font-italic">for loop</span> has three major features: the <span class="font-weight-bold">initial expression</span>, which allows you to set the starting criteria for loop iteration; the <span class="font-weight-bold">condition expression</span>, which allows you to set the ending criteria for loop iteration; and the <span class="font-weight-bold">increment expression</span>, which allows you to set the criteria for incremental advancement from the beginning to the end of the loop iteration.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `Another useful feature of <span class="font-italic">for loops</span> is the ability to use a `,
                },
                {
                    type: 'external_link',
                    url: `https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Loops_and_iteration#break_statement`,
                    content: `break`,
                },
                {
                    type: 'text',
                    content: ` statement, which allows you to end iteration of the loop early if specific criteria defined in the loop logic have been met. In reality, <span class="font-italic">for loops</span> are not specifically tied to arrays in Javascript, but they were one of the earliest ways in which you could work with arrays efficiently and logically.`,
                },
            ],
        },
        {
            type: 'text',
            content: `For the sake of completeness, the following code sample demonstrates the use of a <span class="font-italic">for loop</span> in Javascript to calculate the total cost of a list of expenses:`,
        },
        {
            type: 'code',
            title: `Traditional Javascript for loop`,
            content: `
var expenses = [
    { total: 148.39, purpose: 'food' },
    { total: 27.45, purpose: 'entertainment' },
    { total: 59.36, purpose: 'food' },
    { total: 95.27, purpose: 'food' },
    { total: 284.74, purpose: 'entertainment' }
];
var grandTotal = 0;

for (var i = 0; i < expenses.length; i++) {
    grandTotal += expenses[i].total;
}`,
        },
        {
            type: 'text',
            content: `As a developer coming upon this code, I would need to pause for a moment to review the logic and figure out what exactly this code is trying to do. First, I would need to look at the three expressions defined at the top of the <span class="font-italic">for loop</span> to determine what they are doing. If any of these expressions were defined differently than they are in this code sample, which would be considered the typical and most common way to define a <span class="font-italic">for loop</span>, there could be some confusion as to what exactly this code sample is trying to achieve. After reviewing the expressions, I would then need to examine the <span class="font-italic">for loop</span> logic to figure out what that is trying to do.`,
        },
        {
            type: 'text',
            content: `This code sample does not do so, but if the code sample were to also have included a <span class="font-italic">break</span> statement, it might even take more time to figure out what exactly the entire <span class="font-italic">for loop</span> is trying to achieve because that would introduce additional complexity into the <span class="font-italic">for loop</span> logic.`,
        },
        {
            type: 'text',
            content: `In this particular code sample, the totals for a list of expenses are being summed to produce a grand total for all of the expenses. If you were to read this code sample as a sentence, it might look something like this: <span class="font-italic">For every expense in a list of expenses, from the first expense to the last expense, the expense total is added to a grand total</span>.`,
        },
        {
            type: 'text',
            content: `My main purpose for providing this example of typical <span class="font-italic">for loop</span> usage is to demonstrate its lower level of readability in comparison to other code samples that we will see later in this article. Also, all further code samples in this article will work with the same dataset as defined in this first code sample in order to ease the comparison process.`,
        },
        {
            type: 'title',
            content: `Array.forEach() function`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The first function that I want to look at is the `,
                },
                {
                    type: 'code_link',
                    url: `https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/forEach`,
                    content: `forEach()`,
                },
                {
                    type: 'text',
                    content: ` function, which is basically exactly what you would get if you were to create the <span class="font-italic">for loop</span> from the previous code sample in function form. If you wanted to improve the readability of a <span class="font-italic">for loop</span> that you have written by translating it directly to a function, the `,
                },
                {
                    type: 'code',
                    content: `forEach()`,
                },
                {
                    type: 'text',
                    content: ` function is what you would use during that transfer process.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `This function is simple in that it takes only one parameter, a parameter function that is applied to every item in the original array. The only parameter to this parameter function is each array item. Most of the functions that I will cover in this article have the same function signature as this function. Let’s see a code sample using the `,
                },
                {
                    type: 'code',
                    content: `forEach()`,
                },
                {
                    type: 'text',
                    content: ` function, along with the equivalent <span class="font-italic">for loop</span> code for comparison:`,
                },
            ],
        },
        {
            type: 'code',
            title: `Javascript Array.forEach() function`,
            content: `
var expenses = [
    { total: 148.39, purpose: 'food' },
    { total: 27.45, purpose: 'entertainment' },
    { total: 59.36, purpose: 'food' },
    { total: 95.27, purpose: 'food' },
    { total: 284.74, purpose: 'entertainment' }
];

////////// Array.forEach() //////////
var grandTotal = 0;

expenses.forEach(expense => grandTotal += expense.total);

////////// for loop Equivalent //////////
var grandTotal = 0;

for (var i = 0; i < expenses.length; i++) {
    grandTotal += expenses[i].total;
}`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `As you can see in this code sample, the logic within the parameter function passed to the `,
                },
                {
                    type: 'code',
                    content: `forEach()`,
                },
                {
                    type: 'text',
                    content: ` function is almost identical to the logic contained inside the equivalent <span class="font-italic">for loop</span> code. By transferring that logic into a function with a much clearer name, it is easier to read and understand that the total of <span class="font-italic">each</span> item in the entire expenses list is being added to a variable external to the function in order to produce a grand total of all expenses.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `Due to the appropriate name given to this function, you can read the `,
                },
                {
                    type: 'code',
                    content: `forEach()`,
                },
                {
                    type: 'text',
                    content: ` function code in this code sample like this: <span class="font-italic">For each expense in the expenses list add the expense total to a grand total</span>. Despite the logic being almost identical in the two different versions of the code, this sentence for the `,
                },
                {
                    type: 'code',
                    content: `forEach()`,
                },
                {
                    type: 'text',
                    content: ` function code conveys what is happening much more clearly and succinctly than the sentence used to describe the equivalent <span class="font-italic">for loop</span> code.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The one drawback with using the `,
                },
                {
                    type: 'code',
                    content: `forEach()`,
                },
                {
                    type: 'text',
                    content: ` function, as well as all of the functions presented in this article, is that you cannot explicitly specify a <span class="font-italic">break</span> statement to end execution of the function early. This is not a problem, however, because some of the functions actually have built-in <span class="font-italic">break</span> statements and they will end their loop execution early in most cases before iterating over the entire array. Functions that behave this way do so depending on their intended purpose. Where applicable I will state when such behavior is possible while discussing functions further along in this article.`,
                },
            ],
        },
        {
            type: 'title',
            content: `Array.map() function`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `Another function that we can use to improve code readability over and above a <span class="font-italic">for loop</span> is the `,
                },
                {
                    type: 'code_link',
                    url: `https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/map`,
                    content: `map()`,
                },
                {
                    type: 'text',
                    content: ` function. Along with the `,
                },
                {
                    type: 'code',
                    content: `forEach()`,
                },
                {
                    type: 'text',
                    content: ` function, the `,
                },
                {
                    type: 'code',
                    content: `map()`,
                },
                {
                    type: 'text',
                    content: ` function is probably one of the most widely used array functions that I have encountered in Javascript code written by other developers. The `,
                },
                {
                    type: 'code',
                    content: `map()`,
                },
                {
                    type: 'text',
                    content: ` function is essentially the same as the `,
                },
                {
                    type: 'code',
                    content: `forEach()`,
                },
                {
                    type: 'text',
                    content: ` function, except for the function return value. The `,
                },
                {
                    type: 'code',
                    content: `forEach()`,
                },
                {
                    type: 'text',
                    content: ` function does not return any results and simply executes a parameter function on each item in an array.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The `,
                },
                {
                    type: 'code',
                    content: `map()`,
                },
                {
                    type: 'text',
                    content: ` function executes a parameter function on each item in an array, but it returns a new list containing the return value from each iteration of that parameter function. This parameter function is the only parameter accepted by the `,
                },
                {
                    type: 'code',
                    content: `map()`,
                },
                {
                    type: 'text',
                    content: ` function.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `Choosing between using the `,
                },
                {
                    type: 'code',
                    content: `map()`,
                },
                {
                    type: 'text',
                    content: ` function and the `,
                },
                {
                    type: 'code',
                    content: `forEach()`,
                },
                {
                    type: 'text',
                    content: ` function is generally dictated by whether or not you need to produce a completely new array of items containing the same number of items as the original array. As the function name suggests, what you are doing when using the `,
                },
                {
                    type: 'code',
                    content: `map()`,
                },
                {
                    type: 'text',
                    content: ` function is <span class="font-italic">mapping</span> each of the items in the original array to a completely new item in the resulting array. Let’s see a code sample using the `,
                },
                {
                    type: 'code',
                    content: `map()`,
                },
                {
                    type: 'text',
                    content: ` function, along with the equivalent <span class="font-italic">for loop</span> code for comparison:`,
                },
            ],
        },
        {
            type: 'code',
            title: `Javascript Array.map() function`,
            content: `
var expenses = [
    { total: 148.39, purpose: 'food' },
    { total: 27.45, purpose: 'entertainment' },
    { total: 59.36, purpose: 'food' },
    { total: 95.27, purpose: 'food' },
    { total: 284.74, purpose: 'entertainment' }
];

////////// Array.map() //////////
var updatedExpenses = expenses.map((expense) => { ...expense, preTaxTotal: expense.total / 1.1 });

////////// for loop Equivalent //////////
var updatedExpenses = [];

for (var i = 0; i < expenses.length; i++) {
    updatedExpenses.push({ ...expenses[i], preTaxTotal: expenses[i].total / 1.1 });
}`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `In this code sample we are using the same input as the `,
                },
                {
                    type: 'code',
                    content: `forEach()`,
                },
                {
                    type: 'text',
                    content: ` function code sample, but instead of working with an external array we are producing a completely new array filled with an updated list of expenses. In addition to all of the data contained in the original array, each item in this new array also contains a pre-tax expense total.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The logic for the `,
                },
                {
                    type: 'code',
                    content: `map()`,
                },
                {
                    type: 'text',
                    content: ` function code and the equivalent <span class="font-italic">for loop</span> code is more strikingly different here than it was in the `,
                },
                {
                    type: 'code',
                    content: `forEach()`,
                },
                {
                    type: 'text',
                    content: ` function code sample. Rather than having to work with an external array the function simply produces its own new array upon completion of function execution. The `,
                },
                {
                    type: 'code',
                    content: `map()`,
                },
                {
                    type: 'text',
                    content: ` function code in this code sample could be read like this: <span class="font-italic">Update a list of expenses so each expense also contains a pre-tax expense total</span>.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `This is just one example of how the `,
                },
                {
                    type: 'code',
                    content: `map()`,
                },
                {
                    type: 'text',
                    content: ` function could be useful in comparison to the `,
                },
                {
                    type: 'code',
                    content: `forEach()`,
                },
                {
                    type: 'text',
                    content: ` function. Any time when you would like to produce a new array of items based on the items in an existing array, use the `,
                },
                {
                    type: 'code',
                    content: `map()`,
                },
                {
                    type: 'text',
                    content: ` function. The new array of items could be an updated list of items from the original array that we saw in this code sample or it could be an entirely new list with no direct structural connection to the original array.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The key to remember when using the `,
                },
                {
                    type: 'code',
                    content: `map()`,
                },
                {
                    type: 'text',
                    content: ` function is that the new array will have the same length as the original array. The `,
                },
                {
                    type: 'code',
                    content: `forEach()`,
                },
                {
                    type: 'text',
                    content: ` function is more appropriate for when you would like to work with some existing external value or data structure and do not require an entirely new array to be produced by the function.`,
                },
            ],
        },
        {
            type: 'title',
            content: `Array.filter() function`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `A function that is similar to the `,
                },
                {
                    type: 'code',
                    content: `forEach()`,
                },
                {
                    type: 'text',
                    content: ` and `,
                },
                {
                    type: 'code',
                    content: `map()`,
                },
                {
                    type: 'text',
                    content: ` functions but produces a different result then both is the `,
                },
                {
                    type: 'code_link',
                    url: `https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/filter`,
                    content: `filter()`,
                },
                {
                    type: 'text',
                    content: ` function. Like the `,
                },
                {
                    type: 'code',
                    content: `forEach()`,
                },
                {
                    type: 'text',
                    content: ` and `,
                },
                {
                    type: 'code',
                    content: `map()`,
                },
                {
                    type: 'text',
                    content: ` functions, the `,
                },
                {
                    type: 'code',
                    content: `filter()`,
                },
                {
                    type: 'text',
                    content: ` function iterates through every item in the original array. Similar to the `,
                },
                {
                    type: 'code',
                    content: `map()`,
                },
                {
                    type: 'text',
                    content: ` function, it produces a new array of items, but the difference is that the items in that new array are the same items in the original array for which the parameter function to the `,
                },
                {
                    type: 'code',
                    content: `filter()`,
                },
                {
                    type: 'text',
                    content: ` function returns a truthy value. Let’s see a code sample using the `,
                },
                {
                    type: 'code',
                    content: `filter()`,
                },
                {
                    type: 'text',
                    content: ` function, along with the equivalent <span class="font-italic">for loop</span> code for comparison:`,
                },
            ],
        },
        {
            type: 'code',
            title: `Javascript Array.filter() function`,
            content: `
var expenses = [
    { total: 148.39, purpose: 'food' },
    { total: 27.45, purpose: 'entertainment' },
    { total: 59.36, purpose: 'food' },
    { total: 95.27, purpose: 'food' },
    { total: 284.74, purpose: 'entertainment' }
];

////////// Array.filter() //////////
var foodExpenses = expenses.filter(expense => expense.purpose === 'food');

////////// for loop Equivalent //////////
var foodExpenses = [];

for (var i = 0; i < expenses.length; i++) {
    if (expenses[i].purpose === 'food') {
        foodExpenses.push(expenses[i]);
    }
}`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `In this code sample we are using the same input as before, but this time we are filtering the full list of expenses into a new list containing only food expenses. Just like in the `,
                },
                {
                    type: 'code',
                    content: `map()`,
                },
                {
                    type: 'text',
                    content: ` function code sample, the difference between the `,
                },
                {
                    type: 'code',
                    content: `filter()`,
                },
                {
                    type: 'text',
                    content: ` function code and the equivalent <span class="font-italic">for loop</span> code is substantial. Just like with the `,
                },
                {
                    type: 'code',
                    content: `map()`,
                },
                {
                    type: 'text',
                    content: ` function, it is not necessary to work with an external array because the `,
                },
                {
                    type: 'code',
                    content: `filter()`,
                },
                {
                    type: 'text',
                    content: ` function produces its own new array upon function execution completion. The `,
                },
                {
                    type: 'code',
                    content: `filter()`,
                },
                {
                    type: 'text',
                    content: ` function code in this code sample could be read like this: <span class="font-italic">Filter a list of expenses into a list of food expenses</span>.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `Unlike the `,
                },
                {
                    type: 'code',
                    content: `map()`,
                },
                {
                    type: 'text',
                    content: ` function, which is guaranteed to return an array containing the same number of items as the original array, the `,
                },
                {
                    type: 'code',
                    content: `filter()`,
                },
                {
                    type: 'text',
                    content: ` function can return an array containing as few as zero items or as many items as there are in the original array. This is entirely determined by the logic contained in the parameter function that is passed to the `,
                },
                {
                    type: 'code',
                    content: `filter()`,
                },
                {
                    type: 'text',
                    content: ` function.`,
                },
            ],
        },
        {
            type: 'title',
            content: `Array.reduce() function`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `With the `,
                },
                {
                    type: 'code',
                    content: `map()`,
                },
                {
                    type: 'text',
                    content: ` and `,
                },
                {
                    type: 'code',
                    content: `filter()`,
                },
                {
                    type: 'text',
                    content: ` functions covered, another useful function that we can look to for assistance in increasing the readability of our code is the `,
                },
                {
                    type: 'code_link',
                    url: `https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/Reduce`,
                    content: `reduce()`,
                },
                {
                    type: 'text',
                    content: ` function. This function is a bit more complicated than the other functions that we have covered so far, but in many ways, it is a cross between the `,
                },
                {
                    type: 'code',
                    content: `map()`,
                },
                {
                    type: 'text',
                    content: ` and `,
                },
                {
                    type: 'code',
                    content: `filter()`,
                },
                {
                    type: 'text',
                    content: ` functions. For parameters the `,
                },
                {
                    type: 'code',
                    content: `reduce()`,
                },
                {
                    type: 'text',
                    content: ` function takes a parameter function that will be applied to every item in the array, as well as an initial value that will be passed to that parameter function on its first iteration.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The parameter function accepts two parameters. The first parameter is an accumulator, which begins with the first loop iteration as the initial value passed to the `,
                },
                {
                    type: 'code',
                    content: `reduce()`,
                },
                {
                    type: 'text',
                    content: ` function. This accumulator parameter will continue to be carried forward to the next loop iteration as the value returned from the previous iteration of the parameter function, and it will ultimately be the value that is returned by the `,
                },
                {
                    type: 'code',
                    content: `reduce()`,
                },
                {
                    type: 'text',
                    content: ` function once execution has completed. In order to get a better idea of how this works, let’s see a code sample using the `,
                },
                {
                    type: 'code',
                    content: `reduce()`,
                },
                {
                    type: 'text',
                    content: ` function, along with the equivalent <span class="font-italic">for loop</span> code for comparison:`,
                },
            ],
        },
        {
            type: 'code',
            title: `Javascript Array.reduce() function`,
            content: `
var expenses = [
    { total: 148.39, purpose: 'food' },
    { total: 27.45, purpose: 'entertainment' },
    { total: 59.36, purpose: 'food' },
    { total: 95.27, purpose: 'food' },
    { total: 284.74, purpose: 'entertainment' }
];

////////// Array.reduce() //////////
var foodTotalExpenses = expenses.reduce((total, expense) => {
    if (expense.purpose === 'food') {
        total += expense.total;
    }

    return total;
}, 0);

////////// for loop Equivalent //////////
var foodTotalExpenses = 0;

for (var i = 0; i < expenses.length; i++) {
    if (expenses[i].purpose === 'food') {
        foodTotalExpenses += expenses[i].total;
    }
}`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The `,
                },
                {
                    type: 'code',
                    content: `reduce()`,
                },
                {
                    type: 'text',
                    content: ` function is special because, rather than depending on the use of an external variable, like in the equivalent <span class="font-italic">for loop</span> code here or even in the case of the `,
                },
                {
                    type: 'code',
                    content: `forEach()`,
                },
                {
                    type: 'text',
                    content: ` function, the function maintains its own internal variable across loop iterations. In this code sample that variable is `,
                },
                {
                    type: 'code',
                    content: `total`,
                },
                {
                    type: 'text',
                    content: `, the first parameter to the parameter function, and it would begin with the value of `,
                },
                {
                    type: 'code',
                    content: `0`,
                },
                {
                    type: 'text',
                    content: `, the second parameter passed to the `,
                },
                {
                    type: 'code',
                    content: `reduce()`,
                },
                {
                    type: 'text',
                    content: ` function. This variable would be the equivalent of the grand total variable from the `,
                },
                {
                    type: 'code',
                    content: `forEach()`,
                },
                {
                    type: 'text',
                    content: ` function code sample. The `,
                },
                {
                    type: 'code',
                    content: `reduce()`,
                },
                {
                    type: 'text',
                    content: ` function code in this code sample could be read like this: <span class="font-italic">Reduce a list of expenses down to a food expenses grand total</span>.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The reason why I stated earlier that the `,
                },
                {
                    type: 'code',
                    content: `reduce()`,
                },
                {
                    type: 'text',
                    content: ` function is almost like a cross between the `,
                },
                {
                    type: 'code',
                    content: `map()`,
                },
                {
                    type: 'text',
                    content: ` and `,
                },
                {
                    type: 'code',
                    content: `filter()`,
                },
                {
                    type: 'text',
                    content: ` functions is because it is able to serve the same purpose as both of those functions in just one function. This eliminates the need to apply both of those functions separately to a single array, piping the results of one function execution to the other.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The `,
                },
                {
                    type: 'code',
                    content: `reduce()`,
                },
                {
                    type: 'text',
                    content: ` function serves the same purpose as the `,
                },
                {
                    type: 'code',
                    content: `map()`,
                },
                {
                    type: 'text',
                    content: ` function because it allows you to return a different value depending on the value of each item in the array. The `,
                },
                {
                    type: 'code',
                    content: `reduce()`,
                },
                {
                    type: 'text',
                    content: ` function serves the same purpose as the `,
                },
                {
                    type: 'code',
                    content: `filter()`,
                },
                {
                    type: 'text',
                    content: ` function because it allows you to choose which items in the array will result in a value being reflected in the final function return value.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `This `,
                },
                {
                    type: 'code',
                    content: `reduce()`,
                },
                {
                    type: 'text',
                    content: ` function code sample may look more complicated than the <span class="font-italic">for loop</span> code sample on the surface, but one reason why I wanted to cover this function is because I think it is actually superior to an equivalent <span class="font-italic">for loop</span> implementation. This superiority stems from the fact that the `,
                },
                {
                    type: 'code',
                    content: `reduce()`,
                },
                {
                    type: 'text',
                    content: ` function does not need to depend on the use of any variables external to the function in order to maintain its state across loop iterations.`,
                },
            ],
        },
        {
            type: 'text',
            content: `The initial value parameter allows you to specify a starting point from which to work and you can build upon that initial value however you see fit throughout the duration of function execution. This keeps usage of the function more self-contained, which I think ultimately increases its readability over and above that of the <span class="font-italic">for loop</span> implementation.`,
        },
        {
            type: 'title',
            content: `Array.every() function`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The `,
                },
                {
                    type: 'code_link',
                    url: `https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/every`,
                    content: `every()`,
                },
                {
                    type: 'text',
                    content: ` function is the first function discussed in this article that does not have a guarantee to iterate through every item in the original array before function execution has completed. It is also unlike most of the previous functions in that it does not return an array of items in some way related to the items in the original array. Instead, it returns a simple boolean.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The `,
                },
                {
                    type: 'code',
                    content: `every()`,
                },
                {
                    type: 'text',
                    content: ` function receives a parameter function as its sole parameter, and the only parameter for that function is each successive item in the original array. The function will iterate through each item in the array until it encounters an item for which the parameter function returns a `,
                },
                {
                    type: 'external_link',
                    url: `https://developer.mozilla.org/en-US/docs/Glossary/Falsy`,
                    content: `falsy`,
                },
                {
                    type: 'text',
                    content: ` value. If this happens, the function ends execution early and returns `,
                },
                {
                    type: 'code',
                    content: `false`,
                },
                {
                    type: 'text',
                    content: ` immediately. Otherwise, it iterates through every item in the original array and returns `,
                },
                {
                    type: 'code',
                    content: `true`,
                },
                {
                    type: 'text',
                    content: `. Let’s see a code sample using the `,
                },
                {
                    type: 'code',
                    content: `every()`,
                },
                {
                    type: 'text',
                    content: ` function, along with the equivalent <span class="font-italic">for loop</span> code for comparison:`,
                },
            ],
        },
        {
            type: 'code',
            title: `Javascript Array.every() function`,
            content: `
var expenses = [
    { total: 148.39, purpose: 'food' },
    { total: 27.45, purpose: 'entertainment' },
    { total: 59.36, purpose: 'food' },
    { total: 95.27, purpose: 'food' },
    { total: 284.74, purpose: 'entertainment' }
];

///////// Array.every() //////////
var expensesUnderBudget = expenses.every(expense => expense.total <= 100);

////////// for loop Equivalent //////////
var expensesUnderBudget = true;

for (var i = 0; i < expenses.length; i++) {
    if (expenses[i].total > 100) {
        expensesUnderBudget = false;
        break;
    }
}`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `In this code sample we are using the same input as before, but this time we are checking to see if every expense in our list of expenses has a total below a budget threshold. From the list of expenses on which the `,
                },
                {
                    type: 'code',
                    content: `every()`,
                },
                {
                    type: 'text',
                    content: ` function is being executed, it is easy to see that the function will end execution and return `,
                },
                {
                    type: 'code',
                    content: `false`,
                },
                {
                    type: 'text',
                    content: ` immediately after only the first iteration. When compared to the equivalent <span class="font-italic">for loop</span> code sample, which would also end execution of the loop after only the first iteration, I believe it is very clear to see the benefit of using the `,
                },
                {
                    type: 'code',
                    content: `every()`,
                },
                {
                    type: 'text',
                    content: ` function over a <span class="font-italic">for loop</span>.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `In the case of the equivalent <span class="font-italic">for loop</span> code, an external variable is required to track the ultimate result across loop iterations, which I believe instantly makes it far less readable than the `,
                },
                {
                    type: 'code',
                    content: `every()`,
                },
                {
                    type: 'text',
                    content: ` function implementation, which keeps everything internal until the final function result is produced. The `,
                },
                {
                    type: 'code',
                    content: `every()`,
                },
                {
                    type: 'text',
                    content: ` function code in this code sample could be read like this: <span class="font-italic">Check if every expense in a list of expenses has an expense total under 100</span>.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The real benefit of the `,
                },
                {
                    type: 'code',
                    content: `every()`,
                },
                {
                    type: 'text',
                    content: ` function is what you are saying when you use this function in your code. Using this function on an array means that you are verifying that <span class="font-italic">every</span> item in that array passes whatever logical check you implement inside the parameter function.`,
                },
            ],
        },
        {
            type: 'text',
            content: `Additionally, you are stating that you want to be notified immediately if just one of those items does not pass that logical check. This logical check acts as a short circuit and can provide an answer to your query much faster than it would take to check every single item in the array. The only time that you would need to iterate through the entire array is when it turns out that all items in the array have passed the logical check, which is perfectly acceptable and logically necessary.`,
        },
        {
            type: 'title',
            content: `Array.some() function`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `While the primary use of the `,
                },
                {
                    type: 'code',
                    content: `every()`,
                },
                {
                    type: 'text',
                    content: ` function was to check an array for a single logical failure case, the primary function of the `,
                },
                {
                    type: 'code_link',
                    url: `https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/some`,
                    content: `some()`,
                },
                {
                    type: 'text',
                    content: ` function is to check an array for a single logical success case. These two functions are essentially polar opposites in their purpose and how they approach evaluating an array. Like most of the other previous functions, the `,
                },
                {
                    type: 'code',
                    content: `some()`,
                },
                {
                    type: 'text',
                    content: ` function takes a single parameter, a parameter function, and the only parameter of that function is each successive item in the original array.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The function will iterate through each item in the array until it encounters an item for which the parameter function returns a `,
                },
                {
                    type: 'external_link',
                    url: `https://developer.mozilla.org/en-US/docs/Glossary/Truthy`,
                    content: `truthy`,
                },
                {
                    type: 'text',
                    content: ` value. If this happens, the function ends execution early and returns `,
                },
                {
                    type: 'code',
                    content: `true`,
                },
                {
                    type: 'text',
                    content: ` immediately. Otherwise, it iterates through every item in the original array and returns `,
                },
                {
                    type: 'code',
                    content: `false`,
                },
                {
                    type: 'text',
                    content: `. Let’s see a code sample using the `,
                },
                {
                    type: 'code',
                    content: `some()`,
                },
                {
                    type: 'text',
                    content: ` function, along with the equivalent <span class="font-italic">for loop</span> code for comparison:`,
                },
            ],
        },
        {
            type: 'code',
            title: `Javascript Array.some() function`,
            content: `
var expenses = [
    { total: 148.39, purpose: 'food' },
    { total: 27.45, purpose: 'entertainment' },
    { total: 59.36, purpose: 'food' },
    { total: 95.27, purpose: 'food' },
    { total: 284.74, purpose: 'entertainment' }
];

////////// Array.some() //////////
var expenseOverBudget = expenses.some(expense => expense.total > 100);

////////// for loop Equivalent //////////
var expenseOverBudget = false;

for (var i = 0; i < expenses.length; i++) {
    if (expenses[i].total > 100) {
        expenseOverBudget = true;
        break;
    }
}`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `In this code sample we are using the same input as always while checking to see if at least one expense in a list of expenses has a total over a budget threshold. From the list of expenses that we have by now grown accustomed to using, it is easy to see that the function will end execution and return `,
                },
                {
                    type: 'code',
                    content: `true`,
                },
                {
                    type: 'text',
                    content: ` immediately after only the first iteration. The `,
                },
                {
                    type: 'code',
                    content: `some()`,
                },
                {
                    type: 'text',
                    content: ` function code in this code sample could be read like this: <span class="font-italic">Check if some expense in a list of expenses has an expense total over 100</span>.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `Just like with the `,
                },
                {
                    type: 'code',
                    content: `every()`,
                },
                {
                    type: 'text',
                    content: ` function code sample, usage of the `,
                },
                {
                    type: 'code',
                    content: `some()`,
                },
                {
                    type: 'text',
                    content: ` function in this code sample offers a clear benefit over usage of the <span class="font-italic">for loop</span>. In fact, if you compare the <span class="font-italic">for loop</span> equivalent code from both code samples, you will see that they are almost identical, except for the fact that the initial value of the external variable is swapped with the short-circuit logic value defined inside the <span class="font-italic">for loop</span>. This is a real problem because if you were to see the <span class="font-italic">for loop</span> equivalent code from the two code samples next to each other, it is not immediately obvious that they are actually completely opposite in their intended logical purposes.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `Usage of the `,
                },
                {
                    type: 'code',
                    content: `some()`,
                },
                {
                    type: 'text',
                    content: ` and `,
                },
                {
                    type: 'code',
                    content: `every()`,
                },
                {
                    type: 'text',
                    content: ` functions next to each other would not present the same problem, somewhat because the logic contained in the parameter functions is clearly opposite, but mostly because the names of the actual functions are clearly very different and indicate opposite behaviors.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `Just like with the `,
                },
                {
                    type: 'code',
                    content: `every()`,
                },
                {
                    type: 'text',
                    content: ` function, the real benefit of the `,
                },
                {
                    type: 'code',
                    content: `some()`,
                },
                {
                    type: 'text',
                    content: ` function is what you are saying when you use this function in your code. Using this function on an array means that you are verifying that <span class="font-italic">some</span> item in that array passes whatever logical check you implement inside the parameter function.`,
                },
            ],
        },
        {
            type: 'text',
            content: `Additionally, you are stating that you want to be notified immediately as soon as one of those items passes that logical check. This logical check acts as a short circuit and can provide an answer to your query much faster than it would take to check every single item in the array. The only time that you would need to iterate through the entire array is when none of the items in the array have passed the logical check, which makes sense from a logical standpoint.`,
        },
        {
            type: 'title',
            content: `Array.find() function`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The last function that I want to discuss, the `,
                },
                {
                    type: 'code_link',
                    url: `https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/find`,
                    content: `find()`,
                },
                {
                    type: 'text',
                    content: ` function, is quite similar to the `,
                },
                {
                    type: 'code',
                    content: `some()`,
                },
                {
                    type: 'text',
                    content: ` function, but it does not return a simple boolean like that function does. The `,
                },
                {
                    type: 'code',
                    content: `find()`,
                },
                {
                    type: 'text',
                    content: ` function takes a single parameter, a parameter function, and the only parameter of that function is each successive item in the original array.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `Like the `,
                },
                {
                    type: 'code',
                    content: `some()`,
                },
                {
                    type: 'text',
                    content: ` function, the `,
                },
                {
                    type: 'code',
                    content: `find()`,
                },
                {
                    type: 'text',
                    content: ` function will iterate through each item in the original array until it encounters an item for which the parameter function returns a truthy value. If this happens, however, the function ends execution early and immediately returns the array item for which the truthy value was returned, rather than a simple boolean. Otherwise, the function iterates through every item in the original array and returns `,
                },
                {
                    type: 'code',
                    content: `undefined`,
                },
                {
                    type: 'text',
                    content: `. Let’s see a code sample using the `,
                },
                {
                    type: 'code',
                    content: `find()`,
                },
                {
                    type: 'text',
                    content: ` function, along with the equivalent for loop code for comparison:`,
                },
            ],
        },
        {
            type: 'code',
            title: `Javascript Array.find() function`,
            content: `
var expenses = [
    { total: 148.39, purpose: 'food' },
    { total: 27.45, purpose: 'entertainment' },
    { total: 59.36, purpose: 'food' },
    { total: 95.27, purpose: 'food' },
    { total: 284.74, purpose: 'entertainment' }
];

////////// Array.find() //////////
var expenseOverBudget = expenses.find(expense => expense.total > 100);

////////// for loop Equivalent //////////
var expenseOverBudget = null;

for (var i = 0; i < expenses.length; i++) {
    if (expenses[i].total > 100) {
        expenseOverBudget = expenses[i];
        break;
    }
}`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `This code sample is almost identical to the `,
                },
                {
                    type: 'code',
                    content: `some()`,
                },
                {
                    type: 'text',
                    content: ` function code sample, both in the use of the `,
                },
                {
                    type: 'code',
                    content: `find()`,
                },
                {
                    type: 'text',
                    content: ` function as well as in the <span class="font-italic">for loop</span> equivalent code. In fact, the only real difference is in what is returned as the ultimate value at the end of the function execution. From the list of expenses that we have been using throughout this article, it is clear that the function will end execution and return the first item in the list after only the first iteration. The `,
                },
                {
                    type: 'code',
                    content: `find()`,
                },
                {
                    type: 'text',
                    content: ` function code in this code sample could be read like this: <span class="font-italic">Find an expense in a list of expenses with an expense total over 100</span>.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `Just like in the case of the `,
                },
                {
                    type: 'code',
                    content: `some()`,
                },
                {
                    type: 'text',
                    content: ` function code sample, the `,
                },
                {
                    type: 'code',
                    content: `find()`,
                },
                {
                    type: 'text',
                    content: ` function code here is clearly superior because of its brevity, its ability to internally maintain all necessary results until function execution has finished, and the fact that the function name clearly indicates what type of operation is being executed on the original array. Just like with the `,
                },
                {
                    type: 'code',
                    content: `every()`,
                },
                {
                    type: 'text',
                    content: ` and `,
                },
                {
                    type: 'code',
                    content: `some()`,
                },
                {
                    type: 'text',
                    content: ` functions, if you were to see the <span class="font-italic">for loop</span> equivalent code for the `,
                },
                {
                    type: 'code',
                    content: `find()`,
                },
                {
                    type: 'text',
                    content: ` function next to that for one of those functions, it would require much more attention and thought to distinguish the two <span class="font-italic">for loops</span> apart in terms of their ultimate purpose.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `Since the `,
                },
                {
                    type: 'code',
                    content: `find()`,
                },
                {
                    type: 'text',
                    content: ` function and `,
                },
                {
                    type: 'code',
                    content: `some()`,
                },
                {
                    type: 'text',
                    content: ` function work identically except for their function return value, your choice between using them is defined by what you would like to do with the function return value. If you simply need to know that an array contains at least one item that passes a logical check and you do not need to know any more details about that item, then use the `,
                },
                {
                    type: 'code',
                    content: `some()`,
                },
                {
                    type: 'text',
                    content: ` function. If you need to know more details about that item, then use the `,
                },
                {
                    type: 'code',
                    content: `find()`,
                },
                {
                    type: 'text',
                    content: ` function.`,
                },
            ],
        },
        {
            type: 'title',
            content: `Conclusions`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `In this article we covered a couple of the most common ways in which I have observed developers interacting with arrays in Javascript (`,
                },
                {
                    type: 'code',
                    content: `forEach()`,
                },
                {
                    type: 'text',
                    content: ` and `,
                },
                {
                    type: 'code',
                    content: `map()`,
                },
                {
                    type: 'text',
                    content: `). Additionally, I presented several alternatives to those commonly used functions in order to not only highlight their existence but also to explain in what types of situations they can be used to better communicate the intentions of the code and ultimately its overall readability. I hope that this article will help many developers broaden their horizons when it comes to all Javascript array-related coding situations.`,
                },
            ],
        },
    ],
}