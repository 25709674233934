export default {
    id: '2019-11-22',
    year: 2019,
    month: 11,
    date: 22,
    title: `Developer Story: DB Migrations in NodeJS (PostgreSQL Edition)`,
    blog_url: `https://medium.com/javascript-in-plain-english/developer-story-db-migrations-postgresql-edition-3f7cf9dc790b`,
    image_url: `https://miro.medium.com/max/700/0*6exgH6bVJSUVriP2.jpg`,
    contents: [
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `As mentioned in my `,
                },
                {
                    type: 'internal_link',
                    year: 2019,
                    month: 11,
                    date: 18,
                    content: `previous`,
                },
                {
                    type: 'text',
                    content: ` developer story entry, in contrast to the contents of that entry which discuss how to create NodeJS database migrations for a MongoDB database, this entry will discuss how to create NodeJS database migrations for a Postgres database. After searching and evaluating a few similar but different packages, I chose the `,
                },
                {
                    type: 'bold_link',
                    url: `https://www.npmjs.com/package/db-migrate`,
                    content: `db-migrate`,
                },
                {
                    type: 'text',
                    content: ` and supporting `,
                },
                {
                    type: 'bold_link',
                    url: `https://www.npmjs.com/package/db-migrate-pg`,
                    content: `db-migrate-pg`,
                },
                {
                    type: 'text',
                    content: ` packages, which are at versions 0.11.6 and 1.0.0 respectively as of the time of writing this entry. The <span class="font-weight-bold">db-migrate</span> package provides the core functionality for running the DB migrations and the <span class="font-weight-bold">db-migrate-pg</span> package is a plugin that provides specific functionality for dealing with a Postgres database system.`,
                },
            ],
        },
        {
            type: 'text',
            content: `Unlike with the MongoDB migration script files, which can contain all of the code for a specific migration step up and down in a single file, this system requires that three separate files are created in order to implement a specific migration step with both up and down logic. First, if I wanted to write a migration script that creates a simple <span class="font-italic">users</span> table in my database, I would write the following:`,
        },
        {
            type: 'code',
            title: `migrations/sqls/20190101000000-create_table_users_up.sql`,
            content: `
CREATE TABLE users (
    user_id uuid NOT NULL PRIMARY KEY DEFAULT gen_random_uuid(),
    email text NOT NULL,
    password text NOT NULL,
    username text NOT NULL
);`,
        },
        {
            type: 'text',
            content: `And conversely, if I wanted to write a migration script that drops that <span class="font-italic">users</span> table from my database, I would write the following:`,
        },
        {
            type: 'code',
            title: `migrations/sqls/20190101000000-create_table_users_down.sql`,
            content: `
DROP TABLE users;`,
        },
        {
            type: 'text',
            content: `In order to complete the migration script creation process for the <span class="font-italic">users</span> table, I would write the following:`,
        },
        {
            type: 'code',
            title: `migrations/20190101000000-create_table_users.js`,
            content: `
var dbm
var type
var fs = require('fs')
var path = require('path')
var Promise

exports.setup = (options) => {
    dbm = options.dbmigrate
    type = dbm.dataType
    Promise = options.Promise
}

exports.up = (db) => {
    const filePath = path.join(__dirname, 'sqls', '20190101000000-create_table_users_up.sql')
    return new Promise((resolve, reject) => {
        fs.readFile(filePath, { encoding: 'utf-8' }, (err, data) => {
            if (err) return reject(err)
            resolve(data);
        })
    })
    .then((data) => {
        return db.runSql(data)
    })
}

exports.down = (db) => {
    const filePath = path.join(__dirname, 'sqls', '20190101000000-create_table_users_down.sql')
    return new Promise((resolve, reject) => {
        fs.readFile(filePath, { encoding: 'utf-8' }, (err, data) => {
            if (err) return reject(err)
            resolve(data);
        })
    })
    .then((data) => {
        return db.runSql(data)
    })
}`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `With these three files created and located in the correct directories within the application directory, you will be able to create a <span class="font-italic">users</span> table and drop it with a single migration script. The key thing to remember with the file contents above is that the Javascript file should be located in a <span class="font-italic">migrations</span> directory at the top level of the application directory, while the SQL files should be located in a <span class="font-italic">sqls</span> directory inside of that <span class="font-italic">migrations</span> directory. Another thing to pay attention to is that, like in the MongoDB migration script naming case, all of the file names here begin with an `,
                },
                {
                    type: 'external_link',
                    url: `https://en.wikipedia.org/wiki/ISO_8601`,
                    content: `ISO 8601`,
                },
                {
                    type: 'text',
                    content: `-like string. Since there are three files corresponding to one migration script, make sure to keep them all logically grouped by using the same date string for all related files and order them in the correct way that you want your database to be built. For instance, make sure that any referentially-dependent tables are created after the tables that they depend on and put all of your seed scripts after your migration scripts. This will ensure that there are no surprises when running your migration scripts.`,
                },
            ],
        },
        {
            type: 'text',
            content: `If you happen to browse the documentation for the <span class="text--primary font-weight-bold">db-migrate</span> and <span class="text--primary font-weight-bold">db-migrate-pg</span> NPM packages, you may find that it is in fact possible to, in the case of creating and dropping a table like I am doing above, write all of the logic within a single migration script file. But my databases never simply require just simple tables to be built. I always have the need to create extensions, create functions, and seed my tables in addition to build them, so in those cases I would need to use the strategy above to define at least some of my migration scripts and all of my seed scripts. This is because the functionality that has been built into these packages for accomplishing a single migration script file solution is not robust enough to include functions to suit all of those needs. As a result, because I prefer consistency over simplicity when writing similar and repetitive code like all of my PostgreSQL migration code, I choose to write all of my migration scripts using the above strategy. This is to ensure that the process of creating and dropping a table is the exact same and looks the same as the process to create and drop a function or extension.`,
        },
        {
            type: 'text',
            content: `Now, if I wanted to write a seed script that adds a test user to my <span class="font-italic">users</span> table, I would write the following:`,
        },
        {
            type: 'code',
            title: `migrations/sqls/20200101000000-seed_table_users_up.sql`,
            content: `
INSERT INTO users VALUES
    (DEFAULT, 'testuser@test.com', 'test_password', 'Test User');`,
        },
        {
            type: 'text',
            content: `And conversely, if I wanted to write a script that removes that user from my database, I would write the following:`,
        },
        {
            type: 'code',
            title: `migrations/sqls/20200101000000-seed_table_users_down.sql`,
            content: `
DELETE FROM users;`,
        },
        {
            type: 'text',
            content: `In order to complete the seed script creation process for the <span class="font-italic">users</span> table, I would write the following:`,
        },
        {
            type: 'code',
            title: `migrations/20200101000000-seed_table_users.js`,
            content: `
var dbm
var type
var fs = require('fs')
var path = require('path')
var Promise

exports.setup = (options) => {
    dbm = options.dbmigrate
    type = dbm.dataType
    Promise = options.Promise
}

exports.up = (db) => {
    const filePath = path.join(__dirname, 'sqls', '20200101000000-seed_table_users_up.sql')
    return new Promise((resolve, reject) => {
        fs.readFile(filePath, { encoding: 'utf-8' }, (err, data) => {
            if (err) return reject(err)
            resolve(data);
        })
    })
    .then((data) => {
        return db.runSql(data)
    })
}

exports.down = (db) => {
    const filePath = path.join(__dirname, 'sqls', '20200101000000-seed_table_users_down.sql')
    return new Promise((resolve, reject) => {
        fs.readFile(filePath, { encoding: 'utf-8' }, (err, data) => {
            if (err) return reject(err)
            resolve(data);
        })
    })
    .then((data) => {
        return db.runSql(data)
    })
}`,
        },
        {
            type: 'text',
            content: `The contents of this seed script file are identical to the contents of the migration script file earlier in this entry with the exception of the two file names that are referenced in the up and down steps. As you can see from the contents of all the migration and seed script files above, compared to the migration and seed scripts for the MongoDB migration process, the PostgreSQL migration process requires a lot more code to be written, specifically where the contents of the SQL files need to be loaded before executing them. This is a rather redundant process and results in a lot of the same code having to be written for every single migration and seed script. In most cases, this would be a bad thing and should generally be avoided as much as possible. In my opinion, however, this is specific case of redundant code is not nearly so bad because the logic is not overly complicated and simply loads some SQL file contents before passing them on to an execution function. The solution that I have provided above is about as simple as you can get if, like me, you prefer to have your Javascript and SQL code cleanly isolated in separate files. With these three files created and located in the correct directories within the application directory, you will be able to add a test user to the <span class="font-italic">users</span> table and remove all rows from the <span class="font-italic">users</span> table from within a single migration script.`,
        },
        {
            type: 'text',
            content: `Now, before I can utilize the migration and seed scripts above in some handy NPM CLI commands, I just have to create a configuration file with details about the database that will hold my <span class="font-italic">users</span> table like the following:`,
        },
        {
            type: 'code',
            title: `config/test_config.json`,
            content: `
{
    "defaultEnv": "test",
    "test": {
        "driver": "pg",
        "host": "localhost",
        "schema": "public",
        "database": "postgres",
        "user": "postgres"
    }
}`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `According to the contents of this sample configuration file there would need to be an instance of Postgres running on your local machine with a user named `,
                },
                {
                    type: 'code',
                    content: `postgres`,
                },
                {
                    type: 'text',
                    content: ` that has access to it. The `,
                },
                {
                    type: 'code',
                    content: `defaultEnv`,
                },
                {
                    type: 'text',
                    content: ` setting makes it so that we do not have to specify an additional argument in our NPM CLI commands and the configuration settings defined here will be used by default.`,
                },
            ],
        },
        {
            type: 'text',
            content: `With a migration script, a seed script, and a configuration file created, the only thing left to do is create some useful NPM CLI commands. The particular NPM CLI commands that I use in all of my Postgres database applications are the following:`,
        },
        {
            type: 'code',
            title: `package.json`,
            content: `
{
    ...,
    "scripts": {
        "migrate_down": "db-migrate down -c 1 --config ./config/test_config.json",
        "migrate_up": "db-migrate up -c 1 --config ./config/test_config.json",
        "migrate_up_all": "db-migrate up --config ./config/test_config.json"
    },
    ...,
}`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `According to these commands the configuration file from above needs to be located in a <span class="font-italic">config</span> directory at the top level of the application directory, but you can put it anywhere that you prefer as long as it is referenced properly in these commands. And with that, everything that is needed to connect to a database, create a <span class="font-italic">users</span> table, and seed that <span class="font-italic">users</span> table has been created. The thing to keep in mind when using these commands with <span class="font-weight-bold">db-migrate</span> is that the `,
                },
                {
                    type: 'code',
                    content: `migrate_down`,
                },
                {
                    type: 'text',
                    content: ` command only runs one migration file at a time for safety purposes, while the `,
                },
                {
                    type: 'code',
                    content: `migrate_up`,
                },
                {
                    type: 'text',
                    content: ` command does the same thing but in the opposite direction. In order to avoid having to run individual migration scripts up to fully complete a database migration, the `,
                },
                {
                    type: 'code',
                    content: `migrate_up_all`,
                },
                {
                    type: 'text',
                    content: ` command can be run so that all migration files through the end of the list of files in the <span class="font-italic">migrations</span> directory are run. Unfortunately, there is no status command included in the <span class="font-weight-bold">db-migrate</span> NPM package, so I cannot create an NPM CLI command for checking the migration status of the database. In order to find out where I am at in the migration process, I usually run the migrate_up command once, and then I can migrate back down if necessary.`,
                },
            ],
        },
        {
            type: 'text',
            content: `And that concludes the details about my personal system for cleanly and efficiently creating and populating a Postgres database. This was a system that I developed after seeing that such a thing was possible and being given the responsibility of building and administering a Postgres database. For added safety and separation of concerns, in professional settings I also tend to create the migration scripts on a development branch and the seed scripts on a completely isolated seed branch so that existing data is not disturbed in any way. This is definitely overfill for personal projects and other low-risk projects, but it is a good practice to keep in mind if you have serious data integrity concerns. Now that I have detailed by process for migrating both SQL and NoSQL databases, my next developer story entries will focus more on server development, since I am currently working on that part of my personal project. Please stay tuned for my next entries detailing that process and others as I make further progress on my personal project.`,
        },
    ],
}