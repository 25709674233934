<template>
    <v-timeline-item
        class="align-center"
        color="light-blue darken-4"
        fill-dot
    >
        <template #default>
            <div v-bind:class="[ `text-${align ? 'end' : 'start'}`, 'text-h6' ]">{{ year }}</div>
        </template>
        <template #icon>
            <v-icon color="white">
                {{ icons.calendarMonth }}
            </v-icon>
        </template>
    </v-timeline-item>
</template>

<script>
import { mdiCalendarMonth } from '@mdi/js'

export default {
    name: 'ResumeYear',
    props: [
        'align',
        'year',
    ],
    data() {
        return {
            icons: {
                calendarMonth: mdiCalendarMonth,
            },
        }
    },
}
</script>

<style scoped>

</style>