export default {
    ux: {
        name: 'General Assembly',
        location: 'Central, Hong Kong',
        program: 'User Experience Design',
        time: 'July 2014 - September 2014',
        topics: [
            {
                name: `Unit 1: Design Process`,
                units: [
                    `Intro to UX and Design Thinking`,
                    `Prototypes, Feedback, and Critique`,
                ],
            },
            {
                name: `Unit 2: Rapid Prototype`,
                units: [
                    `User Research`,
                    `Competitive Research`,
                    `Synthesizing Research and Creating Personas`,
                    `Defining User Goals and User Flows`,
                    `Paper Prototyping and Usability Testing Basics`,
                ],
            },
            {
                name: `Unit 3: Hi-Fidelity Prototype`,
                units: [
                    `User Stories and Feature Prioritization`,
                    `Information Architecture and Navigation`,
                    `Responsive/Native Design and Design Patterns`,
                    `Wireframing`,
                    `Visual Design Basics`,
                    `High Fidelity Prototyping`,
                    `Advanced Usability Testing`,
                ],
            },
            {
                name: `Unit 4: Refine`,
                units: [
                    `Onboarding and Behavior Change`,
                ],
            },
        ],
    },
    web: {
        name: 'General Assembly',
        location: 'Central, Hong Kong',
        program: 'Front-End Web Development',
        time: 'October 2013 - December 2013',
        topics: [
            {
                name: `Unit 1: The Basics`,
                units: [
                    `HTML Basics`,
                    `CSS Basics`,
                    `Advanced CSS`,
                    `Page Layout`,
                    `HTML/CSS Lab`,
                    `Introduction to Programming`,
                    `JavaScript Basics`,
                    `JavaScript Functions`,
                    `Intro to jQuery`,
                    `Arrays and Traversing the DOM`,
                ],
            },
            {
                name: `Unit 2: Building in Concert`,
                units: [
                    `Responsive Web Design`,
                    `Web Forms`,
                ],
            },
        ],
    },
    engineer: {
        name: 'Milwaukee School of Engineering',
        location: 'Milwaukee, Wisconsin, USA',
        program: 'Bachelor of Science in Software Engineering',
        time: 'September 2004 - May 2008',
        topics: [
            { name: `Algorithms` },
            { name: `American Government` },
            { name: `Artificial Intelligence` },
            { name: `Business Finance` },
            { name: `Design of Operating Systems` },
            { name: `Embedded Computer System Design` },
            { name: `Embedded System Software` },
            { name: `Ethics and Professional Issues in Computing` },
            { name: `Ethics for Managers and Engineers` },
            { name: `Formal Methods` },
            { name: `Global History 1` },
            { name: `Global History 2` },
            { name: `Intro to Game Programming` },
            { name: `Intro to Japanese Culture` },
            { name: `Intro to Software Verification` },
            { name: `Introduction to Database Systems` },
            { name: `Management` },
            { name: `Principles of Accounting` },
            { name: `Senior Design` },
            { name: `Software Architecture` },
            { name: `Software Design 1` },
            { name: `Software Design 2` },
            { name: `Software Development Laboratory` },
            { name: `Software Engineering Process` },
            { name: `Software Quality Assurance` },
            { name: `Speech` },
            { name: `Web Software Applications` },
        ],
    },
}