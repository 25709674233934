export default {
    id: '2019-12-17',
    year: 2019,
    month: 12,
    date: 17,
    title: `Developer Story: Configuration Single Source of Truth`,
    blog_url: `https://medium.com/javascript-in-plain-english/developer-story-configuration-single-source-of-truth-ea83a1bcb560`,
    image_url: `https://miro.medium.com/max/700/0*TtAb0ZKQvLbcvom6.jpg`,
    contents: [
        {
            type: 'text',
            content: `As a matter of principle, all server applications that I create always strictly regulate and validate all incoming information. Obviously it is always absolutely essential to ensure that any data that is received from clients is sanitized, but another set of data that I am always sure to validate before trusting it is configuration data.`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `Previously I have `,
                },
                {
                    type: 'text',
                    year: 2019,
                    month: 12,
                    date: 1,
                    content: `discussed`,
                },
                {
                    type: 'text',
                    content: ` my personal strategy for process management when it comes to NodeJS applications. In that developer story entry the method that I used to manage application processes also provided a way to deliver configuration data known as environment variables to an application. In this developer story entry I will discuss my personal strategy for validating all of that configuration data and providing a single source of truth for all configuration data to all corners of the application logic.`,
                },
            ],
        },
        {
            type: 'text',
            content: `As a reminder, the configuration data delivered to my applications through the process management system looked something like the following:`,
        },
        {
            type: 'code',
            title: `ecosystem.config.js`,
            content: `
...
DB_HOSTS: 'localhost,localhost,localhost',
DB_NAME: 'db_test',
DB_PASSWORD: 'db_password',
DB_PORTS: '27017,27018,27019',
DB_USER: 'db_user',
RS_NAME: 'db_rs',
SERVER_PORT: '1234',
...`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `This is the configuration file for the process management system that I use to maintain smooth operation of all of my server applications, and these environment variables are injected into the application on initialization and retrievable through the `,
                },
                {
                    type: 'code',
                    content: `process.env`,
                },
                {
                    type: 'text',
                    content: ` global variable available in all NodeJS applications. As I said earlier, I am very strict about not simply trusting what I find on the `,
                },
                {
                    type: 'code',
                    content: `process.env`,
                },
                {
                    type: 'text',
                    content: ` global variable. Instead, I validate all of the data contained in this global variable and build my own more programmatically consumable global configuration object.`,
                },
            ],
        },
        {
            type: 'text',
            content: `For the configuration data specified in the example above, the logic for validating this data and making it globally available would look like the following:`,
        },
        {
            type: 'code',
            title: `env.js`,
            content: `
const err = require('./util/error')
const logger = require('./log').app
const val = require('./util/validation')

let env = {}

module.exports.initialize = async () => {
    logger.info(\`Initializing environment variables\`)

    val.isNonEmptyString(process.env.DB_HOSTS, 'DB_HOSTS')
    const hosts = process.env.DB_HOSTS.split(',')
    val.isNonEmptyArray(hosts, 'DB_HOSTS', val.isNonEmptyString, 'host')
    env.DB_HOSTS = hosts

    if (hosts.length > 1) {
        val.isNonEmptyString(process.env.DB_PORTS, 'DB_PORTS')
        const ports = process.env.DB_PORTS.split(',')
        val.isNonEmptyArray(ports, 'DB_PORTS', val.isPortString, 'port')
        if (hosts.length !== ports.length) throw err.createError('ENV_HOSTS_PORTS_MISMATCH', FUNCTION_NAME)
        env.DB_PORTS = ports

        val.isNonEmptyString(process.env.RS_NAME, 'RS_NAME')
        env.RS_NAME = process.env.RS_NAME
    }

    val.isNonEmptyString(process.env.DB_NAME, 'DB_NAME')
    env.DB_NAME = process.env.DB_NAME

    val.isNonEmptyString(process.env.DB_PASSWORD, 'DB_PASSWORD')
    env.DB_PASSWORD = process.env.DB_PASSWORD

    val.isNonEmptyString(process.env.DB_USER, 'DB_USER')
    env.DB_USER = process.env.DB_USER

    val.isPortString(process.env.SERVER_PORT, 'SERVER_PORT')
    env.SERVER_PORT = process.env.SERVER_PORT

    logger.info(\`Successfully initialized environment variables\`)
}

module.exports.retrieve = () => {
    const FUNCTION_NAME = 'env.retrieve'
    try {
        if (Object.keys(env).length === 0) throw err.createError('ENV_NOT_INITIALIZED', FUNCTION_NAME)

        return env
    } catch(e) {
        const error = err.createError(e, FUNCTION_NAME)
        logger.error(error.display)
        throw error
    }
}`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `There are quite a few things going on here, some of which I have discussed previously and some of which I am presenting in code samples for the first time. One thing that you may notice is the usage of a `,
                },
                {
                    type: 'code',
                    content: `logger`,
                },
                {
                    type: 'text',
                    content: ` variable in a few different places. I previously `,
                },
                {
                    type: 'internal_link',
                    year: 2019,
                    month: 12,
                    date: 9,
                    content: `discussed`,
                },
                {
                    type: 'text',
                    content: ` my personal strategy for implementing logging functionality in all of my NodeJS applications and this is another example of using such functionality. Another thing is the error handling which is used throughout this code sample through the usage of the `,
                },
                {
                    type: 'code',
                    content: `err`,
                },
                {
                    type: 'text',
                    content: ` variable. My personal strategy for implementing error handling functionality in all of my NodeJS applications is something that I will discuss in a later developer story entry. Those two things aside, the bulk of the logic in this code sample is implemented in the `,
                },
                {
                    type: 'code',
                    content: `initialize()`,
                },
                {
                    type: 'text',
                    content: ` function utilizing the `,
                },
                {
                    type: 'code',
                    content: `val`,
                },
                {
                    type: 'text',
                    content: ` variable to perform validation on all of the configuration data provided on application initialization.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `As you will notice, the `,
                },
                {
                    type: 'code',
                    content: `val`,
                },
                {
                    type: 'text',
                    content: ` variable is initialized at the top of the script by importing code from a utility validation module in another part of the application. In that validation module exist a number of very light-weight functions for checking pieces of data throughout the application. Mainly it is used here to check that most of the configuration data is non-empty strings, but there are many other useful validation functions in that validation module that serve a great many purposes throughout the entire application. Some of this validation logic is implemented with the help of the `,
                },
                {
                    type: 'bold_link',
                    url: `https://www.npmjs.com/package/validator`,
                    content: `validator`,
                },
                {
                    type: 'text',
                    content: ` NodeJS package, which I highly recommend looking into if you require any kind of string validation in your applications.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `Although most of the logic here is pretty straightforward, being just simple string validation, one of the main things that I validate is that the number of database hosts matches the number of database ports. Since I primarily use MongoDB with most of my NodeJS applications and all situations after early development require a MongoDB instance to be implemented with a replica set database configuration, this logic is included to ensure that the two lists of hosts and ports match. If this check fails then the entire validation process fails, because if the application cannot properly connect to the database then what sense is there in starting the application in the first place? Once all of the validation is complete and construction of the `,
                },
                {
                    type: 'code',
                    content: `env`,
                },
                {
                    type: 'text',
                    content: ` variable initialized at the top of the script has completed, the global object is ready for retrieval everywhere in the application through the use of the `,
                },
                {
                    type: 'code',
                    content: `retrieve()`,
                },
                {
                    type: 'text',
                    content: ` function. As you may notice from looking at the logic for this function, trying to retrieve the global configuration object before initializing it will throw an error. This ensures that the application will not complete initialization without the global configuration object being successfully initialized.`,
                },
            ],
        },
        {
            type: 'text',
            content: `As you may have already realized, this global configuration object holds immense value for setup of the entire application. In order to ensure that it has been created before it is retrieved anywhere in the application the following code appears in the startup script for all of my NodeJS applications:`,
        },
        {
            type: 'code',
            title: `server.js`,
            content: `
async function run() {
    logger.info(\`Initializing application\`)

    const env = require('./env')
    await env.initialize()
    ...
    })
}`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The global configuration object is initialized at the absolute beginning of the application initialization process before anything else happens, including connection to the database or initialization of any of the other application modules. From that point forward all configuration data will be available through the `,
                },
                {
                    type: 'code',
                    content: `env`,
                },
                {
                    type: 'text',
                    content: ` variable when the following line of code is placed at the top of any file in any module in the entire application:`,
                },
            ],
        },
        {
            type: 'code',
            content: `const env = require(‘../env’).retrieve()`,
        },
        {
            type: 'text',
            content: `Besides configuration data needed for the database connection process, this configuration data should be used anywhere that a hardcoded string would normally appear. The reason why it is better to gather all of an application’s hardcoded strings into a single place is so that they can be specified appropriately depending on the environmental context in which the application is being run. These hardcoded strings could be used for everything from your tokenization logic used in the authentication process to the application’s interfaces with other server systems where an API URL and ID are needed to authenticate with those systems.`,
        },
        {
            type: 'text',
            content: `It really is up to you as the developer to choose how much of your system uses this strategy for propagating configuration data to the rest of the application. But it is a good idea to err on the side of caution and maximize its use, if for no other reason than the fact that utilizing this strategy will allow you to validate all of that specified configuration data so that you can be sure it is correct when it is used to initialize your application.`,
        },
        {
            type: 'text',
            content: `And with that, you now have a good idea about how I validate and propagate configuration data throughout the entirety of all of my NodeJS applications. Although this strategy was not something that I developed immediately when I first began implementing NodeJS applications, it has certainly become a cornerstone feature of all of the NodeJS applications that I create now both in my professional career and in my private development.`,
        },
        {
            type: 'text',
            content: `I sincerely hope that my sharing it here will provide you with a good idea of how to implement such a system in all of your NodeJS applications as well. Please stay tuned for more developer story entries as I continue to make further progress on my personal project.`,
        },
    ],
}