import Vue from 'vue'
import VueRouter from 'vue-router'
import goTo from 'vuetify/es5/services/goto'

// Views
import Blog from '../views/Blog'
import Blogs from '../views/Blogs'
import Home from '../views/Home'
import Reading from '../views/Reading'
import Resume from '../views/Resume'
import Project from '../views/Project'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home,
    },
    {
        path: '/resume',
        name: 'resume',
        component: Resume,
    },
    {
        path: '/reading',
        name: 'reading',
        component: Reading,
    },
    {
        path: '/blogs',
        name: 'blogs',
        component: Blogs,
        props: route => {
            return {
                params: {
                    year: 0,
                    month: 0,
                    date: 0,
                },
            }
        },
    },
    {
        path: '/blogs/:year',
        name: 'blogs_year',
        component: Blogs,
        props: route => {
            return {
                params: {
                    year: route.params.year && !isNaN(route.params.year) ? parseInt(route.params.year) : 0,
                    month: 0,
                },
            }
        },
    },
    {
        path: '/blogs/:year/:month',
        name: 'blogs_month',
        component: Blogs,
        props: route => {
            return {
                params: {
                    year: route.params.year && !isNaN(route.params.year) ? parseInt(route.params.year) : 0,
                    month: route.params.month && !isNaN(route.params.month) ? parseInt(route.params.month) : 0,
                },
            }
        },
    },
    {
        path: '/blogs/:year/:month/:date',
        name: 'blogs_date',
        component: Blog,
        props: route => {
            return {
                params: {
                    year: route.params.year && !isNaN(route.params.year) ? parseInt(route.params.year) : 0,
                    month: route.params.month && !isNaN(route.params.month) ? parseInt(route.params.month) : 0,
                    date: route.params.date && !isNaN(route.params.date) ? parseInt(route.params.date) : 0,
                },
            }
        },
    },
    {
        path: '/project',
        name: 'project',
        component: Project,
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior: (to, from, savedPosition) => {
        let scrollTo = 0

        if (to.hash) {
            scrollTo = to.hash
        } else if (savedPosition) {
            scrollTo = savedPosition.y
        }

        return goTo(scrollTo)
    },
    routes,
})

router.beforeEach((to, from, next) => {
    if (!to.name) {
        next({ name: 'home' })
    } else {
        next()
    }
})

export default router
