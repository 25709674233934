export default {
    id: '2020-03-13',
    year: 2020,
    month: 3,
    date: 13,
    title: `An In-Depth Exploration of the Array.slice() Function`,
    blog_url: `https://levelup.gitconnected.com/an-in-depth-exploration-of-the-array-slice-function-e5b9b5e3583b`,
    image_url: `https://images.unsplash.com/photo-1505238680356-667803448bb6`,
    image_caption: `Photo by <a class="text--primary" href="https://unsplash.com/@blakeconnally">Blake Connally</a> on <a class="text--primary" href="https://unsplash.com/s/photos/javascript-code">Unsplash</a>`,
    contents: [
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `There have been many useful functions that have been added to the Javascript `,
                },
                {
                    type: 'code',
                    content: `Array`,
                },
                {
                    type: 'text',
                    content: ` global object over the last few years that offer developers a wide variety of options when they are writing code that works with arrays. These functions offer a number of advantages, the most noteworthy of which is the fact that, while at one time in the past developers had to implement their own complex logic to perform various array operations, now the need for such homegrown implementations has been eliminated by all of these new functions. One of those useful functions that will be explored in this article is the `,
                },
                {
                    type: 'code_link',
                    url: `https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/slice`,
                    content: `slice()`,
                },
                {
                    type: 'text',
                    content: ` function.`,
                },
            ],
        },
        {
            type: 'title',
            content: `Function Overview`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The `,
                },
                {
                    type: 'code',
                    content: `slice()`,
                },
                {
                    type: 'text',
                    content: ` function provides the ability to shallow copy a portion of the items in an array into a completely new array and return that new array upon function completion. As this function produces a completely new array, the original array is left completely unaltered once the function has completed the operation. No further precautions need to be taken before beginning the operation in order to maintain a copy of the original array.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The `,
                },
                {
                    type: 'code',
                    content: `slice()`,
                },
                {
                    type: 'text',
                    content: ` function takes up to two parameters, both of which are zero-based indexes and both of which are <span class="font-italic">optional</span>. If either of these parameters has a negative value, they will be counted from the end of the array instead of from the beginning. This means that a parameter value of `,
                },
                {
                    type: 'code',
                    content: `-3`,
                },
                {
                    type: 'text',
                    content: ` would actually result in the value of that parameter being `,
                },
                {
                    type: 'code',
                    content: `3`,
                },
                {
                    type: 'text',
                    content: ` less than the length of the array (`,
                },
                {
                    type: 'code',
                    content: `Array.length + -3`,
                },
                {
                    type: 'text',
                    content: `).`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The first parameter is the `,
                },
                {
                    type: 'code',
                    content: `begin`,
                },
                {
                    type: 'text',
                    content: ` parameter. This parameter is the starting index from which array items will be copied and the copied items <span class="font-italic font-weight-bold">will</span> include the item at this index. As this parameter is optional, it has a default value of `,
                },
                {
                    type: 'code',
                    content: `0`,
                },
                {
                    type: 'text',
                    content: ` and array items will be copied starting from the beginning of the array if this parameter is not specified. The second parameter is the `,
                },
                {
                    type: 'code',
                    content: `end`,
                },
                {
                    type: 'text',
                    content: ` parameter. This parameter is the ending index up to which array items will be copied and the copied items <span class="font-italic font-weight-bold">will not</span> include the item at this index. As this parameter is optional, it has a default value of the length of the array (`,
                },
                {
                    type: 'code',
                    content: `Array.length`,
                },
                {
                    type: 'text',
                    content: `) and array items will be copied up to the last index of the array if this parameter is not specified.`,
                },
            ],
        },
        {
            type: 'title',
            content: `No Parameters`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `With the general function behavior covered, let’s take a look at some examples of how the `,
                },
                {
                    type: 'code',
                    content: `slice()`,
                },
                {
                    type: 'text',
                    content: ` function works in practice. The following example demonstrates the situation where no parameter values are specified:`,
                },
            ],
        },
        {
            type: 'code',
            content: `var array1 = ['abc', 'def', 'ghi', 'jkl', 'mno'];
var array2 = array1.slice();
// array2: ['abc', 'def', 'ghi', 'jkl', 'mno']`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The `,
                },
                {
                    type: 'code',
                    content: `slice()`,
                },
                {
                    type: 'text',
                    content: ` function is called with no parameter values. Considering the optional parameter default values, this function call is the same as `,
                },
                {
                    type: 'code',
                    content: `slice(0, 5)`,
                },
                {
                    type: 'text',
                    content: `. This means that all of the array items from the beginning to the end of the array are copied into the new array. Using the `,
                },
                {
                    type: 'code',
                    content: `slice()`,
                },
                {
                    type: 'text',
                    content: ` function in this way provides the ability to make a completely new copy of the entire original array if such functionality is required. There are many different ways to achieve such a result, and calling the `,
                },
                {
                    type: 'code',
                    content: `slice()`,
                },
                {
                    type: 'text',
                    content: ` function with no parameters is just one of those viable options.`,
                },
            ],
        },
        {
            type: 'title',
            content: `Positive Begin`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The following example demonstrates the situation where only a positive `,
                },
                {
                    type: 'code',
                    content: `begin`,
                },
                {
                    type: 'text',
                    content: ` parameter value is specified:`,
                },
            ],
        },
        {
            type: 'code',
            content: `var array1 = ['abc', 'def', 'ghi', 'jkl', 'mno'];
var array2 = array1.slice(2);
// array2: ['ghi', 'jkl', 'mno']`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The `,
                },
                {
                    type: 'code',
                    content: `slice()`,
                },
                {
                    type: 'text',
                    content: ` function is called with only a `,
                },
                {
                    type: 'code',
                    content: `begin`,
                },
                {
                    type: 'text',
                    content: ` parameter value of `,
                },
                {
                    type: 'code',
                    content: `2`,
                },
                {
                    type: 'text',
                    content: `. Considering the optional parameter default values, this function call is the same as `,
                },
                {
                    type: 'code',
                    content: `slice(2, 5)`,
                },
                {
                    type: 'text',
                    content: `. Since there are only three index places left in the array when beginning at index `,
                },
                {
                    type: 'code',
                    content: `2`,
                },
                {
                    type: 'text',
                    content: `, only the last three items in the array are copied into the new array.`,
                },
            ],
        },
        {
            type: 'title',
            content: `Negative Begin`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The following example demonstrates the situation where only a negative `,
                },
                {
                    type: 'code',
                    content: `begin`,
                },
                {
                    type: 'text',
                    content: ` parameter value is specified:`,
                },
            ],
        },
        {
            type: 'code',
            content: `var array1 = ['abc', 'def', 'ghi', 'jkl', 'mno'];
var array2 = array1.slice(-2);
// array2: ['jkl', 'mno']`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The `,
                },
                {
                    type: 'code',
                    content: `slice()`,
                },
                {
                    type: 'text',
                    content: ` function is called with only a `,
                },
                {
                    type: 'code',
                    content: `begin`,
                },
                {
                    type: 'text',
                    content: ` parameter value of `,
                },
                {
                    type: 'code',
                    content: `-2`,
                },
                {
                    type: 'text',
                    content: `. Considering the optional parameter default values and how negative parameter values are evaluated, this function call is the same as `,
                },
                {
                    type: 'code',
                    content: `slice(3, 5)`,
                },
                {
                    type: 'text',
                    content: `. Since there are only two index places left in the array when beginning at index `,
                },
                {
                    type: 'code',
                    content: `3`,
                },
                {
                    type: 'text',
                    content: `, only the last two items in the array are copied into the new array.`,
                },
            ],
        },
        {
            type: 'title',
            content: `Positive Begin, Positive End`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The following example demonstrates the situation where positive `,
                },
                {
                    type: 'code',
                    content: `begin`,
                },
                {
                    type: 'text',
                    content: ` and `,
                },
                {
                    type: 'code',
                    content: `end`,
                },
                {
                    type: 'text',
                    content: ` parameter values are specified:`,
                },
            ],
        },
        {
            type: 'code',
            content: `var array1 = ['abc', 'def', 'ghi', 'jkl', 'mno'];
var array2 = array1.slice(1, 3);
// array2: ['def', 'ghi']`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The `,
                },
                {
                    type: 'code',
                    content: `slice()`,
                },
                {
                    type: 'text',
                    content: ` function is called with a `,
                },
                {
                    type: 'code',
                    content: `begin`,
                },
                {
                    type: 'text',
                    content: ` parameter value of `,
                },
                {
                    type: 'code',
                    content: `1`,
                },
                {
                    type: 'text',
                    content: ` and an `,
                },
                {
                    type: 'code',
                    content: `end`,
                },
                {
                    type: 'text',
                    content: ` parameter value of `,
                },
                {
                    type: 'code',
                    content: `3`,
                },
                {
                    type: 'text',
                    content: `. With the bounds of the array items to be copied starting at index `,
                },
                {
                    type: 'code',
                    content: `1`,
                },
                {
                    type: 'text',
                    content: ` and ending at index `,
                },
                {
                    type: 'code',
                    content: `2`,
                },
                {
                    type: 'text',
                    content: `, only two array items are copied into the new array. One important thing to keep in mind when specifying a positive `,
                },
                {
                    type: 'code',
                    content: `end`,
                },
                {
                    type: 'text',
                    content: ` parameter value is that if the value is greater than the length of the array, the `,
                },
                {
                    type: 'code',
                    content: `slice()`,
                },
                {
                    type: 'text',
                    content: ` function will ignore the `,
                },
                {
                    type: 'code',
                    content: `end`,
                },
                {
                    type: 'text',
                    content: ` parameter value and instead copy items through to the end of the array.`,
                },
            ],
        },
        {
            type: 'title',
            content: `Positive Begin, Negative End`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The following example demonstrates the situation where a positive `,
                },
                {
                    type: 'code',
                    content: `begin`,
                },
                {
                    type: 'text',
                    content: ` parameter value and a negative `,
                },
                {
                    type: 'code',
                    content: `end`,
                },
                {
                    type: 'text',
                    content: ` parameter value are specified:`,
                },
            ],
        },
        {
            type: 'code',
            content: `var array1 = ['abc', 'def', 'ghi', 'jkl', 'mno'];
var array2 = array1.slice(2, -3);
// array2: []`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The `,
                },
                {
                    type: 'code',
                    content: `slice()`,
                },
                {
                    type: 'text',
                    content: ` function is called with a `,
                },
                {
                    type: 'code',
                    content: `begin`,
                },
                {
                    type: 'text',
                    content: ` parameter value of `,
                },
                {
                    type: 'code',
                    content: `2`,
                },
                {
                    type: 'text',
                    content: ` and an `,
                },
                {
                    type: 'code',
                    content: `end`,
                },
                {
                    type: 'text',
                    content: ` parameter value of `,
                },
                {
                    type: 'code',
                    content: `-3`,
                },
                {
                    type: 'text',
                    content: `. Considering how negative parameter values are evaluated, this function call is the same as `,
                },
                {
                    type: 'code',
                    content: `slice(2, 2)`,
                },
                {
                    type: 'text',
                    content: `. This code sample is the first example that we have seen in this article where the `,
                },
                {
                    type: 'code',
                    content: `begin`,
                },
                {
                    type: 'text',
                    content: ` and `,
                },
                {
                    type: 'code',
                    content: `end`,
                },
                {
                    type: 'text',
                    content: ` parameter values are the same. Due to the fact that these two parameter values reference the same index, no array items are actually copied into the new array, resulting in an empty array being returned upon function completion.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `This same behavior where an empty array is returned will occur in any of the following situations when using the `,
                },
                {
                    type: 'code',
                    content: `slice()`,
                },
                {
                    type: 'text',
                    content: ` function: the `,
                },
                {
                    type: 'code',
                    content: `begin`,
                },
                {
                    type: 'text',
                    content: ` and `,
                },
                {
                    type: 'code',
                    content: `end`,
                },
                {
                    type: 'text',
                    content: ` parameters reference the same index; the `,
                },
                {
                    type: 'code',
                    content: `end`,
                },
                {
                    type: 'text',
                    content: ` parameter index falls before the `,
                },
                {
                    type: 'code',
                    content: `begin`,
                },
                {
                    type: 'text',
                    content: ` parameter index; the `,
                },
                {
                    type: 'code',
                    content: `begin`,
                },
                {
                    type: 'text',
                    content: ` parameter value is equal to or greater than the length of the array; and the `,
                },
                {
                    type: 'code',
                    content: `end`,
                },
                {
                    type: 'text',
                    content: ` parameter value is equal to or less than `,
                },
                {
                    type: 'code',
                    content: `0`,
                },
                {
                    type: 'text',
                    content: `.`,
                },
            ],
        },
        {
            type: 'title',
            content: `Lessons Learned`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `There are a few lessons to take away from this article regarding the `,
                },
                {
                    type: 'code',
                    content: `slice()`,
                },
                {
                    type: 'text',
                    content: ` function. The first thing to keep in mind is that the `,
                },
                {
                    type: 'code',
                    content: `slice()`,
                },
                {
                    type: 'text',
                    content: ` function does not alter the original array in any way so there is no need to maintain its state before using the function. The only array that will be altered by the `,
                },
                {
                    type: 'code',
                    content: `slice()`,
                },
                {
                    type: 'text',
                    content: ` function is the completely new array that is returned once the function has completed.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The second and most important thing to keep in mind about the `,
                },
                {
                    type: 'code',
                    content: `slice()`,
                },
                {
                    type: 'text',
                    content: ` function is how it copies the items contained in the original array. For array items that are primitives like strings, numbers, and booleans, the `,
                },
                {
                    type: 'code',
                    content: `slice()`,
                },
                {
                    type: 'text',
                    content: ` function will copy their value into the new array. This means that any changes that are made to copied strings, numbers, or booleans in either the original array or the new array will not affect the other array. The following example shows this functionality in action:`,
                },
            ],
        },
        {
            type: 'code',
            content: `var array1 = ['abc', 'def', 'ghi'];
var array2 = array1.slice();

// array1: ['abc', 'def', 'ghi']
// array2: ['abc', 'def', 'ghi']

array2[2] = 'cba';
array2[1] = 'fed';
array2[0] = 'ihg';

// array1: ['abc', 'def', 'ghi']
// array2: ['ihg', 'fed', 'cba']`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `Making changes to the primitive values in the new array that was produced by the `,
                },
                {
                    type: 'code',
                    content: `slice()`,
                },
                {
                    type: 'text',
                    content: ` function does not result in any changes being made to the primitive values in the original array. Only the new array will reflect any primitive value changes being made.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `Unlike how it treats primitives, the `,
                },
                {
                    type: 'code',
                    content: `slice()`,
                },
                {
                    type: 'text',
                    content: ` function treats object references differently. For array items that are object references, the `,
                },
                {
                    type: 'code',
                    content: `slice()`,
                },
                {
                    type: 'text',
                    content: ` function will copy those references into the new array. This means that any object references that are copied from the original array into the new array will refer to the exact same object. If any changes are made to a referenced object, the changes will be seen in both the original array and the new array. The following example shows this functionality in action:`,
                },
            ],
        },
        {
            type: 'code',
            content: `var objectRef = { abc: 123 };
var array1 = [ objectRef ];
var array2 = array1.slice();

// array1: [{ abc: 123 }]
// array2: [{ abc: 123 }]

objectRef.def = 456;

// array1: [{ abc: 123, def: 456 }]
// array2: [{ abc: 123, def: 456 }]`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `Making changes to the object that is referenced in the new array that was produced by the `,
                },
                {
                    type: 'code',
                    content: `slice()`,
                },
                {
                    type: 'text',
                    content: ` function will also result in those changes being reflected in the original array. Both the original array and the new array will reflect any referenced object changes being made.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The final thing to keep in mind when using the `,
                },
                {
                    type: 'code',
                    content: `slice()`,
                },
                {
                    type: 'text',
                    content: ` function is that the original array and the new array produced by the `,
                },
                {
                    type: 'code',
                    content: `slice()`,
                },
                {
                    type: 'text',
                    content: ` function are truly completely separate arrays in terms of the list of items that they contain. This means that adding items to or removing items from either array will not affect the other array. The following example shows this functionality in action:`,
                },
            ],
        },
        {
            type: 'code',
            content: `var array1 = ['abc', 'def', 'ghi'];
var array2 = array1.slice();

// array1: ['abc', 'def', 'ghi']
// array2: ['abc', 'def', 'ghi']

array2.shift();
array2.push('jkl')

// array1: ['abc', 'def', 'ghi']
// array2: ['def', 'ghi', 'jkl']`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The new array produced by the `,
                },
                {
                    type: 'code',
                    content: `slice()`,
                },
                {
                    type: 'text',
                    content: ` function is first altered so that the first item in the array is removed and all other items are shifted down one index using the `,
                },
                {
                    type: 'code',
                    content: `shift()`,
                },
                {
                    type: 'text',
                    content: ` function. Second, a new item is added to the end of the new array using the `,
                },
                {
                    type: 'code',
                    content: `push()`,
                },
                {
                    type: 'text',
                    content: ` function. The original array remains unaltered while the new array reflects the changes.`,
                },
            ],
        },
        {
            type: 'title',
            content: `Conclusions`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `Thank you very much for reading this article. I hope that this exploration of the `,
                },
                {
                    type: 'code',
                    content: `slice()`,
                },
                {
                    type: 'text',
                    content: ` function on the Javascript `,
                },
                {
                    type: 'code',
                    content: `Array`,
                },
                {
                    type: 'text',
                    content: ` global object has been informative and I hope that after gaining some knowledge about it you will be able to put it to good use in your own code. I encourage you to consult the resource link below for all information pertaining to the `,
                },
                {
                    type: 'code',
                    content: `slice()`,
                },
                {
                    type: 'text',
                    content: ` function if you still have any further questions about how it works. Please stop back in the future for more articles about interesting and useful functions that can be found on the Javascript `,
                },
                {
                    type: 'code',
                    content: `Array`,
                },
                {
                    type: 'text',
                    content: ` global object.`,
                },
            ],
        },
    ],
}