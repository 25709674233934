const initialState = {
    showNavbar: false,
}

export default {
    namespaced: true,
    state: { ...initialState },
    actions: {
        toggleNavbar({ commit }) {
            commit('TOGGLE_NAVBAR')
        },
    },
    getters: {
        showNavbar: state => state.showNavbar,
    },
    mutations: {
        RESET_STATE(state) {
            Object.keys(initialState).forEach(key => {
                state[key] = initialState[key]
            })
        },
        TOGGLE_NAVBAR(state) {
            state.showNavbar = !state.showNavbar
        },
    },
}