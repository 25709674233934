<template>
    <div class="pt-14">
        <page-title>Batman: The Animated Series</page-title>
    </div>
</template>

<script>
// Components
import PageTitle from '../components/application/PageTitle'

export default {
    name: 'Project',
    components: {
        PageTitle,
    },
}
</script>

<style scoped>

</style>