export default {
    id: '2020-02-11',
    year: 2020,
    month: 2,
    date: 11,
    title: `Developer Story: Progress Continues`,
    blog_url: `https://keithvictordawson.medium.com/developer-story-progress-continues-c8834a8cfdda`,
    image_url: `https://images.unsplash.com/photo-1515704089429-fd06e6668458`,
    image_option: `Photo by <a class="text--primary" href="https://unsplash.com/@badashproducts">Ash Edmonds</a> on <a class="text--primary" href="https://unsplash.com/collections/3630762/software-development">Unsplash</a>`,
    contents: [
        {
            type: 'text',
            content: `It has been a little over a month since the last time that I wrote a developer story entry, so I wanted to check in with a progress report of what I have been doing lately with regards to my personal project. I have made a lot of progress on my personal project over the past month, both on the client side as well as on the server side, with most of the effort being put into the client side. As I had mentioned in a previous developer story entry right before the new year, I shifted my development focus to client side beginning last month and continuing on into this month. I also want to provide some updates on a recent expansion that I made to the scope of my personal project. Finally, I want to share my plans regarding my upcoming development schedule for the next month and a half through the end of this first quarter of 2020.`,
        },
        {
            type: 'text',
            content: `In terms of server-side development, there are a couple of things that I have completed since the beginning of the year. In order to support all of the client-side development that I have completed over the past few weeks, I made several improvements not only on the primary server API application that I will be using as the backend for my personal project, but I also made some database changes as well. As it currently stands right now, my database schema is the bare minimum necessary to support a simple language learning application. One of the database changes that I made separated one of the models that I use in my database schema into two models. This will make it easier to work with the data in those two models going forward and can also allow for the expansion of both those models as my personal project continues to develop further going forward. I also adjusted all of the views that I use for accessing data in the database as requested by the client. In response to all of these database changes, I also made all of the appropriate corresponding server application changes in order to make the new and updated data structures accessible to all of the clients.`,
        },
        {
            type: 'text',
            content: `In the future, I will definitely be expanding further on the database schema that I currently have in order to support more features that I plan to add to the client-side applications that I am developing. These features will require new database models, which will require further database work as well as work on the server application in order to make those new database structures available to the client applications. I have two different flavors of client-side applications in my system, admin and user, and the changes that I have planned will serve new functionality in both of those contexts. On the admin side, I plan to add a lot of activity tracking so that the users of the admin system have a clear view of all of the changes that are made throughout the system by both other admins and regular users. Also, users of the admin system will gain access to additional functionality to build a lot of content that will be made available in the expanded feature set in the client applications. On the user side, I plan to add more features for not only language learning but also cultural learning as well. All of these features support the expanded scope that I have envisioned for my personal project.`,
        },
        {
            type: 'text',
            content: `On the client side, since the new year, I have built initial versions of the admin and user web applications for my personal project. Both of these web applications are built using ReactJS, which is the main client-side framework that I will focus on using for all of the client-side applications that I develop for my personal project. For the admin web application, I wanted to provide a more minimalistic experience, since it is basically going to be a CMS. As a result, I chose to use Microsoft’s Fabric UI framework and component library to build the initial version of that application. For the user web application, I want the application to look more modern and for the design to align with many other applications that are on the internet. As a result, I chose to use the Material UI framework and component library to build the initial version of that application. Both of these applications will obviously be expanded upon further in the future, but for now I wanted to make sure that I at least have a base application to work with while I further develop all of the ideas that I am exploring for my personal project.`,
        },
        {
            type: 'text',
            content: `Additionally on the client side, I will also be building a user mobile application, which is something that I have never done before. In order to achieve this goal, I will be using React Native. Also, since the user web application will be using the Material UI framework to establish its look and feel, I will be using Material UI in the user mobile application as well. Much like with the web applications, which at this point are only starting points for future expansion, I will be getting the mobile application to an initial starting point during this first phase, which should be complete by the end of this month. In the future, I will expand on the mobile application feature set along with the feature sets of the web applications.`,
        },
        {
            type: 'text',
            content: `As for the future of my personal project, one of the biggest developments since the new year has been my decision to expand the scope of the overall application suite. Not only will the application provide functionality for learning my native tongue, but it will also provide functionality for learning more about my native culture as well. I want the application suite that results from my personal project to provide users with access to a wealth of information about my native language and culture, all at their fingertips, whether they are on a computer or on their smart phone. I believe that making all of this information more easily accessible will serve to further strengthen the bond between my people and their bond with our collective culture. It is harder and harder for all of us to connect to the culture when we are a relatively small group and we are spread out over the entire world. I truly hope that the application suite that comes out of my personal project will allow those connections to be made much easier in the future. This will ensure that our culture can avoid extinction and survive well into the future.`,
        },
        {
            type: 'text',
            content: `And that wraps up the latest progress update for my personal project. I know that quite a bit of time has passed since my last update. This does not mean that I have not been working hard as well as writing about other topics related to software engineering. I hope to provide more frequent updates on my progress going forward in the future. Please stay tuned for more developer story entries as I continue making further progress on my personal project.`,
        },
    ],
}