<template>
    <v-container
        class="blue lighten-4"
        fill-height
        fluid
        id="resume"
        style="background: url('https://images.unsplash.com/photo-1526327227970-4bda49fa3489'); background-size: cover; height: 100%; width: 100%;"
    >
        <v-layout
            align-center
            column
            justify-center
        >
            <div class="text-h2 font-weight-black white--text text-xs-center hidden-sm-and-down">Keith Dawson</div>
            <div class="text-h3 font-weight-black white--text text-xs-center hidden-md-and-up">Keith Dawson</div>

            <div class="text-h3 font-weight-black white--text text-xs-center hidden-sm-and-down">Software Architect / Software Engineer</div>
            <div class="text-h5 font-weight-black white--text text-xs-center hidden-md-and-up hidden-xs-only">Software Architect / Software Engineer</div>
            <div class="text-h6 font-weight-black white--text text-xs-center hidden-sm-and-up">Software Architect / Software Engineer</div>
            <!-- Display only on extra small screens -->
            <div class="d-flex d-sm-none flex-column">
                <resume-resume-button />
                <resume-linkedin-button />
            </div>
            <!-- Display on small and larger screens -->
            <div class="d-none d-sm-flex">
                <resume-resume-button />
                <resume-linkedin-button />
            </div>
        </v-layout>
    </v-container>
</template>

<script>
// Components
import ResumeLinkedinButton from './ResumeLinkedinButton'
import ResumeResumeButton from './ResumeResumeButton'

export default {
    name: 'HomeResume',
    components: {
        ResumeLinkedinButton,
        ResumeResumeButton,
    },
}
</script>

<style scoped>

</style>