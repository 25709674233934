<template>
    <v-col class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <v-hover #default="{ hover }">
            <v-card
                :class="{ 'on-hover': hover }"
                :elevation="hover ? 12 : 0"
                :to="`/blogs/${blog.year}/${blog.month}/${blog.date}`"
            >
                <v-list-item style="height: 100px;">
                    <v-row>
                        <v-col>
                            <div class="text-h7 font-weight-bold">{{ blog.title }}</div>
                            <div class="text-caption font-weight-bold grey--text text--darken-1">{{ dateString(blog) }}</div>
                        </v-col>
                    </v-row>
                </v-list-item>
                <v-img
                    height="300px"
                    :lazy-src="placeholder"
                    :src="blog.image_url"
                >
                    <template #default>
                        <v-expand-transition>
                            <div
                                v-if="hover"
                                class="d-flex transition-fast-in-fast-out align-center justify-center light-blue darken-4 text-h4 white--text"
                                style="bottom: 0; height: 100%; opacity: .5; position: absolute; width: 100%;"
                            >
                                Read
                            </div>
                        </v-expand-transition>
                    </template>
                    <template #placeholder>
                        <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                        >
                            <v-progress-circular
                                indeterminate
                                class="light-blue--text text--darken-4"
                            />
                        </v-row>
                    </template>
                </v-img>
            </v-card>
        </v-hover>
    </v-col>
</template>

<script>
import helpers from '../../assets/helpers'

export default {
    name: 'BlogItem',
    props: {
        blog: {
            type: Object,
        },
        placeholder: {
            type: String,
        },
    },
    methods: {
        dateString: function({ year, month, date }) {
            return `${year} ${helpers.monthString(month)} ${date}`
        },
    },
}
</script>

<style scoped>

</style>