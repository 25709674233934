<template>
    <resume-item
        :icon="icons.officeBuilding"
        :item-id="jobId"
        :time="time"
    >
        <template #header>
            <resume-item-header
                :subtitle="`at ${company}`"
                :title="position"
            />
        </template>
        <template #content>
            <resume-item-content-item
                :content="location"
                title="Location: "
            />
            <resume-item-content-item
                :content="tech"
                title="Technologies Used: "
            />
            <ul class="mt-3">
                <li v-for="(task, index) in tasks" :key="index">
                    <resume-item-list-item :content="task" />
                </li>
            </ul>
        </template>
    </resume-item>
</template>

<script>
import { mdiOfficeBuilding } from '@mdi/js'

// Components
import ResumeItem from './ResumeItem'
import ResumeItemContentItem from './ResumeItemContentItem'
import ResumeItemHeader from './ResumeItemHeader'
import ResumeItemListItem from './ResumeItemListItem'

export default {
    name: 'ResumeJob',
    components: {
        ResumeItem,
        ResumeItemContentItem,
        ResumeItemHeader,
        ResumeItemListItem,
    },
    props: [
        'company',
        'jobId',
        'location',
        'position',
        'tasks',
        'tech',
        'time',
    ],
    data() {
        return {
            icons: {
                officeBuilding: mdiOfficeBuilding,
            },
        }
    },
}
</script>

<style scoped>

</style>