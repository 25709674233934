export default {
    id: '2020-01-12',
    year: 2020,
    month: 1,
    date: 12,
    title: `An SE’s Perspective: The Elements of User Experience (Part 1)`,
    blog_url: `https://keithvictordawson.medium.com/an-ses-perspective-the-elements-of-user-experience-part-1-fb526177279a`,
    image_url: `https://miro.medium.com/max/700/1*De5Lfps7YDWxi1LBTAwGyw.png`,
    image_caption: `The Elements of User Experience, Jesse James Garrett`,
    contents: [
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `In this series I read through each chapter of the groundbreaking UX design book `,
                },
                {
                    type: 'italic_link',
                    url: `http://www.jjg.net/elements/`,
                    content: `The Elements of User Experience`,
                },
                {
                    type: 'text',
                    content: ` and discuss the content from my perspective as a software engineer. The author of this book, Jesse James Garrett, wrote it in the hope that it would help UX designers or anyone else interested in UX design to understand the decisions that UX practitioners make during the course of their work. Unlike the first edition, this second edition of the book is not so heavily focused on UX as it pertains to web development, but rather written in such a way as to apply to any context in which UX plays an important role. Please keep in mind that my perspective comes from the direction not of a UX designer but of a software engineer who strives to wield the principles of UX as a tool to inform all of the software development decisions that I make and enhance all of the software that I create. As a result, all discussion about this book will be in that context.`,
                },
            ],
        },
        {
            type: 'spacer',
        },
        {
            type: 'highlight',
            content: `We have a double-edged relationship with the products and services we use. They empower us and frustrate us; they simplify and complicate our lives; they separate us and bring us closer together. But even though we interact with countless products and services every day, we easily forget that they are made by people, and that someone, somewhere should get credit when they work well for us — or get the blame when they don’t.`,
        },
        {
            type: 'text',
            content: `The title of the first chapter is <span class="font-italic">User Experience and Why It Matters</span>, and I believe that the author does a great job of communicating that core idea to the reader. Throughout the chapter two different lines of discussion are provided to help the reader better understand UX and why it matters so much. The first line of discussion works towards that goal by zeroing in on what UX design <span class="font-italic">is</span> and <span class="font-italic">is not</span> and how it can assist other design disciplines that also work towards developing a finished product. The second line of discussion provides a variety of different reasons why UX should matter to everyone who works in an organization that is developing a product, from the frontline engineers and designers getting their hands dirty in development to the executives in the boardroom making all of the high-level decisions driving that development. The first line of discussion begins with a very personally relatable example of how much UX can truly affect you in your everyday life.`,
        },
        {
            type: 'title',
            content: `Everyday Miseries`,
        },
        {
            type: 'text',
            content: `The first section of this chapter begins by describing a series of unfortunate events an office worker faces from the time that she wakes up until the time that she sits down at her office desk. Right from the beginning, this chapter really drives home how even one small and seemingly insignificant UX failure can have a large negative impact on your day, and coupled with several other UX failures, can result in a truly catastrophic day.`,
        },
        {
            type: 'text',
            content: `Due to the fact that every product or service that we use does not have an equal level of importance during the course of our day, UX failures do not all impact our lives equally. But when our use of those products and services throughout the day begins to fail, taken as a whole, the failures can really snowball into a huge waste of time, a significant loss in productivity, and ultimately lead to a drop in our overall mood.`,
        },
        {
            type: 'title',
            content: `Introducing User Experience`,
        },
        {
            type: 'text',
            content: `While rewinding through the entire experience of the office worker, the author highlights the small but significant UX failures caused by each of the products that she encounters along the journey from her bed to her office desk. All of these UX failures can mostly be attributed not to an act of maliciousness on the part of the product developers, but rather, to unintended ignorance at best and a lack of attention to UX design at worst.`,
        },
        {
            type: 'quote',
            content: `When a product is being developed, people pay a great deal of attention to what it does. User experience is the other, often overlooked, side of the equation — how it works — that can often make the difference between a successful product and a failure.`,
        },
        {
            type: 'text',
            content: `All too often, product developers spend the majority of their time dreaming up all kinds of amazing features that they will add to their product. While allowing their imaginations to run wild, following their mind’s eye down all of the rabbit holes presented by those new and exciting features, it can be quite difficult to take a step back and assess the product on a macro-level, through the eyes of the user. Of course, any individual feature of a product sounds great in a vacuum, but what about when many features all have to be incorporated into a single product and not only succeed individually, but also succeed in a single cohesive experience for the user. The following explanation is offered to communicate not only what UX is, but also what it is not:`,
        },
        {
            type: 'quote',
            content: `User experience is not about the inner workings of a product or service. User experience is about how it works on the outside, where a person comes into contact with it. When someone asks you what it’s like to use a product or service, they’re asking about the user experience. Is it hard to do simple things? Is it easy to figure out? How does it feel to interact with the product?`,
        },
        {
            type: 'text',
            content: `Whereas the features offered by any given product are tangible and easily enumerable, the experience provided by that product can be much more elusive and challenging to describe, not to mention seemingly impossible to define and intentionally design. Also, from one product to the next, the solutions necessary to deliver a successful user experience can and will be completely different. All of the individual products identified in the office worker’s terrible morning experience failed, from a UX perspective, in different ways. It is the job of UX design to anticipate these failures and eliminate them before they can be introduced into a given product. The unfortunate thing for practitioners of UX design is that the causes of those failures are oftentimes small and subtle details that don’t exactly jump out at you and proclaim their existence.`,
        },
        {
            type: 'title',
            content: `From Product Design to User Experience Design`,
        },
        {
            type: 'text',
            content: `On the way to providing a suitable definition for UX, the author detours past another design discipline, product design. To begin this section, some common beliefs about what exactly product design is are presented:`,
        },
        {
            type: 'quote',
            content: `When most people think about product design, they often think of it in terms of aesthetic appeal: a well-designed product is one that looks good to the eye and feels good to the touch. Another common way people think about product design is in functional terms: a well-designed product is one that does what it promises to do. And a badly designed product is one that somehow doesn’t. Products might look great and work well functionally, but designing products with the user experience as an explicit outcome means looking beyond the functional or aesthetic.`,
        },
        {
            type: 'text',
            content: `The previous section began the journey towards defining UX by explaining how it deals with what is on the outside of a product, coming in direct contact with the user. This section, as exemplified by the previous quote, takes that discussion further by explaining that UX is not merely what is on the outside of a product, but it does help inform other disciplines that are also concerned with how products look.`,
        },
        {
            type: 'text',
            content: `Functionality and aesthetics are very important parts of any product and they certainly should never be overlooked during the product development process. In fact, the real concern of UX is supporting both of these aspects of product development in order to ensure that they serve their purposes individually and also combine with other aspects of the product to provide the user with a cohesive experience. The ways in which UX accomplishes this are explained by the author:`,
        },
        {
            type: 'quote',
            content: `User experience design often deals with questions of context. Aesthetic design makes sure the button on the coffeemaker is an appealing shape and texture. Functional design makes sure it triggers the appropriate action on the device. User experience design makes sure the aesthetic and functional aspects of the button work in the context of the rest of the product, asking questions like “Is the button too small for such an important function?” User experience design also makes sure the button works in the context of what the user is trying to accomplish, asking questions like “Is the button in the right place relative to the other controls the user would be using at the same time?”`,
        },
        {
            type: 'text',
            content: `Coupled together with all of the previous quotes, this explanation can leave you with the idea that UX design is concerned with everything and has its hands in everything. To some degree, that is true, at least as it applies to the parts of the product that the user can physically see and interact with. In reality, anything that can and will be seen by the user should ultimately be considered from a UX design standpoint. There are a wide variety of individual and specialized disciplines that focus on their own specific aspects of a product, but UX design is the only discipline that factors into every single one of the equations represented by those other disciplines. It needs to be considered in all cases because it is the only discipline concerned with tying everything together into a cohesive whole.`,
        },
        {
            type: 'title',
            content: `Designing (for) Experience: Use Matters`,
        },
        {
            type: 'text',
            content: `One of the things that may be confusing about UX design is where to draw the line when it comes to incorporating it into the product development process. As an example, the author mentions simple products like tables and chairs, which surely do not require deep consideration in order to make sure that they present the user with an acceptable experience. Although, I would argue that employing some level of UX design, even in the development of tables and chairs, can ensure that your products are much more desirable, useful, and fulfilling for users. For example, a simple chair usually does not provide good back support to prevent the user from experiencing lower back pain after sitting for a long time. Employing UX design can in some ways assist in reducing or altogether eliminating such a problem in a chair, as long as it is done correctly with attention always being focused on the needs of the user.`,
        },
        {
            type: 'text',
            content: `The author provides some advice on how to identify the threshold for UX design intervention in the product development process:`,
        },
        {
            type: 'quote',
            content: `With simple products, the requirements to deliver a successful user experience are built into the definition of the product itself. With more complex products, though, the requirements to deliver a successful user experience are independent of the definition of the product. And the more complex a product is, the more difficult it becomes to identify exactly how to deliver a successful experience to the user. Each additional feature, function, or step in the process of using a product creates another opportunity for the experience to fall short. As a result, the process of creating a successful product has to be quite different. That’s where product design has to be supported by user experience design.`,
        },
        {
            type: 'text',
            content: `The truth is that there is no definitive line past which UX design should be employed in the product development process. The only real rule to follow is, when your product becomes complex enough that someone completely unfamiliar with the product cannot simply encounter the product for the first time and immediately understand how to use it, that is the appropriate time to consider your product from a UX design perspective.`,
        },
        {
            type: 'text',
            content: `As a software engineer who has spent his entire career building software systems that enable users to perform complex actions that effect meaningful outcomes, I was always of the belief that software was the only sufficiently complex product upon which UX design could be effectively applied. But after considering all of the example non-software products that have been discussed throughout the past few sections of this chapter, my eyes have truly been opened to the importance of applying UX design not only to software but to all of the things that surround us in our daily lives. It is very clear to me that all of the same UX design lessons that apply to software products can also be applied to hardware products as well. This realization further emboldens me to keep all of the valuable lessons of UX design in the forefront of my mind while I am developing software.`,
        },
        {
            type: 'text',
            content: `To take that idea further, I would argue that the principles of UX do not simply have to be applied to the way in which front-end, user-facing software is developed. I personally strive to also apply some UX design principles to the server-side software that I write as well, because I believe that UX design principles can also have a positive effect on the ease with which software systems interact with each other. When developing a server API for any number of clients, I put a significant amount of thought and effort into ensuring that the API is intuitive, easy to use, and puts the least possible burden on the API consumer. I don’t want the consumer spending a lot of time or effort trying to figure out how exactly to use my API. In this context, I see the software developers creating clients that will consume that API as the users of that software system.`,
        },
        {
            type: 'text',
            content: `I also strive to apply these same UX design principles when developing the individual modules or software units that I build into any given software system. When creating any software with a team, it is inevitable that that software will be touched by other developers at some point in the future. When those developers come along to make modifications or additions to that software system, I want to leave them with a clearly defined set of modules that can make their development efforts easier and less time-consuming. The software developers modifying or adding functionality to a software system would also be the users of that software system, albeit internal users rather than external users like in the case of the server API. I believe that in these two cases, taking into account the users of my server-side software (other developers rather than end-users) ultimately eases the burden that my software puts on those users.`,
        },
        {
            type: 'text',
            content: `When considering my personal application of UX design principles in the software development process, I can definitely see that there is a lot of overlap between the core principles of software design and the core principles of UX design. In high-level terms, good software design dictates that software interfaces should minimize the burden of their use on other software interfaces, just as good UX design dictates that products should minimize the burden of their use on the user. Since both disciplines have their roots in the same general time period of the 1940s or 1950s and they have over the last few decades come to develop a strong synergistic connection to each other, it makes complete sense to me that they would have developed some similarities in this regard.`,
        },
        {
            type: 'text',
            content: `Throughout the years, as both software design and UX design have come to be distinct disciplines in their own right, I suppose that they have cross-pollinated ideas and theories which have become deeply rooted in both disciplines in the present day. I also suppose that this may have been what the author had in mind when he mentioned in the introduction to this book that the foundational UX design principles that he presents can be applied in a wide range of contexts.`,
        },
        {
            type: 'title',
            content: `User Experience and the Web`,
        },
        {
            type: 'quote',
            content: `User experience is vital to all kinds of products and services. This book is primarily about the user experience of one particular kind of product: Web sites. On the Web, user experience becomes even more important than it is for other kinds of products. But the lessons we’ve learned from creating user experiences on the Web can be applied far beyond its boundaries.`,
        },
        {
            type: 'text',
            content: `Up until this point in the book, the author has made it very clear that UX design is useful not only in the software development process, but in the development process for any type of product that requires a deeper level of consideration in terms of how the user will interact with that product. All of the lessons presented throughout this book could very well be applied to the creation of anything else other than software. But this is the point at which the author directs the focus of this book towards the software development process specifically and makes it clear that the rest of this book will focus on the UX design as it pertains to the development of web sites.`,
        },
        {
            type: 'text',
            content: `One of the major reasons why UX design is such an important part of the software development process, specifically in terms of web site development, is offered by the author:`,
        },
        {
            type: 'quote',
            content: `Regardless of the type of site, in virtually every case, a web site is a self-service product. There is no instruction manual to read beforehand, no training seminar to attend, no customer service representative to help guide the user through the site. There is only the user, facing the site alone with only her wits and personal experience to guide her.`,
        },
        {
            type: 'text',
            content: `When it comes to most other products, whether it be any type of hardware product or even desktop software products, it is usually possible to gain some amount of knowledge about the product even before you use it. Cars, for example, come with user manuals the size and thickness of a regular book. Your smartphone also comes with a manual that explains all of the complicated features that it offers. Even most articles of clothing have tags sewn onto them explaining how to properly care for them when it comes time to clean them. And many desktop software products often come with at least a high-level guide on how to use the various features on offer, typically available for download as a PDF. The same cannot be said for web sites. Web sites are the one thing that you cannot prepare yourself for before using them. Everything that is available for you to understand a specific web site is contained within it.`,
        },
        {
            type: 'quote',
            content: `If user experience is such a vital part of any web site, why is it so often neglected in the development process? Many web sites are built with the idea that being first to market is the key to success. In the earliest days of the web, established companies raced to set up web sites, determined not to be perceived as falling behind the times. But in most cases, companies considered merely having deployed the site a great accomplishment; whether the site actually worked for people was, at best, an afterthought.`,
        },
        {
            type: 'text',
            content: `As a software engineer who has worked for ten different companies in three different countries on two continents, I can say that unfortunately, even today in the year 2020, many companies are still of the mindset that simply having something out there on the internet is the most important achievement. I have been scoffed at by more than a few managers and colleagues after suggesting that certain features on consideration for the software products I have worked on may not be appropriately designed with the user in mind. In fact, this response is partially the reason why I no longer work at any of those companies.`,
        },
        {
            type: 'text',
            content: `But I have also met some colleagues who actually do share my passion for creating software that is designed from the ground up with the user in mind. It is those people that I have enjoyed working with most in my career and it is with them that I have had the most fulfilling work relationships. Some of the best software that I have ever developed in my career has been with those who take the job as seriously as I do and who care about delivering products that are not only good for the company, but also equally good for the user.`,
        },
        {
            type: 'text',
            content: `The one good thing that I can say about the state of the software development industry today is that more companies have begun to wake up to the fact that if they neglect the needs of their users, then the company’s future prospects for remaining in the market become ever more grim. The author sums up the advantages that company’s can enjoy if they take a user-oriented approach to product development and harness the power of UX design:`,
        },
        {
            type: 'quote',
            content: `More and more businesses have now come to recognize that providing a quality user experience is an essential, sustainable competitive advantage — not just for web sites, but for all kinds of products and services. It is user experience that forms the customer’s impression of a company’s offerings; it is user experience that differentiates a company from its competitors; and it is user experience that determines whether your customer will ever come back.`,
        },
        {
            type: 'title',
            content: `Good User Experience is Good Business`,
        },
        {
            type: 'text',
            content: `Way back at the beginning of this article I mentioned that the author provides two different lines of discussion throughout this first chapter of the book. The first line of discussion served to provide the reader with a definition of UX and how it can assist the product development process. That line of discussion was presented in the first few sections of this chapter, concluding in the previous section. The second line of discussion begins in this section and serves to provide the reader with a variety of reasons why UX should matter to every single person in any company that is developing a product.`,
        },
        {
            type: 'text',
            content: `Right from the beginning of this section the author provides a compelling reason to care about UX design for not only the people in the trenches developing all of the products for a company, but also for all of the managers and executives in the company who are primarily focused on maintaining the company’s bottom line:`,
        },
        {
            type: 'quote',
            content: `If your site consists mainly of content — that is, information — then one of the main goals of your site is to communicate that information as effectively as possible. It’s not enough just to put it out there. It has to be presented in a way that helps people absorb it and understand it. Otherwise, the user might not ever find out that you offer the service or product they’re looking for. And even if they do manage to find that information, they’re likely to draw the conclusion that if your site is difficult to work with, your company probably is as well.`,
        },
        {
            type: 'text',
            content: `This reasoning reflects on every person in a company because it touches on some of the things that should be uniquely important for every single person in the company. For the designers and engineers creating products for a company, whatever those products may be, the most important thing is for the fruits of their labor to arrive in the hands of the intended users at some point in the future. Without customers, there is no product, and without products, designers and engineers have no purpose.`,
        },
        {
            type: 'text',
            content: `As a software engineer, I can tell you that nothing is more depressing or demoralizing to a software development team than working towards a goal and knowing that, when it is reached, the result will never see the light of day. In fact, most of the software that I have worked on professionally has never made it into the hands of the customers for one reason or another. That kind of cold, harsh reality can really weigh on your mind, and when you are in the thick of development and killing yourself to meet a tight deadline, not knowing whether or not what you are doing really matters to the customer can determine your ultimate willingness to push just a little bit harder to reach the finish line.`,
        },
        {
            type: 'text',
            content: `But unfortunately, reaching the finishing line and delivering the product to the customer is not really a victory if that product is unusable for the customer. In reality, the perceived finishing line in delivering a product to a customer is just a stepping stone to the real goal of the design and development team, which is a product in the hands of the user that brings them pleasure and successfully assists them in accomplishing what they set out to do by purchasing that product in the first place.`,
        },
        {
            type: 'quote',
            content: `Even if your site is a web-based application that people can use to accomplish certain tasks, effective communication is a key factor in the success of your product. The world’s most powerful functionality falters and fails if users can’t figure out how to make it work.`,
        },
        {
            type: 'text',
            content: `For the managers and executives watching over the financial health of the company, nothing is more vital to keeping a company alive than ensuring that the products on which you spend vast sums of money to develop can be exchanged with the customer for cold, hard cash. Those products cannot even get to the customers in the first place if they are unaware of their existence or unable to discover the pathway to purchasing those products. And this moneymaking effort is just a mere short-term concern for the management team of a company. The long-term focus is ensuring that your company maintains a strong reputation so that customers will continue to buy products from your company now and in the future. Being perceived as a company that is difficult to work with does not help to build a strong reputation and it will not engender loyalty in your customers.`,
        },
        {
            type: 'quote',
            content: `Simply put, if your users have a bad experience, they won’t come back. If they have an OK experience with your site but a better experience with your competitor’s site, they’ll go back to that competitor, not you. Features and functions always matter, but user experience has a far greater effect on customer loyalty. All your sophisticated technology and brand messaging won’t bring those customers back a second time. A good user experience will — and you don’t get much of a second chance to get it right.`,
        },
        {
            type: 'text',
            content: `Focusing your UX design efforts outwardly on the customer is only one way in which you can have a positive impact on the overall company and everyone in it, whether it be directly or indirectly. An inward focus is also an important key to success for the company as a whole. One of the ways that UX design can be harnessed during this inward facing effort is during the development of web sites that are only consumed internally by employees of the company. Just like with your customers, it is important to create a pleasurable experience for the user, but there is also another factor to keep in mind as well:`,
        },
        {
            type: 'quote',
            content: `Any user experience effort aims to improve efficiency. This basically comes in two key forms: helping people work faster and helping them make fewer mistakes. Improving the efficiency of the tools you use improves the productivity of the business as a whole. The less time it takes to complete any given task, the more you can get done in a day. In keeping with the old notion that time is money, saving your employees time translates directly into saving your business money.`,
        },
        {
            type: 'text',
            content: `This argument for employing UX design during internal web site development again appeals to all of the people working in a company, albeit for different reasons at different levels of the corporate ladder. For employees who are always racing to meet their deadlines and complete as many tasks as possible in a given day under the watchful eyes of their managers, having tools that work with them rather than against them can significantly improve the quality of their work experience. As a software engineer, I have had a wide variety of opportunities to pick and choose the tools that I use to complete my job throughout my career. Some companies give their employees significant latitude to make those choices, while others lock their employees into a very specific set of tools. Nothing is more frustrating than being locked into working with a tool that either does not seem to allow you to do what you need to do or fights you all along the way while you are attempting to complete your task with that tool.`,
        },
        {
            type: 'text',
            content: `For managers and executives who are tasked with guarding the financial stability of the company above all else, apart from getting products into the hands of customers so that they can give you money in return, the other most effective way to maximize the company’s bottom line is squeezing every last drop of effort and efficiency out of your employees. If employees spend half their time fighting with their tools, they are not effectively and efficiently working towards meeting the next looming development deadline. Reducing as many barriers as possible to task completion should be one of the top priorities of managers and executives who aspire to run their company like a well-oiled machine. Paying attention to the UX design of all internally consumed tools can provide a significantly increased chance of success in that realm. The author offers some final words of wisdom regarding harnessing UX design in order to provide the best tools possible to your employees and ensure their continued job satisfaction:`,
        },
        {
            type: 'quote',
            content: `Efficiency doesn’t only affect the bottom line, though. People like their jobs more when their tools are natural and easy to use, not frustrating and needlessly complex. If that person is you, these kinds of tools make the difference between coming home satisfied at the end of the day and coming home exhausted and hating your job. If that person is your employee, providing these kinds of tools increases not only their productivity, but also their job satisfaction, making the employee less likely to seek a new job. This, in turn, means you save on recruiting and training costs, plus you benefit from the higher level of quality that a more dedicated, experienced employee brings to her work.`,
        },
        {
            type: 'title',
            content: `Minding Your Users`,
        },
        {
            type: 'text',
            content: `The author rounds out this first chapter of the book with some final details to help get the reader comfortable with the realm of UX design and drive home the importance of including UX design in the product development process.`,
        },
        {
            type: 'quote',
            content: `The practice of creating engaging, efficient user experiences is called user-centered design. The concept of user-centered design is very simple: Take the user into account every step of the way as you develop your product. The implications of this simple concept, however, are surprisingly complex. Everything the user experiences should be the result of a conscious decision on your part. Realistically, you might have to make a compromise here and there because of the time or expense involved in creating a better solution. But a user-centered design process ensures that those compromises don’t happen by accident. By thinking about the user experience, breaking it down into its component elements, and looking at it from several perspectives, you can ensure that you know all the ramifications of your decisions.`,
        },
        {
            type: 'text',
            content: `Even before introducing the reader to the multi-layered structure of the <span class="font-italic">Elements of User Experience</span>, the author is priming the reader to get them into the mindset of a UX designer. As any UX designer will tell you, UX design is not about looking at a product with a single eye, but rather a multitude of eyes, all from different angles and in different lights. In reality, UX design is about asking questions. Not only should you be questioning everyone that you are working with to bring a product to market, but you should be questioning yourself as well, constantly. All of those questions will force you to provide answers, and those answers should help you to develop a product that has been picked apart every way possible and put back together in the most optimal form. Asking yourself and your colleagues enough questions during the design and development process will ensure that the users will not have any questions once they have your product in their hands. Doing all of the thinking for the user up front will leave the user with only one thing to do, and that is enjoy your product and effortlessly achieve whatever goals they may have while using that product.`,
        },
        {
            type: 'quote',
            content: `The biggest reason user experience should matter to you is that it matters to your users. If you don’t provide them with a positive experience, they won’t use your product. For the users who do come, you must set out to provide them with an experience that is cohesive, intuitive, and maybe even pleasurable — an experience in which everything works the way it should. No matter how the rest of their day has gone.`,
        },
    ],
}