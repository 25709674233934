<template>
    <v-app-bar
        class="light-blue darken-4 white--text"
        elevation="0"
        fixed
        height="56"
    >
        <v-app-bar-nav-icon @click="toggleNavbar">
            <v-icon class="white--text">{{ icons.menu }}</v-icon>
        </v-app-bar-nav-icon>
        <router-link class="white--text text-decoration-none ml-2" to="/">
            <v-toolbar-title>keithvictordawson.com</v-toolbar-title>
        </router-link>
        <v-spacer />
        <v-speed-dial
            direction="bottom"
            open-on-hover
            v-model="open"
        >
            <template #activator>
                <v-btn
                    class="white--text"
                    color="light-blue darken-4"
                    elevation="0"
                    fab
                    style="height: 48px; width: 48px;"
                    v-model="open"
                >
                    <v-icon v-if="open">{{ icons.close }}</v-icon>
                    <v-icon v-if="!open">{{ icons.shareVariant }}</v-icon>
                </v-btn>
            </template>
            <template v-for="fab in fabs">
                <v-btn color="light-blue darken-4" fab :href="fab.url">
                    <v-icon class="blue--text text--lighten-4">{{ fab.icon }}</v-icon>
                </v-btn>
            </template>
        </v-speed-dial>
    </v-app-bar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
    mdiClose,
    mdiGithub,
    mdiLinkedin,
    mdiMenu,
    mdiPost,
    mdiShareVariant,
    mdiStackOverflow,
} from '@mdi/js'

export default {
    name: 'Appbar',
    data() {
        return {
            fabs: [
                { icon: mdiLinkedin, url: 'https://www.linkedin.com/in/keith-dawson-b9037173/' },
                { icon: mdiGithub, url: 'https://github.com/keith-victor-dawson' },
                { icon: mdiStackOverflow, url: 'https://stackoverflow.com/users/3586038/keith-dawson' },
                { icon: mdiPost, url: 'https://keithvictordawson.medium.com/' },
            ],
            icons: {
                close: mdiClose,
                menu: mdiMenu,
                shareVariant: mdiShareVariant,
            },
            open: false,
        }
    },
    computed: {
        ...mapGetters('Settings', {
            showNavbar: 'showNavbar',
        }),
    },
    methods: {
        ...mapActions('Settings', {
            toggleNavbar: 'toggleNavbar',
        }),
    },
}
</script>

<style scoped>

</style>