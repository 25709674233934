<template>
    <div class="blue lighten-4 pt-14" style="height: 100%;">
        <page-title>Blogs</page-title>
        <div class="d-flex justify-center text-body-2 mx-16">View all of my blogs here on Medium by clicking the button below.</div>
        <div class="d-flex justify-center">
            <v-btn
                class="white--text ma-4"
                color="light-blue darken-4"
                href="https://keithvictordawson.medium.com/"
            >
                <v-icon class="blue--text text--lighten-3">{{ icons.post }}</v-icon>
                <span class="ml-2">View Medium Profile</span>
            </v-btn>
        </div>
        <v-container fluid>
            <v-row class="hidden-xs-only">
                <v-col
                    class="d-flex align-center justify-end offset-xl-3 offset-lg-2 offset-md-0"
                    cols="2"
                >
                    <blog-sort-button @click="toggleSort" :sort-descending="sort_descending" />
                </v-col>
                <v-col class="d-flex align-center col-xl-2 col-lg-3 col-md-5">
                    <blog-year-select
                        @input="selectYear"
                        :selected-year="selected_year"
                        :year-options="year_options"
                    />
                </v-col>
                <v-col class="d-flex align-center col-xl-2 col-lg-3 col-md-5">
                    <blog-month-select
                        :disabled="month_disabled"
                        @input="selectMonth"
                        :month-options="months"
                        :selected-month="selected_month"
                    />
                </v-col>
            </v-row>
            <v-row class="hidden-sm-and-up">
                <v-col class="d-flex align-center justify-center" cols="12">
                    <blog-sort-button @click="toggleSort" :sort-descending="sort_descending" />
                </v-col>
                <v-col cols="12">
                    <blog-year-select
                        @input="selectYear"
                        :selected-year="selected_year"
                        :year-options="year_options"
                    />
                </v-col>
                <v-col cols="12">
                    <blog-month-select
                        :disabled="month_disabled"
                        @input="selectMonth"
                        :month-options="months"
                        :selected-month="selected_month"
                    />
                </v-col>
            </v-row>
        </v-container>
        <v-container fluid>
            <v-row>
                <template v-for="(blog, index) in blogs">
                    <blog-item :blog="blog" :placeholder="placeholder" />
                </template>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import { mdiPost } from '@mdi/js'

// Components
import BlogItem from '../components/blog/BlogItem'
import BlogMonthSelect from '../components/blog/BlogMonthSelect'
import BlogSortButton from '../components/blog/BlogSortButton'
import BlogYearSelect from '../components/blog/BlogYearSelect'
import PageTitle from '../components/application/PageTitle'
import placeholder_image from '../../public/image_512x512.png'

// Data
import allBlogs from '../assets/data/blogs/index'
// Helpers
import helpers from '../assets/helpers'

const sort_options = (optionA, optionB) => {
    if (optionA.value < optionB.value) {
        return -1
    } else if (optionA.value > optionB.value) {
        return 1
    } else {
        return 0
    }
}

const year_options = allBlogs.reduce((options, blog) => {
    if (options.findIndex(option => option.value === blog.year) === -1) {
        options.push({ text: `${blog.year}`, value: blog.year })
    }

    return options
}, [ { text: 'Show All Years', value: 0 } ]).sort(sort_options)

const month_options = (blogs) => {
    return blogs.reduce((options, blog) => {
        if (options.findIndex(option => option.value === blog.month) === -1) {
            options.push({ text: helpers.monthString(blog.month), value: blog.month })
        }

        return options
    }, [ { text: 'Show All Months', value: 0 } ]).sort(sort_options)
}

export default {
    name: 'Blogs',
    props: [ 'params' ],
    components: {
        BlogItem,
        BlogMonthSelect,
        BlogSortButton,
        BlogYearSelect,
        PageTitle,
    },
    data() {
        return {
            icons: {
                post: mdiPost,
            },
            month_disabled: this.params.year === 0,
            month_options: month_options(allBlogs),
            placeholder: placeholder_image,
            selected_month: this.params.month || 0,
            selected_year: this.params.year || 0,
            sort_descending: true,
            year_options,
        }
    },
    computed: {
        blogs() {
            const sort_blogs = (blogA, blogB) => {
                if (blogA.id < blogB.id) {
                    return this.sort_descending ? 1 : -1
                } else if (blogA.id > blogB.id) {
                    return this.sort_descending ? -1 : 1
                } else {
                    return 0
                }
            }

            if (!this.selected_year && !this.selected_month) {
                return allBlogs.sort(sort_blogs)
            } else {
                let displayBlogs = []

                if (!this.selected_month) {
                    displayBlogs = allBlogs.filter(blog => blog.year === this.selected_year)
                } else {
                    displayBlogs = allBlogs.filter(blog => blog.year === this.selected_year && blog.month === this.selected_month)
                }

                if (displayBlogs.length === 0) {
                    this.selected_year = 0
                    this.selected_month = 0
                    this.$router.replace('/blogs')
                    return allBlogs.sort(sort_blogs)
                } else {
                    return displayBlogs.sort(sort_blogs)
                }
            }
        },
        months() {
            if (!this.selected_year) {
                return month_options(allBlogs)
            } else {
                return month_options(allBlogs.filter(blog => blog.year === this.selected_year))
            }
        },
    },
    methods: {
        selectMonth(month) {
            this.selected_month = month
        },
        selectYear(year) {
            this.selected_month = 0
            this.selected_year = year
            this.month_disabled = year === 0
        },
        toggleSort() {
            this.sort_descending = !this.sort_descending
        },
    },
}
</script>

<style scoped>

</style>