export default {
    id: '2020-04-17',
    year: 2020,
    month: 4,
    date: 17,
    title: `An In-Depth Exploration of the Array.flat() Function`,
    blog_url: `https://levelup.gitconnected.com/an-in-depth-exploration-of-the-array-flat-function-3d769f5ff91d`,
    image_url: `https://images.unsplash.com/photo-1523800503107-5bc3ba2a6f81`,
    image_caption: `Photo by <a class="text--primary" href="https://unsplash.com/@oskaryil">Oskar Yildiz</a> on <a class="text--primary" href="https://unsplash.com/s/photos/javascript">Unsplash</a>`,
    contents: [
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `There have been many useful functions that have been added to the Javascript `,
                },
                {
                    type: 'code',
                    content: `Array`,
                },
                {
                    type: 'text',
                    content: ` global object over the last few years that offer developers a wide variety of options when they are writing code that works with arrays. These functions offer a number of advantages, the most noteworthy of which is the fact that, while at one time in the past developers had to implement their own complex logic to perform various array operations, now the need for such homegrown implementations has been eliminated by all of these new functions. One of those useful functions that will be explored in this article is the `,
                },
                {
                    type: 'code_link',
                    url: `https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/flat`,
                    content: `flat()`,
                },
                {
                    type: 'text',
                    content: ` function.`,
                },
            ],
        },
        {
            type: 'title',
            content: `Function Overview`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The `,
                },
                {
                    type: 'code',
                    content: `flat()`,
                },
                {
                    type: 'text',
                    content: ` function provides the ability to concatenate a set of array items into a completely new array and return that new array upon function completion. As this function produces a completely new array, any existing and completely separate arrays that are contained within the original array are left completely unaltered once the function has completed the operation. No precautions need to be taken before beginning the operation in order to ensure that any of the contained arrays are maintained.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The `,
                },
                {
                    type: 'code',
                    content: `flat()`,
                },
                {
                    type: 'text',
                    content: ` function only takes one parameter, which is <span class="font-italic">optional</span>. This sole parameter is the `,
                },
                {
                    type: 'code',
                    content: `depth`,
                },
                {
                    type: 'text',
                    content: ` parameter. This parameter determines how many array levels the function will flatten into a single level in the case that the original array contains one or more nested array structures. As this parameter is optional, it has a default value of `,
                },
                {
                    type: 'code',
                    content: `1`,
                },
                {
                    type: 'text',
                    content: ` and only single-level arrays will be flattened into the completely new array returned upon function completion.`,
                },
            ],
        },
        {
            type: 'title',
            content: `No Parameters`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `With the general function behavior covered, let’s take a look at some examples of how the `,
                },
                {
                    type: 'code',
                    content: `flat()`,
                },
                {
                    type: 'text',
                    content: ` function works in practice. The following example demonstrates the situation where no parameter values are specified:`,
                },
            ],
        },
        {
            type: 'code',
            content: `var array1 = [1, 2, [3, 4], [[5, 6]], [[[7, 8]]], [[[[9, 10]]]]];
var array2 = array1.flat();
// array2: [1, 2, 3, 4, [5, 6], [[7, 8]], [[[9, 10]]]]`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The `,
                },
                {
                    type: 'code',
                    content: `flat()`,
                },
                {
                    type: 'text',
                    content: ` function is called with no parameter values. Considering the optional parameter default value, this function call is the same as `,
                },
                {
                    type: 'code',
                    content: `flat(1)`,
                },
                {
                    type: 'text',
                    content: `. This means that any arrays with a depth of one in the original array will be completely flattened so that all of their contents are individually concatenated to the new array. Any arrays in the original array with a depth of two or more will have their depth decreased by one and any individual array items at a depth of one in these arrays will be individually concatenated to the new array. As a result, the first array in the original array containing `,
                },
                {
                    type: 'code',
                    content: `3`,
                },
                {
                    type: 'text',
                    content: ` and `,
                },
                {
                    type: 'code',
                    content: `4`,
                },
                {
                    type: 'text',
                    content: ` is flattened so that these two array items are individually concatenated to the new array. Additionally, each of the remaining three nested arrays is concatenated to the new array with their nested depth decreased by one.`,
                },
            ],
        },
        {
            type: 'title',
            content: `Positive Depth`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The following example demonstrates the situation where a positive `,
                },
                {
                    type: 'code',
                    content: `depth`,
                },
                {
                    type: 'text',
                    content: ` parameter value is specified:`,
                },
            ],
        },
        {
            type: 'code',
            content: `var array1 = [1, 2, [3, 4], [[5, 6]], [[[7, 8]]], [[[[9, 10]]]]];
var array2 = array1.flat(2);
// array2: [1, 2, 3, 4, 5, 6, [7, 8], [[9, 10]]]`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The `,
                },
                {
                    type: 'code',
                    content: `flat()`,
                },
                {
                    type: 'text',
                    content: ` function is called with a `,
                },
                {
                    type: 'code',
                    content: `depth`,
                },
                {
                    type: 'text',
                    content: ` parameter value of `,
                },
                {
                    type: 'code',
                    content: `2`,
                },
                {
                    type: 'text',
                    content: `. This means that any arrays with a depth up to two in the original array will be completely flattened so that all of their contents are individually concatenated to the new array. Any arrays in the original array with a depth of three or more will have their depth decreased by two and any individual array items at a depth of one or two in these arrays will be individually concatenated to the new array. As a result, the first two arrays in the original array containing `,
                },
                {
                    type: 'code',
                    content: `3`,
                },
                {
                    type: 'text',
                    content: ` and `,
                },
                {
                    type: 'code',
                    content: `4`,
                },
                {
                    type: 'text',
                    content: ` and `,
                },
                {
                    type: 'code',
                    content: `5`,
                },
                {
                    type: 'text',
                    content: ` and `,
                },
                {
                    type: 'code',
                    content: `6`,
                },
                {
                    type: 'text',
                    content: ` are flattened so that these four array items are individually concatenated to the new array. Additionally, each of the remaining two nested arrays is concatenated to the new array with their nested depth decreased by two.`,
                },
            ],
        },
        {
            type: 'title',
            content: `Infinite Depth`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The following example demonstrates the situation where an infinite `,
                },
                {
                    type: 'code',
                    content: `depth`,
                },
                {
                    type: 'text',
                    content: ` parameter value is specified:`,
                },
            ],
        },
        {
            type: 'code',
            content: `var array1 = [1, 2, [3, 4], [[5, 6]], [[[7, 8]]], [[[[9, 10]]]]];
var array2 = array1.flat(Infinity);
// array2: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The `,
                },
                {
                    type: 'code',
                    content: `flat()`,
                },
                {
                    type: 'text',
                    content: ` function is called with a `,
                },
                {
                    type: 'code',
                    content: `depth`,
                },
                {
                    type: 'text',
                    content: ` parameter value of `,
                },
                {
                    type: 'code',
                    content: `Infinity`,
                },
                {
                    type: 'text',
                    content: `. This means that all arrays with any depth in the original array will be flattened so that all of their contents are individually concatenated to the new array. One very important thing to keep in mind when using a value like `,
                },
                {
                    type: 'code',
                    content: `Infinity`,
                },
                {
                    type: 'text',
                    content: ` with the `,
                },
                {
                    type: 'code',
                    content: `flat()`,
                },
                {
                    type: 'text',
                    content: ` function is that your application may run out of memory if it encounters an array that is nested sufficiently deep. Although `,
                },
                {
                    type: 'code',
                    content: `Infinity`,
                },
                {
                    type: 'text',
                    content: ` is used here to demonstrate that using such a value with the `,
                },
                {
                    type: 'code',
                    content: `flat()`,
                },
                {
                    type: 'text',
                    content: ` function is possible, it is recommended to use a much smaller finite parameter value in order to avoid any unforeseen errors in your application.`,
                },
            ],
        },
        {
            type: 'title',
            content: `Zero Depth`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The following example demonstrates the situation where a `,
                },
                {
                    type: 'code',
                    content: `depth`,
                },
                {
                    type: 'text',
                    content: ` parameter value of `,
                },
                {
                    type: 'code',
                    content: `0`,
                },
                {
                    type: 'text',
                    content: ` is specified:`,
                },
            ],
        },
        {
            type: 'code',
            content: `var array1 = [1, 2, [3, 4], [[5, 6]], [[[7, 8]]], [[[[9, 10]]]]];
var array2 = array1.flat(0);
// array2: [1, 2, [3, 4], [[5, 6]], [[[7, 8]]], [[[[9, 10]]]]]`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The `,
                },
                {
                    type: 'code',
                    content: `flat()`,
                },
                {
                    type: 'text',
                    content: ` function is called with a `,
                },
                {
                    type: 'code',
                    content: `depth`,
                },
                {
                    type: 'text',
                    content: ` parameter value of `,
                },
                {
                    type: 'code',
                    content: `0`,
                },
                {
                    type: 'text',
                    content: `. This means that no arrays contained within the original array are flattened and the new array has the exact same composition of individual array items and nested arrays as the original array.`,
                },
            ],
        },
        {
            type: 'title',
            content: `Negative Depth`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The following example demonstrates the situation where a negative `,
                },
                {
                    type: 'code',
                    content: `depth`,
                },
                {
                    type: 'text',
                    content: ` parameter value is specified:`,
                },
            ],
        },
        {
            type: 'code',
            content: `var array1 = [1, 2, [3, 4], [[5, 6]], [[[7, 8]]], [[[[9, 10]]]]];
var array2 = array1.flat(-Infinity);
// array2: [1, 2, [3, 4], [[5, 6]], [[[7, 8]]], [[[[9, 10]]]]]`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The `,
                },
                {
                    type: 'code',
                    content: `flat()`,
                },
                {
                    type: 'text',
                    content: ` function is called with a `,
                },
                {
                    type: 'code',
                    content: `depth`,
                },
                {
                    type: 'text',
                    content: ` parameter value of `,
                },
                {
                    type: 'code',
                    content: `-Infinity`,
                },
                {
                    type: 'text',
                    content: `. Due to the fact that a negative depth value makes no sense in terms of flattening nested arrays, in the case of a negative `,
                },
                {
                    type: 'code',
                    content: `depth`,
                },
                {
                    type: 'text',
                    content: ` parameter value being specified a value of `,
                },
                {
                    type: 'code',
                    content: `0`,
                },
                {
                    type: 'text',
                    content: ` is used instead. As the previous example demonstrates when a `,
                },
                {
                    type: 'code',
                    content: `depth`,
                },
                {
                    type: 'text',
                    content: ` parameter value of `,
                },
                {
                    type: 'code',
                    content: `0`,
                },
                {
                    type: 'text',
                    content: ` is specified, no arrays in the original array are flattened and the new array has the exact same composition of individual array items and nested arrays as the original array.`,
                },
            ],
        },
        {
            type: 'title',
            content: `Lessons Learned`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `There are a couple of lessons to take away from this article regarding the `,
                },
                {
                    type: 'code',
                    content: `flat()`,
                },
                {
                    type: 'text',
                    content: ` function. The first thing to keep in mind is that the `,
                },
                {
                    type: 'code',
                    content: `flat()`,
                },
                {
                    type: 'text',
                    content: ` function does not alter any normal or nested arrays in the original array in any way so there is no need to maintain the states of these arrays before using the function. The only array that will be altered by the `,
                },
                {
                    type: 'code',
                    content: `flat()`,
                },
                {
                    type: 'text',
                    content: ` function is the completely new array that is returned once the function has completed, which is simply built using all of the contents of the original array.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The second thing to keep in mind is that the `,
                },
                {
                    type: 'code',
                    content: `flat()`,
                },
                {
                    type: 'text',
                    content: ` function will remove any empty slots that exist in the original array. The following example shows this functionality in action:`,
                },
            ],
        },
        {
            type: 'code',
            content: `var array1 = [1, , 3, , 5];
var array2 = array1.flat();

// array2: [1, 3, 5]`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `Although the original array had five slots occupied, with the second and fourth slots having a value of `,
                },
                {
                    type: 'code',
                    content: `undefined`,
                },
                {
                    type: 'text',
                    content: `, the `,
                },
                {
                    type: 'code',
                    content: `flat()`,
                },
                {
                    type: 'text',
                    content: ` function removes these two array items from the new array returned upon function completion. As a result, the new array only contains the three array items that did not have a value of `,
                },
                {
                    type: 'code',
                    content: `undefined`,
                },
                {
                    type: 'text',
                    content: `.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The third and final thing to keep in mind about the `,
                },
                {
                    type: 'code',
                    content: `flat()`,
                },
                {
                    type: 'text',
                    content: ` function is the general purpose that it serves and how it can help to simplify logic that you may have already found it necessary to implement in your own code. Without a `,
                },
                {
                    type: 'code',
                    content: `flat()`,
                },
                {
                    type: 'text',
                    content: ` function available for use before, the usual method of consolidating all of the items contained in any number of arrays would have been to write custom logic to iterate through all of those arrays individually pulling items from one array and placing them into another array, possibly accounting for nested arrays along the way. Such logic is often messy and error-prone, making it a prime candidate for avoiding altogether through the use of abstract built-in functions like the `,
                },
                {
                    type: 'code',
                    content: `flat()`,
                },
                {
                    type: 'text',
                    content: ` function.`,
                },
            ],
        },
        {
            type: 'title',
            content: `Conclusions`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `Thank you very much for reading this article. I hope that this exploration of the `,
                },
                {
                    type: 'code',
                    content: `flat()`,
                },
                {
                    type: 'text',
                    content: ` function on the Javascript `,
                },
                {
                    type: 'code',
                    content: `Array`,
                },
                {
                    type: 'text',
                    content: ` global object has been informative and I hope that after gaining some knowledge about it you will be able to put it to good use in your own code. I encourage you to consult the resource link below for all information pertaining to the `,
                },
                {
                    type: 'code',
                    content: `flat()`,
                },
                {
                    type: 'text',
                    content: ` function if you still have any further questions about how it works. Please stop back in the future for more articles about interesting and useful functions that can be found on the Javascript `,
                },
                {
                    type: 'code',
                    content: `Array`,
                },
                {
                    type: 'text',
                    content: ` global object.`,
                },
            ],
        },
    ],
}