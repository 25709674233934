<template>
    <resume-item
        :icon="icons.school"
        :item-id="schoolId"
        :time="time"
    >
        <template #header>
            <resume-item-header
                :subtitle="`from ${name}`"
                :title="program"
            />
        </template>
        <template #content>
            <resume-item-content-item
                :content="location"
                title="Location: "
            />
            <ul class="mt-3">
                <li v-for="(topic, index) in topics" :key="index">
                    <resume-item-list-item :content="topic.name" />
                    <ul v-if="topic.units">
                        <li v-for="(unit, index) in topic.units" :key="index">
                            <resume-item-list-item :content="unit" />
                        </li>
                    </ul>
                </li>
            </ul>
        </template>
    </resume-item>
</template>

<script>
import { mdiSchool } from '@mdi/js'

// Components
import ResumeItem from './ResumeItem'
import ResumeItemContentItem from './ResumeItemContentItem'
import ResumeItemHeader from './ResumeItemHeader'
import ResumeItemListItem from './ResumeItemListItem'

export default {
    name: 'ResumeSchool',
    components: {
        ResumeItem,
        ResumeItemContentItem,
        ResumeItemHeader,
        ResumeItemListItem,
    },
    props: [
        'location',
        'name',
        'program',
        'schoolId',
        'time',
        'topics',
    ],
    data() {
        return {
            icons: {
                school: mdiSchool,
            },
        }
    },
}
</script>

<style scoped>

</style>