export default {
    id: '2020-01-24',
    year: 2020,
    month: 1,
    date: 24,
    title: `An SE’s Perspective: The Elements of User Experience (Part 5)`,
    blog_url: `https://keithvictordawson.medium.com/an-ses-perspective-the-elements-of-user-experience-part-5-20359a4f0ceb`,
    image_url: `https://miro.medium.com/max/700/1*De5Lfps7YDWxi1LBTAwGyw.png`,
    image_caption: `The Elements of User Experience, Jesse James Garrett`,
    contents: [
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `In this series I read through each chapter of the groundbreaking UX design book `,
                },
                {
                    type: 'italic_link',
                    url: `http://www.jjg.net/elements/`,
                    content: `The Elements of User Experience`,
                },
                {
                    type: 'text',
                    content: ` and discuss the content from my perspective as a software engineer. The author of this book, Jesse James Garrett, wrote it in the hope that it would help UX designers or anyone else interested in UX design to understand the decisions that UX practitioners make during the course of their work. Unlike the first edition, this second edition of the book is not so heavily focused on UX as it pertains to web development, but rather written in such a way as to apply to any context in which UX plays an important role. Please keep in mind that my perspective comes from the direction not of a UX designer but of a software engineer who strives to wield the principles of UX as a tool to inform all of the software development decisions that I make and enhance all of the software that I create. As a result, all discussion about this book will be in that context.`,
                },
            ],
        },
        {
            type: 'spacer',
        },
        {
            type: 'text',
            content: `The title of the fifth chapter is <span class="font-italic">The Structure Plane - Interaction Design and Information Architecture</span> and in this chapter the author continues to evolve the products of the Scope Plane, functional requirements and content requirements, by discussing how to analyze those products through the dual lenses of interaction design and information architecture. In terms of interaction design, there is a direct progression of work being done on the functional requirements that were developed in the Scope Plane. In terms of information architecture, there is a direct progression of work being done on the content requirements that were developed in the Scope Plane. This is evident when considering the part of the <span class="font-italic">Elements of User Experience</span> diagram for the Structure Plane:`,
        },
        {
            type: 'image',
            url: `https://miro.medium.com/max/455/1*eliGFH4VG1zj3inC8u7NJA.png`,
            caption: `The Structure Plane, Jesse James Garrett`,
        },
        {
            type: 'text',
            content: `In the Scope Plane the functionality perspective of the UX design process was entirely concerned with developing a set of functional requirements for the system under development. Here in the Structure Plane the functionality perspective is entirely concerned with putting those functional requirements through an interaction design process. Also, in the Scope Plane the information perspective of the UX design process was entirely concerned with developing a set of content requirements for the system under development. Here in the Structure Plane the information perspective is entirely concerned with putting those content requirements through a process of information architecture analysis.`,
        },
        {
            type: 'text',
            content: `While moving up one plane from Scope to Structure, the parallel perspectives of functionality and information continue to form a more concrete picture of how the product or service being created will function and how information will fill in all of the gaps around that functionality. This progression through the UX design process is summed up best by the author in the following quote:`,
        },
        {
            type: 'quote',
            content: `After the requirements have been defined and prioritized, we have a clear picture of what will be included in the final product. The requirements, however, don’t describe how the pieces fit together to form a cohesive whole. This is the next level up from scope: developing a conceptual structure for the site.`,
        },
        {
            type: 'text',
            content: `While the functional requirements and content requirements that were developed in the Scope Plane define many individual facets of a system, the job of the processes employed in the Structure Plan is to stitch those facets together into one cohesive structure, both in terms of functionality and the content presented around that functionality. As I mentioned in my discussion of the Scope Plane, I felt that the work being done in that plane had a direct correlation to the work of software engineers that is performed in the requirements gathering phase of the software development process. That was the first step in the UX design process that had a direct correlation to the first step in the software development process where the view of the system being created started to take on a more concrete form.`,
        },
        {
            type: 'text',
            content: `In the context of the UX design process, the Structure Plane is the first step where the view of the system being created begins to take on a more concrete form. The reason for this is because the Structure Plane is the first step in the UX design process that <span class="font-italic">directly</span> takes into consideration the users and how they will approach both the functionality and the content of the system. The author sums this fact up best by describing the duality of interaction design and information architecture:`,
        },
        {
            type: 'quote',
            content: `Interaction design and information architecture share an emphasis on defining patterns and sequences in which options will be presented to users. Interaction design concerns the options involved in performing and completing tasks. Information architecture deals with the options involved in conveying information to a user.`,
        },
        {
            type: 'text',
            content: `Just as the Scope Plane was concerned with defining the two sides of an equation for building out the features of a product or service, functional specifications and content requirements, the Structure Plane is concerned with further clarifying both sides of that equation by processing those abstract deliverables into a product that is entirely user-oriented. Further along in the software development and UX design process, this product can serve as a blueprint for building a system that presents the functionality and content of that system in the most conceptually and logically appropriate way possible to enable users to fully engage with the system. This is where anticipation of how users will respond to what they see is most important, which is why the dual disciplines of interaction design and information architecture are employed at this step in the UX design process.`,
        },
        {
            type: 'text',
            content: `While discussing the interaction design process and information architecture analysis in this chapter the author highlights a core concept at the heart of this step in the UX design process that I connect with strongly and value highly as a software engineer. The author stresses the importance of maintaining <span class="font-italic">consistency</span> in whichever way that you choose to design the interactions that the user will have with the system and how all of the information in the system will be presented to the user. While developing software, one of the most important things that I emphasize both in the code that I write as well as demand in the code written by others that I work with is consistency.`,
        },
        {
            type: 'text',
            content: `In terms of code, consistency means ensuring that the software that you write is generally structured in the same way throughout the entire system. Different parts of the system can and should each have their own focus in terms of the work that they perform, but the way in which this work is performed should generally be the same in each of those individual parts. When developing software, you should strive to follow a strict convention when defining all of your logic and how all of that logic flows throughout the system. Using different conventions in different parts of the system can make it very difficult or nearly impossible to return to your own software after a long time and figure out how the system works. And imagine how difficult of an experience it would be for a developer who is entirely new to a code base that has been written using different conventions in different parts of the system. They would lose their mind trying to follow all of the logic throughout the system.`,
        },
        {
            type: 'text',
            content: `Now think about how difficult of an experience it would be for the user of a completely new software system that they have never seen before. If the interface is not presented in a consistent manner or the ways in which the user is expected to interact with all of the information that they are presented with do not follow a logical pattern, the whole experience would be very frustrating and they would probably refuse to ever touch that software system again. This is the type of user experience that the interaction design and information architecture disciplines are meant to address and hopefully avoid. These disciplines have the goal of establishing a consistent visual and functional language throughout the entire system that effectively communicates to the user a couple of things. The first is what actions are available to the user and what the consequences of those actions will be. The second is a logical presentation of all of the information that makes the most sense for the users at which the system is targeted.`,
        },
        {
            type: 'text',
            content: `While working through the interaction design process, it can be very useful to look to real-world examples of common actions that people perform in certain situations and base the actions that you design for the system on them. After all, any product or service that we could ever create will never exist in a complete vacuum. Every single person has a lifetime worth of experiences that have conditioned them to assume that when a certain action is performed they can expect a certain result. An important thing to keep in mind when engaging in this process of mimicking real-world analogs in our interaction design is highlighted by the author:`,
        },
        {
            type: 'quote',
            content: `We don’t have to communicate our conceptual models to our users explicitly — in fact, sometimes this only confuses users instead of helping them. It’s more important that conceptual models are used consistently throughout the development of the interaction design.`,
        },
        {
            type: 'text',
            content: `This process of basing the interaction design of a system on real-world processes is also used in software engineering as well. Due to the fact that all software systems deal with some type of data and many of those software systems provide the user with the ability to interact with that data, the code that is written for those systems is often modeled after the real-world objects represented by or related to that data. This is one of the core ideas of object-oriented design, which is a software design philosophy that involves modeling software systems in terms of objects that interact with each other. It is a common practice to build some of these objects in a way that they resemble or mimic real-world objects or processes. When attempting to reason about the software that they are building, using this type of software design process can make the job of a software engineer much easier. Similarly, the users of a software system should be able to reason about a software system more easily if it has been designed in such a way as to be similar to familiar real-world processes or systems.`,
        },
        {
            type: 'text',
            content: `Although information architecture has a similar goal of easing the burden on the user in finding the specific information that they want in a software system, it does not involve organizing that information in some fashion to mimic real-world objects or systems. Instead, information architecture involves the process of considering a set of data and finding the best way to divide it into logical groups so that a user can find a specific piece of information with as little effort as possible. From this perspective, the organization of the data that will fill the software system is dictated by the types of users that are most likely to use the system and how they think about the data and would typically expect it to be divided. Additionally, the strategy employed in dividing data at the highest levels of the system will probably be different than the strategy employed in dividing data at the lower levels of the system. The author describes this dynamic in the following quote:`,
        },
        {
            type: 'quote',
            content: `Generally, the organizing principles you employ at the highest levels of your site are closely tied to the product objectives and user needs. At lower levels in the architecture, issues specific to the content and functional requirements begin to have a greater influence on the organizing principles that should be used.`,
        },
        {
            type: 'text',
            content: `The results of the Strategy Plane come to the rescue when first determining how to divide up all of the information at the entry point to the system. As you go deeper into the lower levels of the system, the results of the Structure Plane will be your guide to how best to divide all of the information. There are several different structures into which you can divide the data for any given software system, all of which have their own advantages and disadvantages. Going into detail about each of these structures is beyond the scope of this article, but I strongly encourage you to look more into information architecture and learn about these many useful alternatives, which all have appropriate contexts in which they can and should be used. I strongly believe that if more companies put a little more thought into the way that they divided and presented their data, the internet would be a much more pleasant place to explore. It would certainly make the task of finding the exact information that I am looking for when browsing around the internet much smoother. All too often I find myself visiting multiple similar websites looking for a particular piece of information before I stumble on one where I can actually find what I am looking for.`,
        },
        {
            type: 'text',
            content: `Overall, I have found the Structure Plane to be the most interesting one yet in the <span class="font-italic">Elements of User Experience</span> framework. I think it is because I relate a lot to the goals of this plane due to the fact that my preferred area of work in software development is on the backend, either on the server or in the database. I feel like a lot of the thought and consideration that I put into developing a database architecture and a software system around that database for interacting with it is very similar to the thought and consideration that UX designers put into the interaction design, and particularly the information architecture, of a software system. In my opinion, the information architecture analysis process is the user-facing counterpart to the database design and server interface development process. One faces the database, while the other faces the user that will be presented with all of the data in that database. For that simple fact, the processes that should be followed in developing a software system to bridge the divide between those two contexts should generally mirror each other. This will ensure a more consistent and easy-to-understand software system as a whole.`,
        },
    ],
}