export default {
    id: '2019-12-01',
    year: 2019,
    month: 12,
    date: 1,
    title: `Developer Story: Process Management is Key`,
    blog_url: `https://keithvictordawson.medium.com/developer-story-process-management-is-key-5115d2886ccc`,
    image_url: `https://images.unsplash.com/photo-1534972195531-d756b9bfa9f2`,
    image_caption: `Photo by <a class="text--primary" href="https://unsplash.com/@ffstop">Fotis Fotopoulos</a> on <a class="text--primary" href="https://unsplash.com/s/photos/software">Unsplash</a>`,
    contents: [
        {
            type: 'text',
            content: `Without a doubt, process management was one of the aspects of application development that caused me the most concern and anxiety when for the first time in my career I was tasked with being the sole administrator of a server application. Just like with database systems, you want to be certain that your server application maintains maximum uptime so as not to interfere with the availability of your application. This is doubly important when your application has a high amount of traffic or demands high availability when serving certain industries, such as the financial, medical, or e-commerce industries. One of the first things that I did after being given the task of administering a server application was to search for a dependable system that would allow me to leave all of my process management concerns behind me.`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `Since I was working in NodeJS at the time of being given this task, my search centered around solutions that could be used in that development environment. For many years I had known about `,
                },
                {
                    type: 'bold_link',
                    url: `https://www.npmjs.com/package/forever`,
                    content: `forever`,
                },
                {
                    type: 'text',
                    content: `, and for as long as I could remember it was really the only option for anyone who wanted to make sure that their NodeJS application continued to run no matter what happened. But when I began my search for a process management system I wondered if there were any other available options. Many years has passed since I had first heard about forever, so surely there were other options available on the market, I remember having thought to myself. Fortunately, my search proved that to be the case. At the time, forever has fallen into disrepair and had not really received any significant updating or bug fixing for quite some time. Another process management solution called `,
                },
                {
                    type: 'bold_link',
                    url: `https://www.npmjs.com/package/pm2`,
                    content: `pm2`,
                },
                {
                    type: 'text',
                    content: ` was in a completely different state, receiving regular and frequent updates and bug fixes. Additional comparative research convinced me that, whereas forever may have served its purposes well enough in development scenarios, <span class="font-weight-bold">pm2</span> was the only real option to use in production scenarios. So with that I had made my decision to go with <span class="font-weight-bold">pm2</span> for all of my process management needs.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `Now, with that decision made, I needed to figure out how to incorporate <span class="font-weight-bold">pm2</span> into my overall development workflow. If you take a look at the <span class="font-weight-bold">pm2</span> documentation you will learn that all usage of the system centers around defining a configuration file, which in the <span class="font-weight-bold">pm2</span> system is referred to as an `,
                },
                {
                    type: 'external_link',
                    url: `https://pm2.keymetrics.io/docs/usage/application-declaration/`,
                    content: `ecosystem file`,
                },
                {
                    type: 'text',
                    content: `. The following is what one of these ecosystem files may look like in your application:`,
                },
            ],
        },
        {
            type: 'code',
            title: `ecosystem.config.js`,
            content: `
const path = require('path')
exports = module.exports = {
    apps: [{
        name: 'application_name',
        script: 'server.js',
        output: path.join(process.cwd(), 'logs', 'pm2', 'output.log'),
        error: path.join(process.cwd(), 'logs', 'pm2', 'error.log'),
        env: {
            DB_HOST: 'localhost',
            DB_NAME: 'db_local',
            DB_PORT: '27017',
            NODE_ENV: 'local'
        },
        env_dev: {
            DB_HOST: 'localhost',
            DB_NAME: 'db_dev',
            DB_PORT: '27017',
            NODE_ENV: 'development'
        },
        env_staging: {
            DB_HOST: 'localhost',
            DB_NAME: 'db_staging',
            DB_PORT: '27017',
            NODE_ENV: 'staging'
        },
        env_production: {
            DB_HOST: 'localhost',
            DB_NAME: 'db_production',
            DB_PORT: '27017',
            NODE_ENV: 'production'
        }
    }]
}`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `In these file contents there are a number of things to note. First, since it is possible to define configuration for multiple applications, you will want to ensure that your application name, specified using the `,
                },
                {
                    type: 'code',
                    content: `name`,
                },
                {
                    type: 'text',
                    content: ` field, is unique per application so as not to cause any issues when referring to an application while using the <span class="font-weight-bold">pm2</span> CLI. Second, your output and error log file paths are specified using the `,
                },
                {
                    type: 'code',
                    content: `output`,
                },
                {
                    type: 'text',
                    content: ` and `,
                },
                {
                    type: 'code',
                    content: `error`,
                },
                {
                    type: 'text',
                    content: ` fields, respectively. In order to avoid runtime errors and ensure that everything runs smoothly, you will obviously want to make sure that these referenced files actually exist by the time your application produces any output. The output file will contain any output from your application that is written using `,
                },
                {
                    type: 'code',
                    content: `console`,
                },
                {
                    type: 'text',
                    content: ` while the error file will contain any error output from your application that is produced by NodeJS. In reality you can use the same file for both purposes, but I prefer to use separate files so as to maintain a separation of concerns for my application output. Finally, the environment variables that you want to inject into each individual version of your application environment should be defined in object fields named either `,
                },
                {
                    type: 'code',
                    content: `env`,
                },
                {
                    type: 'text',
                    content: ` or `,
                },
                {
                    type: 'code',
                    content: `env_`,
                },
                {
                    type: 'text',
                    content: ` plus the name of your environment. In this case, I have defined four separate environment configurations, one for use on my local machine, which will also serve as the default environment when starting my application using the <span class="font-weight-bold">pm2</span> CLI, and one for each of the three external environments that my application would exist in during deployment through to a live production system.`,
                },
            ],
        },
        {
            type: 'text',
            content: `With an ecosystem file created, the only thing left to do is create some useful NPM CLI commands. The particular NPM CLI commands that I use in all of my professional applications are the following:`,
        },
        {
            type: 'code',
            title: `package.json`,
            content: `
{
    ...,
    "scripts": {
        "start": "pm2 start ecosystem.config.js",
        "start_local": "pm2 start ecosystem.config.js",
        "start_dev": "pm2 start ecosystem.config.js --env dev",
        "start_staging": "pm2 start ecosystem.config.js --env staging",
        "start_prod": "pm2 start ecosystem.config.js --env production"
    },
    ...,
}`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The first two commands in this file start the application up using the default `,
                },
                {
                    type: 'code',
                    content: `env`,
                },
                {
                    type: 'text',
                    content: ` configuration defined in the ecosystem file. You can tell this because neither of these commands uses the `,
                },
                {
                    type: 'code',
                    content: `--env`,
                },
                {
                    type: 'text',
                    content: ` command line argument. I prefer to set the default start command to reference this configuration so that I do not start an external environment version of my application, which interacts with external environment versions of my database, without typing the correct longer command name for that particular environment. The other commands each refer to a configuration for a different external environment, which is determined by appending the `,
                },
                {
                    type: 'code',
                    content: `--env`,
                },
                {
                    type: 'text',
                    content: ` argument value to `,
                },
                {
                    type: 'code',
                    content: `env_`,
                },
                {
                    type: 'text',
                    content: `. In using this clean and efficient application execution system, I can ensure that the correct environment version of my application is executed at exactly the right time.`,
                },
            ],
        },
        {
            type: 'text',
            content: `And with that, I can put all of my process management concerns behind me and focus more on the actual development of my applications. Since I started using the <span class="font-weight-bold">pm2</span> system in all of my professional production and development software systems as well as in my personal projects I have not encountered a single issue. Personally, I would be hard-pressed to find a better process management system for all of my NodeJS applications. Also, I would encourage all of you to take a further look at the <span class="font-weight-bold">pm2</span> documentation and read about all of the other numerous features that this system provides. I have only featured a small subset of the features in this developer story entry, but I can assure you that there are many more interesting and useful functions built into this system that will make your development process easier and more enjoyable. I will also document other ways in which I use <span class="font-weight-bold">pm2</span> functionality in upcoming developer story entries, so please stay tuned for that and more as I make further progress on my personal project.`,
        },
    ],
}