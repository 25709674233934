import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

// Stores
import Settings from './modules/settings.store'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        Settings,
    },
    plugins: [
        createPersistedState({
            key: 'settings',
            paths: [ 'Settings' ],
            storage: window.sessionStorage,
        }),
    ],
})
