export default {
    id: '2019-11-01',
    year: 2019,
    month: 11,
    date: 1,
    title: `Developer Story: Genesis of an Idea`,
    blog_url: `https://keithvictordawson.medium.com/developer-diary-the-beginning-13448dbbb0c6`,
    image_url: `https://images.unsplash.com/photo-1512314889357-e157c22f938d`,
    image_caption: `Photo by <a class="text--primary" href="https://unsplash.com/@freegraphictoday">AbsolutVision</a> on <a class="text--primary" href="https://unsplash.com/s/photos/idea">Unsplash</a>`,
    contents: [
        {
            type: 'text',
            content: `Precisely one month ago today I left my previous company and began a new chapter in my journey as a software engineer. In the months leading up to the time that I left that company I had been racking my brain. Throughout my entire career, I have sought a personal project to which I could fully dedicate myself and all of the software engineering skills that I have developed up to this point. The three requirements that I had for such a project were simple: the project must be of such a size that I can complete it by myself; the project must have great personal meaning and significance; and the project must require a fairly even amount of front-end and back-end work. I have literally been thinking about this personal project for over a decade, but the last few months leading up to the beginning of last month were when I finally told myself that I needed to make up my mind, once and for all. Up to this point in my career, I have gained enough experience and skill in my craft to know that now is the right time.`,
        },
        {
            type: 'text',
            content: `Earlier in my career, I thought that maybe creating my own personal website could suitably fill the role of this project. But the problem with a simple static or semi-static website, in my opinion, is that it does not really provide high enough stakes or a challenge to rise to the level of the type of project that I am looking for. I am not a simple static website hacker that throws landing pages up on the internet. I build complex applications that require significant and intricate logic on both the server and the client. This is not to say that I would not do this type of project as a minor side project, but it is definitely not going to fill my need for a serious personal project. Without a doubt, a simple personal website does not fulfill my third requirement from above.`,
        },
        {
            type: 'text',
            content: `Ever since I was back in university, I also considered the possibility that building some kind of a game might be suitable. I am a gamer myself and I enjoy playing a number of different types of games. But I am also a realist and I know that my brain leans too far to the logic side of the scale, far away from the artistic side of the scale. I see the world, first and foremost, in terms of data. Whenever I have ever started any professional project in my career, I have always approached it from the database first and built up from there. Also, I mainly see the world in terms of blue, so any game that I would create would be too heavily focused on the blue part of the light spectrum. I am not afraid to admit that I am not artistically imaginative enough to create a concept for a game that could be both engaging for another person as well as be engaging enough for me to build to completion. Without a doubt, a game does not fulfill my second requirement from above.`,
        },
        {
            type: 'text',
            content: `So considering that my strengths lie mostly in logic and not in artistry, I realized that whatever this personal project would be, it would have to be heavier on the logically complex side of things. Since I am an on-again, off-again student of several different languages, I realized that working on a personal project that focuses on learning a new language might be just the right thing. I am fully confident that building some type of language application fulfills my first requirement from above because I can choose how much or how little functionality I put into such an application to make it sufficiently sized for me to be able to complete solo. Also, a language application would require an even amount of front-end and back-end work because there would need to be some substantial logic on the user interface to make it useful and engaging and there would also need to be significant logic on the server side to manage the heavy data load of such an application, thereby fulfilling my third requirement from above. The only thing to consider now is which language to dedicate this language application to in order to make it personally significant and meaningful.`,
        },
        {
            type: 'text',
            content: `Well, it just so happens that I am a member of a Native American tribe that has a seriously endangered language. When I was much younger, in middle school and high school, I used to go to classes to learn this language. But after starting a part-time job in high school, going to university, and then starting my career, I lost contact with my language. I have been meaning to get back into it for a long time now, and I believe doing so while also building a language application to help learn my language are a perfect combination of purpose and outcome that fulfills my second requirement from above.`,
        },
        {
            type: 'text',
            content: `Since I am the kind of software engineer that thinks from the database up, I have spent the past month gathering and cleaning up a dataset that I can feel comfortable about using in my language application. This dataset is literally a dictionary’s worth of information, so it has taken quite a long time for me to gather all of the data, figure out what shape I want the data to be in, and do the work of cleaning that data and molding it into the shape that I need for my language application. I have been sanitizing all of the data that I gathered to prepare it for entry into a number of seed scripts that I will develop to fill the initial version of the database that I will use with my language application. To be honest, after a busy month, I am still not done yet, but I have made significant progress on getting the data to where I want it to be.`,
        },
        {
            type: 'text',
            content: `I set today as a personal deadline for shifting from the data gathering phase of my project to beginning work on development of the actual application itself, which is part of the reason why I am beginning this developer diary series. Starting today, I will split my time between continuing work on cleaning up the initial dataset and building out all of the necessary parts of the language application. In future developer diary series entries, I will provide much more discussion about the actual features that I plan to develop for the application as well as my motivations for doing the things that I want to do while building this application. Please stay tuned for further updates so that you can track the progress of my personal project.`,
        },
    ],
}