export default {
    id: '2020-02-25',
    year: 2020,
    month: 2,
    date: 25,
    title: `Developer Story: A Milestone Reached`,
    blog_url: `https://keithvictordawson.medium.com/developer-story-a-milestone-reached-84f05e042724`,
    image_url: `https://images.unsplash.com/photo-1544228865-7d73678c0f28`,
    image_caption: `Photo by <a class="text--primary" href="https://unsplash.com/@lorenzorui">Lorenzo Rui</a> on <a class="text--primary" href="https://unsplash.com/s/photos/mobile-phone">Unsplash</a>`,
    contents: [
        {
            type: 'text',
            content: `Way back at the beginning of October last year, when I first began working on my personal project, I started devising a series of milestones that I would need to reach in order to make the ultimate end goal of my personal project a reality. As I have mentioned previously, I prefer to approach any software problem that I am faced with beginning with the database and working my way up from there towards the client. With this perspective in mind, my first minor milestone was to create a database in which I could begin to store some of the seed data that I knew the system would need to contain. In November last year I finally got a base version of my database built and running and started to fill it with some of the seed data, although not all of it yet, due to the fact that the dataset is rather large and requires a great deal of effort to sanitize before molding it into database seed scripts. At present, I am using a MongoDB cluster as the database for my system and I fully intend to continue expanding it to house all of the data necessary to support features that I will be adding to all of the client applications for the system.`,
        },
        {
            type: 'text',
            content: `Beyond the database, the next minor milestone that I devised was a server API application. As I have mentioned several times previously, part of the point of working on this personal project is to work with new technologies and learn new skills that I did not have before. For this reason, I actually built two different server API applications for the system, although only one of them will actually be the one that I use in the final version of my personal project. In December of last year I completed the first version of my base server API application using ExpressJS. ExpressJS is the server interface framework that I am most comfortable with and most knowledgeable about, so this is the server API application that I will use in the final version of my personal project. Also in December of last year, I completed the second version of my base server API application using GraphQL. I chose to create this second version using GraphQL because I have been hearing a lot about it over the last couple of years. I really wanted to give it a try so that I could understand how the system works and understand the philosophy behind graph-oriented API design.`,
        },
        {
            type: 'text',
            content: `With the database and server API application milestones reached, the next minor milestones that I had set for myself were three separate client applications. The system that I am creating with my personal project requires the system to be accessed by both administrators who oversee all of the system data and regular users who interact with a subset of that data. As a result, two of the minor client milestones that I created for myself were an admin web client application and a user web client application. In terms of client frameworks, ReactJS is the one that I am the most familiar with, so both of these client applications will be built using ReactJS. Additionally, I have a very strong desire to gain some experience working on mobile applications, so the third minor client milestone that I set for myself was building a mobile user client application. In order to leverage the knowledge and experience that I have working with ReactJS, I decided to create the mobile user client application using React Native. Last month I completed a base version of the admin web client application and just this month I completed base versions of the user web and mobile client applications.`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `With these five minor milestones reached, I have succeeded in reaching the first <span class="font-italic">major</span> milestone that I had devised for myself back at the start of this project. While working on my personal project, my thoughts about what it should include and the direction in which I should be working have changed. In general, as revealed in my `,
                },
                {
                    type: 'internal_link',
                    year: 2020,
                    month: 2,
                    date: 11,
                    content: `previous`,
                },
                {
                    type: 'text',
                    content: ` developer story entry, the scope of my personal project has grown and widened more and more as I have made further progress on each part of the system. Originally I had devised the system to be oriented only towards language learning. Since the beginning of the project, however, I have changed course and decided that I want the system to be oriented towards cultural learning, which will also include a language learning component within it.`,
                },
            ],
        },
        {
            type: 'text',
            content: `Even back when the goal was initially just to create a language learning system, I knew that in order to provide myself with the room to let my imagine run wild and devise some useful and interesting features for the system, I needed to have a canvas on which I could project those thoughts. This canvas would need to exist in some form close to the final state of the overall system that will be the result of my personal project. I knew that this meant that I would need to create a base three-client system with a supporting server API application and database. Without this base version of the system built, I was sure that I would have a difficult time envisioning some of the features that I ultimately want to go into the system.`,
        },
        {
            type: 'text',
            content: `Now that I have this canvas available to me, I will be taking a step back to look at the entire system as a whole and make improvements and adjustments where necessary. I will also be switching into the imagination phase of my personal project, where I can start to map out and implement changes that will need to be made throughout the system to support all of the features necessary in a cultural learning system. Please stay tuned for more developer story entries as I begin to develop the ideas that I have for those features and detail the progress that I continue to make on my personal project.`,
        },
    ],
}