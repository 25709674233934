export default {
    id: '2020-01-31',
    year: 2020,
    month: 1,
    date: 31,
    title: `An SE’s Perspective: The Elements of User Experience (Part 8)`,
    blog_url: `https://keithvictordawson.medium.com/an-ses-perspective-the-elements-of-user-experience-part-8-a613e1c8c789`,
    image_url: `https://miro.medium.com/max/700/1*De5Lfps7YDWxi1LBTAwGyw.png`,
    image_caption: `The Elements of User Experience, Jesse James Garrett`,
    contents: [
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `In this series I read through each chapter of the groundbreaking UX design book `,
                },
                {
                    type: 'italic_link',
                    url: `http://www.jjg.net/elements/`,
                    content: `The Elements of User Experience`,
                },
                {
                    type: 'text',
                    content: ` and discuss the content from my perspective as a software engineer. The author of this book, Jesse James Garrett, wrote it in the hope that it would help UX designers or anyone else interested in UX design to understand the decisions that UX practitioners make during the course of their work. Unlike the first edition, this second edition of the book is not so heavily focused on UX as it pertains to web development, but rather written in such a way as to apply to any context in which UX plays an important role. Please keep in mind that my perspective comes from the direction not of a UX designer but of a software engineer who strives to wield the principles of UX as a tool to inform all of the software development decisions that I make and enhance all of the software that I create. As a result, all discussion about this book will be in that context.`,
                },
            ],
        },
        {
            type: 'spacer',
        },
        {
            type: 'highlight',
            content: `The elements of user experience remain consistent no matter how complex your product is. But putting the ideas behind the elements into practice can sometimes seem like a challenge all by itself. It’s not just a question of time and resources — it’s often a question of mindset.`,
        },
        {
            type: 'text',
            content: `The title of the eighth chapter is <span class="font-italic">The Elements Applied</span> and in this chapter the author provides a lot of advice on how best to ensure that you and your team effectively apply all of the practices detailed throughout the entire book to every product that you work on. Although a lot of the specialized design disciplines that are introduced on various planes of the <span class="font-italic">Elements of User Experience</span> would seem to require the expertise of a large group of highly experienced specialists, the author is quick to point out that this is not necessarily true for all organizations or products. Even small teams with a limited set of resources will be able to achieve results similar to that of the larger and more experienced group. The real determining factor for any team to succeed is that team’s ability to maintain strict discipline and approach the UX design problems that they face with the right frame of mind.`,
        },
        {
            type: 'quote',
            content: `Designing the user experience is really little more than a very large collection of very small problems to be solved. The difference between a successful approach and one doomed to failure really comes down to two basic ideas: Understand what problem you’re trying to solve. Understand the consequences of your solution to the problem.`,
        },
        {
            type: 'text',
            content: `I really appreciate that the author makes the point here that any UX design problem, no matter how large, is really just a collection of many small problems. One of the most important and valuable lessons that I learned and skills that I gained while earning my software engineering degree in university was this simple idea. Despite its simplicity, very few people are able to embrace this fact when faced with the seemingly insurmountable challenge of developing an entire comprehensive UX design for a complex product or architecting and building all of the software necessary to bring that product to life. It is almost like an instinct that you develop for running headlong at the problem and addressing it directly rather than trying to avoid the problem by skirting around it. It is a skill that can be developed, as I did during my time in university, but it usually only comes after facing the great adversity of a particularly challenging and complex development project into which you are forced to put all of your blood, sweat, and tears. Since the software development process and the UX design process are so closely intertwined and the software development process requires this skill in order to achieve effective results, I am very happy that the author also stresses the importance of this skill as it pertains to UX design.`,
        },
        {
            type: 'text',
            content: `To have a truly successful outcome as you and your team follow the UX design process, the author mentions in the quote above that the two most important ideas to keep in mind while doing all of your work are understanding the problem that you are trying to solve and what consequences result from your solution to that problem. In order to actually achieve both of these things, it is absolutely critical that you or another member of your team dedicate the necessary attention to all of the lessons presented throughout this book, as the author explains:`,
        },
        {
            type: 'quote',
            content: `Only by having someone in your organization think about each of the five planes can you address all the considerations crucial to creating a successful user experience. How these responsibilities are distributed in your organization isn’t as important as making sure all the elements of user experience are accounted for.`,
        },
        {
            type: 'text',
            content: `As long as you and your team are able to effectively address all of the concerns that make up the <span class="font-italic">Elements of User Experience</span> framework, you should be able to avoid a lot of the pitfalls that lie ahead of you in the UX design process. At the end of the introduction to this final chapter, the author highlights three particularly poor design choices that are often made when designing the user experience for a product. The first choice is <span class="font-italic">design by default</span>, which is when the structure of the user experience follows the underlying technological structure of the product or the structure of the company that is developing that product. The second choice is <span class="font-italic">design by mimicry</span>, which is when the user experience for a product depends upon the conventions established in other products, regardless of whether or not those conventions are appropriate for the users of the product under development. The third choice is <span class="font-italic">design by fiat</span>, which is when the decisions that are made for the user experience of a product are dictated not by the product objectives and user needs of that product, as they should be, but by the personal preferences of a single person in the company who has an outsized influence on the design and development process. Prevent your team from making these foolish choices by ensuring that your team gives all of the necessary consideration to each of the five planes in the <span class="font-italic">Elements of User Experience</span> framework.`,
        },
        {
            type: 'title',
            content: `Asking the Right Questions`,
        },
        {
            type: 'text',
            content: `One of the biggest challenges in successfully executing the UX design process is determining what questions are the <span class="font-italic">right</span> questions to ask about the product. In the midst of all the background noise that you will experience while going through the process it can be very easy to lose your direction and begin to question whether you are following the correct path. The author offers some useful advice to help you continue in the right direction:`,
        },
        {
            type: 'quote',
            content: `The right approach is to ground each decision in your understanding of the underlying issues at play. The first question you should ask yourself (and the first question you should be able to answer) about any aspect of the user experience is: Why did you do it that way?`,
        },
        {
            type: 'text',
            content: `As long as you are accurately able to answer this question as it applies to every action that you are making along the way, and the answer that you provide is reasonable and generally indicates that you are working towards fulfilling the goals that have been defined for the product, you can be assured that you are going in the right direction. With that guiding principle, the main obstacle that you will likely face in implementing the UX design process is convincing the right people in your organization to provide it with the time and money that it actually requires in order to be fully executed. Obviously, every single person who has ever worked on a software project for a company knows that the first and last things that are talked about and considered throughout the entire lifetime of the project are how much time will the development process take to complete and how much money will completion of that process cost the company.`,
        },
        {
            type: 'text',
            content: `The author stresses the importance of remaining strong and resisting the temptation that you may feel from external forces to gloss over portions of the UX design process or to delay commencement of the UX design process until some time later in the project lifetime, when it is too late to make any meaningful UX design decisions. It is common for a lot of higher-ups in an organization to believe that the right approach to UX design is to add a user-oriented process like user acceptance testing to the end of the development process. The problem with such thinking is that it leaves all of the foundational decisions that need to be made about how the product should be built to satisfy the needs of the user until the end of the entire development process, when those decisions can no longer have any sort of useful or positive impact on the product. In terms of the fundamental principles of UX design, doing this is literally putting the cart before the horse. Fighting for proper consideration to be given to the user experience design of a product is fighting for the success of that product. It is a struggle worth having when considering the fact that you will likely dedicate months or years of your life to the development of that product.`,
        },
        {
            type: 'text',
            content: `Assuming that you are able to overcome these very real organizational hurdles and actually focus your attention and energy on properly executing the UX design process, the only thing that you have left to worry about is, as the title of this section suggests, asking the right questions. Opinions differ on how best to do that, as some people believe techniques such as user testing are an important part of the UX design process. The author goes in a different direction, however, and posits that directly asking your users about their needs is not the only tool that should be employed in discovering and documenting the user needs of a product. Users are fallible and oftentimes unable to accurately state how they feel, much less describe what they want and expect out of a product. Additionally, there may be flaws in your approach to user testing that could yield the wrong questions and as a result the wrong answers. In this case, if all you did was rely on user testing, then you would be following the wrong path forward right from this point. Direct interaction with your users cannot be the only means of discovering their needs, as the author stresses in his conclusion to this section:`,
        },
        {
            type: 'quote',
            content: `You simply cannot depend on your users to articulate their needs. The challenge in creating any user experience is to understand the needs of the users better than they understand those needs themselves. Testing can help you understand the needs of your users, but it’s just one of many tools that can help achieve the same end.`,
        },
        {
            type: 'title',
            content: `The Marathon and the Sprint`,
        },
        {
            type: 'text',
            content: `The author begins this section with an analogy comparing the UX design process to the experience of sprinters and marathon runners. If you are a software developer you will know exactly what I mean when I use the words sprint and marathon in the context of the software development process. As a software engineer who has struggled through endless sprint and marathon cycles of the software development processes of many different companies, I am very happy that the author recognizes the failure of some UX designers to identify working pace as an important factor in successfully carrying out their duties. Both the UX design process and the software development process can yield strikingly different results depending on the pace with which team executed those processes. I believe the following advice is particularly powerful in demonstrating the need to exercise self-constraint while working through the UX design process:`,
        },
        {
            type: 'quote',
            content: `Product development is rarely a sprint. More often, there will be times when you push forward, building prototypes and generating ideas, followed by times when you pull back, testing what you’ve built, seeing how the pieces fit together, and refining the big picture for the project. Some tasks are best undertaken with an emphasis on speed; others require a more deliberate approach.`,
        },
        {
            type: 'text',
            content: `The value in knowing when to maintain the appropriate pace while working through the problems really is as important as actually solving the problems themselves. There are times when you should focus hard on solving a particular problem and race towards the solution as fast as possible, while there are other times when it is best to take a step back and consider where you are in the overall journey. Oftentimes, while you have your head down and you are focusing on only that one particular problem, other equally critical problems will pass right by you and your failure to recognize and solve those problems can have a very detrimental impact on the process and the product as a whole. It can be quite difficult and costly to recover from a lack of attention being paid to solving the right problems at the right time in both the UX design and software development processes.`,
        },
        {
            type: 'quote',
            content: `Looking at a product from the outside it’s easy to focus on the more obvious elements near the top of the five-plane model at the expense of those closer to the bottom. The irony, however, is that the elements that are hardest to see — the strategy, scope, and structure of the product — play the most important role in the overall success or failure of the user experience. In many cases, failures on upper planes can obscure successes on lower planes. Similarly, making all the right decisions on the upper planes means nothing if those decisions are founded on bad choices made on the lower planes.`,
        },
        {
            type: 'text',
            content: `While looking back at everything that has been introduced and discussed throughout this book in terms of the five planes and how they are heavily interconnected and dependent on the success of one another, the author drives the point home one last time about the importance of working from the bottom up. If all you do is focus on the superficial planes near the top of the framework without addressing the concerns of the deeper planes that really get to the heart of the matter in determining the real needs of your users, you are likely to experience failure. The entire reason why the five planes are organized the way that they are with the surface-level considerations positioned at the end of the process rather than at the beginning is because, when it comes down to it, UX design is not driven by what the users want to see, but rather what they want to do. If you put a shiny, colorful product in front of a user that only looks nice and does not fulfill any of their functional needs, in all likelihood they will move on to something better. Substance is the key differentiator between a successful product and a failed product, and the lower planes are what helps to ensure that your product is infused with as much substance as necessary to satisfy your users. The author offers these final words of advice on delivering a product with real, tangible value to the user:`,
        },
        {
            type: 'quote',
            content: `If you approach your product development process with the complete user experience in mind, you can come out of it with a product that’s an asset, not a liability. By making everything the user experiences with your product the result of a conscious, explicit decision, you can ensure that the product works to fulfill both your strategic goals and the needs of your users.`,
        },
        {
            type: 'spacer',
        },
        {
            type: 'text',
            content: `Having arrived at the end of my second read through this book after first reading it over five years ago, I have to say that I have learned a lot that I did not really pick up on my first time through. I think the reason why I missed a lot of those lessons was because, at the time of my first exposure to this book, I had not fully matured to the veteran software engineer that I am today. At that point in my career I had only worked for half of the companies that I have worked for at this point in my career. I had yet to experience some of the best and worst work experiences of my career, and I had yet to see the biggest successes and failures of my career as well. Having experienced those ups and downs in my career and working in positions where I have had a variety of perspectives on the software engineering process has instilled in me a deep respect for not only the software engineering process but also the UX design processes and lessons that the author has presented throughout this book.`,
        },
        {
            type: 'text',
            content: `One of the biggest things that I missed on my first reading and realized throughout my second reading of this book is how much the author understands and respects the software engineering process. As I was an immature software engineer while I first read this book, I don’t think that I had a true understanding of the entire software engineering process as a whole, at least from a practical standpoint, nor did I respect its relationship and interaction with other critical processes that aid in its efforts to create good software that users understand and enjoy using. I certainly did not see the UX design process as a close companion to the software engineering process, even after that first reading. Only now, after having let the lessons in this book slowly seep into my brain and mix with all of the engineering experiences that I have had over the past five years have I come to understand that the software engineering process and UX design process are actually two sides of the same coin. Their synergistic relationship is truly necessary and unavoidable if your ultimate goal is to create software that people love to use.`,
        },
        {
            type: 'text',
            content: `The reason why I have repeatedly stated throughout my discussion of this book that I believe their is a synergistic relationship between UX design and software engineering is because there are so many parts of the software engineering process that incorporate various aspects of the UX design process. I am not a UX designer by profession, but I would surmise that a veteran UX designer would have something similar to say about the UX design process as well. Whereas the pure software engineering process has the ultimate end goal of producing a software system that provides the user with a defined set of functions and abilities, the pure UX design process has the ultimate end goal of injecting the human element into that software system. Software engineering provides the spark, while UX design provides the spice. Software engineering provides the foundation, while UX design provides the warmth and humanity that will make the software system appealing to users and help them to more easily and effectively use the software.`,
        },
        {
            type: 'text',
            content: `One of the things that I have noticed over the past five years since reading this book for the first time is how much of the work that I perform on a regular basis in my job originates in some way from the UX design process. Many software engineers would probably disagree with me when I say this, but in the course of performing our duties as software engineers we regularly swap our software engineering hat for a UX design hat. This is especially true for those of us who focus our efforts on the user interfaces of software systems that we are building, but this could be said of some of the practices that we follow while working in other parts of the system as well. In reality, any time that you wonder to yourself how something that you are building would be perceived by the user or used by the user, you are actually following the UX design process is some way. Doing so is nothing to be ashamed of and, in fact, I believe that more software engineers should be willing to swap hats and view all of the work that they do through the lens of UX design.`,
        },
        {
            type: 'text',
            content: `Many software engineers do not like to admit it and even more probably do not even realize it, but truly becoming a software engineer means that you lose a portion of your humanity and become more like a machine in order to think like one. There is nothing wrong with this happening as it is a necessary part of logical reasoning, but that transformation should not cause you to outright reject all of the support provided by UX designers and all of the benefits you can gain from practicing a bit of UX design yourself. In most of the companies that I have worked at, the software engineering team and the design team always seem to be hostile towards each other, regularly jumping at the chance to criticize the work of the other team. First of all, thinking of these two teams as being separate is counterproductive as they are in reality one team that includes both software engineers and designers. And both sides should welcome criticism from the other side as criticism, assuming it is constructive, always offers an opportunity to grow and improve. Instead, of finding ways to avoid UX design and isolate our work from it, we as software engineers should embrace UX design and welcome all of the good that it can bring to our pursuit of creating the best software possible.`,
        },
        {
            type: 'text',
            content: `As a software engineer, coming back to all of the lessons put forth in this book and viewing them in the context of all of the work that I have done throughout my career, I can undoubtedly say that they have positively altered my sense of respect for not only the UX design process but the software engineering process as well. I can see now that the UX design process has only ever existed to support and enhance the software engineering process, not tear it down. I can clearly see the light now and I know that the only true way to proceed forward is to embrace both of these disciplines not as two separate competing efforts, but as one cohesive process to follow in the pursuit of ultimate software engineering nirvana.`,
        },
    ],
}