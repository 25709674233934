<template>
    <v-btn
        class="white--text ma-4"
        color="light-blue darken-4"
        href="https://www.linkedin.com/in/keith-dawson-b9037173/"
    >
        <v-icon class="blue--text text--lighten-3">{{ icons.linkedin }}</v-icon>
        <span class="ml-2">View LinkedIn</span>
    </v-btn>
</template>

<script>
import { mdiLinkedin } from '@mdi/js'

export default {
    name: 'ResumeLinkedinButton',
    data() {
        return {
            icons: {
                linkedin: mdiLinkedin,
            },
        }
    },
}
</script>

<style scoped>

</style>