<template>
    <v-btn
        class="light-blue darken-4 white--text"
        @click="$emit('click')"
        elevation="2"
        fab
        icon
    >
        <v-icon v-if="!sortDescending">{{ icons.sortAscending }}</v-icon>
        <v-icon v-if="sortDescending">{{ icons.sortDescending }}</v-icon>
    </v-btn>
</template>

<script>
import {
    mdiSortAscending,
    mdiSortDescending,
} from '@mdi/js'

export default {
    name: 'BlogSortButton',
    props: [
        'sortDescending',
    ],
    data() {
        return {
            icons: {
                sortAscending: mdiSortAscending,
                sortDescending: mdiSortDescending,
            },
        }
    },
}
</script>

<style scoped>

</style>