export default {
    id: '2020-01-08',
    year: 2020,
    month: 1,
    date: 8,
    title: `Developer Story: Logical Routing Module Design for a RESTful Server API`,
    blog_url: `https://medium.com/javascript-in-plain-english/developer-story-logical-routing-module-design-for-a-restful-server-api-3578dd3ad59a`,
    image_url: `https://miro.medium.com/max/700/0*3mH9vVKMn9RaCrC5.png`,
    contents: [
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `In previous developer story entries I shared my thoughts and personal techniques for how to `,
                },
                {
                    type: 'internal_link',
                    year: 2019,
                    month: 12,
                    date: 6,
                    content: `design`,
                },
                {
                    type: 'text',
                    content: ` a proper RESTful server API structure and how to `,
                },
                {
                    type: 'internal_link',
                    year: 2019,
                    month: 12,
                    date: 13,
                    content: `architect`,
                },
                {
                    type: 'text',
                    content: ` a module structure in a RESTful server application. Both of these entries dealt with each of those topics on an application-wide level, but I also wanted to share my thoughts and personal techniques for designing <span class="font-italic">just</span> the module where all of the routes for an application are defined.`,
                },
            ],
        },
        {
            type: 'text',
            content: `In accordance with the software design principle that each piece of an application should serve a single purpose, the purpose of the routing module should be to define all of the routes for the application, nothing more and nothing less. A simple idea, but you would be surprised how many developers struggle with putting the appropriate amount of logic into this critical module.`,
        },
        {
            type: 'text',
            content: `The first question to ask yourself when considering how the routing module should be designed is: what types of clients will be consuming the API? Sometimes the answer may be a single type of client and sometimes there may be multiple types of clients. If there is a single type of client that the application will serve, then the shape of the module will be much simpler than it would be if the application needs to serve multiple types of clients.`,
        },
        {
            type: 'text',
            content: `The second question to ask yourself when considering the routing module design is: how many distinct logical constructs need to be presented to the API clients? Sometimes the answer is simple and database-centric, meaning that the API is a simple one-to-one representation of the database schema. Other times the answer is more complicated and client-centric, meaning that the client requires an asymmetric representation of the database contents.`,
        },
        {
            type: 'text',
            content: `Let’s first discuss the problem of dividing up the clients that will be accessing the application. This problem is better to solve first because the answer will help you to divide your routing module into logical segments at a higher level before worrying about the details of which logical constructs to present within those segments.`,
        },
        {
            type: 'text',
            content: `For many applications the answer to this question will be simple and there will only be one type of client that will be accessing the application. It could be an administrator accessing metadata and other metrics through an admin portal application, or it could be a standard user accessing publicly available information.`,
        },
        {
            type: 'text',
            content: `But ultimately this type of client is just a user with a specific view of the information stored in the database, and the API is meant to provide only that single client view. In this case, the resulting routing module would contain a single segment providing that client view. The routing module would have a very flat structure and look something like the following:`,
        },
        {
            type: 'code',
            title: `routes/`,
            content: `
api_resource_a.js
api_resource_b.js
api_resource_c.js
authentication.js
index.js`,
        },
        {
            type: 'text',
            content: `In this routing module, since there is just a single client view being represented by the API, there is just a single segment consisting of one route sub-module each for the different resources that the client needs access to as well as an optional separate route sub-module for authentication if the application provides such functionality.`,
        },
        {
            type: 'text',
            content: `Not all APIs serve a single type of client, however. Sometimes an API will provide access to both the standard user as well as the admin, and those users may be accessing the API from different types of devices as well.`,
        },
        {
            type: 'text',
            content: `I strongly believe that combinations of these different user types and contexts demand separate segments in the overall routing module. If there were both admin and user clients accessing the API from a web application, the routing module would have a structure that looks something like the following:`,
        },
        {
            type: 'code',
            title: `routes/`,
            content: `
admin_api
--> api_resource_a.js
--> api_resource_b.js
--> api_resource_c.js
--> api_resource_d.js
--> api_resource_e.js
--> authentication.js
--> index.js
user_api
--> api_resource_a.js
--> api_resource_b.js
--> api_resource_c.js
--> authentication.js
--> index.js
index.js`,
        },
        {
            type: 'text',
            content: `In this routing module, since there needs to be an admin as well as a user view represented by the API, there are two segments. Like with the flat routing module, each segment consists of one route sub-module each for the different resources needed by the admin client and user client respectively, as well as an authentication route sub-module.`,
        },
        {
            type: 'text',
            content: `If there were both admin and user clients accessing the API from a web application and there was also a user client accessing the API from a mobile application, the routing module would have a structure that looks something like the following:`,
        },
        {
            type: 'code',
            title: `routes/`,
            content: `
mobile_api
--> user_api
    --> api_resource_a.js
    --> api_resource_b.js
    --> api_resource_c.js
    --> authentication.js
    --> index.js
    index.js
web_api
--> admin_api
    --> api_resource_a.js
    --> api_resource_b.js
    --> api_resource_c.js
    --> api_resource_d.js
    --> api_resource_e.js
    --> authentication.js
    --> index.js
--> user_api
    --> api_resource_a.js
    --> api_resource_b.js
    --> api_resource_c.js
    --> authentication.js
    --> index.js
    index.js
index.js`,
        },
        {
            type: 'text',
            content: `This routing module is very similar to the previously presented version above, except for an additional sub-module layer. The two segments servicing the web application from above are contained in a web API sub-module while the segment servicing the mobile application is contained in a mobile API sub-module.`,
        },
        {
            type: 'text',
            content: `No matter which way the routing module is segmented, I believe that the most important thing is to ensure that each segment provides a unique view of the underlying database to the clients for each context. Doing so ultimately makes it easier to reason about the underlying business logic connecting the routes to the database.`,
        },
        {
            type: 'text',
            content: `Also, this allows the business logic to be properly composed and constructed with minimal complexity both in terms of the actual business logic as well as in terms of how to determine which business logic ultimately applies to which route.`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `I discuss my strategy for enforcing this standard in that previous developer story entry about `,
                },
                {
                    type: 'internal_link',
                    year: 2019,
                    month: 12,
                    date: 13,
                    content: `designing`,
                },
                {
                    type: 'text',
                    content: ` a proper server module structure. Basically, this whole routing module segmentation strategy encourages you to match the routing module structure to the high-level controller module structure so there is a one-to-one correlation between the two.`,
                },
            ],
        },
        {
            type: 'text',
            content: `With the high-level routing module segmentation strategy covered, the next thing to discuss is determining which logical constructs should be reflected in the route sub-modules defined in each routing module segment. With a database-centric API, the individual tables or collections in the database that should be visible to the client being serviced by each segment should have their own route sub-module.`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `In the various routing module structures presented above, the `,
                },
                {
                    type: 'code',
                    content: `api_resource_a`,
                },
                {
                    type: 'text',
                    content: `, `,
                },
                {
                    type: 'code',
                    content: `api_resource_b`,
                },
                {
                    type: 'text',
                    content: `, and `,
                },
                {
                    type: 'code',
                    content: `api_resource_c`,
                },
                {
                    type: 'text',
                    content: ` sub-modules may correspond to tables or collections in the database that contain publicly available information. This is the reason why they are the only tables or collections for which route sub-modules have been defined in the user client contexts.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `Conversely, the `,
                },
                {
                    type: 'code',
                    content: `api_resource_d`,
                },
                {
                    type: 'text',
                    content: ` and `,
                },
                {
                    type: 'code',
                    content: `api_resource_e`,
                },
                {
                    type: 'text',
                    content: ` sub-modules may correspond to tables or collections in the database containing privileged data that only admins are allowed to see. This is the reason why they are the only tables or collection for which route sub-modules have been defined in the admin client contexts.`,
                },
            ],
        },
        {
            type: 'text',
            content: `This method for dividing access to functionality within the routing module is simple, but sometimes it is not appropriate based on the functionality demanded by the clients serviced by the API. In those cases a client-centric API structure is needed, where the route sub-modules are divided into distinct logical constructs determined by the client.`,
        },
        {
            type: 'text',
            content: `Since the logical constructs in this case are not tied to a finite list of tables or collections in the database, the list of route sub-modules in each segment is much more open-ended and driven by all of the functionality implemented in the client. Since the possibilities are endless in this case, the division of actions to be performed through the API is much more ambiguous and it becomes very difficult to organize actions logically.`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `For that reason, I generally prefer to stick to database-centric API designs. Doing so allows for the implementation of a `,
                },
                {
                    type: 'external_link',
                    url: `https://cloud.google.com/apis/design/resources`,
                    content: `resource oriented design`,
                },
                {
                    type: 'text',
                    content: ` pattern to be applied to all of the routes defined in each of the route sub-modules. I believe this design pattern is superior because it provides a faster and more logically efficient way for the client to specify which data to perform an action on.`,
                },
            ],
        },
        {
            type: 'text',
            content: `I urge you to review the resource oriented design documentation and seriously consider using it as a guide for defining routes in any APIs that you create. I believe this pattern can bring a lot more sanity and logic to the chaotic and uneven world of API development.`,
        },
        {
            type: 'text',
            content: `With the routing module segmentation and segment structure strategies discussed, the last thing that I wanted to do was present some example code for one of those route sub-modules.`,
        },
        {
            type: 'text',
            content: `As I mentioned at the beginning of this entry, I have seen a great many developers struggle with the amount of logic that they put into their route sub-modules. To implement one of any of the ambiguous route sub-modules above, the code would look like the following:`,
        },
        {
            type: 'code',
            title: `routes/mobile_api/user_api/api_resource_a.js`,
            content: `
const express = require('express')
const logger = require('../../../log').app
const util = require('../../../util')

const cResourceA = require('../../../controllers').mobile.user.api_resource_a

const api_resource_a = express.Router()

api_resource_a.delete('/:api_resource_a_id', async (req, res) => {
    const FUNCTION_NAME = 'DELETE /mobile/user/:api_resource_a_id'
    try {
        const result = await cResourceA.remove(req)
        return result ? res.status(200).json(result) : res.sendStatus(400)
    } catch(e) {
        const error = util.err.createError(e, FUNCTION_NAME)
        logger.error(error.display)
        return res.sendStatus(error.code)
    }
})

api_resource_a.get('/', async (req, res) => {
    const FUNCTION_NAME = 'GET /mobile/user/'
    try {
        const result = await cResourceA.retrieveMany(req)
        return result ? res.status(200).json(result) : res.sendStatus(404)
    } catch(e) {
        const error = util.err.createError(e, FUNCTION_NAME)
        logger.error(error.display)
        return res.sendStatus(error.code)
    }
})

api_resource_a.get('/:api_resource_a_id', async (req, res) => {
    const FUNCTION_NAME = 'GET /mobile/user/:api_resource_a_id'
    try {
        const result = await cResourceA.retrieveOne(req)
        return result ? res.status(200).json(result) : res.sendStatus(404)
    } catch(e) {
        const error = util.err.createError(e, FUNCTION_NAME)
        logger.error(error.display)
        return res.sendStatus(error.code)
    }
})

api_resource_a.put('/:api_resource_a_id', async (req, res) => {
    const FUNCTION_NAME = 'PUT /mobile/user/:api_resource_a_id'
    try {
        const result = await cResourceA.update(req)
        return result ? res.status(200).json(result) : res.sendStatus(400)
    } catch(e) {
        const error = util.err.createError(e, FUNCTION_NAME)
        logger.error(error.display)
        return res.sendStatus(error.code)
    }
})

exports = module.exports = api_resource_a`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `There are a few things happening in this code sample, some of which I have discussed in previous developer story entries. The use of the `,
                },
                {
                    type: 'code',
                    content: `logger`,
                },
                {
                    type: 'text',
                    content: ` variable throughout the code sample is something that I discussed in my developer story entry about developing a `,
                },
                {
                    type: 'internal_link',
                    year: 2019,
                    month: 12,
                    date: 9,
                    content: `logging`,
                },
                {
                    type: 'text',
                    content: ` solution for NodeJS applications.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The use of the util.error variable as well as the structure of all of the route definition functions is something that I discussed in my developer story entry about developing an effective `,
                },
                {
                    type: 'internal_link',
                    year: 2019,
                    month: 12,
                    date: 28,
                    content: `error handling`,
                },
                {
                    type: 'text',
                    content: ` solution for NodeJS applications.`,
                },
            ],
        },
        {
            type: 'text',
            content: `Aside from these things, there are a couple key points that I want to draw attention to in this code sample. First, aside from the error handling logic in each of the route definition functions, there are only two lines of code per function.`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The first line of code passes the received request to the appropriate controller function on the `,
                },
                {
                    type: 'code',
                    content: `cResourceA`,
                },
                {
                    type: 'text',
                    content: ` variable, while the second line examines the result received from the controller to determine an appropriate response to return to the client. This is really all the code that is needed in any route definition. Just a simple handing of the request information down to the business logic encapsulated in the controller object and returning a response to the client.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The real importance of the route definition has already been accomplished by the time execution has reached the route definition, since the route itself has already been defined through the use of the functions on the `,
                },
                {
                    type: 'code',
                    content: `express`,
                },
                {
                    type: 'text',
                    content: ` object.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `Any additional logic should be confined to the vast and complex business logic layer. This keeps all of the route sub-modules short and clean with no room for confusion about where to look when a problem arises in the code: the controller object represented here by the `,
                },
                {
                    type: 'code',
                    content: `cResourceA`,
                },
                {
                    type: 'text',
                    content: ` variable.`,
                },
            ],
        },
        {
            type: 'text',
            content: `The other key point to note here is that the controller object has exactly one function per route defined in the route sub-module. Maintaining this symmetric relationship between the route sub-module and the corresponding controller sub-module additionally ensures that there is no confusion about where an error may be originating from.`,
        },
        {
            type: 'text',
            content: `The appropriate place for an asymmetric shape to the underlying logic, if there is one, is in the business logic layer. Beyond this simple route definition logic, everything else that is needed to filter the client request down to the database and bubble the response back up for return to the client is contained in that all-important business logic. The route sub-modules are only there to define interfaces for the client to access, and as such they should do nothing else.`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `That concludes my thoughts on how to build a proper routing module for defining a logically structured API. The shape and contents of this module are meant to mirror the similarly constructed database interface module that I discuss in my developer story entry about how to develop a `,
                },
                {
                    type: 'internal_link',
                    year: 2019,
                    month: 12,
                    date: 20,
                    content: `single database interface`,
                },
                {
                    type: 'text',
                    content: ` for NodeJS applications.`,
                },
            ],
        },
        {
            type: 'text',
            content: `Both of these modules are kept deliberately thin and simple because they exist to server a simple purpose. All of the complexity and heavy lifting should be performed by the business logic, which constitutes the rest of the code that exists in a NodeJS application.`,
        },
        {
            type: 'text',
            content: `I hope that my strategy for routing module design helps you to create higher quality and easier-to-understand and maintain routing modules in your own server API applications. Please stay tuned for more developer story entries as I continue further progress on my personal project.`,
        },
    ],
}