<template>
    <div class="blue lighten-4 pt-14">
        <page-title id="resume-title">Resume</page-title>
        <div class="d-flex justify-center text-body-2 mx-16">View my resume here on LinkedIn as well as my profile on other platforms by clicking any of the buttons below.</div>

        <!-- Display only on extra small screens -->
        <v-row class="flex-column hidden-sm-and-up align-center my-1">
            <linkedin-profile-button />
            <github-profile-button />
            <stackoverflow-profile-button />
        </v-row>

        <!-- Display on small screens -->
        <v-row class="hidden-xs-only hidden-md-and-up justify-center mt-1">
            <linkedin-profile-button />
            <github-profile-button />
        </v-row>
        <v-row class="hidden-xs-only hidden-md-and-up justify-center mb-1">
            <stackoverflow-profile-button />
        </v-row>

        <!-- Display on medium and larger screens -->
        <v-row class="hidden-sm-and-down justify-center my-1">
            <linkedin-profile-button />
            <github-profile-button />
            <stackoverflow-profile-button />
        </v-row>

        <page-subtitle>Summary</page-subtitle>
        <div class="text-body-2 mx-16">Highly-experienced, creative, and versatile senior software engineer and architect with a passion for creating high-quality software that both helps people and improves their lives. Although I am perfectly capable of working on my own to develop software solutions, I prefer to work with a team of engineers and designers because I truly believe that the best software only manifests itself when many opinions and viewpoints are considered and included. Additionally, I prefer working in team environments because they enable me to mentor others in their efforts to improve upon their software development skills and also provide me with opportunities to continually self-reflect and improve upon my own skills and viewpoints.</div>

        <page-subtitle>Technical Skills</page-subtitle>
        <div v-intersect="{ handler: onIntersect, options: { threshold: [ 0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0 ] } }">
            <v-simple-table class="rounded-0 mx-16">
                <template #default>
                    <thead>
                        <tr>
                            <th class="text-left text-body-1 white--text font-weight-bold light-blue darken-4">Category</th>
                            <th class="text-left text-body-1 white--text font-weight-bold light-blue darken-4">Technologies</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(skill, index) in skills" :key="index">
                            <td class="blue lighten-5">{{ skill.category }}</td>
                            <td>{{ skill.tech.join(', ') }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </div>

        <page-subtitle>Experience</page-subtitle>
        <v-timeline class="mx-16">
            <resume-job job-id="mxn3" v-bind="jobs.mxn3" />
            <resume-job job-id="mxn2" v-bind="jobs.mxn2" />
            <resume-year year="2021" />
            <resume-job job-id="mxn1" v-bind="jobs.mxn1" />
            <resume-year year="2020" />
            <resume-job job-id="project" v-bind="jobs.project" />
            <resume-job job-id="namo" v-bind="jobs.namo" />
            <resume-year align="true" year="2019" />
            <resume-job job-id="proof" v-bind="jobs.proof" />
            <resume-year align="true" year="2018" />
            <resume-year year="2017" />
            <resume-job job-id="currenxie" v-bind="jobs.currenxie" />
            <resume-year year="2016" />
            <resume-year align="true" year="2015" />
            <resume-job job-id="ironfly" v-bind="jobs.ironfly" />
            <resume-school school-id="ux" v-bind="schools.ux" />
            <resume-year year="2014" />
            <resume-job job-id="tapevents" v-bind="jobs.tapevents" />
            <resume-school school-id="web" v-bind="schools.web" />
            <resume-year align="true" year="2013" />
            <resume-job job-id="blue7" v-bind="jobs.blue7" />
            <resume-year align="true" year="2012" />
            <resume-year year="2011" />
            <resume-job job-id="trisept" v-bind="jobs.trisept" />
            <resume-year year="2010" />
            <resume-year align="true" year="2009" />
            <resume-job job-id="thinair" v-bind="jobs.thinair" />
            <resume-year align="true" year="2008" />
            <resume-job job-id="rockwell" v-bind="jobs.rockwell" />
            <resume-year align="true" year="2007" />
            <resume-year year="2006" />
            <resume-year align="true" year="2005" />
            <resume-school school-id="engineer" v-bind="schools.engineer" />
            <resume-year align="true" year="2004" />
        </v-timeline>
        <v-btn
            bottom
            class="blue--text text--lighten-4"
            @click="scroll"
            color="light-blue darken-4"
            fab
            fixed
            right
            style="height: 48px; width: 48px;"
            v-if="showScroller"
        >
            <v-icon>{{ icons.chevronUp }}</v-icon>
        </v-btn>
    </div>
</template>

<script>
import {
    mdiChevronUp,
} from '@mdi/js'

// Components
import GithubProfileButton from '../components/resume/GithubProfileButton'
import LinkedinProfileButton from '../components/resume/LinkedinProfileButton'
import PageSubtitle from '../components/application/PageSubtitle'
import PageTitle from '../components/application/PageTitle'
import ResumeJob from '../components/resume/ResumeJob'
import ResumeSchool from '../components/resume/ResumeSchool'
import ResumeYear from '../components/resume/ResumeYear'
import StackoverflowProfileButton from '../components/resume/StackoverflowProfileButton'

// Data
import jobs from '../assets/data/jobs'
import schools from '../assets/data/schools'
import skills from '../assets/data/skills'

export default {
    name: 'Resume',
    components: {
        GithubProfileButton,
        LinkedinProfileButton,
        PageSubtitle,
        PageTitle,
        ResumeJob,
        ResumeSchool,
        ResumeYear,
        StackoverflowProfileButton,
    },
    data() {
        return {
            icons: {
                chevronUp: mdiChevronUp,
            },
            jobs,
            schools,
            showScroller: false,
            skills,
        }
    },
    methods: {
        onIntersect(entries, observer) {
            this.showScroller = !entries[0].isIntersecting
        },
        scroll() {
            this.$vuetify.goTo('#resume-title', { offset: 56 })
        },
    },
}
</script>

<style scoped>

</style>