<template>
    <div>
        <div class="text-h6">{{ title }}</div>
        <div class="text-body-1 grey--text text--darken-4">{{ subtitle }}</div>
    </div>
</template>

<script>
export default {
    name: 'ResumeItemHeader',
    props: [
        'subtitle',
        'title',
    ],
}
</script>

<style scoped>

</style>