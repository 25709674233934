export default {
    id: '2019-11-15',
    year: 2019,
    month: 11,
    date: 15,
    title: `Developer Story: The Database is the Foundation`,
    blog_url: `https://keithvictordawson.medium.com/developer-story-step-1-db-7e2cf8291eb9`,
    image_url: `https://images.unsplash.com/photo-1483736762161-1d107f3c78e1`,
    image_caption: `Photo by <a class="text--primary" href="https://unsplash.com/@tofi">Tobias Fischer</a> on <a class="text--primary" href="https://unsplash.com/s/photos/database">Unsplash</a>`,
    contents: [
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `It has been two weeks since I published the `,
                },
                {
                    type: 'internal_link',
                    year: 2019,
                    month: 11,
                    date: 1,
                    content: `first entry`,
                },
                {
                    type: 'text',
                    content: ` in my developer story and I am back with a progress update. The first entry was more about the genesis of my project idea and did not get into any of the specifics of my project, but for the rest of this developer story series I will be covering the specifics of many different aspects of the development of my project. As I had mentioned in the first entry, I am the type of software engineer who approaches any new software problem beginning with the database, so this entry will only be discussing my project database.`,
                },
            ],
        },
        {
            type: 'text',
            content: `Throughout my career, I have met many developers who approach any new project from the front-end, visual part of the application and they build towards the back-end and database from there. I have no problem with that approach and I completely understand the impulse from software developers that approach problems in that way. Most people are much more artistically and visually minded than I am, so this approach would naturally be the better approach for them when encountering a new software project. I, however, have never felt comfortable working in that direction, and in fact, I prefer to work in the complete opposite direction.`,
        },
        {
            type: 'text',
            content: `Since every software project that I have worked on professionally has had a substantially sized database, in order for me to be able to build out all of the pieces of a software application visually in my brain during the planning phase, I have always required a foundation on which to build. As the database drives most or all of the content presented in the software applications that I have worked on, I believe that it makes much more sense to get a clear idea of how that database will be shaped before the other pieces of the system are built on top of it. Of course, it is not reasonable to demand that the entire database schema be completely nailed down and the entire database to be fully built before development of the other parts of the system can be started. But I do prefer and I do think that it is reasonable to put a lot of thought into the initial database shape and come up with something that you feel comfortable with before moving on to developing the visual representation of that data.`,
        },
        {
            type: 'text',
            content: `Which brings me to the current development phase of my project. In this developer story entry, not only did I want to share my thoughts about why I started working on the database for my project first before the other parts of the system, but I also wanted to share my current and future plans for all of the database work that I will be doing on this project. Over the last two weeks since my first developer story entry, I have been shifting from purely working on cleaning up a dataset that I will use in my project to populating seed scripts for populating my project database. As I mentioned in my previous entry, the reason for this taking so long is that the dataset I am working with is quite large, with over seventy thousand entries. It is literally a dictionary’s worth of data and it was not at all clean or consistent to begin with, which is why it has taken so much time and is such a challenge to get into a shape that I feel is acceptable for this project. The work on finalizing the data cleanup and populating the seed scripts will continue on for the foreseeable future, but it will be happening in parallel with my work on the actual back-end and front-end parts of the system instead of taking up all of my time as it did before my first developer story entry.`,
        },
        {
            type: 'text',
            content: `The first type of database that I will be using for my project is MongoDB. There are many reasons why I am starting with MongoDB, but the biggest reason is that it is the database that I have used the most recently in my professional career. Another big reason is that, in my opinion, it is the most flexible database that I can use while going through the database design phase and early development phase of my application. Another reason for using MongoDB initially is that there are a number of features included in MongoDB that I have not yet had a chance to experiment with and use in my professional career, so I want to make a point of utilizing those features during the development of my application. But long-term, I will not stick exclusively to using MongoDB because I want to broaden my horizons as far as the database systems that I am current on and familiar with.`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `As far as No-SQL database systems go, I am also thinking about developing a CouchDB version of my database so that I can gain more familiarity with that specific database system. I have never used it before, either professionally or personally, but I want to get experience working with another No-SQL database system so that it can further inform my understanding of such database systems. Also, after having worked with RedisDB in the past, I would like to work with it again on this project, although I am not yet sure how I would use it to suit the purposes of my project. Due to the fact that a lot of development has been done on it in the past couple of years since I last used it, I would really like to get back into using it so that I can learn about these newer features and become comfortable with using it again. Finally, I plan on getting back into SQL with a PostgreSQL version of my database. I have previously worked with a few different SQL database systems, but PostgreSQL is by far the one that I prefer working with most because of its symbiotic relationship with Javascript in the form of plpgSQL. If you have not worked with `,
                },
                {
                    type: 'external_link',
                    url: `https://www.postgresql.org/docs/12/plpgsql.html`,
                    content: `plpgSQL`,
                },
                {
                    type: 'text',
                    content: `, I highly recommend giving it a try.`,
                },
            ],
        },
        {
            type: 'text',
            content: `My vision for the future of this project is that I would have a few different data-equivalent versions of my database built, each using a different database system but all equivalent in terms of shape. With such a system built, I would honestly like to be able to run tests to see which database system performs better in different use cases so that I can gain a better first-hand understanding of when to use a specific database system in certain situations. This would be the ultimate goal and the benefit of having such a system built would serve to solidify my knowledge on a variety of different database systems. Also, I would love to be able to take the results of those tests and present them in a future developer story entry for the benefit of anyone out there looking for more comparison evidence between No-SQL and SQL database systems. Not only do I intend for my developer story to serve as a record of what I have done during my development journey, but I would also like to provide useful information to the audience for their benefit as well. And on that note, before ending this entry, I would like to say that I fully intend to include code samples in many of my upcoming entries. Please stay tuned for those and more on my thoughts as I make further progress on my personal project.`,
        },
    ],
}