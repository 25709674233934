import blog_2019_11_01 from './2019-11-01'
import blog_2019_11_15 from './2019-11-15'
import blog_2019_11_18 from './2019-11-18'
import blog_2019_11_22 from './2019-11-22'
import blog_2019_11_28 from './2019-11-28'
import blog_2019_12_01 from './2019-12-01'
import blog_2019_12_06 from './2019-12-06'
import blog_2019_12_09 from './2019-12-09'
import blog_2019_12_13 from './2019-12-13'
import blog_2019_12_17 from './2019-12-17'
import blog_2019_12_20 from './2019-12-20'
import blog_2019_12_28 from './2019-12-28'
import blog_2019_12_31 from './2019-12-31'

export default [
    blog_2019_11_01,
    blog_2019_11_15,
    blog_2019_11_18,
    blog_2019_11_22,
    blog_2019_11_28,
    blog_2019_12_01,
    blog_2019_12_06,
    blog_2019_12_09,
    blog_2019_12_13,
    blog_2019_12_17,
    blog_2019_12_20,
    blog_2019_12_28,
    blog_2019_12_31,
]