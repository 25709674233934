export default {
    id: '2020-01-27',
    year: 2020,
    month: 1,
    date: 27,
    title: `An SE’s Perspective: The Elements of User Experience (Part 6)`,
    blog_url: `https://keithvictordawson.medium.com/an-ses-perspective-the-elements-of-user-experience-part-6-56f577cbce92`,
    image_url: `https://miro.medium.com/max/700/1*De5Lfps7YDWxi1LBTAwGyw.png`,
    image_caption: `The Elements of User Experience, Jesse James Garrett`,
    contents: [
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `In this series I read through each chapter of the groundbreaking UX design book `,
                },
                {
                    type: 'italic_link',
                    url: `http://www.jjg.net/elements/`,
                    content: `The Elements of User Experience`,
                },
                {
                    type: 'text',
                    content: ` and discuss the content from my perspective as a software engineer. The author of this book, Jesse James Garrett, wrote it in the hope that it would help UX designers or anyone else interested in UX design to understand the decisions that UX practitioners make during the course of their work. Unlike the first edition, this second edition of the book is not so heavily focused on UX as it pertains to web development, but rather written in such a way as to apply to any context in which UX plays an important role. Please keep in mind that my perspective comes from the direction not of a UX designer but of a software engineer who strives to wield the principles of UX as a tool to inform all of the software development decisions that I make and enhance all of the software that I create. As a result, all discussion about this book will be in that context.`,
                },
            ],
        },
        {
            type: 'spacer',
        },
        {
            type: 'highlight',
            content: `The conceptual structure begins to give shape to the mass of requirements arising from our strategic objectives. On the skeleton plane, we further refine that structure, identifying specific aspects of interface, navigation, and information design that will make the intangible structure concrete.`,
        },
        {
            type: 'text',
            content: `The title of the sixth chapter is <span class="font-italic">The Skeleton Plane - Interface Design, Navigation Design, and Information Design</span> and in this chapter the author describes another layer of refinement of all of the deliverables that came out of the Structure Plane. In the Structure Plane, the UX design process was primarily concerned with defining how the product being developed will work, and it mainly focused on macro-level issues such as architecture and interaction. In the Skeleton Plane, the UX design process is primarily concerned with defining what form all of the functionality in the product will take, and it focuses much more on micro-level issues such as individual components and their relationships to other parts of the system.`,
        },
        {
            type: 'title',
            content: `Defining the Skeleton`,
        },
        {
            type: 'image',
            url: `https://miro.medium.com/max/471/1*SpL7WNE7cR6tNEYJVHnTfA.png`,
            caption: `The Skeleton Plane, Jesse James Garrett`,
        },
        {
            type: 'text',
            content: `This chapter begins with a discussion of the three specific disciplines that make up all of the work that is performed while working in the Skeleton Plane of the <span class="font-italic">Elements of User Experience</span>. The <span class="font-italic">interface design</span> process looks at the product from the functionality perspective, while the <span class="font-italic">navigation design</span> process looks at it from the information perspective. Additionally, the <span class="font-italic">information design</span> process bridges both perspectives. The relationship between these three disciplines can be complicated and confusing, as the author explains:`,
        },
        {
            type: 'quote',
            content: `These three elements are closely bound together — more so that any of the other elements covered in this book. It’s not uncommon to be faced with navigation design problems that begin to blur into information design problems, or to encounter questions about information design that turn out to be matters of interface design.`,
        },
        {
            type: 'text',
            content: `As a software engineer, my foremost concern is ensuring that all of the functionality that I build into the product works properly and communicates well with the server. The very narrow distinction between the three disciplines in the Skeleton Plane can oftentimes feel like an unnecessary distraction and complication added to the already difficult task of building a product interface that works well and delivers all of the functionality defined in the software requirements. But paying attention to the problems that these three disciplines are meant to address can actually be more of a help than a hindrance. Understanding this fact may be a lot easier if each of their individual focuses can be defined in simple layman terms:`,
        },
        {
            type: 'quote',
            content: `If it involves providing users with the ability to do things, it’s interface design. If it involves providing users with the ability to go places, it’s navigation design. If it involves communicating ideas to the user, it’s information design.`,
        },
        {
            type: 'text',
            content: `I think in these terms it is much easier for a software engineer to understand that these three disciplines really do provide assistance to all of the work that needs to be completed during this phase in the development process rather than hinder it. In fact, it is not only the job of the UX designer to ensure that these three concepts receive due consideration during development of the product. The software engineer should also keep these concerns in the forefront of their mind while building all of the user interfaces for the product. All of that functionality being built into the product would not really have much value if the user is ultimately unable to understand how to use that functionality, how to navigate around the product to discover all of that functionality, or understand the ways in which the product attempts to effectively communicate all of the information that it provides to the user. For these simple reasons alone, every software engineer should embrace these three disciplines as an integral part of their duty in developing the user interface of any product.`,
        },
        {
            type: 'text',
            content: `The chapter introduction ends on a high note with a clear explanation for why the information design process is applied to a product from both a functionality perspective as well as an information perspective:`,
        },
        {
            type: 'quote',
            content: `Information design is the broadest of the three elements of this plane, potentially incorporating or drawing upon aspects of almost everything we’ve seen so far on both the functionality side and the information side. Information design crosses the boundary between task-oriented functionality and information-oriented systems because neither interface design nor navigation design can be fully successful without good information design to support them.`,
        },
        {
            type: 'title',
            content: `Convention and Metaphor`,
        },
        {
            type: 'text',
            content: `Before diving into a discussion about interface design, the author provides a bit more background on the importance of one of the underpinnings of interface design, which is the concept of conventions. Over a lifetime, all humans inherently experience the establishment of certain conventions in their brains through the development of reflexes that they subconsciously perform when encountering a familiar object or situation. This is true not only when dealing with tangible real-world objects, but also when dealing with software. For example, when logging into a website you are always asked to enter your username or email address and a password, in that order. If you encountered a website that asked you for those things in the reverse order, that website would be said to be breaking from convention.`,
        },
        {
            type: 'text',
            content: `Typically, software engineers do not only have experience working with software systems that they have built themselves. They also have experience using and working with a variety of other software systems that they have not had a hand in building. Having the experience of working with a variety of other software systems is an important part of being a software engineer because it forces them to develop an awareness of how those other software systems handle certain situations, both right and wrong. The more of these experiences that you can have as a software engineer, the better sense that you will have to identify when the interface for a particular software system is built right from an interface design perspective, and when it is does not quite achieve its intended interface design goals. Essentially, these experiences enable you to build a catalog in your brain of the various conventions that are established in other software systems, so that when it comes time to implement something similar in your own software you are prepared to follow those conventions. Of course, not all conventions are appropriate for all situations. As the author points out, conventions should only be followed when it makes sense to do so and you should not blindly follow them for their own sake:`,
        },
        {
            type: 'quote',
            content: `You should be cautious about deviating from convention and only do so when a different approach offers clear benefits. Creating a successful user experience requires having explicitly defined reasons for every choice you make.`,
        },
        {
            type: 'text',
            content: `In addition to having an awareness of conventions, another interface design concept that it is important to be aware of is metaphors. Whereas conventions should generally be followed in most contexts due to their universal acceptance and understanding, metaphors should be avoided in most cases. The reason for this is because metaphors can rely too heavily on the cultural experiences of the user. For example, the types of icons that you use on buttons in your software may have a particular meaning for you based on where you are from, but they may have an entirely different meaning for a user from another place. Even small things like icons can have the unintended effect of confusing a user rather than clarifying something for the user. The author offers this sensible advice on the use of metaphors when building any software system:`,
        },
        {
            type: 'quote',
            content: `Drawing analogies between features of your site and experiences people have in the real world might seem like a good way to help people get a handle on what those features are all about. However, this kind of approach usually obscures the nature of the feature instead of revealing it. Even though the association between the feature and its metaphorical representation is clear to you, it’s just one of any number of associations your users might apply.`,
        },
        {
            type: 'title',
            content: `Interface Design`,
        },
        {
            type: 'text',
            content: `Interface design is one of those areas of software development that I believe a lot of software engineers believe they always succeed in but usually fall short of what is really required in most of the software systems that they build. In this discussion of the interface design process, the author highlights something that I know I am most definitely guilty of when developing user interfaces for any software that I create. And in doing so, he made me realize just how important it is for software engineers to give interface design the attention that it deserves.`,
        },
        {
            type: 'quote',
            content: `Interface design is all about selecting the right interface elements for the task the user is trying to accomplish and arranging them on the screen in a way that will be readily understood and easily used.`,
        },
        {
            type: 'text',
            content: `I know what you’re thinking. This definition of interface design is common sense and how could I so spectacularly fail at accomplishing such a basic and straightforward goal? The author’s description of what constitutes a <span class="font-italic">successful interface</span> is what really helped me to see the error of my ways.`,
        },
        {
            type: 'quote',
            content: `Successful interfaces are those in which users immediately notice the important stuff. Unimportant stuff, on the other hand, doesn’t get noticed — sometimes because it’s not there at all. One of the biggest challenges of designing interfaces for complex systems is figuring out which aspects the users don’t need to deal with and reducing their visibility (or leaving them out altogether).`,
        },
        {
            type: 'text',
            content: `That last part, in my opinion, is what really drives the point home that what many software engineers think is good interface design is anything but. I have known many software engineers that think giving the user more options to choose from is better than giving them less. Only a few of the software engineers that I have known understand that, as a user who is experiencing a new software system for the first time, less is actually more when it comes to the options that they should have to worry about.`,
        },
        {
            type: 'text',
            content: `One of the major reasons why so many options always seem to sneak into the interfaces that software engineers build is because they are always trying to handle all of those edge cases in which users invariably find themselves. In order to head the users off, software engineers add a control here and another control there and before you know it the interface is littered with all of the different options that we as the creators of the software believe are necessary in order to ensure that the user enjoys an error-free experience. This pitfall can be avoided by simply putting yourself into the shoes of the user and picturing what it is they would prefer to see during typical usage of the software that you are creating. Understanding the typical needs and desires of your users with respect to your software can really help you achieve success in terms of interface design.`,
        },
        {
            type: 'quote',
            content: `An interface that gives a small number of extreme cases the same weight as the needs of the vast majority of users ends up ill-equipped to make either audience happy. A well-designed interface recognizes the courses of actions users are most likely to take and makes those interface elements easiest to access and use.`,
        },
        {
            type: 'text',
            content: `The best trick for avoiding failures in interface design is to understand what would be the best <span class="font-italic">defaults</span> that a typical user would require. Setting those defaults into the various controls automatically can go a long way in making your user’s experience with your software much smoother. Given enough thought and consideration, you will most likely also begin to recognize which of those controls that would typically contain default values for most users do not actually need to be displayed on a typical initial presentation of the user interface. Offering users ways to deviate from the usual flow and view all of the controls holding those defaults can then allow your software to address all of those edge cases that you discovered while building out the user interface.`,
        },
        {
            type: 'title',
            content: `Navigation Design`,
        },
        {
            type: 'quote',
            content: `The navigation design of any site must accomplish three simultaneous goals: it must provide users with a means for getting from one point to another on the site; it must communicate the relationship between the elements it contains; it must communicate the relationship between its contents and the page the user is currently viewing.`,
        },
        {
            type: 'text',
            content: `Much like with interface design, navigation design is another aspect of the development process that a lot of software engineers think they could never possibly get wrong. Unfortunately, the answer to all navigation design problems is not simply to put links to all other places in the software system on every individual user interface. Navigation design requires more refined consideration and it truly is one of those parts of the development process where it is imperative to put yourself into the shoes of the user and look at the software system through their eyes. In essence, navigation design is all about giving the users a map to the software system. I don’t necessarily mean in the literal sense, although the concept of a site map may be necessary in some software systems, as is the concept of an index. By map I mean you need to give the users visual cues and options to indicate where they are at all times and how they can move through the software system to get to where they want to go.`,
        },
        {
            type: 'text',
            content: `There is not a one-size-fits-all solution to this problem and different types of software systems can require one or more of the many different navigation systems that are detailed by the author in this discussion about navigation design. These navigation systems can range anywhere from global navigation, which provides the user with access to the entire software system, to local navigation, which provides the user with access to nearby parts of the software system, and everything else in between. Different software systems will require a different set of navigation systems. In order to get it right, as a software engineer, it is essential to understand who your users are, how they think about the information and functionality presented in your software system, and how they will use your software system to access that functionality and information.`,
        },
        {
            type: 'title',
            content: `Information Design`,
        },
        {
            type: 'text',
            content: `As the portion of the Skeleton Plane that perceives the product from both a functionality perspective and an information perspective, information design is one of those disciplines that can elude a single definition. The author provides some incite into how to classify information design in the overall context of the UX design process.`,
        },
        {
            type: 'quote',
            content: `Information design often serves as the glue that holds the other components of the design together. In all cases, information design comes down to making decisions about how to present information so that people can use it or understand it more easily.`,
        },
        {
            type: 'text',
            content: `Even this explanation is rather vague, but it does give us something to work with when building a useful interface for our users. In terms of the functionality in a software system, information design can inform the choices that software engineers make to communicate to the user what that functionality does. One example is the icons that we use to indicate to a user what will happen when a certain button is pressed. Another is the manner in which we choose to present a set of information to the user, for example, as a bar chart or a pie chart. In these examples, information design focuses more on the visual aspects of the user interface.`,
        },
        {
            type: 'text',
            content: `In terms of the information in a software system, information design can inform the choices that software engineers make in organizing that information in specific ways to best communicate its relation or meaning to the user. An example of this is the way in which we organize key pieces of information on a profile page describing a person’s job, address, and contact information. Depending on how we organize that information, the user viewing it will have an easier or more difficult time determining what exactly that information represents. In this example, information design focuses more on the grouping and arrangement of individual pieces of information in the user interface.`,
        },
        {
            type: 'text',
            content: `In some ways, these examples bring the real value of conventions that was discussed at the beginning of this chapter full circle. Paying attention to the conventions that have been established in other software systems in terms of icon usage, visual data presentation, and personal profile information can inform a lot of the decisions that software engineers should make when working with these types of information in their own software systems. Doing so will ensure that users avoid confusion when moving from those software systems to the new software systems being built by software engineers. The author closes out this discussion of information design by describing how it is really a two-way.`,
        },
        {
            type: 'quote',
            content: `Information design plays a role in interface design problems because the interface must not only gather information from the user, but communicate information to the user as well.`,
        },
        {
            type: 'title',
            content: `Wayfinding`,
        },
        {
            type: 'quote',
            content: `One important function that information design and navigation design work together to perform is supporting wayfinding — helping people understand where they are and where they can go.`,
        },
        {
            type: 'text',
            content: `The combination of good navigation design and good information design is the only way that software engineers can ensure that users of their software system will be able to navigate to all of the places that they want to go in the system. Without the combination of these two design processes, the individual dots that represent all of the individual user interfaces in the software system that have been created by the software engineer have not really been connected. It is not merely enough to give the user navigation options for moving from one place to another through an effective application of the navigation design process. If users are unable to discover those options, then all of that navigation design effort was for nothing. Good navigation design culminates in an effective navigation system for the user when coupled with information design by clearly and effectively communicating all of the aspects of the navigation design of the system to the user. The author describes the point at which you will know when you have implemented an effective wayfinding strategy for your users.`,
        },
        {
            type: 'quote',
            content: `Good wayfinding enables users to quickly get a mental picture of where they are, where they can go, and which choices will get them closer to their objectives.`,
        },
        {
            type: 'title',
            content: `Wireframes`,
        },
        {
            type: 'text',
            content: `Probably one of the most politically contentious documents that can be produced in the UX design process is the wireframe. For some team members, like software engineers, wireframes provide too much detail and they can be seen as a constraint on their creative expression during the development process. For other team members, like traditional web designers, wireframes provide too little detail and are perceived as an oversimplification of all of their hard work. For yet another group of team members, like the early product strategists, wireframes can fail to capture the true essence of the system that has been under design and development since way back in the Strategy Plane.`,
        },
        {
            type: 'text',
            content: `A lot of this dispute comes from the fact that there is no true single definition for what constitutes a wireframe. A wireframe is meant to be a bare-bones depiction of a specific user interface, but opinions can vary widely on how much detail should be included directly in the wireframe versus how much detail should be included in supplementary documentation that is appended to the wireframe. Despite the lack of a clear definition for what a wireframe truly is, the author does provide a good reason for why it is created in the Skeleton Plane and the significance it holds in the overall UX design process.`,
        },
        {
            type: 'quote',
            content: `The value of wireframes is the way they integrate all three elements of the structure plane: interface design, through the arrangement and selection of interface elements; navigation design, through the identification and definition of core navigation systems; and information design, through the placement and prioritization of information components. By bringing all three together into a single document, the wireframe can define a skeleton that builds on the underlying conceptual structure while pointing the way forward toward the surface design.`,
        },
        {
            type: 'text',
            content: `As a software engineer that has worked on many software systems with many different teams composed of team members serving in a variety of roles, I can definitely say that I am not always clear on where the system will end up visually. Also, as a software engineer who is more inclined to working on the server side than the client side, I can sometimes struggle with taking in all of the requirements for a software system and forming a picture in my mind of how that software system will ultimately look. Wireframes are the first piece of documentation to which I can refer and really solidify my mental image of how the user interface will ultimately look. Despite their sometimes bad reputation, all software engineers should look to wireframes as an important tool for building a user interface in the best way possible so that it captures all of the design and development knowledge that has been gained up to this point. Wireframes are not meant to be a straitjacket on your development efforts, but rather they are meant to be an effective guide for you to follow towards the <span class="font-italic">correct</span> solution on which the entire development and design team has been working.`,
        },
        {
            type: 'text',
            content: `Aside from the value that I personally believe all software engineers can gain from wireframes, the author does provide some final wisdom on the value they represent in terms of our progression through all of the planes of the <span class="font-italic">Elements of User Experience</span> towards the final plane.`,
        },
        {
            type: 'quote',
            content: `In many ways, the architecture diagram we saw back in the structure plane is the grand vision for the project; here in the skeleton plane, the wireframe is the detailed document that shows just how that vision will be fulfilled.`,
        },
    ],
}