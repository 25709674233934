<template>
    <v-container class="d-flex flex-column align-center mb-8">
        <v-img
            :lazy-src="placeholder"
            min-width="100%"
            :src="url"
        />
        <div
            class="text-caption mt-2"
            v-if="caption"
        >
            <span v-html="caption" />
        </div>
    </v-container>
</template>

<script>
export default {
    name: 'BlogImage',
    props: [
        'caption',
        'placeholder',
        'url',
    ],
}
</script>

<style scoped>

</style>