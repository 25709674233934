export default {
    id: '2019-12-09',
    year: 2019,
    month: 12,
    date: 9,
    title: `Developer Story: Always Bring Receipts`,
    blog_url: `https://medium.com/javascript-in-plain-english/developer-story-always-bring-receipts-ed0ddad4fbcd`,
    image_url: `https://miro.medium.com/max/1530/0*YjMHN1mX1PzwTmlQ.jpg`,
    contents: [
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `I know that I may be sounding a bit repetitive at this point, proclaiming so many different parts of the software development process as vital, including `,
                },
                {
                    type: 'internal_link',
                    year: 2019,
                    month: 12,
                    date: 1,
                    content: `process management`,
                },
                {
                    type: 'text',
                    content: ` and `,
                },
                {
                    type: 'internal_link',
                    year: 2019,
                    month: 12,
                    date: 6,
                    content: `API structure`,
                },
                {
                    type: 'text',
                    content: `. But when it comes to collecting and maintaining receipts of the activity within your application, another truly vital part of the software development process, the only way to do it correctly is through the use of a robust logging system. In order for such a logging system to be useful, it needs to be both dependable and used consistently throughout the entire system to maintain a complete and consistent record of all the activity occurring throughout the system. Without a complete record of such activity, it would be very difficult to accurately diagnose the cause of any errors that software systems produce from time to time. Additionally, in the case of systems where individual user activity tracking functionality is required, a properly deployed logging system will ensure that a record of all user activity is maintained for later review. Without a high-quality logging system in place, no one can truly know what has happened or is currently happening within a software system.`,
                },
            ],
        },
        {
            type: 'text',
            content: `When I was solely tasked with architecting, building, and administering an entire server software system at one of my previous companies, while architecting all of the different modules that the system required, one of the first things that I sought was a logging system that could serve my immediate development needs. The first of these needs was to track what requests were coming into the system and details about how long the server to took to respond to each request and the size of the response. The second need was to track critical operational activity as well as all errors that occurred in the system. Since this application was developed using NodeJS, I searched for potential logging solutions in NPM. As it turned out, my search revealed that I would need to use two separate logging solutions in concert to create the overall logging system for the application that I was developing.`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `In order to fulfill my first need, which was request logging, I chose the consistently popular and well-worn `,
                },
                {
                    type: 'bold_link',
                    url: `https://www.npmjs.com/package/morgan`,
                    content: `morgan`,
                },
                {
                    type: 'text',
                    content: `, which at the time of writing this entry is at version 1.9.1. Per the Readme for <span class="font-weight-bold">morgan</span> it was meant to be used as HTTP request logger middleware, and that was exactly how I used it in my application. The following is how I implemented the main request logging logic in my application using <span class="font-weight-bold">morgan</span>:`,
                },
            ],
        },
        {
            type: 'code',
            title: `log/request.js`,
            content: `
function pad(num) {
    return \`\${num > 9 ? '' : '0'}\${num}\`
}

module.exports = (logsDirectory) => {
    const messageFormat = \`:date[iso] - ':method :url :status' :res[content-length] - :response-time ms\`
    const stream = require('rotating-file-stream')((time, index) =>
    {
        return !time ? 'request.log' : \`request_\${time.getFullYear()}-\${pad(time.getMonth() + 1)}-\${pad(time.getDate())}-\${index || '0'}.log\`
    }, {
        interval: '1d',
        path: logsDirectory,
    })

    return require('morgan')(messageFormat, { stream })
}`,
        },
        {
            type: 'text',
            content: `There are a few things to note in this code sample. First, I define the desired message format for every request that is logged. The format that I have defined here is completely custom and suits my particular logging needs, but it is by no means the only format that you can use in your log messages. There is an extensive list of pre-defined formats provided by <span class="font-weight-bold">morgan</span> as well as a long list of individual request details that can be included in a custom log message format. For all of the details and proper syntax regarding log message formatting, please see the <span class="font-weight-bold">morgan</span> documentation. I guarantee that they will provide everything that you need to ensure that you can collect all desired details about all of the requests received by your application.`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The other thing to note in this code sample is the fact that I define a stream to pass to <span class="font-weight-bold">morgan</span> using another NPM package named `,
                },
                {
                    type: 'bold_link',
                    url: `https://www.npmjs.com/package/rotating-file-stream`,
                    content: `rotating-file-stream`,
                },
                {
                    type: 'text',
                    content: `. Due to the fact that I was creating my application to run not only in a development context but also in a production context, one of the important hurdles that I needed to clear was ensuring that my request logs are divided up according to some reasonable time interval. Obviously, if your system is going to receive a lot of incoming requests, the log files of those requests could become quite massive if they were not rotated periodically. I won’t go into all of the details of how the file streaming works here, but I encourage you to read through the documentation for <span class="font-weight-bold">rotating-file-stream</span> to see what options they provide as far as determining where and how your logs are stored. The only detail in my code sample to note regarding rotating file streams is that I chose to divide the individual log files up into 24-hour chunks named using the log file creation date with an appended index in case the application were to restart, resulting in a new log file being created for that day. Also, the name of every log file generated will be prepended with `,
                },
                {
                    type: 'code',
                    content: `request_`,
                },
                {
                    type: 'text',
                    content: ` to differentiate it from other types of log files in the log file directory. Finally, the exported function in this code sample returns a <span class="font-weight-bold">morgan</span> logger middleware function that will be useful later when I show how I incorporate request logging into the overall logic of my application.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `In order to fulfill my second need from above, which was critical activity and error logging, I chose the even more popular `,
                },
                {
                    type: 'bold_link',
                    url: `https://www.npmjs.com/package/winston`,
                    content: `winston`,
                },
                {
                    type: 'text',
                    content: `, which at the time of writing this entry is at version 3.2.1. Per the Readme for <span class="font-weight-bold">winston</span> it is meant to be a simple and universal logging library. The following is how I implemented the main critical activity and error logging logic in my application using <span class="font-weight-bold">winston</span>:`,
                },
            ],
        },
        {
            type: 'code',
            title: `log/app.js`,
            content: `
const { createLogger, format, transports } = require('winston')
require('winston-daily-rotate-file')

exports = module.exports = (logsDirectory) => {
    return createLogger({
        format: format.combine(
            format.timestamp(),
            format.align(),
            format.printf((info) => \`\${info.timestamp} - \${info.level.toUpperCase()} - \${info.message}\`)
        ),
        level: 'info',
        transports: [
            new transports.DailyRotateFile({
                filename: 'app_%DATE%.log',
                datePattern: 'YYYY-MM-DD',
                dirname: logsDirectory
            })
        ]
    })
}`,
        },
        {
            type: 'text',
            content: `As with the request logging code sample, there are a few things to note in this code sample. First, using a couple things imported from <span class="font-weight-bold">winston</span>, I define the format in which I want my log messages to be printed. Like with <span class="font-weight-bold">morgan</span>, <span class="font-weight-bold">winston</span> provides many different options for creating a custom log message format. But unlike with <span class="font-weight-bold">morgan</span>, <span class="font-weight-bold">winston</span> does not really provide any pre-defined log message formats for you to use. I suspect that the reason for this is because the creators of <span class="font-weight-bold">winston</span> intended for it to be used as a universal logging system so they did not want to put anyone inside of a box with regards to how they use the logging functionality and instead simply provide all of the tools necessary to build any desired custom log messages. I encourage you to review the <span class="font-weight-bold">winston</span> documentation for details on how to create all of your own custom log messages using this logging system.`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The other thing to note in this code sample is where I define the transport or destination of all of the log messages. I use a daily file rotation NPM package named `,
                },
                {
                    type: 'bold_link',
                    url: `https://www.npmjs.com/package/winston-daily-rotate-file`,
                    content: `winston-daily-rotate-file`,
                },
                {
                    type: 'text',
                    content: `, also provided by the creators of <span class="font-weight-bold">winston</span>, to divide all of my log files up into 24-hour chunks, and just like with the log files created using <span class="font-weight-bold">morgan</span>, the names of all logs files contain the date of file creation. Additionally, the name of every log file generated will be prepended with `,
                },
                {
                    type: 'code',
                    content: `app_`,
                },
                {
                    type: 'text',
                    content: ` to differentiate it from other types of log files in the log file directory. Luckily, since <span class="font-weight-bold">winston</span> is such a widely used logging solution, there are many different transport NPM packages that have been created by both the creators of <span class="font-weight-bold">winston</span> as well as other developers. I recommended searching `,
                },
                {
                    type: 'external_link',
                    url: `https://www.npmjs.com/`,
                    content: `npmjs.com`,
                },
                {
                    type: 'text',
                    content: ` to see the many different ways that your log messages can be stored. Finally, the exported function in this code sample returns a logger object that can be used anywhere to generate log messages and will be useful later when I show how I incorporate error logging into the overall logic of my application.`,
                },
            ],
        },
        {
            type: 'text',
            content: `As you may have noticed in the two code samples above, both of the exported functions received a logs directory path parameter. This logs directory path was used to tell <span class="font-weight-bold">morgan</span> and <span class="font-weight-bold">winston</span> where all of the log files should be created. The following code sample shows how that logs directory path was generated:`,
        },
        {
            type: 'code',
            title: `log/index.js`,
            content: `
const fs = require('fs')
const logsDirectory = require('path').join(process.env.CWD || process.cwd(), 'logs', 'app')
fs.existsSync(logsDirectory) || fs.mkdirSync(logsDirectory, { recursive: true })

exports = module.exports = {
    app: require('./app')(logsDirectory),
    req: require('./request')(logsDirectory)
}`,
        },
        {
            type: 'text',
            content: `The key thing to note in this code sample is that I define the intended logs directory path, <span class="font-italic">logs/app</span> from the top-level application directory, and check to see if it already exists or create it if it does not exist. Once the logs directory is guaranteed to exist, the logs directory path is passed to each of the two modules for which I provided code samples earlier in this developer story entry. With both logging systems initialized, the last thing to do is use them in the appropriate places in the application initialization logic. The following code sample shows how I do this in the startup script for all of my NodeJS applications:`,
        },
        {
            type: 'code',
            title: `server.js`,
            content: `
const logger = require('./log').app

run().catch(e => {
    logger.error(\`Application has encountered an error: {e.message}\`)
    return process.exit(0)
})

async function run() {
    logger.info(\`Initializing application\`)
    ...
    const app = require('express')()
    app.use(require('./log').req)
    ...
    app.listen(1234, () => {
        logger.info('Server listening to port 1234')
    })
}`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `In this code sample I make use of both logging systems that I detailed in earlier code samples. First, the most important part is that I initialize the <span class="font-italic">log</span> module by calling `,
                },
                {
                    type: 'code',
                    content: `require(‘./log’)`,
                },
                {
                    type: 'text',
                    content: ` on the first line of my startup script to ensure that the logs directory exists before initializing both of the logging systems. While initializing that <span class="font-italic">log</span> module I also import the critical operational activity and error logging module for direct use in the startup script. In both the catch-all function and the execution function that performs all of the application initialization logic you can see that I use the `,
                },
                {
                    type: 'code',
                    content: `logger.error`,
                },
                {
                    type: 'text',
                    content: ` and `,
                },
                {
                    type: 'code',
                    content: `logger.info`,
                },
                {
                    type: 'text',
                    content: ` functions to log information about the current step of the initialization process being executed as well as any errors that may be encountered during operation of the application. In addition to using the error logging module, I also import the request logging module from the <span class="font-italic">log</span> module and use the exported function as middleware in the Express application that I have started for use by my application. This is the only thing that needs to be done in order to ensure that all HTTP requests to the application are logged to the correct location. As you can see from this code sample, whereas the request logging module is used passively and only needs to be imported once for initialization as middleware, use of the error logging module is much more active and needs to be imported and used everywhere necessary to ensure that all errors and critical operational activity are logged. But once everything has been implemented correctly like I have implemented here, you should have no doubts about what your application is doing or where errors are arising should they be encountered.`,
                },
            ],
        },
        {
            type: 'text',
            content: `And with that, you now have a good idea about how I log the majority of the activity and errors in all of my NodeJS applications. The technique of using two separate logging systems in concert is something that I have developed previously while building out several NodeJS applications over the course of my career. As I built each subsequent application I improved upon my logging functionality, and in the future I plan to further evolve the logging functionality in my NodeJS applications. One piece that I have not fully figured out yet but will be using in my personal project is a system for tracking all user actions throughout the application. It will not be logging of the application activity, but rather it will be maintaining a running list of all actions that users take that has an effect on their data and other data in the system, almost like an accounting ledger system. Such a system will not require a traditional logging system like the ones that I have detailed in this developer story entry. Instead, I would like to track such activity in a database like RedisDB or MongoDB and take advantage of the functionality that one or both of those DB systems provides. But I have not decided yet, so I will detail those decisions and more in upcoming developer story entries. Please stay tuned for that and more as I make further progress on my personal project.`,
        },
    ],
}