export default {
    id: '2020-01-22',
    year: 2020,
    month: 1,
    date: 22,
    title: `An SE’s Perspective: The Elements of User Experience (Part 4)`,
    blog_url: `https://keithvictordawson.medium.com/an-ses-perspective-the-elements-of-user-experience-part-4-bae04c26da6e`,
    image_url: `https://miro.medium.com/max/700/1*De5Lfps7YDWxi1LBTAwGyw.png`,
    image_caption: `The Elements of User Experience, Jesse James Garrett`,
    contents: [
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `In this series I read through each chapter of the groundbreaking UX design book `,
                },
                {
                    type: 'italic_link',
                    url: `http://www.jjg.net/elements/`,
                    content: `The Elements of User Experience`,
                },
                {
                    type: 'text',
                    content: ` and discuss the content from my perspective as a software engineer. The author of this book, Jesse James Garrett, wrote it in the hope that it would help UX designers or anyone else interested in UX design to understand the decisions that UX practitioners make during the course of their work. Unlike the first edition, this second edition of the book is not so heavily focused on UX as it pertains to web development, but rather written in such a way as to apply to any context in which UX plays an important role. Please keep in mind that my perspective comes from the direction not of a UX designer but of a software engineer who strives to wield the principles of UX as a tool to inform all of the software development decisions that I make and enhance all of the software that I create. As a result, all discussion about this book will be in that context.`,
                },
            ],
        },
        {
            type: 'spacer',
        },
        {
            type: 'text',
            content: `The title of the fourth chapter is <span class="font-italic">The Scope Plane - Functional Specifications and Content Requirements</span> and I can definitely say that I connected with this chapter much more on a software engineering level than I did with the third chapter, which was about the Strategy Plane. As I have mentioned multiple times previously, as a software engineer I feel that the UX design process and the software design process have a strong synergistic relationship. My reasoning for this is that the majority of the practices followed by UX designers align very closely or directly with the practices followed by software engineers. In essence, the two design philosophies have a strongly correlated feedback loop in which the products of each process feeds supplementary data and ideas into the other, thereby resulting in an ever-expanding and more concrete software development plan for producing a solution to a given software problem.`,
        },
        {
            type: 'text',
            content: `As an experienced software engineer, I have a wealth of experience working in all phases of the software development process from beginning to end. In that role, the earliest of these phases in which I have ever worked is the requirements gathering phase, which directly aligns with the Scope Plane. This is where I believe the UX design process and the software development process first overlap in their efforts towards a software solution. The Strategy Plane comes even before this plane in the UX design process, which is why I believe that the Strategy Plane is a purely UX design process phase. There is no real phase in the software development process that can correlate to the Strategy Plane, and the software development process can only kick off after questions of strategy in terms of product objectives and user needs have been answered in the Strategy Plane. As a result, from a purely software engineering standpoint, the Scope Plane is the first plane in the <span class="font-italic">Elements of User Experience</span> that I can truly look to and draw direct parallels between actions that should be taken in the UX design process to the actions that I have taken previously in the software development process.`,
        },
        {
            type: 'text',
            content: `One of the major features of the <span class="font-italic">Elements of User Experience</span> is considering any given product or service that is being designed from two parallel perspectives, functionality and information. Although these two perspectives run parallel to each other in terms of some of the actions that are taken in the UX design process, they do actually have a strong influence on each other in terms of the answers to questions that are asked from either perspective. In the case of the Scope Plane, these two perspectives had equal influence on the two products coming out of the Scope Plane, user needs and product objectives. This is, however, not the case with the Strategy Plane, as can be seen in the part of the <span class="font-italic">Elements of User Experience</span> diagram for the Scope Plane:`,
        },
        {
            type: 'image',
            url: `https://miro.medium.com/max/449/1*2xfKaFmdLy1D5MbfIOdkmw.png`,
            caption: `The Scope Plane, Jesse James Garrett`,
        },
        {
            type: 'text',
            content: `As evident in the diagram, the functionality perspective of the UX design process directly informs functional specifications of a product or service, while the information perspective directly informs the content requirements. This makes sense because functional specifications for a product or service provide a detailed description of how all of the functionality in that product or service will work, while content requirements provide a detailed description of all of the text and other media that will fill that product or service. Already at this early phase in the UX design process you can see the two different results of that process beginning to coalesce. The functional specifications are the first step in defining a frame or skeleton and the content requirements are the first step in defining the meat on that skeleton. We begin to move from a purely theoretical discussion about our product or service towards a more practical discussion.`,
        },
        {
            type: 'text',
            content: `After providing an initial short explanation of the Scope Plane and how it fits into the <span class="font-italic">Elements of User Experience</span> framework directly above the Strategy Plane, the author discusses the oftentimes difficult process of actually creating functional specifications and content requirements. In this discussion he provides a lot of advice on how to build a thorough set of both of these deliverables in order to make them as useful as possible in the forthcoming software development process. These two deliverables, functional specifications and content requirements, are going to be used to build an entire software system, so they need to describe as accurately as possible how that system should work and what information will fill up the space around the functionality built into that system. Throughout this discussion there were a few quotes that I found to be useful in conveying the importance of the Scope Plane in the overall <span class="font-italic">Elements of User Experience</span> framework.`,
        },
        {
            type: 'spacer',
        },
        {
            type: 'highlight',
            content: `On the scope plane, we start from the abstract question of “Why are we making this product?” that we dealt with in the strategy plane and build upon it with a new question: “What are we going to make?”`,
        },
        {
            type: 'text',
            content: `I found this quote to be particularly useful because it succinctly emphasizes the focus of the two planes and how the efforts of one plane build up into the efforts of the other plane. In the Strategy Plane you are dealing entirely with theory, and throughout the third chapter the author stresses the point that you should not make any sort of plans with regards to implementation. That would be putting the cart before the horse and you would be allowing the implementation to dictate the design of the system. Instead you should be doing the exact opposite, allowing the needs of the users and the objectives of the organization to dictate the design of the system. While working in the Strategy Plane, you are merely determining the justification for why certain things should be done based on those needs and objectives.`,
        },
        {
            type: 'text',
            content: `Once you arrive at the Scope Plane, you can take these justifications and use them to mold actual implementation solutions. You no longer have to consider everything about the product or service being built on a theoretical level and can now consider everything on a practical level. But the key thing to keep in mind while doing this, as the author stresses in this chapter, is that all of these implementation solutions that you devise must tie back to a requirement that was created as a direct result of a user need or product objective that was defined in the Strategy Plane. While working in the Scope Plane, nothing should be added to the implementation plan without having a requirement to back it up. This is to ensure that no superfluous work is done without having a reason for it. By closely adhering to this rule, it is possible to minimize the effects of scope creep on the software system being designed. Also, from a business perspective, sticking tightly to a well-thought-out set of requirements allows you to meet your deadlines much more comfortably and avoid any unnecessary delays in the implementation phase of the software development process.`,
        },
        {
            type: 'spacer',
        },
        {
            type: 'highlight',
            content: `If you clearly articulate exactly what you’re setting out to build, everyone will know what the project’s goals are and when they’ve been reached.`,
        },
        {
            type: 'text',
            content: `This sentence is fairly obvious and almost goes without saying, but you would be surprised just how many software development teams allow this simple fact to elude them. And in those cases where software development teams are aware of this fact, they sometimes think that they can beat the odds and skip the requirements gathering process altogether in the hopes that doing so will allow them to develop a product faster and beat the competition to market. I’ll grant you that this may be possible in some very specific contexts where a software development team is particularly well-motivated and organized and the software system being developed has low complexity. But for any software system of average or higher complexity, it is virtually impossible to develop a product that is both high-quality and completely functional without first sitting down at the drawing board and mapping out what the development efforts will be in terms of functional specifications and content requirements.`,
        },
        {
            type: 'text',
            content: `As the quote suggests, one of the major benefits of taking the time to define a clear set of requirements for the software system being developed is that they give your team objectives to complete. In completing those objectives, your software team will know when the work on a particular part of the system or the system as a whole has been completed. Without having those objectives defined clearly, each member of the team may have a different standard for what is considered complete. In such cases, at the end of a development cycle, some parts of the system may be overdeveloped while others may not actually be complete according to the terms defined by whoever is dictating the scope of the system. In some cases, when defining clear requirements for a software system, words may not be enough to describe the threshold for completion, as described in the following quote:`,
        },
        {
            type: 'quote',
            content: `We can also eliminate subjectivity by defining some requirements quantitatively. Just as success metrics make strategic goals quantifiable, defining a requirement in quantitative terms can help us identify whether we’ve met the requirement.`,
        },
        {
            type: 'text',
            content: `One half of the equation when defining requirements for a software system is providing sufficient detail to guide development efforts, but the other half of the equation is the elimination of subjectivity within that detail. What may seem like an obvious interpretation of a specific requirement to one person may be ambiguous to another person. If those two people worked to fulfill that same requirement, their results may vary widely. This variance may be avoided by incorporating quantitative detail in the requirement. Also, not only does quantitative detail in the requirements help different developers reach the same solution, but it also provides another threshold for completion when running down a list of requirements and checking to make sure they have all been fulfilled. Providing a set of requirements with not only enough word detail but also with appropriate quantitative detail can save a lot of time and headaches during the implementation phase of the software development process.`,
        },
        {
            type: 'spacer',
        },
        {
            type: 'highlight',
            content: `Having a defined set of requirements allows you to parcel out responsibility for the work more efficiently.`,
        },
        {
            type: 'text',
            content: `A clear set of software requirements not only provides help in terms of the actual development of a software system, but it can also provide a lot of guidance to software development managers as well. One of the biggest difficulties in managing any software development team is accurately determining how many team members need to devote how much of their time to completing any given task. Without having any sort of gauge for the scope of the task, estimates could vary widely on how much time and how many team members it would take to complete that task. With a clear set of software requirements, however, you essentially have a roadmap to guide you in how best to efficiently divide up your team and the time of each individual team member to focus the right talent where it can do the best work.`,
        },
        {
            type: 'text',
            content: `Contrary to what most developers claim about being full-stack developers, all developers always have strengths in certain areas and weaknesses in certain areas. With knowledge of those strengths and weaknesses and a roadmap in hand, a software development manager can focus the efforts of each team member where they will provide the most benefit. Anyone who has experience working in a software development environment that adheres to some form of the agile development process can recognize the advantages that this brings. Having a good idea of how much effort it will take to complete a given task is essential in planning out a development timeline for a project and it eliminates most of the guesswork in terms of how to plan a release schedule for a given product or service.`,
        },
    ],
}