<template>
    <span class="text-body-2 grey--text text--darken-2">{{ content }}</span>
</template>

<script>
export default {
    name: 'ResumeItemListItem',
    props: [
        'content',
    ],
}
</script>

<style scoped>

</style>