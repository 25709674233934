export default {
    id: '2021-01-31',
    year: 2021,
    month: 1,
    date: 31,
    title: `Developer Story: The Long Detour`,
    blog_url: `https://keithvictordawson.medium.com/developer-story-the-long-detour-4fe76e944673`,
    image_url: `https://images.unsplash.com/photo-1612015746959-ecd2155b242d`,
    image_caption: `Photo by <a class="text--primary" href="https://unsplash.com/@rorymck94">Rory McKeever</a> on <a class="text--primary" href="https://unsplash.com">Unsplash</a>`,
    contents: [
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `It has been quite a while since my `
                },
                {
                    type: 'internal_link',
                    year: 2020,
                    month: 4,
                    date: 30,
                    content: `last progress`,
                },
                {
                    type: 'text',
                    content: ` report on my personal project, almost nine months, in fact. As it has been for everyone, a lot has changed for me during that time. At the time of my previous progress report, the pandemic was only just getting started for most of the world and no one had any real sense of how long the disruption to all of our lives would last. Even at the time of writing this article, it is still not clear when the place that I am currently at or the majority of the world will be able to return to some sense of normality. There is no guarantee that 2021 will see the end of this global disaster. As a result of all of this uncertainty, shortly after that last progress report, I decided that I needed to take the less desirable but much safer route through this pandemic and put my personal project on hold to return to the ranks of the employed.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `Luckily, during the first few weeks after the last progress report, I was finally able to complete work on the seed scripts necessary to get all of the language data that I had amassed for my personal project into the application database. Having that surprisingly mammoth task behind me was definitely a huge weight off of my shoulders in terms of completing a solid foundation for my project. Unfortunately, I was not able to enjoy too big of a sigh of relief before I was forced to go into quarantine for two weeks after leaving my home country and returning to renew my visa. I wrote about the entire quarantine process that I experienced in a series of `,
                },
                {
                    type: 'internal_link',
                    year: 2020,
                    month: 5,
                    date: 26,
                    content: `blog posts`,
                },
                {
                    type: 'text',
                    content: `, not only for posterity’s sake but to serve as a record for anyone else who may decide to travel to my home country while the world is still in the midst of this pandemic. During that time of absolute solitude while I was in quarantine, I came to the realization that I did not want to subject myself to regular psychological punishment and decided that it would be best for me to get back to building software for someone else.`,
                },
            ],
        },
        {
            type: 'text',
            content: `Immediately after exiting quarantine, I got to work on updating my resume and LinkedIn profile. I established a few job seeking connections and got to work on sending my resume to a wide variety of companies in many different industries. My intention was to find a good job at a company that could really appreciate and value my software development talents. The opportunity to work on an interesting project was also at the forefront of my mind during my job search, but it took second place to my ultimate goal, which was to avoid the quarantine experience again. Luckily, after an extremely busy three months of phone interviews, video interviews, coffees shop interviews, office interviews, online coding tests, and take-home coding projects, I was able to land a pretty good position at a stable company working on an interesting project in an industry that I had never worked in before in my career. I began working for that company right at the beginning of September of last year.`,
        },
        {
            type: 'text',
            content: `Having found a new job and as a result a sponsor for my visa, at least I was now safe from having to go through the quarantine process on a regular basis. I had assumed that I would be able to go back to working on my personal project at night while working at my new job during the day. Since the tech stack for my new job required that I get up to speed with Vue.js as soon as possible, I decided to dedicate the nights during my first couple of weeks on the job to building a new version of my personal website using Vue.js. That effort turned out to be a rather pleasant experience and it really helped me to hit the ground running with the project that I was assigned at my job. Unfortunately, due to many business decisions that were made before I had started the position, the deadline for completion of the first version of that project was extremely tight. Partially due to my software engineering instincts, partially due to my desire to prove myself to my new company, and partially due to my natural inclination to be tough on myself and always push extra hard to reach seemingly difficult or impossible goals, I resolved not to allow the first version of my project to miss the predetermined deadline.`,
        },
        {
            type: 'text',
            content: `As a result, I enacted for myself a punishing work schedule, very much like running a full marathon at a sprinter’s pace, in order to ensure that all of the features for version one of the project were completed on time. This was not easy for a number of reasons, chief among them the fact that I was diving into an industry that I had no prior experience in or knowledge of, as well as the fact that I was building an entirely new complex application suite from scratch using technologies that I either had never worked with before starting the job or had not worked with for quite some time. Coupled with the fact that the ultimate end goal of the project beyond version one is still very nebulous and I still have a mountain of questions that need answering in order for me to be able to implement all of the features to reach that final end goal, the last five months have truly been a challenging but interesting experience. Regardless of all of the challenges that I have faced in this job so far, I most certainly do not regret getting myself into this situation because I have learned so many more useful things while delivering this first version of the project.`,
        },
        {
            type: 'text',
            content: `Which brings me back to my personal project. There have been so many valuable lessons that I have learned over these last several months about how to build better software. Since I have been away from my personal project for quite some time now, I plan on stepping back into it in the near future and reassessing everything that I have done with an eye on how I can improve what I have already completed by applying all of the lessons that I have learned recently. I know that there are a lot of ways in which I can improve my personal project not only from an efficiency standpoint, but also from the standpoint of assembling completely new parts of the system that can provide functionality in a much more modular way. These new possibilities for my personal project are making me excited to once again jump back in and view everything that I have done through the lens of my evolved understanding of web development.`,
        },
        {
            type: 'text',
            content: `In addition to digging back into the code for my personal project and both improving upon it and pushing it forward, another effort that I want to pick up again is my documentation of all of the various parts of the system that I have been building for my personal project here on Medium. I have previously published many articles detailing those various parts and I want to publish updated versions of those articles where necessary to provide an improved perspective on the way in which I think it is best to assemble those parts. Additionally, since I have worked with a number of completely new technologies lately in my job, I want to showcase some of those technologies and demonstrate how they can be used to solve various software problems. And on top of all of this, I have been and will continue to study in order to earn some important software certifications that will make the future of my career even brighter.`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `Just like I had `,
                },
                {
                    type: 'internal_link',
                    year: 2019,
                    month: 12,
                    date: 31,
                    content: `written`,
                },
                {
                    type: 'text',
                    content: ` right at the end of 2019 and beginning of 2020, it is clear that the year ahead for me is not going to be a lazy one. I have many things that will need to be done both for my job and for the various personal goals that I have set for myself this year. And I plan to document various aspects of all of those efforts along the way. Please stay tuned for more articles from me in the near future as I fight my way through another challenging and interesting year.`,
                },
            ],
        },
    ],
}