<template>
    <div class="pt-14">
        <page-title>{{ blog.title }}</page-title>
        <blog-image
            :caption="blog.image_caption"
            :url="blog.image_url"
        />
        <div class="d-flex justify-center">
            <v-btn
                class="white--text ma-4"
                color="light-blue darken-4"
                :href="blog.blog_url"
            >
                <v-icon class="blue--text text--lighten-3">{{ icons.post }}</v-icon>
                <span class="ml-2">View on Medium</span>
            </v-btn>
        </div>
        <v-container>
            <template v-for="(item, index) in blog.contents">
                <p class="text-body-2 mb-8" v-if="item.type === 'text'">
                    <template v-if="typeof item.content === 'string'">
                        <span v-html="item.content" />
                    </template>
                    <template v-else>
                        <template v-for="content_item in item.content">
                            <template v-if="content_item.type === 'text'">
                                <span class="text-body-2" v-html="content_item.content" />
                            </template>
                            <template v-else-if="content_item.type === 'internal_link'">
                                <a class="text--primary" :href="`/blogs/${content_item.year}/${content_item.month}/${content_item.date}`">{{ content_item.content }}</a>
                            </template>
                            <template v-else-if="content_item.type === 'external_link'">
                                <a class="text--primary" :href="content_item.url">{{ content_item.content }}</a>
                            </template>
                            <template v-else-if="content_item.type === 'bold_link'">
                                <a class="text--primary font-weight-bold" :href="content_item.url">{{ content_item.content }}</a>
                            </template>
                            <template v-else-if="content_item.type === 'italic_link'">
                                <a class="text--primary font-italic" :href="content_item.url">{{ content_item.content }}</a>
                            </template>
                            <template v-else-if="content_item.type === 'code'">
                                <code class="grey lighten-3 text--primary py-1">{{ content_item.content }}</code>
                            </template>
                            <template v-else-if="content_item.type === 'code_link'">
                                <code class="grey lighten-3 text--primary py-1"><a class="text--primary" :href="content_item.url">{{ content_item.content }}</a></code>
                            </template>
                        </template>
                    </template>
                </p>
                <template v-else-if="item.type === 'code'">
                    <div class="grey lighten-3 pa-4 mb-8">
                        <pre v-if="item.title" class="font-italic font-weight-bold" style="font-size: 75%; overflow: auto;">{{ item.title }}</pre>
                        <pre style="font-size: 75%; overflow: auto;">{{ item.content }}</pre>
                    </div>
                </template>
                <template v-else-if="item.type === 'highlight'">
                    <div v-html="item.content" class="text-h4 font-italic font-weight-light text--disabled mb-8" />
                </template>
                <template v-else-if="item.type === 'image'">
                    <div class="d-flex flex-column align-center mb-8">
                        <v-img
                            max-height="600"
                            max-width="1000"
                            :src="item.url"
                        />
                        <div v-if="item.caption" class="text-caption">
                            <span v-html="item.caption" />
                        </div>
                    </div>
                </template>
                <template v-else-if="item.type === 'title'">
                    <span class="d-flex justify-center text-h4 font-weight-light mb-8">{{ item.content }}</span>
                </template>
                <template v-else-if="item.type === 'quote'">
                    <div v-html="item.content" class="text-h6 mb-8 px-8" style="border-color: black; border-left: solid; border-right: solid;" />
                </template>
                <template v-else-if="item.type === 'spacer'">
                    <v-divider class="mx-16 mb-8" />
                </template>
                <template v-else-if="item.type === 'subtitle'">
                    <span class="d-flex justify-center text-h5 mb-8">{{ item.content }}</span>
                </template>
            </template>
        </v-container>
    </div>
</template>

<script>
import { mdiPost } from '@mdi/js'

// Components
import BlogImage from '../components/blog/BlogImage'
import PageTitle from '../components/application/PageTitle'

// Data
import blogs from '../assets/data/blogs'

export default {
    name: 'Blog',
    props: [ 'params' ],
    components: {
        BlogImage,
        PageTitle,
    },
    data() {
        const blog = blogs.find(blog => {
            return blog.year === this.params.year &&
                blog.month === this.params.month &&
                blog.date === this.params.date
        })

        if (!blog) {
            this.$router.replace('/blogs')
        }

        return {
            blog: blog || {},
            icons: {
                post: mdiPost,
            },
        }
    },
    beforeRouteUpdate: function(to, from, next) {
        this.blog = blogs.find(blog => {
            return blog.year === to.params.year &&
                blog.month === to.params.month &&
                blog.date === to.params.date
        })

        next()
    },
}
</script>

<style scoped>

</style>