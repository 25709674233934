<template>
    <div class="blue lighten-4 pt-14">
        <page-title id="reading-title">Reading</page-title>
    </div>
</template>

<script>
// Components
import PageTitle from '../components/application/PageTitle'

export default {
    name: 'Reading',
    components: {
        PageTitle,
    },
    data() {
        return {}
    },
}
</script>

<style scoped>

</style>