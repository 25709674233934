export default {
    id: '2019-11-28',
    year: 2019,
    month: 11,
    date: 28,
    title: `Developer Story: The Server is the Focal Point`,
    blog_url: `https://keithvictordawson.medium.com/developer-story-the-server-is-the-focal-point-ca3f2d292bfe`,
    image_url: `https://images.unsplash.com/photo-1550751827-4bd374c3f58b`,
    image_caption: `Photo by <a class="text--primary" href="https://unsplash.com/@adigold1">Adi Goldstein</a> on <a class="text--primary" href="https://unsplash.com/s/photos/computer-server">Unsplash</a>`,
    contents: [
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `If the database is the foundation of an application, as I posited in the `,
                },
                {
                    type: 'internal_link',
                    year: 2019,
                    month: 11,
                    date: 15,
                    content: `discussion`,
                },
                {
                    type: 'text',
                    content: ` of my thoughts on databases, then the server is the focal point of all the activity in an application. What I mean by that is, if the database is the single source of truth in an application, which I believe it absolutely always should be in order to avoid data inconsistency and application state confusion, then the server provides the single access point to the database. The server is the gatekeeper of the database and should provide a clean, concise, and consistent interface for interacting with all of the data contained inside of it.`,
                },
            ],
        },
        {
            type: 'text',
            content: `As I have mentioned previously in past developer story entries, throughout my career I have seen a great many things that have perplexed me in terms of the choices that my colleagues have made, not only when developing database infrastructure, but also when developing the server infrastructure that wraps around that database infrastructure. I have seen more than a few software systems where the database is structured in a certain way and the server providing access to that database does not provide an accurate or even consistent view of the data contained within it or ways to interact with that data. There are often many different programming standards established within a single server instance, none of which provides a complete picture of the underlying database. This is not only frustrating, but can also be dangerous from a business standpoint because it can result in a development team coding itself into such a vast amount of technical debt that it is impossible to code your way out of it due to time or financial constraints.`,
        },
        {
            type: 'text',
            content: `Much of this mismatch can be attributed to the fact that the database infrastructure and the server infrastructure are usually built by different people or even different teams. To compound the problem, those people or teams often do not communicate well enough with each other to develop and agree upon a consistent server programming standard. Most companies that I have worked for have had difficulties establishing an effective communication line between different groups of people, and the results of their development efforts are underwhelming to say the least. I have, however, also worked at a couple companies where this was not the case. Multiple people working on the server at different times independent of each other managed to develop and maintain a consistent programming standard. I do honestly think, though, that such outcomes are the exception to the rule and are rarely realized in professional practice. So it is not impossible, but it seems to be elusive for most companies out there. That is not to say that there are not plenty of people who do actively work hard towards the goal of building and maintaining a consistent server programming standard. Many times, things just slip away from us and as our attention is directed elsewhere technical debt creeps up and piles on before anyone realizes what has happened.`,
        },
        {
            type: 'text',
            content: `Through all of my software development experience, both good and bad, the way that I have come to view the server in any software system is that, if the database houses a clean, efficient, and orderly dataset, then the server provides a clean, efficient, and consistent interface to that dataset to best suit the needs of the client with respect to that data. Of course, the way that data is used on any client never really fully matches the way that it is stored in the database, nor should it in most cases. But the best database designers do attempt to model their datasets in such a way as to be familiar to the clients consuming them. The server merely provides that necessary translation layer when the data comes out of the database and is fed to the client or the client sends data down to the database. Obviously, the closer the database structure is to the usage demands of its data on the client, the less complex the server logic needs to be in order to achieve the necessary translation. This is always a fine balancing act, though, because oftentimes the data needs of the client are much more diverse than the ways in which the data can be stored in the database.`,
        },
        {
            type: 'text',
            content: `The effort to create better server infrastructure with a well considered programming standard is something that I myself have struggled with throughout my entire career, which is the reason why I am so passionate about highlighting this issue in software development. In order to create an effective and efficient server, you usually need to have a good understanding of the database structure as well as the needs of the client, and then you need to have good enough judgment to know how to connect the myriad dots in the middle. To make matters worse, most of the time, especially in large companies and even in medium-sized companies, it is difficult or impossible to gather enough information to make a good judgment on how to proceed with building a high-quality server. Personally, I was never able to get a good idea about how to do things the right way until I was able to glimpse the whole picture of a system that took requests from the client, translated those into meaningful database interactions, executed the necessary database transactions, repackaged the results, and sent them back up to the client, all in one consistent and efficient workflow.`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `With all of that being said, I want to provide more details regarding my plans for server development on my personal project. In the ongoing effort to improve my server development skills and knowledge, I have some very big plans indeed. As I have stated previously, my most recent professional software development experience has focused on NodeJS, so I will first be developing an appropriate server for my application using NodeJS with `,
                },
                {
                    type: 'external_link',
                    url: `https://www.npmjs.com/package/express`,
                    content: `ExpressJS`,
                },
                {
                    type: 'text',
                    content: ` for all routing logic and the `,
                },
                {
                    type: 'external_link',
                    url: `https://www.npmjs.com/package/mongodb`,
                    content: `MongoDB`,
                },
                {
                    type: 'text',
                    content: ` NodeJS driver for all database interaction. This is the server configuration that I have been most comfortable with most recently in my professional and personal development, so it is the configuration with which I can create a server fastest. Like with my plans for database development, however, I will not stop there as there are many other different configurations that it would be helpful to learn in order to expand my skills and knowledge when it comes to server development.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `Once I have created a server using NodeJS and ExpressJS, I would also like to build a server using NodeJS and the increasingly popular `,
                },
                {
                    type: 'external_link',
                    url: `https://www.npmjs.com/package/graphql`,
                    content: `GraphQL`,
                },
                {
                    type: 'text',
                    content: `. I want to stick with NodeJS for the second functionally-equivalent but completely separate version of my server application so that I can focus on learning how to work with GraphQL without having to learn a new language as well. Beyond that, I have had a strong interest in working with Go, so I would like to tackle another version of my server application using that. And even beyond that, although I have a lot of academic experience working with Java, I have not really touched it at all since leaving university. As an excuse to get back into it and learn all of the changes that have transpired since my time in university, I would like to build a version of my server application using Java as well.`,
                },
            ],
        },
        {
            type: 'text',
            content: `So as you can see, I have a lot of work cut out for me in terms of server development for my personal project. All of it will serve the same purpose as my plans to build multiple data-equivalent versions of my database in different database systems. In building functionally-equivalent servers using many different technologies, I can gain a better first-hand understanding of when to use a specific language or framework in different situations. And the results of all of the tests that I will run using these different versions of my server will be presented in future developer story entries for everyone’s benefit. Please stay tuned for those and more on my thoughts as I make further progress on my personal project.`,
        },
    ],
}