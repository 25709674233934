export default {
    id: '2019-12-28',
    year: 2019,
    month: 12,
    date: 28,
    title: `Developer Story: Error Handling`,
    blog_url: `https://medium.com/javascript-in-plain-english/developer-story-error-handling-fcaa357578cc`,
    image_url: `https://miro.medium.com/max/700/0*0uT17ZL3SnRBsP1A.jpg`,
    contents: [
        {
            type: 'text',
            content: `In a previous developer story entry I discussed the importance of including a robust logging system in every server application that I develop, both in my professional work and in my personal projects.`,
        },
        {
            type: 'text',
            content: `As I had mentioned in that entry, not only does a logging system allow the developer of an application to know the current state of that application at any given time, but it will also provide a series of receipts when an error is encountered that will allow the developer to trace that error back to the source.`,
        },
        {
            type: 'text',
            content: `Without the logging system the errors would not be tracked anywhere in an organized fashion, and without a well-implemented error handling mechanism the logging system would have nothing to track when an actual error occurs. In this developer story entry I will discuss my personal error handling strategy that I couple with a robust logging system to ensure that I never have trouble tracking down the source of errors when they are encountered.`,
        },
        {
            type: 'text',
            content: `While introducing different elements of the NodeJS server applications that I develop, I have occasionally mentioned that my applications all contain a utility module composed of a number of different useful sub-modules serving a wide variety of purposes. One of these utility sub-modules is where the bulk of the error handling logic in my applications resides. The contents of this <span class="font-italic">error</span> sub-module look like the following:`,
        },
        {
            type: 'code',
            title: `util/error.js`,
            content: `
class ServiceError extends Error {
    constructor(message, originFunctionName, httpResponseCode = 400, addSuffix = true) {
        super(message)
        Error.captureStackTrace(this, this.constructor)

        this.name = this.constructor.name
        this.origin = originFunctionName
        this.code = httpResponseCode

        const functionName = (originFunctionName.slice(-2) === '()' || !addSuffix) ? originFunctionName : \`\${originFunctionName}()\`
        this.display = \`\${functionName}: \${message}\`
    }
}

class WrapperError extends ServiceError {
    constructor(error, originFunctionName, addSuffix) {
        super(error.message, originFunctionName, error.code, addSuffix)
        Error.captureStackTrace(this, this.constructor)

        this.name = this.constructor.name
        this.data = { error }
    }
}

const errors = {
    // Database-related errors
    DB_CONNECTION_FAILED: { code: 400, message: 'Client database connection failed' },
    DB_CONNECTION_NOT_ESTABLISHED: { code: 400, message: 'Database connection has not been established' },
    DB_SESSION_ENDED: { code: 400, message: 'Database session has already ended' },
    DB_SESSION_NOT_SPECIFIED: { code: 400, message: 'Database session has not been specified' },
}

exports = module.exports = {
    createError: (e, origin, addSuffix) => {
        let error
        if (typeof e === 'string') {
            error = errors[e] ? new ServiceError(errors[e].message, origin, errors[e].code, addSuffix) : new ServiceError(e, origin, 400, addSuffix)
        } else {
            error = e.origin === origin ? e : new WrapperError(e, origin, addSuffix)
        }
        return error
    },
}`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `Although this <span class="font-italic">error</span> sub-module is rather small, it does a lot of work. First, two new error classes are created before any other logic is implemented. The `,
                },
                {
                    type: 'code',
                    content: `ServiceError`,
                },
                {
                    type: 'text',
                    content: ` class is created by extending the built-in NodeJS `,
                },
                {
                    type: 'code',
                    content: `Error`,
                },
                {
                    type: 'text',
                    content: ` class, while the `,
                },
                {
                    type: 'code',
                    content: `WrapperError`,
                },
                {
                    type: 'text',
                    content: ` class is created by extending this `,
                },
                {
                    type: 'code',
                    content: `ServiceError`,
                },
                {
                    type: 'text',
                    content: ` class. The purpose of the `,
                },
                {
                    type: 'code',
                    content: `ServiceError`,
                },
                {
                    type: 'text',
                    content: ` class is to create a more robust error object that contains all pertinent information about what caused the error, where the error originally occurred, and what should happen as a result of this error. The `,
                },
                {
                    type: 'code',
                    content: `WrapperError`,
                },
                {
                    type: 'text',
                    content: ` class, as its name suggests, simply serves as a wrapper for another previously created error class object. Generally, these error class objects wrapped by the `,
                },
                {
                    type: 'code',
                    content: `WrapperError`,
                },
                {
                    type: 'text',
                    content: ` class do not originate from my code but rather from a third-party package used by the application.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `After reading over the NodeJS error `,
                },
                {
                    type: 'external_link',
                    url: `https://nodejs.org/api/errors.html`,
                    content: `documentation`,
                },
                {
                    type: 'text',
                    content: ` you will know that the standard NodeJS `,
                },
                {
                    type: 'code',
                    content: `Error`,
                },
                {
                    type: 'text',
                    content: ` class only provides the `,
                },
                {
                    type: 'code',
                    content: `code`,
                },
                {
                    type: 'text',
                    content: `, `,
                },
                {
                    type: 'code',
                    content: `message`,
                },
                {
                    type: 'text',
                    content: `, and `,
                },
                {
                    type: 'code',
                    content: `stack`,
                },
                {
                    type: 'text',
                    content: ` properties to specify details about the circumstances of a specific error. The `,
                },
                {
                    type: 'code',
                    content: `ServiceError`,
                },
                {
                    type: 'text',
                    content: ` class enhances this by adding the `,
                },
                {
                    type: 'code',
                    content: `display`,
                },
                {
                    type: 'text',
                    content: `, `,
                },
                {
                    type: 'code',
                    content: `name`,
                },
                {
                    type: 'text',
                    content: `, and `,
                },
                {
                    type: 'code',
                    content: `origin`,
                },
                {
                    type: 'text',
                    content: ` properties and repurposing the `,
                },
                {
                    type: 'code',
                    content: `code`,
                },
                {
                    type: 'text',
                    content: ` property to provide a clearer picture about a specific error and make it easier to log a human-friendly error message. The `,
                },
                {
                    type: 'code',
                    content: `name`,
                },
                {
                    type: 'text',
                    content: ` property contains the name of the actual error type (`,
                },
                {
                    type: 'code',
                    content: `ServiceError`,
                },
                {
                    type: 'text',
                    content: `), while the `,
                },
                {
                    type: 'code',
                    content: `origin`,
                },
                {
                    type: 'text',
                    content: ` property contains the fully-qualified and unique name of the function where the error originally occurred. I use a strict function naming convention in all of my server applications to uniquely identify any place where an error may occur. The `,
                },
                {
                    type: 'code',
                    content: `display`,
                },
                {
                    type: 'text',
                    content: ` property contains a human-friendly string that can be consumed by the application logging system. Finally, the `,
                },
                {
                    type: 'code',
                    content: `code`,
                },
                {
                    type: 'text',
                    content: ` property is repurposed so that it contains the HTTP response code that should be returned to the client as a result of the current error. The `,
                },
                {
                    type: 'code',
                    content: `WrapperError`,
                },
                {
                    type: 'text',
                    content: ` class builds upon the `,
                },
                {
                    type: 'code',
                    content: `ServiceError`,
                },
                {
                    type: 'text',
                    content: ` class by adding the `,
                },
                {
                    type: 'code',
                    content: `data`,
                },
                {
                    type: 'text',
                    content: ` property, which is where the error object being wrapped is stored.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `After defining these two custom error classes, the next thing defined in the <span class="font-italic">error</span> sub-module is a set of error objects containing an HTTP response code as well as an error message. These error objects are used to construct a new `,
                },
                {
                    type: 'code',
                    content: `ServiceError`,
                },
                {
                    type: 'text',
                    content: ` object any time that an error occurs in the application and the cause of that error is determined to correspond to one of these error objects. An error is determined to correspond to one of these error objects if the specified error string matches the name of one of these error objects (`,
                },
                {
                    type: 'code',
                    content: `DB_CONNECTION_FAILED`,
                },
                {
                    type: 'text',
                    content: `, `,
                },
                {
                    type: 'code',
                    content: `DB_SESSION_ENDED`,
                },
                {
                    type: 'text',
                    content: `, etc.). Not all errors in the application will correspond to one of these predefined error objects, which is why the `,
                },
                {
                    type: 'code',
                    content: `WrapperError`,
                },
                {
                    type: 'text',
                    content: ` class exists. But all anticipated application errors should be defined in this part of the <span class="font-italic">error</span> sub-module. I have included just a few example database-related errors in this code sample, but this list should be much longer and cover all errors that you anticipate your application encountering during operation. Although you could go as crazy as you want with defining this error list, I personally try to keep the number of predefined errors as small as possible and organize situations that result in certain errors into related groups. Not only does this result in much less code, but it should also prevent duplicate errors from being created. I have seen similar error handling schemes implemented at previous jobs and sometimes overlapping errors are created that should have instead been grouped as one error. It seemed to me that the most frequent cause of this was because so many errors had been created previously that developers did not realize those applicable errors even existed when looking through a very long list of predefined errors. As a result, I try to always consider if an error requires a new HTTP response code or message or both. If it does, then I create a new error for it. Otherwise, I try my best to use existing errors in multiple situations.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The final part of the logic in the <span class="font-italic">error</span> sub-module is where the determination is made to either create a whole new `,
                },
                {
                    type: 'code',
                    content: `ServiceError`,
                },
                {
                    type: 'text',
                    content: ` object or wrap an existing error with a `,
                },
                {
                    type: 'code',
                    content: `WrapperError`,
                },
                {
                    type: 'text',
                    content: ` object. Any time that an error is created using this <span class="font-italic">error</span> sub-module, at least two things must be specified, the error itself and the current location where the error is being created, otherwise referred to as the origin. If the error is a string, then it is checked for a match in the predefined list of errors. If a match is found then the details defined in that error object are used to create a new `,
                },
                {
                    type: 'code',
                    content: `ServiceError`,
                },
                {
                    type: 'text',
                    content: `. Otherwise, a default error object is created with the error string as the error message. Conversely, if the error is not a string then it is assumed to be an object and the origin specified in that object is checked against the specified origin. If the origin of the error matches the specified origin then the error was created in my own application code and the error is used as is. Otherwise, the error is assumed to originate from a third-party package used by the application and it is wrapped with a `,
                },
                {
                    type: 'code',
                    content: `WrapperError`,
                },
                {
                    type: 'text',
                    content: ` object.`,
                },
            ],
        },
        {
            type: 'text',
            content: `With a better understanding of how the error construction logic works, it is important to see how all error handling logic should be implemented throughout the application to take advantage of this functionality. Anywhere that an error could potentially be thrown, the following function structure should be used:`,
        },
        {
            type: 'code',
            title: `controllers/authentication.js`,
            content: `
const logger = require('../log').app
const util = require('../util')

module.exports.login = async ({ body: { email, password } }) => {
    const FUNCTION_NAME = 'controllers.authentication.login'
    try {
        ...
    } catch(e) {
        const error = util.error.createError(e, FUNCTION_NAME)
        logger.error(error.display)
        throw error
    }
}`,
        },
        {
            type: 'text',
            content: `There are a number of things in this code sample to take note of that I mentioned early in this developer story entry. The first is the definition of the function name, which is used to create an error in the <span class="font-italic">catch</span> part of the <span class="font-italic">try-catch</span> statement. I generally name all functions according to their location within a top-level module from the root level all the way down the folder structure tree to the name of the function itself. I am always very strict in making sure to give each function a unique name for inclusion in an error so that there is no doubt if an error originates from this function. After the function name definition, it is imperative that a <span class="font-italic">try-catch</span> statement wraps all code in the function to make sure that no errors escape this function unexpectedly. Within the <span class="font-italic">try</span> part of the <span class="font-italic">try-catch</span> statement all of the necessary function logic will exist. Naturally, there will be logic checks for various conditions to determine whether or not a login attempt is successful. In an unsuccessful case, a line of code like the following should be used to create an error describing the problem:`,
        },
        {
            type: 'code',
            content: `throw util.error.createError('INCORRECT_PASSWORD', FUNCTION_NAME)`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `In this case, there would be a predefined error named `,
                },
                {
                    type: 'code',
                    content: `INCORRECT_PASSWORD`,
                },
                {
                    type: 'text',
                    content: ` in the <span class="font-italic">error</span> sub-module providing both an HTTP response code and a message to use in creating the new `,
                },
                {
                    type: 'code',
                    content: `ServiceError`,
                },
                {
                    type: 'text',
                    content: ` object. That error would be caught in the <span class="font-italic">try</span> part and passed to the <span class="font-italic">catch</span> part of the <span class="font-italic">try-catch</span> statement, where any encountered errors are used to construct either a new `,
                },
                {
                    type: 'code',
                    content: `ServiceError`,
                },
                {
                    type: 'text',
                    content: ` or `,
                },
                {
                    type: 'code',
                    content: `WrapperError`,
                },
                {
                    type: 'text',
                    content: ` object. From the logic in this code sample it is easier to understand that using the utility <span class="font-italic">error</span> sub-module to construct a new error object from an encountered error serves two purposes. The first purpose is so that the newly constructed error object can be used to record the error using the logging system. Since the originally encountered error does not have a `,
                },
                {
                    type: 'code',
                    content: `display`,
                },
                {
                    type: 'text',
                    content: ` property it needs to be reconstructed to have this property before being used by the logging system. The second purpose is so that the newly constructed error object can be thrown and bubbled all the way up to the client interface layer. Once this error bubbles up to that layer it serves an additional purpose, as evident in the following code sample:`,
                },
            ],
        },
        {
            type: 'code',
            title: `routes/authentication.js`,
            content: `
const logger = require('../log').app
const util = require('../util')

authentication.post('/login', async (req, res) => {
    const FUNCTION_NAME = 'POST /authentication/login'
    try {
        ...
    } catch(e) {
        const error = util.err.createError(e, FUNCTION_NAME)
        logger.error(error.display)
        return res.sendStatus(error.code)
    }
})`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The code in this code sample is very similar to the controller code sample. The one difference is in how the error that bubbles up is used at the end of the <span class="font-italic">catch</span> part of the <span class="font-italic">try-catch</span> statement. Whereas it was simply thrown to be bubbled up the call stack in the controller code sample, in this code sample the `,
                },
                {
                    type: 'code',
                    content: `code`,
                },
                {
                    type: 'text',
                    content: ` property is being used to send an appropriate error status code in the HTTP response. This is how an error that originates somewhere deep in the application logic can rise all the way up to the client interface layer to provide a result that is meaningful to the client. I mentioned it earlier in the developer story entry, but I will reiterate it here once more. It is absolutely imperative, just like how the logging system should be used consistently throughout the entire application, the error handling logic presented here should be used in every function that could possibly result in an error being thrown, which would generally be almost all functions in most cases. Also, given that there are additional properties on the two error class objects created in the <span class="font-italic">error</span> sub-module, more details can be logged through the logging system as desired wherever it is necessary. I only provide a simplified version of how to use the logging system with the error handling system here to communicate the general idea, but feel free to use as much or as little of the error information provided by this error handling solution in your own implementation.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `With that, you should have a good idea of how I handle errors in all of my NodeJS applications and also how that error handling functionality is coupled with a robust logging system to create an all-encompassing system that logs all errors and makes it easy to track down the source of each individual error. My personal strategy for handling errors was not always this comprehensive or unobtrusive, but once it evolved into the form presented in this developer story entry I found that it really made development much easier as I worked to implement new features throughout my server applications. No longer was a I forced to throw `,
                },
                {
                    type: 'code',
                    content: `console.log()`,
                },
                {
                    type: 'text',
                    content: ` statements everywhere trying to track down exactly where an error was being thrown. Simply opening the latest log file and scrolling to the bottom revealed the exact location to find the source of the error, saving me time, effort, and a lot of headaches. I hope that my error handling strategy will also help to save you valuable development time and energy as well. Please stay tuned for my next development discussion and more as I continue to make further progress on my personal project.`,
                },
            ],
        },
    ],
}