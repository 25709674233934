<template>
    <v-timeline-item
        color="light-blue darken-4"
        fill-dot
        :id="itemId"
    >
        <template #default>
            <v-hover #default="{ hover }">
                <v-card
                    :class="{ 'on-hover': hover }"
                    :elevation="hover ? 6 : 0"
                >
                    <v-expansion-panels :flat="!expanded">
                        <v-expansion-panel class="blue lighten-5">
                            <v-expansion-panel-header @click="scroll">
                                <template #actions>
                                    <v-icon color="light-blue darken-4">
                                        {{ icons.arrowDownBoldHexagonOutline }}
                                    </v-icon>
                                </template>
                                <div class="d-flex flex-column">
                                    <slot name="header" />
                                </div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <slot name="content" />
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-card>
            </v-hover>
        </template>
        <template #icon>
            <v-icon color="white">
                {{ icon }}
            </v-icon>
        </template>
        <template #opposite>
            <div class="text-h6">{{ time }}</div>
        </template>
    </v-timeline-item>
</template>

<script>
import { mdiArrowDownBoldHexagonOutline } from '@mdi/js'

export default {
    name: 'ResumeItem',
    props: [
        'icon',
        'itemId',
        'time',
    ],
    data() {
        return {
            expanded: false,
            icons: {
                arrowDownBoldHexagonOutline: mdiArrowDownBoldHexagonOutline,
            },
        }
    },
    methods: {
        scroll() {
            if (!this.expanded) {
                setTimeout(() => {
                    this.$vuetify.goTo(`#${this.itemId}`, { offset: 56 })
                }, 300)
            }

            this.expanded = !this.expanded
        },
    },
}
</script>

<style scoped>

</style>