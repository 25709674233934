export default {
    id: '2020-03-30',
    year: 2020,
    month: 3,
    date: 30,
    title: `An In-Depth Exploration of the Array.splice() Function`,
    blog_url: `https://levelup.gitconnected.com/an-in-depth-exploration-of-the-array-splice-function-6b60bb8554c1`,
    image_url: `https://images.unsplash.com/photo-1489389944381-3471b5b30f04`,
    image_caption: `Photo by <a class="text--primary" href="https://unsplash.com/@markusspiske">Markus Spiske</a> on <a class="text--primary" href="https://unsplash.com/s/photos/javascript">Unsplash</a>`,
    contents: [
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `Over the last several years there have been a wide variety of functions that have been added to the Javascript `,
                },
                {
                    type: 'code',
                    content: `Array`,
                },
                {
                    type: 'text',
                    content: ` global object that offer developers new and exciting possibilities when it comes to writing code that works with arrays. One of the major advantages of these new functions is that they provide developers with a set of functionality that they would have previously had to implement on their own with each new codebase that they encountered. Now, the need for such homegrown implementations has been eliminated and replaced with all of these new functions. One of those useful functions that will be explored in this article is the `,
                },
                {
                    type: 'code_link',
                    url: `https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/splice`,
                    content: `splice()`,
                },
                {
                    type: 'text',
                    content: ` function.`,
                },
            ],
        },
        {
            type: 'title',
            content: `Function Overview`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The `,
                },
                {
                    type: 'code',
                    content: `splice()`,
                },
                {
                    type: 'text',
                    content: ` function provides the ability to change the contents of an array, both by deleting existing items from the array and by adding new items to the array. Any items that are deleted from the original array are returned in a completely new array upon function completion. Not only does this function produce a completely new array, but it also modifies the original array on which the `,
                },
                {
                    type: 'code',
                    content: `splice()`,
                },
                {
                    type: 'text',
                    content: ` function was called.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `If you choose to use this function, keep in mind that you will not be able to maintain the original array without making a completely new copy of it beforehand. One very important thing to remember when using the `,
                },
                {
                    type: 'code',
                    content: `splice()`,
                },
                {
                    type: 'text',
                    content: ` function is that the length of the array may be different once the function has completed if the specified number of items to delete from the array and the specified number of items to add to the array are different. This means that your logic should anticipate a change in the size of the array and be able to handle such a situation accordingly.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The `,
                },
                {
                    type: 'code',
                    content: `splice()`,
                },
                {
                    type: 'text',
                    content: ` function is able to take an unlimited number of parameters with only the first parameter being <span class="font-italic">required</span> and all parameters after that being <span class="font-italic">optional</span>. The first parameter is the `,
                },
                {
                    type: 'code',
                    content: `start`,
                },
                {
                    type: 'text',
                    content: ` parameter, and it is the index at which the array will begin to be changed. The `,
                },
                {
                    type: 'code',
                    content: `splice()`,
                },
                {
                    type: 'text',
                    content: ` function will work differently depending on the specified value of this parameter. When this parameter has a value greater than the length of the array, the value will automatically be set to the length of the array. In cases where this is true, the function will only add the specified items to the array and no items will be deleted from the array. When this parameter has a negative value, the value will be counted from the end of the array instead of from the beginning. This means that a parameter value of `,
                },
                {
                    type: 'code',
                    content: `-3`,
                },
                {
                    type: 'text',
                    content: ` would actually result in the value being `,
                },
                {
                    type: 'code',
                    content: `3`,
                },
                {
                    type: 'text',
                    content: ` less than the length of the array (`,
                },
                {
                    type: 'code',
                    content: `Array.length + -3`,
                },
                {
                    type: 'text',
                    content: `). If the parameter has such a large negative value that the sum of the parameter value and the length of the array results in a negative number, then the value of the `,
                },
                {
                    type: 'code',
                    content: `start`,
                },
                {
                    type: 'text',
                    content: ` parameter will automatically be set to `,
                },
                {
                    type: 'code',
                    content: `0`,
                },
                {
                    type: 'text',
                    content: `.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The second parameter is the `,
                },
                {
                    type: 'code',
                    content: `deleteCount`,
                },
                {
                    type: 'text',
                    content: ` parameter, and it is an integer indicating the number of items that should be deleted from the array beginning at the `,
                },
                {
                    type: 'code',
                    content: `start`,
                },
                {
                    type: 'text',
                    content: ` index. As it is the first optional parameter, it is possible to omit this value in any `,
                },
                {
                    type: 'code',
                    content: `splice()`,
                },
                {
                    type: 'text',
                    content: ` function calls. Doing so will result in all items from the `,
                },
                {
                    type: 'code',
                    content: `start`,
                },
                {
                    type: 'text',
                    content: ` index to the end of the array being deleted. This behavior will also occur if the parameter value is larger than the number of items that exist from the `,
                },
                {
                    type: 'code',
                    content: `start`,
                },
                {
                    type: 'text',
                    content: ` index to the end of the array. When this parameter has a negative value or a value of `,
                },
                {
                    type: 'code',
                    content: `0`,
                },
                {
                    type: 'text',
                    content: `, no items will be deleted from the array.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The rest of the parameters from the third parameter onward are named `,
                },
                {
                    type: 'code',
                    content: `item1`,
                },
                {
                    type: 'text',
                    content: `, `,
                },
                {
                    type: 'code',
                    content: `item2`,
                },
                {
                    type: 'text',
                    content: `, and so on up to `,
                },
                {
                    type: 'code',
                    content: `itemN`,
                },
                {
                    type: 'text',
                    content: `. These parameters are all of the items that will be added to the array beginning at the `,
                },
                {
                    type: 'code',
                    content: `start`,
                },
                {
                    type: 'text',
                    content: ` index. If there are no items specified, then items will only be deleted from the array.`,
                },
            ],
        },
        {
            type: 'title',
            content: `Positive Start, Positive Delete Count`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `With the general function behavior covered, let’s take a look at some examples of how the `,
                },
                {
                    type: 'code',
                    content: `splice()`,
                },
                {
                    type: 'text',
                    content: ` function works in practice. The following example demonstrates the situation where positive `,
                },
                {
                    type: 'code',
                    content: `start`,
                },
                {
                    type: 'text',
                    content: ` and `,
                },
                {
                    type: 'code',
                    content: `deleteCount`,
                },
                {
                    type: 'text',
                    content: ` parameter values are specified:`,
                },
            ],
        },
        {
            type: 'code',
            content: `var array = ['abc', 'def', 'ghi', 'jkl', 'mno'];
var deletedItems = array.splice(1, 2);
// array: ['abc', 'jkl', 'mno']
// deletedItems: ['def', 'ghi']`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The `,
                },
                {
                    type: 'code',
                    content: `splice()`,
                },
                {
                    type: 'text',
                    content: ` function is called with a `,
                },
                {
                    type: 'code',
                    content: `start`,
                },
                {
                    type: 'text',
                    content: ` parameter value of `,
                },
                {
                    type: 'code',
                    content: `1`,
                },
                {
                    type: 'text',
                    content: ` and a `,
                },
                {
                    type: 'code',
                    content: `deleteCount`,
                },
                {
                    type: 'text',
                    content: ` parameter value of `,
                },
                {
                    type: 'code',
                    content: `2`,
                },
                {
                    type: 'text',
                    content: `. As a result, the items that are deleted from the original array and returned in the `,
                },
                {
                    type: 'code',
                    content: `deletedItems`,
                },
                {
                    type: 'text',
                    content: ` array are the two items beginning at index `,
                },
                {
                    type: 'code',
                    content: `1`,
                },
                {
                    type: 'text',
                    content: `. The original array still contains the three items that were not specified for deletion and the length of the array has been reduced by `,
                },
                {
                    type: 'code',
                    content: `2`,
                },
                {
                    type: 'text',
                    content: `.`,
                },
            ],
        },
        {
            type: 'title',
            content: `Negative Start, Positive Delete Count`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The following example demonstrates the situation where a negative `,
                },
                {
                    type: 'code',
                    content: `start`,
                },
                {
                    type: 'text',
                    content: ` parameter value and a positive `,
                },
                {
                    type: 'code',
                    content: `deleteCount`,
                },
                {
                    type: 'text',
                    content: ` parameter value are specified:`,
                },
            ],
        },
        {
            type: 'code',
            content: `var array = ['abc', 'def', 'ghi', 'jkl', 'mno'];
var deletedItems = array.splice(-3, 2);
// array: ['abc', 'def', 'mno']
// deletedItems: ['ghi', 'jkl']`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The `,
                },
                {
                    type: 'code',
                    content: `splice()`,
                },
                {
                    type: 'text',
                    content: ` function is called with a `,
                },
                {
                    type: 'code',
                    content: `start`,
                },
                {
                    type: 'text',
                    content: ` parameter value of `,
                },
                {
                    type: 'code',
                    content: `-3`,
                },
                {
                    type: 'text',
                    content: ` and a `,
                },
                {
                    type: 'code',
                    content: `deleteCount`,
                },
                {
                    type: 'text',
                    content: ` parameter value of `,
                },
                {
                    type: 'code',
                    content: `2`,
                },
                {
                    type: 'text',
                    content: `. Considering how a negative `,
                },
                {
                    type: 'code',
                    content: `start`,
                },
                {
                    type: 'text',
                    content: ` parameter value is evaluated, this function call is the same as `,
                },
                {
                    type: 'code',
                    content: `splice(2, 2)`,
                },
                {
                    type: 'text',
                    content: `. As a result, the items that are deleted from the original array and returned in the `,
                },
                {
                    type: 'code',
                    content: `deletedItems`,
                },
                {
                    type: 'text',
                    content: ` array are the two items beginning at index `,
                },
                {
                    type: 'code',
                    content: `2`,
                },
                {
                    type: 'text',
                    content: `. The original array still contains the three items that were not specified for deletion and the length of the array has been reduced by `,
                },
                {
                    type: 'code',
                    content: `2`,
                },
                {
                    type: 'text',
                    content: `.`,
                },
            ],
        },
        {
            type: 'title',
            content: `Positive Start, No Delete Count`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The following example demonstrates the situation where only a positive `,
                },
                {
                    type: 'code',
                    content: `start`,
                },
                {
                    type: 'text',
                    content: ` parameter value is specified:`,
                },
            ],
        },
        {
            type: 'code',
            content: `var array = ['abc', 'def', 'ghi', 'jkl', 'mno'];
var deletedItems = array.splice(2);
// array: ['abc', 'def']
// deletedItems: ['ghi', 'jkl', 'mno']`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The `,
                },
                {
                    type: 'code',
                    content: `splice()`,
                },
                {
                    type: 'text',
                    content: ` function is called with a `,
                },
                {
                    type: 'code',
                    content: `start`,
                },
                {
                    type: 'text',
                    content: ` parameter value of `,
                },
                {
                    type: 'code',
                    content: `2`,
                },
                {
                    type: 'text',
                    content: `. Considering how an omitted `,
                },
                {
                    type: 'code',
                    content: `deleteCount`,
                },
                {
                    type: 'text',
                    content: ` parameter value is evaluated, all of the items in the original array beginning at index `,
                },
                {
                    type: 'code',
                    content: `2`,
                },
                {
                    type: 'text',
                    content: ` and going all the way to the end of the array are deleted and returned in the `,
                },
                {
                    type: 'code',
                    content: `deletedItems`,
                },
                {
                    type: 'text',
                    content: ` array. The original array still contains the two items that were not specified for deletion and in this case the length of the array has been reduced by `,
                },
                {
                    type: 'code',
                    content: `3`,
                },
                {
                    type: 'text',
                    content: `.`,
                },
            ],
        },
        {
            type: 'title',
            content: `Positive Start, Zero Delete Count`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The following example demonstrates the situation where a positive `,
                },
                {
                    type: 'code',
                    content: `start`,
                },
                {
                    type: 'text',
                    content: ` parameter value and a `,
                },
                {
                    type: 'code',
                    content: `deleteCount`,
                },
                {
                    type: 'text',
                    content: ` parameter value of `,
                },
                {
                    type: 'code',
                    content: `0`,
                },
                {
                    type: 'text',
                    content: ` are specified:`,
                },
            ],
        },
        {
            type: 'code',
            content: `var array = ['abc', 'def', 'ghi', 'jkl', 'mno'];
var deletedItems = array.splice(2, 0);
// array: ['abc', 'def', 'ghi', 'jkl', 'mno']
// deletedItems: []`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The `,
                },
                {
                    type: 'code',
                    content: `splice()`,
                },
                {
                    type: 'text',
                    content: ` function is called with a `,
                },
                {
                    type: 'code',
                    content: `start`,
                },
                {
                    type: 'text',
                    content: ` parameter value of `,
                },
                {
                    type: 'code',
                    content: `2`,
                },
                {
                    type: 'text',
                    content: ` and a `,
                },
                {
                    type: 'code',
                    content: `deleteCount`,
                },
                {
                    type: 'text',
                    content: ` parameter value of `,
                },
                {
                    type: 'code',
                    content: `0`,
                },
                {
                    type: 'text',
                    content: `. Based on how the function behaves when a `,
                },
                {
                    type: 'code',
                    content: `deleteCount`,
                },
                {
                    type: 'text',
                    content: ` parameter value of `,
                },
                {
                    type: 'code',
                    content: `0`,
                },
                {
                    type: 'text',
                    content: ` is specified, no items are deleted from the original array and no items are returned in the `,
                },
                {
                    type: 'code',
                    content: `deletedItems`,
                },
                {
                    type: 'text',
                    content: ` array. The original array still contains all of its original items and the length of the array has not changed.`,
                },
            ],
        },
        {
            type: 'title',
            content: `Positive Start, Zero Delete Count, Single Item`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The following example demonstrates the situation where a positive `,
                },
                {
                    type: 'code',
                    content: `start`,
                },
                {
                    type: 'text',
                    content: ` parameter value, a `,
                },
                {
                    type: 'code',
                    content: `deleteCount`,
                },
                {
                    type: 'text',
                    content: ` parameter value of `,
                },
                {
                    type: 'code',
                    content: `0`,
                },
                {
                    type: 'text',
                    content: `, and a single `,
                },
                {
                    type: 'code',
                    content: `itemN`,
                },
                {
                    type: 'text',
                    content: ` parameter value are specified:`,
                },
            ],
        },
        {
            type: 'code',
            content: `var array = ['abc', 'def', 'ghi', 'jkl', 'mno'];
var deletedItems = array.splice(2, 0, 'pqr');
// array: ['abc', 'def', 'pqr', 'ghi', 'jkl', 'mno']
// deletedItems: []`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The `,
                },
                {
                    type: 'code',
                    content: `splice()`,
                },
                {
                    type: 'text',
                    content: ` function is called with a `,
                },
                {
                    type: 'code',
                    content: `start`,
                },
                {
                    type: 'text',
                    content: ` parameter value of `,
                },
                {
                    type: 'code',
                    content: `2`,
                },
                {
                    type: 'text',
                    content: `, a `,
                },
                {
                    type: 'code',
                    content: `deleteCount`,
                },
                {
                    type: 'text',
                    content: ` parameter value of `,
                },
                {
                    type: 'code',
                    content: `0`,
                },
                {
                    type: 'text',
                    content: `, and a single item to be added to the array. Based on how the function behaves when a `,
                },
                {
                    type: 'code',
                    content: `deleteCount`,
                },
                {
                    type: 'text',
                    content: ` parameter value of `,
                },
                {
                    type: 'code',
                    content: `0`,
                },
                {
                    type: 'text',
                    content: ` is specified, no items are deleted from the original array and no items are returned in the `,
                },
                {
                    type: 'code',
                    content: `deletedItems`,
                },
                {
                    type: 'text',
                    content: ` array. Instead, the specified single item is added at index `,
                },
                {
                    type: 'code',
                    content: `2`,
                },
                {
                    type: 'text',
                    content: ` and the length of the array has increased by `,
                },
                {
                    type: 'code',
                    content: `1`,
                },
                {
                    type: 'text',
                    content: `.`,
                },
            ],
        },
        {
            type: 'title',
            content: `Positive Start, Positive Delete Count, Multiple Items`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The following example demonstrates the situation where positive `,
                },
                {
                    type: 'code',
                    content: `start`,
                },
                {
                    type: 'text',
                    content: ` and `,
                },
                {
                    type: 'code',
                    content: `deleteCount`,
                },
                {
                    type: 'text',
                    content: ` parameter values and multiple `,
                },
                {
                    type: 'code',
                    content: `itemN`,
                },
                {
                    type: 'text',
                    content: ` parameter values are specified:`,
                },
            ],
        },
        {
            type: 'code',
            content: `var array = ['abc', 'def', 'ghi', 'jkl', 'mno'];
var deletedItems = array.splice(1, 2, 'pqr', 'stu', 'vwx');
// array: ['abc', 'pqr', 'stu', 'vwx', 'jkl', 'mno']
// deletedItems: ['def', 'ghi']`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The `,
                },
                {
                    type: 'code',
                    content: `splice()`,
                },
                {
                    type: 'text',
                    content: ` function is called with a `,
                },
                {
                    type: 'code',
                    content: `start`,
                },
                {
                    type: 'text',
                    content: ` parameter value of `,
                },
                {
                    type: 'code',
                    content: `1`,
                },
                {
                    type: 'text',
                    content: `, a `,
                },
                {
                    type: 'code',
                    content: `deleteCount`,
                },
                {
                    type: 'text',
                    content: ` parameter value of `,
                },
                {
                    type: 'code',
                    content: `2`,
                },
                {
                    type: 'text',
                    content: `, and three items to be added to the array. As a result, the two items at indexes `,
                },
                {
                    type: 'code',
                    content: `1`,
                },
                {
                    type: 'text',
                    content: ` and `,
                },
                {
                    type: 'code',
                    content: `2`,
                },
                {
                    type: 'text',
                    content: ` are deleted and returned in the `,
                },
                {
                    type: 'code',
                    content: `deletedItems`,
                },
                {
                    type: 'text',
                    content: ` array. Additionally, the three specified items are added into the original array beginning at index `,
                },
                {
                    type: 'code',
                    content: `1`,
                },
                {
                    type: 'text',
                    content: ` and the length of the array has increased by `,
                },
                {
                    type: 'code',
                    content: `1`,
                },
                {
                    type: 'text',
                    content: `.`,
                },
            ],
        },
        {
            type: 'title',
            content: `Lessons Learned`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The most important lesson to take away from this article regarding the `,
                },
                {
                    type: 'code',
                    content: `splice()`,
                },
                {
                    type: 'text',
                    content: ` function is how it provides multiple types of functionality within a single small package. Using the function while providing a `,
                },
                {
                    type: 'code',
                    content: `deleteCount`,
                },
                {
                    type: 'text',
                    content: ` parameter value of `,
                },
                {
                    type: 'code',
                    content: `0`,
                },
                {
                    type: 'text',
                    content: ` and one or more items to add to the array on which the function is called turns this function into a purely <span class="font-italic">additive</span> function. Using the function while providing a positive `,
                },
                {
                    type: 'code',
                    content: `deleteCount`,
                },
                {
                    type: 'text',
                    content: ` parameter value and no items to add to the array on which the function is called turns this function into a purely <span class="font-italic">subtractive</span> function. Using the function while providing a positive `,
                },
                {
                    type: 'code',
                    content: `deleteCount`,
                },
                {
                    type: 'text',
                    content: ` parameter value and a matching number of items to add to the array on which the function is called turns this function into a pure <span class="font-italic">replacement</span> function.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `And finally, as demonstrated by the several examples of `,
                },
                {
                    type: 'code',
                    content: `splice()`,
                },
                {
                    type: 'text',
                    content: ` function usage above, the function can be all three of these at the same time, depending on the values that are specified for each of the function parameters. By acting as an additive function, a subtractive function, and a replacement function, the `,
                },
                {
                    type: 'code',
                    content: `splice()`,
                },
                {
                    type: 'text',
                    content: ` function actually provides the same functionality as a number of other functions that have been added to the Javascript `,
                },
                {
                    type: 'code',
                    content: `Array`,
                },
                {
                    type: 'text',
                    content: ` global object.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `As an additive and replacement function, the `,
                },
                {
                    type: 'code',
                    content: `splice()`,
                },
                {
                    type: 'text',
                    content: ` function implements the same functionality as the `,
                },
                {
                    type: 'code',
                    content: `concat()`,
                },
                {
                    type: 'text',
                    content: `, `,
                },
                {
                    type: 'code',
                    content: `push()`,
                },
                {
                    type: 'text',
                    content: `, and `,
                },
                {
                    type: 'code',
                    content: `unshift()`,
                },
                {
                    type: 'text',
                    content: ` functions. The `,
                },
                {
                    type: 'code',
                    content: `concat()`,
                },
                {
                    type: 'text',
                    content: ` function allows you to add any number of individual array items and the contents of entire arrays to the end of an array, while the `,
                },
                {
                    type: 'code',
                    content: `splice()`,
                },
                {
                    type: 'text',
                    content: ` function allows you to add any number of individual array items to the beginning or the end of an array.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The obvious difference here is that the `,
                },
                {
                    type: 'code',
                    content: `splice()`,
                },
                {
                    type: 'text',
                    content: ` function requires all of the new array items to be specified individually, while the `,
                },
                {
                    type: 'code',
                    content: `concat()`,
                },
                {
                    type: 'text',
                    content: ` function can take a mix of individual array items and entire arrays. Similarly, the `,
                },
                {
                    type: 'code',
                    content: `unshift()`,
                },
                {
                    type: 'text',
                    content: ` function allows you to add any number of individual array items to the <span class="font-italic">beginning</span> of an array and the `,
                },
                {
                    type: 'code',
                    content: `push()`,
                },
                {
                    type: 'text',
                    content: ` function allows you to add any number of individual array items to the <span class="font-italic">end</span> of an array, all in the order that they are specified. The `,
                },
                {
                    type: 'code',
                    content: `splice()`,
                },
                {
                    type: 'text',
                    content: ` function offers both of these behaviors in the exact same way.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `As a subtractive function, the `,
                },
                {
                    type: 'code',
                    content: `splice()`,
                },
                {
                    type: 'text',
                    content: ` function implements the same functionality as the `,
                },
                {
                    type: 'code',
                    content: `pop()`,
                },
                {
                    type: 'text',
                    content: ` and `,
                },
                {
                    type: 'code',
                    content: `shift()`,
                },
                {
                    type: 'text',
                    content: ` functions. The `,
                },
                {
                    type: 'code',
                    content: `shift()`,
                },
                {
                    type: 'text',
                    content: ` function allows you to remove a single item from the <span class="font-italic">beginning</span> of an array and return that item upon function completion. The `,
                },
                {
                    type: 'code',
                    content: `splice()`,
                },
                {
                    type: 'text',
                    content: ` function allows you to remove any number of items from the beginning of an array up to and including the total length of the array and return those items in a completely new array upon function completion. In this sense, the `,
                },
                {
                    type: 'code',
                    content: `splice()`,
                },
                {
                    type: 'text',
                    content: ` function acts as a multiple-item version of the single-item-oriented `,
                },
                {
                    type: 'code',
                    content: `shift()`,
                },
                {
                    type: 'text',
                    content: ` function. The `,
                },
                {
                    type: 'code',
                    content: `pop()`,
                },
                {
                    type: 'text',
                    content: ` function allows you to remove a single item from the end of an array and return that item upon function completion. The `,
                },
                {
                    type: 'code',
                    content: `splice()`,
                },
                {
                    type: 'text',
                    content: ` function allows you to remove any number of items from the end of an array up to and including the total length of the array and return those items in a completely new array upon function completion. In this sense, the `,
                },
                {
                    type: 'code',
                    content: `splice()`,
                },
                {
                    type: 'text',
                    content: ` function acts as a multiple-item version of the single-item-oriented `,
                },
                {
                    type: 'code',
                    content: `pop()`,
                },
                {
                    type: 'text',
                    content: ` function.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `In all of the ways described in this section, the `,
                },
                {
                    type: 'code',
                    content: `splice()`,
                },
                {
                    type: 'text',
                    content: ` function basically acts as a Swiss Army knife, most useful in those situations where you find yourself needing to perform multiple operations on an array at one time. You can add new items, replace existing items, and remove items from the array all within one function call instead of making several function calls, possibly with the use of some looping logic to ensure that all of the correct items are affected. The `,
                },
                {
                    type: 'code',
                    content: `splice()`,
                },
                {
                    type: 'text',
                    content: ` function saves you from all of that hassle and cleans up your code so that it is not only more concise but also much more readable.`,
                },
            ],
        },
        {
            type: 'title',
            content: `Conclusions`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `Thank you very much for reading this article. I hope that this exploration of the `,
                },
                {
                    type: 'code',
                    content: `splice()`,
                },
                {
                    type: 'text',
                    content: ` function on the Javascript `,
                },
                {
                    type: 'code',
                    content: `Array`,
                },
                {
                    type: 'text',
                    content: ` global object has been informative and I hope that after gaining some knowledge about it you will be able to put it to good use in your own code. I encourage you to consult the resource link below for all information pertaining to the `,
                },
                {
                    type: 'code',
                    content: `splice()`,
                },
                {
                    type: 'text',
                    content: ` function if you still have additional questions about how it works. Please stop back in the future for more articles about interesting and useful functions that can be found on the Javascript `,
                },
                {
                    type: 'code',
                    content: `Array`,
                },
                {
                    type: 'text',
                    content: ` global object.`,
                },
            ],
        },
    ],
}