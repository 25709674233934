export default {
    id: '2020-10-12',
    year: 2020,
    month: 10,
    date: 12,
    title: `Basic Dualities in Javascript Array Methods`,
    blog_url: `https://levelup.gitconnected.com/basic-dualities-in-javascript-array-methods-a9d3023fdb2e`,
    image_url: `https://images.unsplash.com/photo-1542903660-eedba2cda473`,
    image_caption: `Photo by <a class="text--primary" href="https://unsplash.com/@markusspiske">Markus Spiske</a> on <a class="text--primary" href="https://unsplash.com/s/photos/coding">Unsplash</a>`,
    contents: [
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `Throughout my career as a software engineer I have worked with a great many software developers on a wide variety of Javascript applications. As I mentioned in a `,
                },
                {
                    type: 'text',
                    year: 2020,
                    month: 2,
                    date: 18,
                    content: `previous article`,
                },
                {
                    type: 'text',
                    content: `, over the years I have observed that most of those developers have lacked imagination and diversity when it comes to how they have chosen to work with Javascript arrays. Over the past several years there have been many useful functions that have been added to the Javascript `,
                },
                {
                    type: 'code',
                    content: `Array`,
                },
                {
                    type: 'text',
                    content: ` global object.`,
                },
            ],
        },
        {
            type: 'text',
            content: `As many of these functions have been added, their logically opposite version has also been added, meaning that it is possible to use one function to perform a certain action and its opposing twin function to undo that action if necessary by performing work in the opposite direction. I have been astounded sometimes as I read through code and see developers using one of these handy functions to perform a certain task while hand implementing a bunch of less than efficient logic to perform the exact opposite task of that function, all while the useful built-in opposing function is sitting there ready to use all along.`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `Due to the apparent widespread lack of knowledge regarding the many pairs of functions that have been added to the Javascript `,
                },
                {
                    type: 'code',
                    content: `Array`,
                },
                {
                    type: 'text',
                    content: ` global object over the years, I felt that it was necessary to call out all of these function pairs. The benefits that using these function pairs together can bring are not only to save developers time that they would have spent implementing their own solutions, but they can also help clean up their code and make it more efficient as well, since the core Javascript functions will generally always operate more efficiently than a hand-rolled implementation.`,
                },
            ],
        },
        {
            type: 'title',
            content: `Array.indexOf() and Array.lastIndexOf() functions`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `When it comes to working with arrays, one of the oldest and most widely used functions is the `,
                },
                {
                    type: 'code_link',
                    url: `https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/indexOf`,
                    content: `indexOf()`,
                },
                {
                    type: 'text',
                    content: ` function. It is undoubtedly one of the first functions that developers reach for when they begin working with Javascript arrays.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The way that the `,
                },
                {
                    type: 'code',
                    content: `indexOf()`,
                },
                {
                    type: 'text',
                    content: ` function works is simple: given a search parameter, search through the entire array from beginning to end and return the index of the first occurrence of that search parameter in the array, or `,
                },
                {
                    type: 'code',
                    content: `-1`,
                },
                {
                    type: 'text',
                    content: ` if the array does not contain the search parameter. The following example demonstrates both of these results:`,
                },
            ],
        },
        {
            type: 'code',
            content: `var letters = ['a', 'b', 'c', 'd', 'e', 'd', 'c', 'b', 'a'];

var dIndex = letters.indexOf('d');
// dIndex: 3
var zIndex = letters.indexOf('z');
// zIndex: -1`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `As described above, the function found and returned the index `,
                },
                {
                    type: 'code',
                    content: `3`,
                },
                {
                    type: 'text',
                    content: ` of the first occurrence of the search parameter that was contained within the array, while it returned the default `,
                },
                {
                    type: 'code',
                    content: `-1`,
                },
                {
                    type: 'text',
                    content: ` index for a search parameter that was not contained within the array. In cases where the contents are presorted into some kind of useful order, using the `,
                },
                {
                    type: 'code',
                    content: `indexOf()`,
                },
                {
                    type: 'text',
                    content: ` function to find the index of a particular array element that is expected to be towards the beginning of the array can go a long ways towards making the code using this function quicker and more efficient.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `But what if there was a particular array element that is expected to be towards the end of the array? Should you first use the `,
                },
                {
                    type: 'code_link',
                    url: `https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/reverse`,
                    content: `reverse()`,
                },
                {
                    type: 'text',
                    content: ` function to perform an in-place rearrangement of the array elements before using the `,
                },
                {
                    type: 'code',
                    content: `indexOf()`,
                },
                {
                    type: 'text',
                    content: ` function to find your index and calculate what index that value would correlate to before the array elements were reversed, as I have previously seen done in some code samples? Certainly not. It is much easier to use the `,
                },
                {
                    type: 'code_link',
                    url: `https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/lastIndexOf`,
                    content: `lastIndexOf()`,
                },
                {
                    type: 'text',
                    content: ` function to accomplish such a task.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The way that the `,
                },
                {
                    type: 'code',
                    content: `lastIndexOf()`,
                },
                {
                    type: 'text',
                    content: ` function works is equally as simple as the `,
                },
                {
                    type: 'code',
                    content: `indexOf()`,
                },
                {
                    type: 'text',
                    content: ` function: given a search parameter, search through the entire array from end to beginning and return the index of the last occurrence of that search parameter in the array, or `,
                },
                {
                    type: 'code',
                    content: `-1`,
                },
                {
                    type: 'text',
                    content: ` if the array does not contain the search parameter. The following example demonstrates both of these results:`,
                },
            ],
        },
        {
            type: 'code',
            content: `var letters = ['a', 'b', 'c', 'd', 'e', 'd', 'c', 'b', 'a'];

var dIndex = letters.indexOf('d');
// dIndex: 5
var zIndex = letters.indexOf('z');
// zIndex: -1`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `As described above, the function found and returned the index `,
                },
                {
                    type: 'code',
                    content: `5`,
                },
                {
                    type: 'text',
                    content: ` of the last occurrence of the search parameter that was contained within the array, while it returned the default `,
                },
                {
                    type: 'code',
                    content: `-1`,
                },
                {
                    type: 'text',
                    content: ` index for a search parameter that was not contained within the array.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `So if you have an ordered list of array elements and you have some idea of which end of the array, beginning or end, a particular array element resides on, you can use either the `,
                },
                {
                    type: 'code',
                    content: `indexOf()`,
                },
                {
                    type: 'text',
                    content: ` or `,
                },
                {
                    type: 'code',
                    content: `lastIndexOf()`,
                },
                {
                    type: 'text',
                    content: ` function to find the index of that array element more efficiently, as long as you make the right choice depending on your expectation of the array element location. Of course, if your array elements are unordered and there is no way of knowing where in the array a particular element resides, using either of these functions will yield the same result. Although, I would suggest in this case that it is best to use the `,
                },
                {
                    type: 'code',
                    content: `indexOf()`,
                },
                {
                    type: 'text',
                    content: ` function, since that is always the function that will be expected to be used in cases where an index is needed, so it is better to follow the general expectations of the people who are likely to read your code.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `On a final note, before moving on to the next function pair, in line with the previous article that I mentioned in the introduction to this article, I would like to make a suggestion on how to improve the readability of code that may be using the `,
                },
                {
                    type: 'code',
                    content: `indexOf()`,
                },
                {
                    type: 'text',
                    content: ` function in certain contexts. Most of the time that I have seen the `,
                },
                {
                    type: 'code',
                    content: `indexOf()`,
                },
                {
                    type: 'text',
                    content: ` function being employed in code it has been purely to determine whether or not an array contains an element, with no further work being performed on that array element. In such contexts, I would argue that the `,
                },
                {
                    type: 'code_link',
                    url: `https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/includes`,
                    content: `includes()`,
                },
                {
                    type: 'text',
                    content: ` function is a much better option. The following example clearly demonstrates my argument:`,
                },
            ],
        },
        {
            type: 'code',
            content: `var letters = ['a', 'b', 'c', 'd', 'e', 'd', 'c', 'b', 'a'];

var containsE = letters.indexOf('e') !== -1;

var includesE = letters.includes('e');`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `While the same result is found, the code that uses the `,
                },
                {
                    type: 'code',
                    content: `indexOf()`,
                },
                {
                    type: 'text',
                    content: ` function does not read nearly as clearly as the code that uses the `,
                },
                {
                    type: 'code',
                    content: `includes()`,
                },
                {
                    type: 'text',
                    content: ` function. You need to stop and read the `,
                },
                {
                    type: 'code',
                    content: `indexOf()`,
                },
                {
                    type: 'text',
                    content: ` function code closer to verify that it is indeed checking whether or not the array contains `,
                },
                {
                    type: 'code',
                    content: `e`,
                },
                {
                    type: 'text',
                    content: `, while the `,
                },
                {
                    type: 'code',
                    content: `includes()`,
                },
                {
                    type: 'text',
                    content: ` function code clearly describes exactly what is being checked, just as the variable name suggests. So in cases where only array element inclusion is being checked, I strongly recommend using the `,
                },
                {
                    type: 'code',
                    content: `includes()`,
                },
                {
                    type: 'text',
                    content: ` function in order to more clearly communicate your intention to the reader.`,
                },
            ],
        },
        {
            type: 'title',
            content: `Array.pop() and Array.push() functions`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The next pair of functions that I would like to discuss are the `,
                },
                {
                    type: 'code_link',
                    url: `https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/pop`,
                    content: `pop()`,
                },
                {
                    type: 'text',
                    content: ` and `,
                },
                {
                    type: 'code_link',
                    url: `https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/push`,
                    content: `push()`,
                },
                {
                    type: 'text',
                    content: ` functions, which I am sure many people are aware of nearly as much as they are aware of the `,
                },
                {
                    type: 'code',
                    content: `indexOf()`,
                },
                {
                    type: 'text',
                    content: ` function. One of the main reasons that I wanted to include them in this article is for the sake of completeness. The reason why these two functions are almost as ubiquitous in Javascript code that works with arrays as the `,
                },
                {
                    type: 'code',
                    content: `indexOf()`,
                },
                {
                    type: 'text',
                    content: ` function is because they are invaluable when it comes to working with arrays and manipulating their elements.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The way that the `,
                },
                {
                    type: 'code',
                    content: `pop()`,
                },
                {
                    type: 'text',
                    content: ` function works is clearly suggested by its name: the last element of the array is <span class="font-italic">popped</span> or removed from the array and returned. Of course, this will change the length of the array, reducing it by one. The following example demonstrates how the `,
                },
                {
                    type: 'code',
                    content: `pop()`,
                },
                {
                    type: 'text',
                    content: ` function works:`,
                },
            ],
        },
        {
            type: 'code',
            content: `var letters = ['a', 'b', 'c', 'd', 'e'];
var lastLetter = letters.pop();
// lastLetter: e
// letters: ['a', 'b', 'c', 'd']`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `Just like the `,
                },
                {
                    type: 'code',
                    content: `pop()`,
                },
                {
                    type: 'text',
                    content: ` function, the `,
                },
                {
                    type: 'code',
                    content: `push()`,
                },
                {
                    type: 'text',
                    content: ` function works like its name suggests: one or more specified elements are pushed or added to the end of the array and the new length of the array is returned. The following example demonstrates how the `,
                },
                {
                    type: 'code',
                    content: `push()`,
                },
                {
                    type: 'text',
                    content: ` function works:`,
                },
            ],
        },
        {
            type: 'code',
            content: `var letters = ['a', 'b', 'c'];
var length = letters.push('d', 'e');
// length: 5
// letters: ['a', 'b', 'c', 'd', 'e']`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The `,
                },
                {
                    type: 'code',
                    content: `pop()`,
                },
                {
                    type: 'text',
                    content: ` function performs the exact opposite work that is performed by the `,
                },
                {
                    type: 'code',
                    content: `push()`,
                },
                {
                    type: 'text',
                    content: ` function, which makes these functions a perfect pair of opposing Javascript `,
                },
                {
                    type: 'code',
                    content: `Array`,
                },
                {
                    type: 'text',
                    content: ` functions.`,
                },
            ],
        },
        {
            type: 'title',
            content: `Array.shift() and Array.unshift() functions`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The next pair of functions that I would like to discuss are the `,
                },
                {
                    type: 'code_link',
                    url: `https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/shift`,
                    content: `shift()`,
                },
                {
                    type: 'text',
                    content: ` and `,
                },
                {
                    type: 'code_link',
                    url: `https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/unshift`,
                    content: `unshift()`,
                },
                {
                    type: 'text',
                    content: ` functions, which work similarly to the `,
                },
                {
                    type: 'code',
                    content: `pop()`,
                },
                {
                    type: 'text',
                    content: ` and `,
                },
                {
                    type: 'code',
                    content: `push()`,
                },
                {
                    type: 'text',
                    content: ` functions, but are not nearly as widely used as those functions based on my experience throughout my career. Despite not being used nearly as much, the `,
                },
                {
                    type: 'code',
                    content: `shift()`,
                },
                {
                    type: 'text',
                    content: ` and `,
                },
                {
                    type: 'code',
                    content: `unshift()`,
                },
                {
                    type: 'text',
                    content: ` functions are equally as valuable when it comes to working with arrays and manipulating their elements.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The way that the `,
                },
                {
                    type: 'code',
                    content: `shift()`,
                },
                {
                    type: 'text',
                    content: ` function works is similar to the `,
                },
                {
                    type: 'code',
                    content: `pop()`,
                },
                {
                    type: 'text',
                    content: ` function: the first element of the array is removed from the array and returned, while all remaining elements in the array are shifted one index lower. Of course, this will change the length of the array, reducing it by one. The following example demonstrates how the `,
                },
                {
                    type: 'code',
                    content: `shift()`,
                },
                {
                    type: 'text',
                    content: ` function works:`,
                },
            ],
        },
        {
            type: 'code',
            content: `var letters = ['a', 'b', 'c', 'd', 'e'];
var firstLetter = letters.shift();
// firstLetter: a
// letters: ['b', 'c', 'd', 'e']`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `Just like the `,
                },
                {
                    type: 'code',
                    content: `push()`,
                },
                {
                    type: 'text',
                    content: ` function, the `,
                },
                {
                    type: 'code',
                    content: `unshift()`,
                },
                {
                    type: 'text',
                    content: ` function works in the exact opposite way to the `,
                },
                {
                    type: 'code',
                    content: `shift()`,
                },
                {
                    type: 'text',
                    content: ` function: one or more specified elements are added to the beginning of the array and the new length of the array is returned. The following example demonstrates how the `,
                },
                {
                    type: 'code',
                    content: `unshift()`,
                },
                {
                    type: 'text',
                    content: ` function works:`,
                },
            ],
        },
        {
            type: 'code',
            content: `var letters = ['c', 'd', 'e'];
var length = letters.unshift('a', 'b');
// length: 5
// letters: ['a', 'b', 'c', 'd', 'e']`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The `,
                },
                {
                    type: 'code',
                    content: `shift()`,
                },
                {
                    type: 'text',
                    content: ` function performs the exact opposite work that is performed by the `,
                },
                {
                    type: 'code',
                    content: `unshift()`,
                },
                {
                    type: 'text',
                    content: ` function. Just like the `,
                },
                {
                    type: 'code',
                    content: `pop()`,
                },
                {
                    type: 'text',
                    content: ` and `,
                },
                {
                    type: 'code',
                    content: `push()`,
                },
                {
                    type: 'text',
                    content: ` functions, these functions are a perfect pair of opposing Javascript `,
                },
                {
                    type: 'code',
                    content: `Array`,
                },
                {
                    type: 'text',
                    content: ` functions.`,
                },
            ],
        },
        {
            type: 'title',
            content: `Array.some() and Array.every() functions`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `Moving away from functions that alter the size of an array, the next functions that I would like to discuss are the `,
                },
                {
                    type: 'code_link',
                    url: `https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/some`,
                    content: `some()`,
                },
                {
                    type: 'text',
                    content: ` and `,
                },
                {
                    type: 'code_link',
                    url: `https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/every`,
                    content: `every()`,
                },
                {
                    type: 'text',
                    content: ` functions. Rather than alter the array or any of its elements in any way, these functions provide functionality for determining whether or not one or more elements in the array pass a specified logic test. Additionally, the fact that they are appropriately named for the functionality that they provide means that their use in your code can provide a higher level of readability than a homegrown implementation attempting to provide similar logic.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The way that the `,
                },
                {
                    type: 'code',
                    content: `some()`,
                },
                {
                    type: 'text',
                    content: ` function works is very simple: the specified test function is run against every array element from beginning to end and as long as that test function returns `,
                },
                {
                    type: 'code',
                    content: `true`,
                },
                {
                    type: 'text',
                    content: ` for at least one array element, `,
                },
                {
                    type: 'code',
                    content: `some()`,
                },
                {
                    type: 'text',
                    content: ` will return`,
                },
                {
                    type: 'code',
                    content: `true`,
                },
                {
                    type: 'text',
                    content: `. Otherwise, `,
                },
                {
                    type: 'code',
                    content: `some()`,
                },
                {
                    type: 'text',
                    content: ` will return `,
                },
                {
                    type: 'code',
                    content: `false`,
                },
                {
                    type: 'text',
                    content: ` if no array elements result in the test function returning `,
                },
                {
                    type: 'code',
                    content: `true`,
                },
                {
                    type: 'text',
                    content: `. The following example demonstrates how the `,
                },
                {
                    type: 'code',
                    content: `some()`,
                },
                {
                    type: 'text',
                    content: ` function works:`,
                },
            ],
        },
        {
            type: 'code',
            content: `var numbers = [1, 2, 3, 4, 5];
var lessThanTen = numbers.some(number => number < 10);
// lessThanTen: true
var greaterThanTen = numbers.some(number => number > 10);
// greaterThanTen: false`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `One of the useful things about the way in which the `,
                },
                {
                    type: 'code',
                    content: `some()`,
                },
                {
                    type: 'text',
                    content: ` function operates is that it will stop execution and return `,
                },
                {
                    type: 'code',
                    content: `true`,
                },
                {
                    type: 'text',
                    content: ` as soon as it finds an array element that satisfies the logic in the test function, rather than continue working its way through the entire array until the end. One thing to keep in mind about the `,
                },
                {
                    type: 'code',
                    content: `some()`,
                },
                {
                    type: 'text',
                    content: ` function is that it will always return `,
                },
                {
                    type: 'code',
                    content: `false`,
                },
                {
                    type: 'text',
                    content: ` when it is called on an empty array, so be sure that an array contains at least one element before executing its `,
                },
                {
                    type: 'code',
                    content: `some()`,
                },
                {
                    type: 'text',
                    content: ` function.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The way that the `,
                },
                {
                    type: 'code',
                    content: `every()`,
                },
                {
                    type: 'text',
                    content: ` function works is directly opposite to but just as simple as the way in which the `,
                },
                {
                    type: 'code',
                    content: `some()`,
                },
                {
                    type: 'text',
                    content: ` function works: the specified test function is run against every array element from beginning to end and as long as that test function returns `,
                },
                {
                    type: 'code',
                    content: `true`,
                },
                {
                    type: 'text',
                    content: ` for every array element, `,
                },
                {
                    type: 'code',
                    content: `every()`,
                },
                {
                    type: 'text',
                    content: ` will return `,
                },
                {
                    type: 'code',
                    content: `true`,
                },
                {
                    type: 'text',
                    content: `. Otherwise, `,
                },
                {
                    type: 'code',
                    content: `every()`,
                },
                {
                    type: 'text',
                    content: ` will return `,
                },
                {
                    type: 'code',
                    content: `false`,
                },
                {
                    type: 'text',
                    content: ` as soon as it finds an array element that results in the test function returning `,
                },
                {
                    type: 'code',
                    content: `false`,
                },
                {
                    type: 'text',
                    content: `. The following example demonstrates how the `,
                },
                {
                    type: 'code',
                    content: `every()`,
                },
                {
                    type: 'text',
                    content: ` function works:`,
                },
            ],
        },
        {
            type: 'code',
            content: `var numbers = [1, 2, 3, 4, 5];
var lessThanTen = numbers.every(number => number < 10);
// lessThanTen: true
var greaterThanTen = numbers.every(number => number > 10);
// greaterThanTen: false`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `Similarly to the `,
                },
                {
                    type: 'code',
                    content: `some()`,
                },
                {
                    type: 'text',
                    content: ` function, the `,
                },
                {
                    type: 'code',
                    content: `every()`,
                },
                {
                    type: 'text',
                    content: ` function will stop execution and return `,
                },
                {
                    type: 'code',
                    content: `false`,
                },
                {
                    type: 'text',
                    content: ` as soon as it finds an array element that does not satisfy the logic in the test function, rather than continue working its way through the entire array until the end. As can be seen from the description of the `,
                },
                {
                    type: 'code',
                    content: `some()`,
                },
                {
                    type: 'text',
                    content: ` function above, the `,
                },
                {
                    type: 'code',
                    content: `every()`,
                },
                {
                    type: 'text',
                    content: ` function works in the complete opposite way, but is equally useful and efficient. One thing to keep in mind about the `,
                },
                {
                    type: 'code',
                    content: `every()`,
                },
                {
                    type: 'text',
                    content: ` function is that it will always return `,
                },
                {
                    type: 'code',
                    content: `true`,
                },
                {
                    type: 'text',
                    content: ` when it is called on an empty array, so be sure that an array contains at least one element before executing its `,
                },
                {
                    type: 'code',
                    content: `every()`,
                },
                {
                    type: 'text',
                    content: ` function.`,
                },
            ],
        },
        {
            type: 'title',
            content: `Array.reduce() and Array.reduceRight() functions`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The final pair of functions that I would like to discuss in this article are the `,
                },
                {
                    type: 'code_link',
                    url: `https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/Reduce`,
                    content: `reduce()`,
                },
                {
                    type: 'text',
                    content: ` and `,
                },
                {
                    type: 'code_link',
                    url: `https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/ReduceRight`,
                    content: `reduceRight()`,
                },
                {
                    type: 'text',
                    content: ` functions. Unlike the other functions that have been covered in this article, these two functions are quite a bit more complicated, but nonetheless very useful when it comes to working with arrays. They are both similar to the `,
                },
                {
                    type: 'code',
                    content: `some()`,
                },
                {
                    type: 'text',
                    content: ` and `,
                },
                {
                    type: 'code',
                    content: `every()`,
                },
                {
                    type: 'text',
                    content: ` functions in that they take a function and run it once against every array element, but that is where the similarity ends.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The way that the `,
                },
                {
                    type: 'code',
                    content: `reduce()`,
                },
                {
                    type: 'text',
                    content: ` function works is as follows: the first parameter is a reducer function and the second parameter is an <span class="font-italic">optional</span> initial value to be passed to the reducer function. The parameters to the reducer function are an accumulator value, which has the initial value during the first execution of the reducer function if it was supplied, and the value of the current array element on which the reducer function is being executed. As the reducer function is executed on subsequent array elements, the accumulator value will have the value that was returned from the previous execution of the reducer function. The following example demonstrates how the `,
                },
                {
                    type: 'code',
                    content: `reduce()`,
                },
                {
                    type: 'text',
                    content: ` function works:`,
                },
            ],
        },
        {
            type: 'code',
            content: `var letters = ['a', 'b', 'c', 'd', 'e'];
var word = array.reduce((word, letter) => word + letter, '')
// word: abcde`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `There are a few very important things to keep in mind when using the `,
                },
                {
                    type: 'code',
                    content: `reduce()`,
                },
                {
                    type: 'text',
                    content: ` function. The first is that the reducer function will not be executed on the first element in the array if an initial value is not provided. Also, if either the array is empty and an initial value is provided, or the array only has one element and the initial value is not provided, the initial value in the former case and the single array element value in the latter case will simply be returned without the reducer function ever being executed. Finally, if no initial value is provided and the array is empty, the `,
                },
                {
                    type: 'code',
                    content: `reduce()`,
                },
                {
                    type: 'text',
                    content: ` function will throw an error. For these reasons, it is strongly advised to always provide an initial value so that unexpected behaviors can be avoided.`,
                },
            ],
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `All of these things should also be kept in mind when using the `,
                },
                {
                    type: 'code',
                    content: `reduceRight()`,
                },
                {
                    type: 'text',
                    content: ` function as well. The `,
                },
                {
                    type: 'code',
                    content: `reduceRight()`,
                },
                {
                    type: 'text',
                    content: ` function works in the exact same way as the `,
                },
                {
                    type: 'code',
                    content: `reduce()`,
                },
                {
                    type: 'text',
                    content: ` function, but it starts at the end of the array and works back through the array to the beginning. This function is especially useful when you have your array ordered in a particular way but you do not want to use the `,
                },
                {
                    type: 'code',
                    content: `reverse()`,
                },
                {
                    type: 'text',
                    content: ` function to switch the order of your array elements before using the `,
                },
                {
                    type: 'code',
                    content: `reduce()`,
                },
                {
                    type: 'text',
                    content: ` function. The following example demonstrates how the `,
                },
                {
                    type: 'code',
                    content: `reduceRight()`,
                },
                {
                    type: 'text',
                    content: ` function works:`,
                },
            ],
        },
        {
            type: 'code',
            content: `var letters = ['a', 'b', 'c', 'd', 'e'];
var word = array.reduceRight((word, letter) => word + letter, '')
// word: edcba`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `The `,
                },
                {
                    type: 'code',
                    content: `reduceRight()`,
                },
                {
                    type: 'text',
                    content: ` function performs the exact same work as the `,
                },
                {
                    type: 'code',
                    content: `reduce()`,
                },
                {
                    type: 'text',
                    content: ` function, but it performs it in the opposite direction of the `,
                },
                {
                    type: 'code',
                    content: `reduce()`,
                },
                {
                    type: 'text',
                    content: ` function. Just like all of the other pairs that were discussed in this article, the `,
                },
                {
                    type: 'code',
                    content: `reduce()`,
                },
                {
                    type: 'text',
                    content: ` and `,
                },
                {
                    type: 'code',
                    content: `reduceRight()`,
                },
                {
                    type: 'text',
                    content: ` functions are a perfect pair of opposing Javascript `,
                },
                {
                    type: 'code',
                    content: `Array`,
                },
                {
                    type: 'text',
                    content: ` functions.`,
                },
            ],
        },
        {
            type: 'title',
            content: `Conclusions`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `In this article we covered several pairs of Javascript `,
                },
                {
                    type: 'code',
                    content: `Array`,
                },
                {
                    type: 'text',
                    content: ` functions that provide various types of functionality. Some of these function pairs offer the ability to determine if the elements of an array satisfy a particular logic test, while other function pairs offer the ability to manipulate the list of elements contained within the array itself. Despite their functionality differences, each of these function pairs provides developers with the ability to implement a particular piece of functionality and also just as easily implement functionality in the opposite direction.`,
                },
            ],
        },
        {
            type: 'text',
            content: `Knowledge of these correlated function pairs is useful because it prevents developers from having to conceive of and implement their own solutions to reverse any changes that may be made to an array or run opposite logic tests on an array. Having them in your coding tool belt can also save a lot of time and mental horsepower for the more important problems that will inevitably manifest themselves when working on an application. I hope that this article will help many developers broaden their horizons when it comes to all Javascript array-related coding situations.`,
        },
    ],
}