export default {
    id: '2020-01-29',
    year: 2020,
    month: 1,
    date: 29,
    title: `An SE’s Perspective: The Elements of User Experience (Part 7)`,
    blog_url: `https://keithvictordawson.medium.com/an-ses-perspective-the-elements-of-user-experience-part-7-dd55c4590ebf`,
    image_url: `https://miro.medium.com/max/700/1*De5Lfps7YDWxi1LBTAwGyw.png`,
    image_caption: `The Elements of User Experience, Jesse James Garrett`,
    contents: [
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `In this series I read through each chapter of the groundbreaking UX design book `,
                },
                {
                    type: 'italic_link',
                    url: `http://www.jjg.net/elements/`,
                    content: `The Elements of User Experience`,
                },
                {
                    type: 'text',
                    content: ` and discuss the content from my perspective as a software engineer. The author of this book, Jesse James Garrett, wrote it in the hope that it would help UX designers or anyone else interested in UX design to understand the decisions that UX practitioners make during the course of their work. Unlike the first edition, this second edition of the book is not so heavily focused on UX as it pertains to web development, but rather written in such a way as to apply to any context in which UX plays an important role. Please keep in mind that my perspective comes from the direction not of a UX designer but of a software engineer who strives to wield the principles of UX as a tool to inform all of the software development decisions that I make and enhance all of the software that I create. As a result, all discussion about this book will be in that context.`,
                },
            ],
        },
        {
            type: 'spacer',
        },
        {
            type: 'highlight',
            content: `At the top of the five-plane model, we turn our attention to those aspects of the product our users will notice first: the sensory design. Here, content, functionality, and aesthetics come together to produce a finished design that pleases the senses while fulfilling all the goals of the other four planes.`,
        },
        {
            type: 'text',
            content: `The title of the seventh chapter is <span class="font-italic">The Surface Plane - Sensory Design</span> and in this chapter the author brings together all of the deliverables that we have seen come out of each of the lower planes into a well-rounded and complete product. As stressed throughout the entire book, that product will not only fulfill all of the goals that the organization has defined for the product, but it will also fulfill all of the needs of the users as well. Due to the fact that the Surface Plane is the closest to the user, all of the design choices that are made in this plane <span class="font-italic">directly</span> impact the user’s experience with the product. In that context, all of the choices that are made while working on this plane are solely dictated by sensory design, which takes into account all of the user’s senses with respect to how they perceive the product and how they interact with it.`,
        },
        {
            type: 'title',
            content: `Defining the Surface`,
        },
        {
            type: 'image',
            url: `https://miro.medium.com/max/481/1*ZA9nVSVPB5rDCjmKRLx8BQ.png`,
            caption: `The Surface Plane, Jesse James Garrett`,
        },
        {
            type: 'text',
            content: `The chapter begins with a short discussion of sensory design and how it ties into all of the work that was done in the Skeleton Plane. The Skeleton Plane was primarily concerned with the arrangement of everything in the product, as the author describes:`,
        },
        {
            type: 'quote',
            content: `On the skeleton plane, we were dealing primarily with arrangement. Interface design concerns the arrangement of elements to enable interaction; navigation design, the arrangement of elements to enable movement through the product; and information design, the arrangement of elements to communicate information to the user.`,
        },
        {
            type: 'text',
            content: `Each of the three design disciplines captured in the Skeleton Plane, interface design, navigation design, and information design, had their particular focus with respect to how and where all of the elements of the product were placed in the user interface. But in general, the best arrangement of all of those elements to achieve their individual goals while also presenting a unified experience to the user was their ultimate goal. This is why, throughout the chapter on the Skeleton Plane, the author stressed the close relationship that each of these three disciplines had with one another and how each of their individual efforts bolstered the efforts of the other two. Now, on the Surface Plane, that optimal arrangement is put through the sensory design process with particular attention paid to visual design, as the author explains:`,
        },
        {
            type: 'quote',
            content: `Moving up to the surface plane, we are now dealing with the sensory design and presentation of the logical arrangements that make up the skeleton of the product. For example, through attention to information design, we determine how we should group and arrange the information elements of the page; through attention to visual design, we determine how that arrangement should be presented visually.`,
        },
        {
            type: 'text',
            content: `In my discussions on previous chapters, I have stated my belief that the UX design process and the software development process have a synergistic relationship in that they each provide fuel to the other in order to keep the overall development process moving forward. Sometimes the two processes overlap and directly collaborate on the production of a specific deliverable. Other times the two processes branch away from each other and support each other through their individual deliverables. As a software engineer who has built plenty of user interfaces throughout my career, I can definitely say that the Surface Plane is a step in the <span class="font-italic">Elements of User Experience</span> framework where the UX design process has completely branched away from the software development process.`,
        },
        {
            type: 'text',
            content: `At this point, the software development process has already succeeded in implementing all of the functionality necessary to fulfill all of the functional requirements that have been defined for the product. In effect, the software development process has concluded its intended mission and has now turned to supporting the UX design process in its efforts to conclude its own mission in fulfilling all of the user needs that have been defined for the product. The software development process has succeeded in getting the product as far as it can by utilizing its own expertise in making things work the way that they should. The UX design process will now take the reins and carry the product over the finish line by utilizing its expertise in making things look and feel the way that they should. We have exited the collaborative environment of the UX design process and software development process and entered the realm of pure UX design.`,
        },
        {
            type: 'title',
            content: `Making Sense of the Senses`,
        },
        {
            type: 'quote',
            content: `Every experience we have fundamentally comes to us through our senses. In the design process, this is the last stop on the way to delivering an experience to our users: determining how everything about our design will manifest to people’s senses. Which of the five senses (vision, hearing, touch, smell, and taste) we can employ depends on the type of product we are designing.`,
        },
        {
            type: 'text',
            content: `Since sensory design is the sole design discipline that dictates all of the work performed on the Surface Plane, this means that all of that work needs to take into account the user’s five senses. In the introduction to this book, the author was quick to point out that the <span class="font-italic">Elements of User Experience</span> can technically be applied in the development of any product or service, not just software. But due to the fact that the majority of this book is focused on UX design as it pertains to software development, the author dedicates the rest of this chapter to a discussion of visual design because that is the sensory design discipline in which UX design is most sophisticated in terms of its approach. The author offers some initial advice on how best to tackle the visual design process before digging deeper into the meat of the discussion:`,
        },
        {
            type: 'quote',
            content: `Instead of evaluating visual design ideas solely in terms of what seems aesthetically pleasing, you should focus your attention on how well they work. How effectively does the design support the objectives defined by each of the lower planes? For example, does the look of the product make distinctions between sections of the architecture unclear or ambiguous, undermining the structure? Or does the visual design clarify the options available to users, reinforcing the structure?`,
        },
        {
            type: 'title',
            content: `Follow the Eye`,
        },
        {
            type: 'text',
            content: `As visual design has everything to do with the user’s sense of sight, the user’s eyes are what we should focus on most in terms of all of the questions that we ask ourselves while working on the Surface Plane. The author raises some of the most compelling questions when suggesting where to start in the visual design process:`,
        },
        {
            type: 'quote',
            content: `One simple way to evaluate the visual design of a product is to ask: Where does the eye go first? What element of the design initially draws the users’ attention? Are they drawn to something important to the product’s strategic objectives? Or is the first object of their attention a distraction from their goals (or yours)?`,
        },
        {
            type: 'text',
            content: `While developing any particular user interface design in a product, there should always be a clearly intended path that the user’s eye will follow as soon as it glimpses that design. Putting conscious thought into what exactly this path will be can go a long ways towards ensuring that the user actually follows the intended path that you have laid out for them. There are a variety of different methods and tricks that you can use in order to determine exactly how successful you are at directing the user’s eye from element to element in your product. Some of these even include using yourself as a test subject. Regardless of the way in which you make this determination, your success will be determined by only a couple of factors, as defined by the author:`,
        },
        {
            type: 'quote',
            content: `If your design is successful, the pattern the user’s eye follows will have two important qualities: First, it follows a smooth flow. Second, it gives users a sort of guided tour of the possibilities available to them without overwhelming them with detail.`,
        },
        {
            type: 'text',
            content: `When the author states that the user’s eye should follow a smooth flow, what he really means is that your design should not present the user with multiple paths for their eye to follow. If there are multiple paths in your design, the user’s eyes will jump back and forth from one path to another and fail to actually be drawn to any path in particular. This would be a failure because the user would be confused as to what their next action should be. Obviously, you should never leave the user hanging in terms of knowing how to proceed in using the product.`,
        },
        {
            type: 'text',
            content: `This is also true when the author states that the path that the user’s eye takes through your design should give them a tour of all of the possibilities available in the design without providing an unnecessary level of detail. Even if you give the user’s eye a single path to follow through your design, providing too much to look at during each stop in that path can also throw the user off and result in confusion about where to go and what to do next. The whole design process can feel like quite a difficult balancing act when it comes to leading the eyes of the user through your interface, and in many ways it is. The key to success is using the right tools for the job.`,
        },
        {
            type: 'title',
            content: `Contrast and Uniformity`,
        },
        {
            type: 'quote',
            content: `In visual design, the primary tool we use to draw the user’s attention is contrast. Contrast is vital to drawing the user’s attention to essential aspects of the interface, contrast helps the user understand the relationships between the navigational elements on the page, and contrast is the primary means of communicating conceptual groups in information design.`,
        },
        {
            type: 'text',
            content: `When two things are different from each other in a striking way, you tend to notice them more than when two things are identical. This is an instinctive behavior that has existed in our brains since the time when humans were not yet at the top of the food chain. In order to survive, we needed to evolve to be able to identify when two things in front of us appeared to be different, which sometimes meant that we were in danger and needed to leave the area as soon as possible. Luckily, we do not need to depend on this instinct for survival as much these days, but having this mental conditioning is very useful in terms of visual design. As the author states above, contrast is used to indicate a number of different things in the context of visual design. In general, though, contrast is used to help the user notice and understand the purpose of the most important parts of an interface. It will only work to your benefit if you use it effectively and in the right amount.`,
        },
        {
            type: 'quote',
            content: `In a visually neutral layout, nothing stands out. Contrast can be used to guide the user’s eye around the page or draw their attention to a few key elements. Overuse of contrast leads to a cluttered look.`,
        },
        {
            type: 'text',
            content: `In a conceptually confusing turn of events, uniformity is another important tool in visual design. The reason why I say that this is confusing is because, when you consider the words contrast and uniformity, you kind of picture them as being on opposite ends of the same scale. On the one end, contrast is a quality defining the difference in two things, while on the other end, uniformity is a quality defining the similarity in two things. But the reason why both of these concepts are important in visual design is because, while contrast focuses more on the color and shading appearance of different elements in the interface, uniformity focuses more on the sizing and placement of different elements in the interface.`,
        },
        {
            type: 'text',
            content: `In terms of sizing, the author cites the importance of keeping the sizes of all of the elements in your design uniform so that it is easy to move them around and recompose them into as many different designs as necessary for your product. Without enforcing size uniformity on the elements in your design, you begin to limit the number of available options that you have for designing interfaces that provide a successful user experience. The same is true in terms of the placement of elements in your design. The author demonstrates this by introducing the grid-based layout:`,
        },
        {
            type: 'quote',
            content: `Grid-based layout is one technique from print design that carries over effectively to the Web. This approach ensures uniformity of design through a master layout that is used as a template for creating layout variations. Not every layout will use every part of the grid but every element’s placement on the grid should be uniform and consistent.`,
        },
        {
            type: 'title',
            content: `Internal and External Consistency`,
        },
        {
            type: 'text',
            content: `One of the biggest problems that software engineers can encounter during the course of their work is a lack of code consistency. Code consistency is a problem that often arises when many different people work on a single codebase and they do not pay attention to the coding standards that have previously been established. These standards may range anywhere from the syntactical layout of functions to the documentation style of functions to the way in which entire modules of an application are constructed. Every software engineer has their own unique style of coding, but in order to avoid complete chaos and ease the burden of development on other software engineers who may touch your code in the future, it is a general rule to be considerate and follow the standards that have already been established. And if you feel strongly enough about changing one of these standards, you better be prepared to put in the work and be thorough enough to ensure that the new standard has been applied throughout the entire codebase.`,
        },
        {
            type: 'text',
            content: `From a UX design standpoint, products can have problems of consistency in visual design as well, as the author describes:`,
        },
        {
            type: 'quote',
            content: `There are problems of internal consistency, in which different parts of the product reflect different design approaches. Then there are problems of external consistency, in which the product doesn’t reflect the same design approach used in other products from the same organization.`,
        },
        {
            type: 'text',
            content: `Just like in the case of the code for a product, the visual design used throughout that product should also adhere to one established standard. When a software engineer encounters multiple different coding standards within the same codebase, they can waste a lot of time and energy trying to unravel all of the logic and figure out where they can and should make their changes to the code. It is best not to put the users of your product in the same situation as they are much less likely to have the patience of a software engineer. While it is the job of the software engineer to make changes to the code and they usually do not have a say in the matter, the user has no obligation to your product and their willingness to continue using your product can evaporate quickly before they decide to move on to another product that better suits their needs.`,
        },
        {
            type: 'text',
            content: `When there are problems of consistency within a single product, that can cause user’s to have a negative view of just that product. The author describes even larger problems that can arise for the company in the eyes of your users if there are problems of consistency across multiple products:`,
        },
        {
            type: 'quote',
            content: `Presenting a style on your Web site that’s inconsistent with your style in other media doesn’t just affect the audience’s impression of that product; it affects their impression of your company as a whole. People respond positively to companies with clearly defined identities. Inconsistent visual styles undermine the clarity of your corporate image and leave the audience with the impression that this is a company that hasn’t quite figured out who it is.`,
        },
        {
            type: 'title',
            content: `Color Palettes and Typography`,
        },
        {
            type: 'quote',
            content: `A color palette should incorporate colors that lend themselves to a wide range of uses. In most cases, brighter or bolder colors can be used for the foreground of your design — elements to which you want to draw attention. More muted colors are better used for background elements that don’t need to jump off the page.`,
        },
        {
            type: 'text',
            content: `One of the best ways that a company can effectively communicate its brand identity is through the choices it makes in selecting the color palette that will be used in all of its media. And just like how contrast and uniformity play a critical role when creating a visual design for a product, they also play a critical role in the creation of a color palette. In terms of contrast, it is important that none of the selected colors are too close to any of the other colors in the color palette. If this is the case, it can undermine the effectiveness of your color palette as a whole by failing to serve as a strong enough differentiator. At the same, using the color palette in a uniform manner throughout the entire product will maintain its overall effectiveness by ensuring that the user knows what to expect when they see certain colors being used in the design.`,
        },
        {
            type: 'text',
            content: `These same rules also apply to the typography that is used in a product. The author highlights a couple guidelines with respect to choosing the right typography for your product:`,
        },
        {
            type: 'quote',
            content: `For body text — any material that will be presented in larger blocks or that will be read by users in longer stretches — simpler is better. For larger text elements or short labels like those seen on navigational elements, typefaces with a little more personality are perfectly appropriate.`,
        },
        {
            type: 'text',
            content: `Contrast and uniformity also play a crucial role in the selection of typography for a product, just like they did in the selection of a color palette. Text elements in your design that serve different purposes should typically be differentiated through the use of different fonts. This contrast will communicate a difference in purpose and meaning to the user. But it is important that those fonts look different enough to the user that they do not run the risk of being confused for the same thing. And just like with color palettes, it is absolutely critical to use all of your chosen fonts in a uniform manner throughout the entire product. The author concludes this section with some sound general advice on the selection of typography for a product:`,
        },
        {
            type: 'quote',
            content: `The principles of using type effectively are really the same as those for other aspects of visual design: Don’t use styles that are very similar but not exactly the same. Use different styles only to indicate differences in the information you’re trying to communicate. Provide enough contrast between styles that you can draw the user’s attention as needed, but don’t overload the design with a wide range of diverse styles.`,
        },
        {
            type: 'title',
            content: `Design Comps and Style Guides`,
        },
        {
            type: 'quote',
            content: `The most direct analog to the wireframe for the realm of visual design is the visual mock-up or design comp, a visualization of the finished product built up from the components that have been chosen. The comp shows how all the pieces work together to form a cohesive whole; or, if they don’t, it shows where the breakdown is happening and demonstrates constraints that any solution will have to account for. You should be able to see a simple one-to-one correlation between components of the wireframe and components of the design comp.`,
        },
        {
            type: 'text',
            content: `The two-step process of creating a wireframe while working on the Skeleton Plane and transforming it into a design comp while working on the Surface Plane has two particular uses in the overall UX design process. While creating that wireframe down on the Skeleton Plane, we are focused on documenting only the skeleton of the product. This allows us to identify and address any skeleton issues that exist while they are still in isolation, before they have been clouded with any surface issues. Once we move up to the Surface Plane and produce a design comp from that wireframe, we can focus our attention entirely on identifying and addressing surface issues.`,
        },
        {
            type: 'text',
            content: `The other purpose of creating a wireframe separately from a design comp is that it allows the company to maintain a snapshot of all of the design decisions that were made at the time that the UX design team made them, as the author describes:`,
        },
        {
            type: 'quote',
            content: `Over time, the reasons for our decisions fade from memory. The ad-hoc decisions made to address a specific problem in a specific circumstance get all jumbled up with the decisions intended to form the foundation for future design work.`,
        },
        {
            type: 'text',
            content: `The team that developed an entire UX design for a product will never stay together forever, nor will the memory of their decisions remain perfectly clear forever. Everyone forgets the decisions that were made right in the heat of the moment, and without a proper accounting of all of the decisions that were made as well as the justification for why they were made, things can become distorted. A small and insignificant decision that was made for a particular portion of the overall design of a product may grow to completely define that product without the proper documentation to relegate that decision to the one place in which it was intended to be applied. And the same can be true of overarching decisions that were made being forgotten and fading into just a shadow of their original breadth and scope.`,
        },
        {
            type: 'text',
            content: `This need for documenting the development journey is very necessary from a software engineering perspective as well. With the advent of cloud services like GitHub, no development team would ever consider the absurd possibility of committing a single final snapshot of the codebase for an entire application. Extremely explicit snapshots of the progression of each individual file in the codebase from start to finish would be housed in the code repository. Each of these snapshots would provide profound incite into all of the tiny code decisions that were made by the development team in building towards a final finished product.`,
        },
        {
            type: 'text',
            content: `The final deliverable to come out of the Surface Plane and the UX design process as a whole is the style guide, the significance and usefulness of which is described by the author:`,
        },
        {
            type: 'quote',
            content: `The definitive documentation of the design decisions we have made is the style guide. This compendium defines every aspect of the visual design. Global standards affecting every part of the product are usually the first things to go into a style guide. The style guide will also include standards specific to a particular section or function of a product. The overall goal of the style guide is to provide enough detail to help people make smart decisions in the future — because most of the thinking has already been done for them.`,
        },
        {
            type: 'text',
            content: `There really is not much more to say about the style guide that what has already been stated above by the author. It is the culmination of all of the work that has been done throughout all five planes of the <span class="font-italic">Elements of User Experience</span>. Without any previous knowledge about the product that is the subject of the style guide, a person can begin to make informed decisions about the direction of future development on that product. This is an incredibly useful document for the long-term maintenance and further development of a product and its importance cannot be overstated.`,
        },
        {
            type: 'text',
            content: `In software engineering there is also an analog to the UX design style guide, which is the coding style guide. At this point in my career I have worked for ten different companies of varying size and discipline. Generally, in my experience, the larger and more disciplined companies tend to produce a coding style guide that can be referenced by developers with questions about anything from proper coding syntax to documentation. Some coding style guides that I have seen also provide guidance on how to structure commit messages when pushing code changes to a repository. I have even had a hand in crafting coding style guides for some of the companies that I have worked for. As a committed and disciplined software engineer, I have always felt that it is an important part of the initiation process for any new developer to hand them a coding style guide that they can reference once diving headlong into the code. This way, they can focus all of their attention on what truly matters most, the logic, and leave all of the decisions of coding style up to the guide.`,
        },
    ],
}