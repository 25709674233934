<template>
    <v-app>
        <appbar />
        <navbar />
        <v-main :style="mainStyle" transition="slide-x-transition">
            <router-view />
        </v-main>
    </v-app>
</template>

<script>
import { mapGetters } from 'vuex'

// Components
import Appbar from './components/application/Appbar'
import Navbar from './components/application/Navbar'

export default {
    name: 'App',
    components: {
        Appbar,
        Navbar,
    },
    computed: {
        ...mapGetters('Settings', {
            showNavbar: 'showNavbar',
        }),
        mainStyle() {
            return {
                marginLeft: `${this.showNavbar && !this.$vuetify.breakpoint.smAndDown ? '256' : '56'}px`,
            }
        },
    },
}
</script>

<style scoped>

</style>