export default {
    id: '2019-11-18',
    year: 2019,
    month: 11,
    date: 18,
    title: `Developer Story: DB Migrations in NodeJS (MongoDB Edition)`,
    blog_url: `https://medium.com/javascript-in-plain-english/developer-story-db-migrations-mongodb-edition-7b36db8f2654`,
    image_url: `https://miro.medium.com/max/700/0*NC_bMa3n-t_pPjEW.jpg`,
    contents: [
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `For this entry in my developer story I want to switch things up and take one step away from the verbose `,
                },
                {
                    type: 'internal_link',
                    year: 2019,
                    month: 11,
                    date: 1,
                    content: `mind`,
                },
                {
                    type: 'text',
                    content: ` `,
                },
                {
                    type: 'internal_link',
                    year: 2019,
                    month: 11,
                    date: 15,
                    content: `dumps`,
                },
                {
                    type: 'text',
                    content: ` that I have written thus far where I merely discuss my thoughts and feelings about this project and software development in general. I want to dig into the meat of what I am actually working on and share more details about the code that I am writing for this project and the thought process behind why I am writing that code. In some cases, this may include things that I am just learning at the time of writing, and at other times it may include things that have been a part of my development process for a long time. The contents of this particular developer story entry will be of the latter variety.`,
                },
            ],
        },
        {
            type: 'text',
            content: `Earlier in my career, before I was ever given any control or influence over the database structure or the data actually contained in the database, those colleagues of mine who were tasked with overseeing this crucial part of the system always talked about it like it like it was this huge, monolithic, insurmountable challenge and burden that they did not want to even think about, much less go in and touch or disturb in any way. This way of thinking never made sense to me because I was of the opinion that if you did not trust your database system enough to do any of these things, then why were you using them? I also often wondered what could be done to improve the system to eliminate all of the things that made this way of thinking so pervasive in all of the companies that I worked in, and seemingly the whole of the software development community.`,
        },
        {
            type: 'text',
            content: `While working at a couple different companies in Hong Kong, I got my first glimpses of how this situation could be alleviated with the help of a system of migration scripts for establishing the database structure and seed scripts for filling that structure with actual useful data. I saw this done successfully on both a Postgres database as well as a MongoDB database, so I knew that such a system could work in both SQL and No-SQL contexts. Once I came to South Korea and took a position at a company where I was not only tasked with architecting and filling a database but also administering it as well, I knew exactly how I was going to accomplish all of these database-related tasks while minimizing the amount of stress and anxiety that they would bring me.`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `In order to accomplish the task of building a MongoDB database and filling it with data, the first thing that I needed to do was find a robust system that could handle the migration process. Since I was working in NodeJS, I searched for NPM packages that were built for such a purpose. After searching and evaluating a few similar but different packages, the one that I rested on was `,
                },
                {
                    type: 'bold_link',
                    url: `https://www.npmjs.com/package/migrate-mongo`,
                    content: `migrate-mongo`,
                },
                {
                    type: 'text',
                    content: `, which is at version 7.0.1 as of the time of writing this entry. This package suits my needs perfectly because it provides exactly all of the features that I need when migrating my database and nothing more. As an added bonus, it requires a minimal amount of code to be written on my end, which means I can focus all of my attention on the task of actually building the necessary logic into my migration and seed scripts without having to worry about anything else in the migration process.`,
                },
            ],
        },
        {
            type: 'text',
            content: `Each individual migration script could not be easier or more straightforward to write and read later. If I wanted to write a migration script that creates a simple <span class="font-italic">users</span> collection in my database with a simple schema, I would write the following:`,
        },
        {
            type: 'code',
            title: `migrations/20190101000000-create_collection_users.js`,
            content: `
module.exports = {
    async up(db) {
        return await db.creationCollection('users', {
            validator: {
                $jsonSchema: {
                    bsonType: 'object',
                    required: [ 'email', 'password', 'username' ],
                    properties: {
                        email: {
                            bsonType: 'string',
                            pattern: '[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}',
                        },
                        password: {
                            bsonType: 'string',
                        },
                        username: {
                            bsonType: 'string',
                        },
                    },
                },
            },
            validationLevel: 'strict',
            validationAction: 'error',
        })
    },
    async down(db) {
        return await db.collection('users').drop()
    },
}`,
        },
        {
            type: 'text',
            content: `And this example is even more complicated than most people may require because I prefer to use MongoDB’s schema validation functionality in all of my applications that use a MongoDB database, while many people who use MongoDB may not require or desire such functionality. In those cases, there would literally only need to be one line of code inside each function, one for creating the collection and one for dropping it. So this migration script would be used by <span class="font-weight-bold">migrate-mongo</span> to either create or destroy the <span class="font-italic">users</span> collection, depending on which direction in the migration process you happen to be going.`,
        },
        {
            type: 'text',
            content: `The seed script for filling this collection would also be almost as easy, or even easier than my example above. If I wanted to write a seed script that adds a test user to my <span class="font-italic">users</span> collection, I would write the following:`,
        },
        {
            type: 'code',
            title: `migrations/20200101000000-seed_collection_users.js`,
            content: `
module.exports = {
    async up(db) {
        return await db.collection('users').insertMany([
            {
                email: 'testuser@test.com',
                password: 'test_password',
                username: 'Test User',
            }
        ], {})
    },
    async down(db) {
        return await db.collection('users').deleteMany({})
    },
}`,
        },
        {
            type: 'text',
            content: `So this seed script would be used by <span class="font-weight-bold">migrate-mongo</span> to either add a user to the <span class="font-italic">users</span> collection or remove all users from the <span class="font-italic">users</span> collection, depending on which direction in the migration process you happen to be going.`,
        },
        {
            type: 'text',
            content: `Now, before I can utilize the migration and seed scripts above in some handy NPM CLI commands, I just have to create a configuration file with details about the database that will hold my <span class="font-italic">users</span> collection like the following:`,
        },
        {
            type: 'code',
            title: `config/test_config.js`,
            content: `
module.exports = {
    mongodb: {
        url: 'mongodb://localhost:27017',
        databaseName: 'test_db',
    },
    migrationsDir: 'migrations',
    changelogCollectionName: 'changelog',
}`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `According to the contents of this sample configuration file there would need to be an instance of MongoDB running at port 27017 on your local machine. And the most important part to pay attention to is that both the migration and seed scripts would need to be located in a <span class="font-italic">migrations</span> directory at the top level of the application directory. Also, a final important thing to note is that <span class="font-weight-bold">migrate-mongo</span> runs all migration and seed scripts in an order dictated by the names of those scripts as they appear in the <span class="font-italic">migrations</span> directory. If you take a look at the sample names of the migration and seed scripts that I provide above, you can see that I name them in such a way that the migration script would be run before the seed script is run when migrating the database. As you may notice, the numbers at the beginning of the file names are `,
                },
                {
                    type: 'external_link',
                    url: `https://en.wikipedia.org/wiki/ISO_8601`,
                    content: `ISO 8601`,
                },
                {
                    type: 'text',
                    content: `-like strings accurate to the second for a specific date. I always establish a consistent numbering format for all of my migration and seed scripts so that they do not get mixed up and so that all migration scripts are run in an appropriate order before any seed scripts are run.`,
                },
            ],
        },
        {
            type: 'text',
            content: `With a migration script, a seed script, and a configuration file created, the only thing left to do is create some useful NPM CLI commands. The particular NPM CLI commands that I use in all of my MongoDB database applications are the following:`,
        },
        {
            type: 'code',
            title: `package.json`,
            content: `
{
    ...,
    "scripts": {
        "migrate_down": "./node_modules/.bin/migrate-mongo down --file ./config/test_config.js",
        "migrate_up": "./node_modules/.bin/migrate-mongo up --file ./config/test_config.js",
        "migrate_status": "./node_modules/.bin/migrate-mongo status --file ./config/test_config.js"
    },
    ...,
}`,
        },
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `According to these commands the configuration file from above needs to be located in a <span class="font-italic">config</span> directory at the top level of the application directory, but you could put it anywhere that you prefer as long as it is referenced properly in these commands. And with that, everything that is needed to connect to a database, create a <span class="font-italic">users</span> collection, and seed that <span class="font-italic">users</span> collection has been created. The thing to keep in mind when using these commands with <span class="font-weight-bold">migrate-mongo</span> is that the `,
                },
                {
                    type: 'code',
                    content: `migrate_down`,
                },
                {
                    type: 'text',
                    content: ` command only runs one migration file at a time for safety purposes, while the `,
                },
                {
                    type: 'code',
                    content: `migrate_up`,
                },
                {
                    type: 'text',
                    content: ` command runs all migration files through the end of the list of files in the <span class="font-italic">migrations</span> directory. Finally, the `,
                },
                {
                    type: 'code',
                    content: `migrate_status`,
                },
                {
                    type: 'text',
                    content: ` command will print out a list of all migration scripts with an indicator for each specifying whether or not it is pending a migration or has already completed a migration.`,
                },
            ],
        },
        {
            type: 'text',
            content: `And that concludes the details about my personal system for cleanly and efficiently creating and populating a MongoDB database. This was a system that I developed after seeing that such a thing was possible and being given the responsibility of building and administering a MongoDB database. For added safety and separation of concerns, in professional settings I also tend to create the migration scripts on a development branch and the seed scripts on a completely isolated seed branch so that existing data is not disturbed in any way. This is definitely overkill for personal projects and other low-risk projects, but it is a good practice to keep in mind if you have serious data integrity concerns. Since this developer story entry is already long enough, I will provide the same process and code details as presented here for an equivalent Postgres database in my next entry, so please stay tuned for that and more as I make further progress on my personal project.`,
        },
    ],
}