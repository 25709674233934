export default {
    mxn3: {
        company: 'MXN Express',
        location: 'Seongdong-gu, Seoul, Korea',
        position: 'Software Engineering Team Leader (팀장)',
        time: 'September 2021 - Present',
        tech: 'Quasar, JavaScript, Node.js, Express.js, Vue.js, Vuex, PostgreSQL, Redis',
        tasks: [
            `Lead software development team while performing the following tasks on an ecommerce order management and shipment processing application: expanded upon existing feature set for RESTful client-server system, accompanying system database, and associated microservice-based job processing suite; oversaw complete reimplementation of client application from using Vue.js Vuetify component library to being implemented using the Vue.js Quasar framework.`,
        ],
    },
    mxn2: {
        company: 'MXN Express',
        location: 'Seongdong-gu, Seoul, Korea',
        position: 'Lead Software Engineer',
        time: 'March 2021 - September 2021',
        tech: 'JavaScript, Node.js, Express.js, Vue.js, Vuex, PostgreSQL, Redis',
        tasks: [
            `Lead software development team while performing the following tasks on an ecommerce order management and shipment processing application: expanded upon existing feature set for RESTful client-server system, accompanying system database, and associated microservice-based job processing suite; introduced usage of Socket.IO in order to improve real-time communications between the client and main server applications. Architected and implemented new email processing microservice integrated with the Twilio SendGrid email API system.`,
        ],
    },
    mxn1: {
        company: 'MXN Express',
        location: 'Seongdong-gu, Seoul, Korea',
        position: 'Senior Software Engineer',
        time: 'September 2020 - March 2021',
        tech: 'JavaScript, Node.js, Express.js, Vue.js, Vuex, PostgreSQL, Redis, PHP, Laravel',
        tasks: [
            `Performed the following tasks on an ecommerce order management and shipment processing application: architected and implemented entire RESTful client-server system using Vue.js, Node.js, and Express.js; architected, implemented, and administered system database using PostgreSQL; architected, implemented, and administered microservice-based job processing suite using PostgreSQL and Redis.`,
        ],
    },
    project: {
        company: 'Personal Project',
        location: 'Mapo-gu, Seoul, Korea',
        position: 'Software Engineer',
        time: 'October 2019 – Present',
        tech: 'JavaScript, Node.js, Express.js, MongoDB, NoSQL, GraphQL, AWS EC2, Java, Go',
        tasks: [
            `Currently working on developing personal project which is a language and cultural learning application suite. The application suite consists of the following elements: user web application implemented using React and Redux with the Material UI visual framework; user mobile application implemented using React Native with the Material UI visual framework; admin data management web application implemented using React and Redux with the Fabric UI visual framework; RESTful server system implemented using Node.js and Express.js; system database implemented using MongoDB clusters. Also implemented alternate versions of RESTful server system using Node.js and GraphQL, Java, and Go.`,
        ],
    },
    namo: {
        company: 'Namo Webiz, Inc.',
        location: 'Songpa-gu, Seoul, Korea',
        position: 'Master',
        time: 'March 2019 - October 2019',
        tech: 'JavaScript, Node.js, Express.js, MongoDB, NoSQL, AWS EC2, C#, Unity 2019, C++, Unreal Engine 4',
        tasks: [
            `Performed the following tasks on a Korean government web-based IDE for developing IoT device software: architected and implemented entire RESTful microservice-based server system using Node.js and Express.js; architected, implemented, and administered system database using MongoDB clusters.`,
            `Performed the following tasks on a Korean government web-based educational VR application: developed entire prototype VR client and server applications using Unreal Engine 4; developed system management server application to act as a bridge for interacting with Korean government data sources using Node.js and Express.js; developed separate proof-of-concept VR application prototypes using Unity 2019 and Unreal Engine 4.`,
            `Produced extensive architecture documentation for a Korean government web-based application for developing 3D explorable spaces for another Korean government VR application.`,
        ],
    },
    proof: {
        company: 'Proof Suite, Inc.',
        location: 'Jongno-gu, Seoul, Korea',
        position: 'Lead Developer / SCRUM Master',
        time: 'January 2018 – March 2019',
        tech: 'JavaScript, Node.js, Express.js, Socket.io, MongoDB, React, Redux, HTML5, CSS3, NoSQL, Mocha, AWS EC2',
        tasks: [
            `Performed the following tasks on a web-based crypto and traditional asset financial trading and analysis platform: architected and implemented entire RESTful and socket-based system management server application using Node.js, Express.js, and Socket.io; implemented full payment lifecycle functionality using Stripe payment system; architected, implemented, and administered system database using MongoDB clusters; architected and implemented entire RESTful system data management server application; architected and implemented entire RESTful email server application; planned, designed, and implemented many client application features using React and Redux; managed small team of developers who implemented client application features.`,
            `Performed the following tasks on a web-based cryptocurrency decentralized exchange platform: implemented UX and UI design specifications for the entire client application; managed small team of designers who turned paper prototypes and wireframe concepts into concrete style designs for the entire client application; consulted server development team on best strategies and practices for server application architecture and development.`,
            `Planned, initiated, and oversaw execution of software development process for entire software development team based on the SCRUM methodology.`,
        ],
    },
    currenxie: {
        company: 'Currenxie',
        location: 'Kwun Tong, Hong Kong',
        position: 'Software Engineer',
        time: 'November 2016 – November 2017',
        tech: 'JavaScript, PostgreSQL, HTML5, CSS3, React, Redux, Node.js, Redis, Bootstrap 3, Mocha',
        tasks: [
            `Implemented entire analytics system for a web-based currency exchange application using PostgreSQL.`,
            `Integrated a few third-party exchange rate providers into existing currency exchange quote engine and developed new version based on Redis.`,
            `Performed the following tasks on a web-based currency exchange order application: developed and improved upon various client and server features; developed and expanded unit, integration, and database tests; integrated server logic with several third-party remittances processing APIs.`,
        ],
    },
    ironfly: {
        company: 'Ironfly Technologies',
        location: 'Sheung Wan, Hong Kong',
        position: 'Software Engineer',
        time: 'September 2014 – November 2016',
        tech: 'JavaScript, HTML5, CSS3, NoSQL, MySQL, XML, Node.js, Meteor.js, MongoDB, PhoneGap, Bootstrap 3, jQuery, Mocha, Should.js',
        tasks: [
            `Planned, designed, and developed a completely new web-based configuration management application.`,
            `Performed the following tasks on a web-based financial portfolio management application: developed and improved upon various client and server features, including integration with the configuration management application; developed a complete unit test suite; and implemented a mobile version of the previously desktop-only application.`,
        ],
    },
    tapevents: {
        company: 'TAPevents',
        location: 'Sheung Wan, Hong Kong',
        position: 'Software Engineer',
        time: 'October 2013 – August 2014',
        tech: 'JavaScript, HTML5, CSS3, NoSQL, Meteor.js, MongoDB, Bootstrap 3, jQuery',
        tasks: [
            `Developed new features for a web-based silent auction application.`,
            `Developed a number of Meteor.js packages intended for general use.`,
            `Performed the following tasks on a web-based event hosting application: improved upon existing client and server features; researched and implemented styling for the application to make it interoperable with over forty different mobile devices; altered styling for use at events and demos for over twenty different high-profile clients; and developed complete system usage and configuration documentation.`,
        ],
    },
    blue7: {
        company: 'Blue7 Solutions LLC',
        location: 'Wisconsin, USA',
        position: 'Systems Engineer',
        time: 'January 2012 – October 2012',
        tech: 'C#, ASP.NET, VB.NET, T-SQL, CAML, .NET 3.0/3.5, WCF',
        tasks: [
            `While working on an electronic travel document customization and processing service application the following tasks were performed: designed half of the database that holds all associated data; implemented portions of the data access layer that retrieves all necessary data from a number of other services; researched, architected, and implemented the dynamic HTML generation engine for the application; and both created the design for and wrote various portions of the business logic layer that transforms and feeds retrieved data into that dynamic HTML generation engine.`,
            `Developed and improved upon a number of web pages used as part of a larger internal data configuration creation and maintenance web site.`,
        ],
    },
    trisept: {
        company: 'Trisept Solutions',
        location: 'Wisconsin, USA',
        position: 'Software Developer',
        time: 'October 2010 – January 2012',
        tech: 'C#, ASP.NET, VB.NET, T-SQL, CAML, .NET 3.0/3.5, WCF',
        tasks: [
            `Took ownership of a multipurpose content service and significantly improved upon its operational efficiency and overall functionality while communicating with other teams, external customers, and team members.`,
            `Created a Google-like search engine service that retrieves search results from Microsoft SharePoint by cross-referencing data results and returning them in a similar fashion.`,
            `Gathered requirements for and developed a data service that retrieves lists of keywords for a search engine based over Microsoft SharePoint.`,
            `Facilitated a number of enhancements to a booking engine comprised of dozens of services based over a multi-technology backend code base.`,
        ],
    },
    thinair: {
        company: 'Thin Air Software LLC',
        location: 'Wisconsin, USA',
        position: 'Junior Software Engineer',
        time: 'July 2008 – March 2009',
        tech: 'C#, ASP.NET, XML, T-SQL, .NET 2.0/3.0/3.5',
        tasks: [
            `Adhered to the SCRUM development process while working simultaneously on three separate software applications with three different development teams.`,
            `Corrected user interface and data processing code defects, communicated project ideas directly to clients on a daily basis, and created test documents summarizing various test results for a desktop-based emissions monitoring and reporting application.`,
            `Significantly expanded user interface capabilities, extensively improved data processing and database interface code, and communicated project ideas directly to clients on a daily basis while working on a web-based work management application.`,
            `Corrected user interface and data processing code defects and extended the unit test suite for a web-based professional networking application.`,
        ],
    },
    rockwell: {
        company: 'Rockwell Automation',
        location: 'Wisconsin, USA',
        position: 'Student Associate Software Development Intern',
        time: 'April 2007 – July 2008',
        tech: 'C#, C++, .NET 2.0',
        tasks: [
            `Resolved defects for and improved upon an existing desktop -based asset management application.`,
            `Planned, designed, developed, and tested an entire desktop-based archiving application that integrated with the existing desktop application.`,
            `Presented project information and progress to supervisors and team members on a regular basis.`,
            `Improved personal software development skills continuously through the use of company-sanctioned tools and resources.`,
        ],
    },
}