<template>
    <v-btn
        class="white--text ma-4"
        color="light-blue darken-4"
        to="/reading"
    >
        <v-icon class="blue--text text--lighten-3">{{ icons.bookOpenVariant }}</v-icon>
        <span class="ml-2">View Reading List</span>
    </v-btn>
</template>

<script>
import { mdiBookOpenVariant } from '@mdi/js'

export default {
    name: 'ReadingListButton',
    data() {
        return {
            icons: {
                bookOpenVariant: mdiBookOpenVariant,
            },
        }
    },
}
</script>

<style scoped>

</style>