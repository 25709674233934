<template>
    <v-container
        class="blue lighten-3"
        fill-height
        fluid
        style="background: url('https://images.unsplash.com/photo-1484387436194-cf7cb70800ce'); background-size: cover; height: 100%; width: 100%;"
    >
        <v-layout
            align-center
            column
            justify-center
        >
            <div class="text-h1 font-weight-black white--text text-xs-center hidden-sm-and-down">Welcome to</div>
            <div class="text-h2 font-weight-black white--text text-xs-center hidden-md-and-up hidden-xs-only">Welcome to</div>
            <div class="text-h3 font-weight-black white--text text-xs-center hidden-sm-and-up">Welcome to</div>

            <div class="text-h2 font-weight-black white--text text-xs-center hidden-sm-and-down">keithvictordawson.com</div>
            <div class="text-h3 font-weight-black white--text text-xs-center hidden-md-and-up hidden-xs-only">keithvictordawson.com</div>
            <div class="text-h4 font-weight-black white--text text-xs-center hidden-sm-and-up">keithvictordawson.com</div>
            <!-- Display only on extra small screens -->
            <div class="d-flex d-sm-none flex-column">
                <hero-resume-button />
                <hero-blog-button />
                <hero-reading-button />
            </div>
            <!-- Display on small and larger screens -->
            <div class="d-none d-sm-flex">
                <hero-resume-button />
                <hero-blog-button />
                <hero-reading-button />
            </div>
        </v-layout>
    </v-container>
</template>

<script>
// Components
import HeroBlogButton from './HeroBlogButton'
import HeroReadingButton from './HeroReadingButton'
import HeroResumeButton from './HeroResumeButton'

export default {
    name: 'HomeHero',
    components: {
        HeroBlogButton,
        HeroReadingButton,
        HeroResumeButton,
    },
}
</script>

<style scoped>

</style>