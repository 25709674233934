export default {
    id: '2020-01-05',
    year: 2020,
    month: 1,
    date: 5,
    title: `Developer Story: A Software Engineer in UX Designer Clothing`,
    blog_url: `https://keithvictordawson.medium.com/developer-story-a-software-engineer-in-ux-designer-clothing-94c02ce968a`,
    image_url: `https://images.unsplash.com/photo-1579572331145-5e53b299c64e`,
    image_caption: `Photo by <a class="text--primary" href="https://unsplash.com/@philipp_lansing">Philipp Lansing</a> on <a class="text--primary" href="https://unsplash.com/s/photos/hoodie">Unsplash</a>`,
    contents: [
        {
            type: 'text',
            content: [
                {
                    type: 'text',
                    content: `As I mentioned in my previous `,
                },
                {
                    type: 'internal_link',
                    year: 2019,
                    month: 12,
                    date: 31,
                    content: `entry`,
                },
                {
                    type: 'text',
                    content: `, while I spent my personal project development efforts in 2019 focused purely on server-side development, now that 2020 is here I will be taking a step back and widening the scope of my development efforts. In addition to continuing work on server-side development, I will also begin working on client-side development for my personal project. After spending the majority of not only my personal project development efforts but also my professional development efforts in 2019 focused on server-side development, I can feel that I really need to dust off the visually-oriented side of my brain. In order to aid my efforts in this realm one of the things that I intend to do this year is refresh my perspective on and understanding of UX design.`,
                },
            ],
        },
        {
            type: 'text',
            content: `During the course of my software engineering career I did not really have much of an understanding of the importance of design or how much of an impact that it could possibly have on my work. In fact, the only real design that I was aware of in terms of software, aside from actual software design which I had gained a deep understanding of during my time at university, was graphic design and UI design. I could not really say what separated those two design disciplines from each other, and I certainly had no idea about UX design or even the fact that it existed as a profession. The existence of these various branches of design did not really dawn on me until some time after I had arrived in Hong Kong several years ago.`,
        },
        {
            type: 'text',
            content: `Fortunately, while living and working in Hong Kong, I made the decision to take a course on UX design. Other than myself, the students in the course were all either UI designers looking to expand their design skills or business people who wanted to gain a better understanding of the flaws in their products and learn how to change those products to better suit the needs of their users. I was the only software engineer in the entire class, so I was initially concerned that the course content would be a difficult fit for my background considering that I come from a highly technical field with no real emphasis on the artistic aspects of software development. Fortunately, the course instructor was amazing and he really helped me understand that an artistic background is not necessary in order to develop a connection to the UX design process and methodology. In fact, after completing the three-month course and really considering all of the things that I had learned, I realized that there are many aspects of UX design that require every bit of scientific thinking that software engineering requires. I found that the UX design concepts that I connected with most were those that were more deeply rooted in logical analysis.`,
        },
        {
            type: 'text',
            content: `In discovering the overlap between software engineering and UX design, I determined that having a good understanding of UX design can help software development efforts not only on the client but also all the way down to the server and database. As a result, throughout the time since I took that UX design course in Hong Kong I have sought to read a variety of books that discuss different aspects of UX design. I do not ever want UX design consideration to exit my brain because incorporating it into my development thought process will ensure that it continues to inform the solutions that I develop for all of the software problems that I encounter.`,
        },
        {
            type: 'text',
            content: `In addition to sharing my experiences while developing my personal project, another one of the things that I want to discuss are my thoughts on UX design. Some of these discussions will be about random UX design topics as they are brought to the forefront of my mind, while others will be about some of the UX design books that I have read or will be reading later this year while gearing my brain up for client-side development. The purpose of all of these discussions will not only be to simply share my thoughts on various UX design topics, but also to help me more thoroughly consider the topics in my own brain as I articulate those thoughts and reactions. In engaging in these discussions, my hope is that I will be able to gain some useful insights on these topics and share those insights with all of you. Please stay tuned for these discussions and more of my software development experiences as I continue further development on my personal project.`,
        },
    ],
}